import moment from 'moment'
import React from 'react'
import ReviewStar from '../../images/reviewstar.svg'


function Feedbacks({search, isLoading, heading, data, handleFeedback }) {
    return (
        <>
            <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
                <h1 className='text-2xl font-medium text-gray-600'>Latest Feedback</h1>
                <div className='relative min-h-[20dvh]'>
                    {isLoading
                        ?
                        <>
                            <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                </div>
                            </div>
                        </>
                        :
                        Object.keys(data)?.filter(item => item?.toLowerCase().includes(search) && item !== 'null')?.length ?
                            <div className='grid grid-cols-2 2xl:grid-cols-3 gap-6 flex-wrap p-5'>
                                {
                                    Object.keys(data)?.filter(item => item?.toLowerCase().includes(search) && item !== 'null')?.map((item, i) => {
                                        if (item !== 'null') {

                                            let projects = []
                                            let companies = []
                                            let company = {
                                                updatedAt: new Date(1622355)
                                            }
                                            let totalRating = 0;
                                            let newReviewCount = 0;
                                            let groupedProject = []
                                            let list = data[item]
                                            for (let i = 0; i < list.length; i++) {
                                                company.companyIcon = list[i]?.companyIcon;
                                                if (list[i].projectId) {
                                                    projects.push(list[i])
                                                    if (!moment(list[i]?.updatedAt).diff(moment(), 'days')) {
                                                        newReviewCount++;
                                                        if (!groupedProject.includes(item?.projectId)) {
                                                            groupedProject.push(item?.projectId)
                                                        }
                                                    }
                                                }
                                                else {
                                                    companies.push(list[i])
                                                    totalRating += isNaN(list[i]?.rating) ? 0 : Number(list[i]?.rating)
                                                }
                                                if (new Date(list[i]?.updatedAt) > new Date(company?.updatedAt)) {
                                                    company.updatedAt = list[i]?.updatedAt
                                                }
                                            }

                                            company.totalRating = totalRating
                                            company.newReviewCount = newReviewCount;
                                            company.groupedProject = groupedProject;

                                            return <Feedback key={i} handleFeedback={handleFeedback} item={data[item]} companyName={item} projects={projects} companies={companies} company={company} />
                                        }
                                        else return <></>
                                    })
                                }
                            </div>
                            :
                            <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>No {heading} Feedbacks</h1>
                    }

                </div>
            </div>
        </>
    )
}

export default Feedbacks

const Feedback = ({ item, companyName, projects, companies, company, handleFeedback }) => {
    return (<>
        <div className='shadow-lg rounded-lg max-w-lg p-5 relative'>
            <span className='text-sm font-medium text-gray-600 absolute top-2 right-5'>{moment(company?.updatedAt).format('DD-MM-YY')}</span>
            <div className='flex items-start gap-4'>
                <div className='w-16 h-16 rounded flex-shrink-0 overflow-hidden'>
                    <img src={item[0]?.companyIcon || "https://images.unsplash.com/photo-1495615080073-6b89c9839ce0"} className='w-full h-full object-cover' alt="" />
                </div>
                <div>
                    <h1 className='text-xl font-medium'>{companyName !== 'null' ? companyName : "unKnown"}</h1>
                    <div className='text-sm text-purple-900 flex items-center gap-1'><img className='w-5 h-5' src={ReviewStar} alt='' /><span className='text-xl font-medium'>{company?.totalRating ? Number((company?.totalRating || 0) / companies?.length).toFixed(1) : 0}</span> Client company rating</div>
                    <div className='py-2 flex items-center gap-y-2 gap-x-3 flex-wrap'>
                        <span className='min-w-max'>{projects?.length || 0} Project Reviews</span>
                        <span className='min-w-max'>{companies?.length || 0} Company Reviews</span>
                    </div>
                    <span>{company?.newReviewCount} new reviews across {company?.groupedProject?.length} projects</span>
                </div>
            </div>
            <div className='flex justify-end pt-8'>
                <button className='py-2 px-6 rounded-lg shadow-lg bg-lightgreen text-green-800 text-sm' onClick={() => {
                    handleFeedback({ companyName, projects, companies })
                }}>View Reviews</button>
            </div>
        </div>
    </>)
}