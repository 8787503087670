import React from 'react'
import RadarChart from '../Charts/RadarChart';
import fillRating from '../../images/reviewstar.svg';
import moment from 'moment';
import { useGetReviewDetails } from '../../hooks';

let vendorArr = [
    { typeOfReview: 'Requirements', rating: 0 },
    { typeOfReview: 'Processes', rating: 0 },
    { typeOfReview: 'Financial', rating: 0 },
    { typeOfReview: 'Stake holder management', rating: 0 },
    { typeOfReview: 'Communication', rating: 0 },
    { typeOfReview: 'Quality check', rating: 0 }
]
let clientArr = [
    { typeOfReview: 'Delivery Mng.', rating: 0 },
    { typeOfReview: 'Contract Mng.', rating: 0 },
    { typeOfReview: 'Vendor Mng.', rating: 0 },
    { typeOfReview: 'Stakeholder Mng.', rating: 0 },
    { typeOfReview: 'Cybersec. Mng.', rating: 0 },
    { typeOfReview: 'Resource Mng.', rating: 0 }
]

function ProjectReviewDetail({ reviewData, vendors, setActive, type }) {

    const closeReviewDetail = () => {
        setActive(false)
    }

    let graphRating = type == 2 ? reviewData?.clientGraphRating : reviewData?.vendorGraphRating

    const { mutate } = useGetReviewDetails();

    const getReviewDetailsById = (id, type) => {
        mutate(id)
    }

    return (
        <div className={`fixed top-0 left-0 right-0 w-full min-h-screen bg-black bg-opacity-40 flex items-center justify-center z-[100] p-4 overflow-hidden md:inset-0 ${true ? '' : 'hidden'}`}>
            <div className="relative w-full max-w-6xl">
                <div className="relative bg-white rounded-lg shadow h-[700px]">
                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => closeReviewDetail()}>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>

                    <div className="px-10 py-2 flex flex-col gap-6 w-full">
                        <div className='w-full h-20 relative max-w-6xl mx-auto mt-6'>
                            <div className='flex items-center justify-center'>
                                <div className='relative max-w-max mx-auto'>
                                    <div className='bg-white rounded border-2 border-bermuda py-2 px-8 max-w-max z-20 relative'>
                                        <span className='text-2xl font-bold'>{type == 2 ? 'All Reviews' : 'Project Vendors'}</span>
                                    </div>
                                    <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                    </div>
                                    <div className=' bg-lightblue rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                    </div>
                                </div>
                            </div>
                            <>
                                {type == 2 && <h1 className='text-purple-900 text-right -mb-4'>{reviewData?.clientRating?.length} review(s) were provided</h1>}
                                <div className='flex-grow flex items-start justify-between gap-4 pt-14'>
                                    <div className='grid place-items-center'>
                                        <h1 className='text-xl font-medium'>Avg Factor Rating</h1>
                                        <RadarChart width={450} height={400} draggable={false} heading={'Factor Rating'} initialData={graphRating?.length ? graphRating : type == 2 ? clientArr : vendorArr} graphData={graphRating?.length ? graphRating : type == 2 ? clientArr : vendorArr} backgroundColor={type == 2 ? 'rgba(239 ,185 ,255 ,0.6)' : 'rgba(184, 227, 236, 0.6)'} borderColor={type == 2 ? 'rgb(222, 22, 255)' : 'rgb(20 ,201 ,201)'} />
                                    </div>

                                    {
                                        type == 2 ?
                                            <div className='flex-grow border-2 border-bermuda rounded relative p-5 min-h-[420px] overflow-y-auto max-h-[420px]'>
                                                <div className='flex-grow flex items-start justify-center gap-8 flex-wrap'>
                                                    {
                                                        reviewData?.clientRating?.length ?
                                                            reviewData?.clientRating?.map(item => (
                                                                <div key={item.id} className='flex flex-col items-center justify-center w-full max-w-[240px] border-2 rounded-lg p-1.5 border-bermuda'>
                                                                    <h1 className='text-lg w-full'>{item?.companyRole}</h1>
                                                                    <div className='flex items-center gap-2 pb-3'>
                                                                        <img src={fillRating} className='w-6' alt="" />
                                                                        <span className='text-xl text-purple-900'>{Number(item?.rating).toFixed(1)}</span>
                                                                    </div>
                                                                    <div className='flex items-baseline justify-between w-full gap-4'>
                                                                        <h1 className='text-xs'>{moment(item?.createdAt).format('DD-MMM-YYYY')}</h1>
                                                                        <div className='rounded-lg bg-bermuda max-w-max'>
                                                                            <button onClick={() => getReviewDetailsById(item.id)} className='rounded-lg bg-lightviolet font-medium text-xs py-1.5 px-2 transform -translate-x-1 -translate-y-1'>Detailed Review</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            <h1 className='text-2xl text-gray-500'>No Review Yet</h1>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className='flex-grow mx-auto max-w-sm flex flex-col overflow-y-auto max-h-[420px]'>
                                                {
                                                    vendors.map((vendor, i) => (
                                                        <div className='flex items-center justify-between gap-4 py-3 border-b border-black'>
                                                            <h1 className='text-lg'>
                                                                {i + 1}. {vendor?.companyName}
                                                            </h1>
                                                            <div className='bg-bermuda max-w-max rounded'>
                                                                <button className='font-medium py-1 px-6 rounded bg-lightblue border-2 border-bermuda min-w-max cursor-pointer transform -translate-x-1 -translate-y-1' onClick={() => window.open(`${window.location.origin}/company/${vendor?.companyName}`, '_blank')}>View Firm</button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div>
                            </>
                            <div className='flex flex-row gap-6 justify-center py-4 items-center'>
                                <div className=" z-[100] relative rounded bg-bermuda h-10">
                                    <div className="z-50 bg-bermuda rounded">
                                        <button className={`border-2 z-[60] py-2 px-8 bg-pinkshade transform -translate-x-1 -translate-y-1 border-bermuda rounded`}
                                            onClick={() => closeReviewDetail()}>
                                            <span className='font-medium'>
                                                Close
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectReviewDetail