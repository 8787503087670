import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAddVendorAndClient } from '../../hooks'
import { selectCompanyList } from '../../slices/companySlice'

function AddVendorAndClient({ adminInfo, heading, popUp, setPopUp }) {
    const companies = useSelector(selectCompanyList)

    const [companyName, setCompanyName] = useState('');
    const [data, setData] = useState({
        companyName: '',
        how: '',
        worked: 'no'
    })

    const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value })

    const { isLoading, mutate: sendRequest } = useAddVendorAndClient(heading, () => {
        setData({
            companyName: '',
            how: '',
            worked: 'no'
        });
        setPopUp(false)
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            adminCompanyId: adminInfo?.companyId,
            companyName: data.companyName,
            knowThisFirm: data.how
        }
        sendRequest(payload)
    }

    return (
        <>
            <div className='fixed inset-0 min-h-screen overflow-hidden bg-transparent grid place-items-center z-50'>
                <div className='fixed inset-0 min-h-screen overflow-hidden bg-black bg-opacity-50 cursor-pointer' onClick={() => setPopUp(false)}>
                </div>
                <form onSubmit={handleSubmit} className='bg-white rounded-lg p-10 z-10 w-full max-w-4xl relative overflow-hidden min-h-[600px]'>
                    <svg onClick={() => setPopUp(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-800 cursor-pointer absolute top-5 right-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1 className='text-4xl font-semibold text-green-800 capitalize'>Add {heading}</h1>
                    <p className='max-w-xl my-2'>The request will be sent to the selected firm, once accepted, they will be part of your {heading} list </p>

                    <div className='grid grid-cols-5 place-items-end gap-4 py-4'>
                        <label className='text-lg font-medium text-right col-span-2'>Company Name</label>
                        <div className='w-full col-span-3 relative'>
                            <input type="text" name='name' value={companyName || data.companyName} onChange={(e) => { setData({ ...data, companyName: '' }); setCompanyName(e.target.value) }} className='bg-white w-full outline-none rounded p-2 shadow border-2 border-gray-100' />
                            {(data.companyName || companyName) &&
                                <>
                                    <svg onClick={() => { setData({ ...data, companyName: '' }); setCompanyName('') }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-400 cursor-pointer absolute top-2.5 right-2.5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>

                                    {companyName && <div className='bg-white rounded-lg shadow-lg absolute top-12 left-0 w-full'>
                                        {
                                            companies.filter(item => (item?.companyName != adminInfo?.companyName && item.companyName?.toLowerCase().includes(companyName?.toLowerCase()))).map((item, i) => <div key={item.id} className='flex items-center gap-2 py-2 px-4 cursor-pointer hover:bg-gray-50' onClick={() => { setCompanyName(''); setData({ ...data, companyName: item.companyName }) }}><img src={item?.companyIcon} className='w-8 h-8 rounded-full object-cover' alt="" /> <div className='flex-grow'><h1>{item.companyName}</h1> <p className='text-xs italic text-gray-400 text-right'>{item?.sectorOfIndustry}</p></div></div>)
                                        }
                                    </div>}
                                </>
                            }
                        </div>
                    </div>
                    <div className='grid grid-cols-5 place-items-end gap-4 py-4'>
                        <label className='text-lg font-medium text-right col-span-2'>How do you know this firm?</label>
                        <div className='col-span-3 w-full'>
                            <select type="text" name='how' value={data.how} onChange={handleChange} className='bg-white w-full outline-none rounded p-2 shadow border-2 border-gray-100 cursor-pointer'>
                                <option value="" disabled selected>Choose Option</option>
                                <option value="from corner qube">From Corner Qube</option>
                                <option value="from advertisement">From advertisement</option>
                                <option value="know contact">Know contact</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                    </div>
                    <div className='grid grid-cols-5 place-items-end gap-4 py-4'>
                        <label className='text-lg font-medium text-right col-span-2'>Have you worked with this firm before?</label>
                        <div className='col-span-3 w-full'>
                            <select type="text" name='worked' value={data.worked} onChange={handleChange} className='bg-white w-full outline-none rounded p-2 shadow border-2 border-gray-100 cursor-pointer'>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className='flex items-center justify-end py-6 z-10 relative'>
                            <button type='submit' disabled={!data.companyName || !data.how || !data.worked || isLoading} className='bg-lightgreen disabled:bg-gray-300 text-green-900 rounded-lg shadow-xl font-medium text-lg py-2 px-6'>
                                Send Request
                            </button>
                        </div>
                    </div>


                    <div className='absolute -bottom-48 -left-48'>
                        <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#BDF0BC" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#BDF0BC" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#BDF0BC" />
                        </svg>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddVendorAndClient