import moment from 'moment'
import React, { useState } from 'react'
import { useUpdateRequest } from '../../hooks'
import { openLink } from '../../services/constant'
import ReviewPopUp from '../Reusable/ReviewPopUp'
import WarnPopUp from '../Reusable/WarnPopUp'


function Requests({ search, adminInfo, heading, reqType, isLoading, data, from, actionType }) {

    const [addReview, setAddReview] = useState(false);
    const [popUp, setPopUp] = useState(false)

    const { isLoading: updating, mutate: updateRequest } = useUpdateRequest()

    const closePopUp = () => {
        setAddReview(false)
    }

    const reviewNow = (item) => {
        let me = item?.vendors?.find(item => item?.vendorId == adminInfo?.companyId)
        setAddReview({ companyId: item?.adminAddedCompanyId, companyName: item?.adminCompanyName, projectId: item?.projectId, heading: item?.adminAddedCompanyId == adminInfo?.companyId ? 'client' : 'vendor', id: item?.id, initialOfferingTags: me?.tags, projectDuration: item?.projectDuration })
    }

    const validateFilter = (item) => {
        let searchFilter = reqType != 'feedbacks' ? item.companyName?.toLowerCase().includes(search) : item.adminCompanyName?.toLowerCase().includes(search);
        let actionFilter = false

        if (actionType == 'rejected') {
            if (item.status == 2) {
                actionFilter = true;
            }
        }
        else if (actionType == 'expired') {
            if (item.expired) {
                actionFilter = true;
            }
        }
        else {
            if (item.status == 0)
                actionFilter = true
        }

        return searchFilter && actionFilter
    }

    return (
        <>
            <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
                <h1 className='text-2xl font-medium text-gray-600 capitalize'>{reqType}</h1>
                {reqType == 'clients' && <p className='text-gray-400 text-sm'>Accepted firms will be part of your "vendors list"</p>}
                <div className='relative min-h-[20dvh]'>
                    {isLoading || !data
                        ?
                        <>
                            <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                    }
                    <table className='w-full my-2' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                        <thead className='text-lg text-gray-300 border-b sticky top-36 bg-white'>
                            {reqType == 'feedbacks' ?
                                <tr className=''>
                                    <td className='pt-4 pb-2 border-b'>SNo.</td>
                                    <td className='pt-4 pb-2 border-b'>Company Name</td>
                                    <td className='pt-4 pb-2 border-b'>Project</td>
                                    {!from && <td className='pt-4 pb-2 border-b'>Type</td>}
                                    <td className='pt-4 pb-2 border-b'>Note</td>
                                    <td className='pt-4 pb-2 border-b'><span className='opacity-0'>Action</span></td>
                                </tr>
                                :
                                <tr className=''>
                                    <td className='pt-4 pb-2 border-b'>SNo.</td>
                                    <td className='pt-4 pb-2 border-b'>Company Name</td>
                                    {!from && <td className='pt-4 pb-2 border-b'>Type</td>}
                                    <td className='pt-4 pb-2 border-b'></td>
                                    <td className='pt-4 pb-2 border-b'></td>
                                    <td className='pt-4 pb-2 border-b'></td>
                                    <td className='pt-4 pb-2 border-b'></td>
                                </tr>
                            }
                        </thead>
                        <>
                            {
                                (!isLoading && !data?.filter(item => validateFilter(item))?.length) ?
                                    <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>{reqType} Request is empty</h1>
                                    : <></>
                            }
                            <tbody>
                                {
                                    reqType == 'feedbacks' ?
                                        <>
                                            {
                                                data?.filter(item => validateFilter(item))?.map((item, i) => <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td className='leading-5'><p>{item.adminCompanyName}<br /><span className='text-xs text-gray-600'>{!from && 'Requested '}{moment(item.createdAt).fromNow()}</span></p></td>
                                                    <td><h1 className='min-w-max'>{item.projectName || <span className='italic'>Overall</span>}</h1></td>
                                                    {!from && <td><h1 className='min-w-max capitalize'>{item.requestType}</h1></td>}
                                                    <td className='min-w-[200px]'>{item.note}</td>
                                                    <td className={actionType ? '' : 'max-w-[80px]'}>
                                                        {
                                                            actionType
                                                                ?
                                                                <div className='flex gap-4'>
                                                                    <div className='w-24 text-center text-[#9E7E18]'>
                                                                        <p>Invited</p>
                                                                        <h1 className='text-xl'>{moment(item.createdAt).format('DD/MM/YY')}</h1>
                                                                    </div>
                                                                    {
                                                                        item?.expired ?
                                                                            <div className='w-24 text-center text-[#8F001C]'>
                                                                                <p>Expired</p>
                                                                                <h1 className='text-xl'>{moment(item.updatedAt).format('DD/MM/YY')}</h1>
                                                                            </div>
                                                                            :
                                                                            <div className='w-24 text-center text-[#8F001C]'>
                                                                                <p>Rejected</p>
                                                                                <h1 className='text-xl'>{moment(item.updatedAt).format('DD/MM/YY')}</h1>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <button disabled={updating || actionType} onClick={() => reviewNow(item)} className='py-2 px-4 text-sm font-medium tracking-wide outline-none rounded w-32 flex items-center justify-center gap-1 bg-lightblue disabled:bg-gray-300'>
                                                                    <span>Review Now</span>
                                                                </button>
                                                        }
                                                    </td>
                                                </tr>)
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                data?.filter(item => validateFilter(item))?.map((item, i) => <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td className='min-w-[200px] leading-5'><p>{item.companyName}<br /><span className='text-xs text-gray-600'>{moment(item.createdAt).fromNow()}</span></p></td>
                                                    {!from && <td><h1 className='min-w-max capitalize'>{item.requestType}</h1></td>}
                                                    <td><h1 className='min-w-max'>Requested {moment(item.updatedAt).fromNow()}</h1></td>
                                                    <td>
                                                        <button onClick={() => openLink(`${window.location.origin}/company/${item.companyName}`)} className='py-2 px-4 outline-none text-sm font-medium tracking-wide rounded w-32 flex items-center justify-center gap-1 bg-lightblue'><span>Visit Page</span></button>
                                                    </td>

                                                    {
                                                        actionType
                                                            ?
                                                            <>
                                                                <td className=' text-center text-[#9E7E18]'>
                                                                    <p>Invited</p>
                                                                    <h1 className='text-xl'>{moment(item.createdAt).format('DD/MM/YY')}</h1>
                                                                </td>
                                                                {
                                                                    item?.expired ?
                                                                        <td className='text-center text-[#8F001C]'>
                                                                            <p>Expired</p>
                                                                            <h1 className='text-xl'>{moment(item.updatedAt).format('DD/MM/YY')}</h1>
                                                                        </td>
                                                                        :
                                                                        <td className='text-center text-[#8F001C]'>
                                                                            <p>Rejected</p>
                                                                            <h1 className='text-xl'>{moment(item.updatedAt).format('DD/MM/YY')}</h1>
                                                                        </td>
                                                                }
                                                            </>
                                                            :
                                                            <>

                                                                <td>
                                                                    <button disabled={updating || actionType} onClick={() => updateRequest({ status: 1, id: item.id, companyId: item.companyId, type: item.requestType == 'client' ? 'vendor' : 'client' })} className='py-2 px-4 text-sm font-medium tracking-wide outline-none rounded w-32 flex items-center justify-center gap-1 bg-lightgreen disabled:bg-gray-300'>
                                                                        <span>
                                                                            <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.23294 11C5.06814 11 4.88959 10.9669 4.6973 10.9008C4.50501 10.8345 4.34019 10.7286 4.20284 10.583L0.247211 6.39359C0.178534 6.31416 0.120166 6.19833 0.0721083 6.04612C0.024036 5.8939 0 5.74493 0 5.59922C0 5.24192 0.123612 4.94412 0.370837 4.70583C0.618062 4.46754 0.920223 4.3484 1.27732 4.3484C1.49708 4.3484 1.68937 4.39143 1.85419 4.47751C2.01901 4.56344 2.15635 4.66598 2.26623 4.78513L5.19174 7.82313L10.6101 0.556078C10.8848 0.185359 11.2488 0 11.702 0C12.0454 0 12.3476 0.119144 12.6086 0.357435C12.8695 0.595725 13 0.886949 13 1.2311C13 1.33696 12.976 1.45611 12.9279 1.58854C12.8798 1.72083 12.8077 1.85319 12.7116 1.98562L6.32487 10.5433C6.09138 10.8478 5.72741 11 5.23294 11Z" fill="black" />
                                                                            </svg>
                                                                        </span>
                                                                        <span>Accept</span>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button disabled={updating || actionType} onClick={() => setPopUp({ status: 2, id: item.id, companyId: item.companyId, type: item.requestType == 'client' ? 'vendor' : 'client' })} className='py-2 px-4 text-sm font-medium tracking-wide rounded w-32 flex items-center justify-center gap-1 bg-lightpink disabled:bg-gray-300 outline-none'>
                                                                        <span>
                                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.07508 11C0.777907 11 0.524429 10.8928 0.314652 10.6783C0.104884 10.464 0 10.2081 0 9.91056C0 9.61297 0.104884 9.35915 0.314652 9.1491L3.95945 5.49999L0.314652 1.86395C0.104884 1.64518 0 1.3914 0 1.10262C0 0.796335 0.104884 0.540368 0.314652 0.334721C0.524429 0.129075 0.777907 0.0262511 1.07508 0.0262511C1.36352 0.0262511 1.61699 0.126888 1.8355 0.328162L5.49341 3.97732L9.17749 0.315029C9.38729 0.105009 9.63642 0 9.92489 0C10.222 0 10.4755 0.105009 10.6853 0.315029C10.8951 0.525057 11 0.778838 11 1.07637C11 1.3739 10.8951 1.63206 10.6853 1.85083L7.02741 5.49999L10.6722 9.13606C10.8819 9.32855 10.9868 9.58671 10.9868 9.91056C10.9868 10.2081 10.8819 10.464 10.6722 10.6783C10.4624 10.8928 10.2046 11 9.89871 11C9.58402 11 9.32616 10.895 9.12514 10.6849L5.49341 7.03582L1.87484 10.6849C1.65633 10.895 1.38974 11 1.07508 11Z" fill="black" />
                                                                            </svg>
                                                                        </span>
                                                                        <span>Reject</span>
                                                                    </button>
                                                                </td>
                                                            </>
                                                    }
                                                </tr>)
                                            }
                                        </>
                                }
                            </tbody>
                        </>
                    </table>
                </div>
            </div>
            {
                addReview ?
                    <ReviewPopUp reviewerCompany={adminInfo?.companyName} adminId={adminInfo?.uuid} companyId={addReview?.companyId} companyName={addReview?.companyName} initial={1} projectId={addReview?.projectId} reviewType={addReview?.projectId ? 'Project' : 'company'} heading={addReview?.requestType || 'client'} closePopUp={closePopUp} requestId={addReview?.id} initialOfferingTags={addReview?.initialOfferingTags} projectDuration={addReview?.projectDuration} />
                    :
                    <></>
            }

            {popUp ? <WarnPopUp heading={`Are you sure to reject the request?`} onCancel={() => setPopUp(false)} disabled={updating} onOk={() => { updateRequest(popUp); setPopUp(false) }} /> : <></>}

        </>
    )
}

export default Requests