import React, { useState } from 'react';
import {Link } from 'react-router-dom';
import signin from '../../images/abstract BLOCK-04.png';
import logo from '../../images/corner-cube-logo.svg';
import rightarrow from '../../images/rightarrow.svg';

export default function Sector() {
    const [industryType, setIndustryType] = useState("")
    const [projectSize, setProjectSize] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState("")
    const [title, setTitle] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    return (
        <div className="h-screen w-[95%]">
            <img src={logo} alt="Logo" className="h-14 w-56" />
            <div className='overflow-x-hidden'><div className='absolute -right-4 -top-9 w-32 h-32 rounded-full bg-lightgreen'></div></div>
            <br /><br /><br />
            <div className='px-80'>
                <div className="flex items-center">
                    <div className="flex items-center text-bermuda relative">
                        <div className=" transition duration-500 ease-in-out">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#bdf0bc" />
                            </svg>
                            {/* </div> */}
                        </div>
                        <div className="absolute top-0 -ml-10 text-center mt-12 w-32 text-sm font-semibold text-bermuda">Industry & size</div>
                    </div>
                    <div className="-ml-1 flex-auto border-t-2 transition duration-500 ease-in-out border-gray-400"></div>
                    <div className="flex items-center text-white relative">
                        <div className="rounded-full transition duration-500 ease-in-out h-4 w-4 py-1 border-2 bg-gray-400 border-gray-400">

                        </div>
                        <div className="absolute top-0 -ml-14 flex flex-row text-center mt-10 w-32 text-sm font-medium text-bermuda">Organisation setup</div>
                    </div>
                    <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-400"></div>
                    <div className="flex items-center text-gray-500 relative">
                        <div className="rounded-full transition duration-500 ease-in-out h-4 w-4 py-1 border-2 bg-gray-400 border-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail ">

                            </svg>
                        </div>
                        <div className="absolute top-0 -ml-14 flex flex-row text-center mt-10 w-32 text-sm font-medium text-bermuda">Upload Certificates</div>
                    </div>
                </div>
            </div><br />
            <div className='mt-16 mx-72'>
                <div className='w-[50%] grid grid-cols-2'>
                    <div className="ml-10 z-50 rounded  w-10/12 h-80 border-2 bg-lightgreen border-bermuda">
                        <div className="z-[60]  rounded w-full h-full bg-gray-50 text-black-500 border-black border-2 px-4 transform -translate-x-3 -translate-y-4">

                            <div className='mt-6'><span className='ml-3 text-xl font-semibold'>Choose Industry type</span>
                                <p className='text-sm font-medium ml-3 pt-3 pb-2'><input type="radio" value={"micro"} className="" onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Micro </p>
                                <p className='text-sm font-medium ml-3 pt-2 pb-2'><input type="radio" value={"small"} onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Small </p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"medium-local"} onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Medium(Local) </p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"medium-global"} onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Medium(Global) </p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"large-local"} onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Large (Local)</p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"large-global"} onChange={(e) => setIndustryType(e.target.value)} name="industryType" aria-describedby="text" /> Large(Global) </p>
                            </div>
                        </div>
                    </div>
                    <div className="ml-10 z-50 rounded  w-10/12 h-80 border-2 bg-lightgreen border-bermuda">
                        <div className="z-[60]  rounded w-full h-full bg-gray-50 text-black-500 border-black border-2 px-4 transform -translate-x-3 -translate-y-4">

                            <div className='mt-6'><span className='ml-3 text-xl font-semibold'>Minimum Project Amount</span>
                                <p className='text-sm font-medium ml-3 pt-3 pb-2'><input type="radio" value={"10,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Below Rs.10,000 </p>
                                <p className='text-sm font-medium ml-3 pt-2 pb-2'><input type="radio" value={"50,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Below Rs.50,000  </p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"1,00,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Below Rs.1,00,000</p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"3,00,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Below Rs.3,00,000 </p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={"5,00,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Below Rs.5,00,000</p>
                                <p className='text-sm ml-3 pt-2 pb-2'><input type="radio" value={">5,00,000"} className="" onChange={(e) => setProjectSize(e.target.value)} name="projectSize" aria-describedby="text" /> Above Rs.5,00,000</p>
                            </div>
                        </div>
                    </div>
                </div><br />
                <div className="mx-8" style={{ display: "flex", float: "right" }}> <div className='w-8 h-8 bg-lightgreen rounded-full'><img src={rightarrow} className="rotate-180" style={{ marginLeft: "5px", marginTop: "5px", width: "20px" }} /></div>
                    <Link to="/sector2">  <div className='ml-2 w-8 h-8 bg-lightgreen rounded-full'><img src={rightarrow} style={{ marginLeft: "5px", marginTop: "5px", width: "20px" }} /></div></Link>
                </div>
            </div>
        </div>

    );
}
