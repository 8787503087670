import React from 'react'

function NotFound() {
  return (

    <section className='bg-[#F4BCC7] w-full h-screen flex flex-col items-center justify-center gap-8'>
      <div className='relative max-w-max mx-auto'>
        <div className='bg-[#282828] rounded border-2 border-bermuda py-2 px-16 max-w-max z-20 relative'>
          <span className='text-4xl text-white'>Wrong corner of the qube!</span>
        </div>
        <div className=' bg-lightgreen rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
        </div>
      </div>

      <img src="/404.svg" className='w-full max-w-xl xl:max-w-2xl object-contain' alt="" />

      <div className='relative max-w-max mx-auto'>
        <div className='bg-lightblue rounded border border-bermuda py-2 px-6 max-w-max z-20 relative'>
          <span className='text-2xl font-semibold'>Back to home</span>
        </div>
        <div className=' bg-cream rounded border border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
        </div>
      </div>
    </section>

  )
}

export default NotFound