import moment from 'moment'
import React, { useState } from 'react'
import WarnPopUp from '../Reusable/WarnPopUp'

function PendingRequests({ heading, isLoading, data, handleRevoke, handleRequestAgain, revoking, actionType, search }) {
    const [popUp, setPopUp] = useState(false)

    const validateFilter = (item) => {
        let searchFilter = item.companyName?.toLowerCase().includes(search);
        let actionFilter = false

        if (actionType == 'rejected') {
            if (item.status == 2) {
                actionFilter = true;
            }
        }
        else if (actionType == 'expired') {
            if (item.expired) {
                actionFilter = true;
            }
        }
        else {
            if (item.status == 0)
                actionFilter = true
        }
        return searchFilter && actionFilter
    }

    return (
        <>
            <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
                <h1 className='text-2xl font-medium text-gray-600 capitalize'>Your {heading}</h1>
                <div className='relative min-h-[20dvh]'>
                    {isLoading || !data
                        ?
                        <>
                            <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                </div>
                            </div>
                        </>
                        :
                        <></>}
                    <table className='w-full my-2' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                        <thead className='text-lg text-gray-300 border-b sticky top-36 bg-white'>
                            <tr className=''>
                                <td className='pt-4 pb-2 border-b text-center'>SNo.</td>
                                <td className='pt-4 pb-2 border-b'>Company Name</td>
                                <td className='pt-4 pb-2 border-b text-center'>Type</td>
                                <td className='pt-4 pb-2 border-b text-center'></td>
                                <td className='pt-4 pb-2 border-b text-center'></td>
                                <td className='pt-4 pb-2 border-b text-center'>Status</td>
                                <td className='pt-4 pb-2 border-b text-center'>Action</td>
                            </tr>
                        </thead>
                        <>
                            {
                                (!isLoading && !data.length) ?
                                    <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>{heading} List is empty</h1>
                                    : <></>
                            }
                            <tbody>
                                {
                                    data?.filter(validateFilter)?.map((item, i) => <tr key={i}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td>{item.companyName}</td>
                                        <td className='text-center'>{item.requestType}</td>
                                        <td className='text-center'>Requested {moment(item?.createdAt).fromNow()}</td>
                                        <td className='text-center'>{(moment(item?.createdAt).add(7, 'days')).diff(moment(), 'days')} Days Remaining</td>
                                        <td className='text-center'>{item?.status == 0 ?
                                            <button className='py-1.5 rounded px-5 text-orange-900'>
                                                Pending
                                            </button>
                                            : item.status == 2 ?
                                                <button className='py-1.5 rounded px-5 text-rose-900'>
                                                    Rejected
                                                </button>
                                                : ''}
                                        </td>

                                        <td className='text-center'>
                                            {
                                                item.status == 2
                                                    ?
                                                    <button disabled={revoking} className='py-1.5 rounded px-5 bg-cream text-orange-800' onClick={() => setPopUp(item)}>
                                                        Request Again
                                                    </button>
                                                    : <button disabled={revoking} className='py-1.5 rounded px-5 bg-lightpink text-rose-900' onClick={() => setPopUp(item)}>
                                                        Revoke
                                                    </button>
                                            }
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </>
                    </table>
                </div>
            </div>
            {popUp ? <WarnPopUp heading={popUp.status == 2 ? 'Are you sure to send request again?' : 'Are you sure to revoke the request?'} onCancel={() => setPopUp(false)} disabled={revoking} onOk={() => { if (popUp.status == 2) { handleRequestAgain(popUp) } else handleRevoke(popUp); setPopUp(false) }} /> : <></>}
        </>
    )
}

export default PendingRequests