import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../../slices/adminSlice';
import { useQuery } from 'react-query';
import { feedbackRequestList, getAllClientStatus, getAllRequests, getAllVendorStatus, getPendingRequests } from '../../services/service';
import * as FeedbackRequests from '../Vendor/Requests';
import PendingRequests from '../Vendor/PendingRequests';
import { useRequestAgain, useRevokeRequest } from '../../hooks';
import * as ProjectRequest from '../Project/Projects';
import ReviewPopUp from '../Reusable/ReviewPopUp';

function Requests() {
    const adminInfo = useSelector(selectAdminDetails)
    const [active, setActive] = useState('feedbacks');
    const [reqType, setReqType] = useState('feedbacks');
    const [actionReqType, setActionReqType] = useState('');

    const [addReview, setAddReview] = useState(false);
    const [search, setSearch] = useState('');
    const [actionType, setActionType] = useState('')

    const { isLoading, data: requestRes } = useQuery('getAllRequests', getAllRequests, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })

    const { isLoading: requestLoading, data: PendingRes } = useQuery('getPendingRequests', getPendingRequests, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, retry: false })

    let { isLoading: revoking, mutate: revoke } = useRevokeRequest('getPendingRequests')
    const handleRevoke = (item) => {
        let payload = {
            docId: item.id,
            requestType: item.requestType,
            type: 'cancel'
        }
        revoke(payload)
    }
    let { isLoading: requesting, mutate: requestAgain } = useRequestAgain('getPendingRequests')
    const handleRequestAgain = (item) => {
        let payload = {
            docId: item.id,
            requestType: item.requestType,
            type: 'cancel'
        }
        requestAgain(payload)
    }

    const closePopUp = () => {
        setAddReview(false)
    }
    const reviewNow = (item) => {
        let me = item?.vendors?.find(item => item?.vendorId == adminInfo?.companyId)
        setAddReview({ companyId: item?.adminAddedCompanyId, companyName: item?.adminCompanyName, projectId: item?.projectId, heading: item?.adminAddedCompanyId == adminInfo?.companyId ? 'client' : 'vendor', id: item?.id, initialOfferingTags: me?.tags, projectDuration: item?.projectDuration })
    }

    return (
        <>
            <div className='bg-body sticky top-20 z-10'>
                <div className='p-4 flex items-center justify-between gap-4 w-full'>
                    <div className='flex items-center bg-white rounded w-full max-w-xl px-3 shadow-md'>
                        <input type="text" placeholder='Search Company' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>

                    {/* <button onClick={() => { }} className='py-2 px-5 rounded-lg shadow-md bg-lightgreen disabled:bg-gray-300 text-green-900 font-medium'>Send Request</button> */}
                </div>

                <div className='flex flex-col w-full px-4 pb-4 gap-4'>
                    <div className='flex items-center gap-4'>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightblue text-sky-700 font-medium ${active == 'feedbacks' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('feedbacks')}>Feedbacks</button>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightviolet text-rose-900 font-medium ${active == 'CV' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('CV')}>Client & Vendor</button>
                        {/* <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightgreen text-green-900 font-medium ${active == 'accepted' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('accepted')}>Accepted</button> */}
                        <div className='relative group'>
                            <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightpeach text-orange-900 font-medium ${active == 'projects' ? 'opacity-100' : 'opacity-50'}`} onClick={() => { setActive('projects'); setReqType('projects') }}>Projects</button>
                            <div className={`group-hover:border flex flex-col absolute top-9 2xl:top-10 left-1/2 transform -translate-x-1/2 rounded-lg h-0 group-hover:h-auto overflow-hidden z-10`}>
                                <button className='py-2 px-4 font-medium bg-orange-50 text-orange-800 hover:text-orange-900 rounded-t-lg border-2 border-orange-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('projects'); setReqType('feedbacks') }}>Feedbacks</button>
                                <button className='py-2 px-4 font-medium bg-orange-50 text-orange-800 hover:text-orange-900 rounded-b-lg border-2 border-t-0 border-orange-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('projects'); setReqType('projects') }}>Projects</button>
                            </div>
                        </div>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-teal-300 text-teal-900 font-medium ${active == 'requests' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('requests')}>Your Requests</button>
                    </div>

                    <div className='flex items-center gap-4'>
                        <button className={`py-2 px-4 text-sm rounded-full shadow-md bg-lightblue text-sky-700 font-medium min-w-[100px] ${actionType == '' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActionType('')}>Latest</button>
                        <button className={`py-2 px-4 text-sm rounded-full shadow-md bg-lightpink text-rose-900 font-medium min-w-[100px] ${actionType == 'expired' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActionType('expired')}>Show Expired</button>
                        <div className='relative group'>
                            <button className={`py-2 px-4 text-sm rounded-full shadow-md bg-lightpink text-rose-900 font-medium min-w-[100px] ${actionType == 'rejected' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActionType('rejected')}>Show Rejected</button>
                            {/* <div className='border flex flex-col absolute top-10 left-1/2 transform -translate-x-1/2 rounded-lg h-0 group-hover:h-auto overflow-hidden'>
                                <button className='py-2 px-4 font-medium bg-pink-100 text-pink-800 hover:text-pink-900 rounded-t-lg border-2 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActionType('rejected'); setActionReqType('yours') }}>By You</button>
                                <button className='py-2 px-4 font-medium bg-pink-100 text-pink-800 hover:text-pink-900 rounded-b-lg border-2 border-t-0 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActionType('rejected'); setActionReqType('others') }}>By Others</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <section className='px-4'>
                {active == 'projects' ? <ProjectRequest.default actionType={actionType} search={search.toLowerCase()} reviewNow={reviewNow} adminInfo={adminInfo} heading={reqType == 'projects' ? 'Project Requests' : 'Feedback Requests'} reqType={reqType} projects={reqType == 'projects' ? requestRes?.data?.projectRequests : requestRes?.data?.projectFeedbackRequests} isLoading={isLoading} /> : <></>}

                {/* {active == 'projects' ? <Projects adminInfo={adminInfo} heading={'Projects'} reviewNow={reviewNow} reqType={'projects'} /> : <></>} */}
                {active == 'feedbacks' ? <FeedbackRequests.default actionType={actionType} search={search.toLowerCase()} adminInfo={adminInfo} heading={'Feedbacks'} reqType={'feedbacks'} isLoading={isLoading} data={requestRes?.data?.feedbackRequests} /> : <></>}
                {active == 'CV' ? <FeedbackRequests.default actionType={actionType} search={search.toLowerCase()} adminInfo={adminInfo} heading={'client & vendor'} reqType={'client & vendor'} isLoading={isLoading} data={[...requestRes?.data?.clientRequests, ...requestRes?.data?.vendorRequests].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} /> : <></>}
                {active == 'requests' ? <PendingRequests actionType={actionType} search={search.toLowerCase()} revoking={revoking || requesting} handleRevoke={handleRevoke} handleRequestAgain={handleRequestAgain} isLoading={requestLoading} heading={'Pending Requests'} data={[...PendingRes?.data?.clientRequests, ...PendingRes?.data?.vendorRequests].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} /> : <></>}
            </section>
            {
                addReview ?
                    <ReviewPopUp reviewerCompany={adminInfo?.companyName} adminId={adminInfo?.uuid} companyId={addReview?.companyId} companyName={addReview?.companyName} initial={1} projectId={addReview?.projectId} reviewType={addReview?.projectId ? 'Project' : 'company'} heading={addReview?.heading} closePopUp={closePopUp} requestId={addReview.id} initialOfferingTags={addReview?.initialOfferingTags} projectDuration={addReview?.projectDuration} />
                    :
                    <></>
            }
        </>
    )
}

export default Requests