import React from 'react'

function Circles() {
    return (
        <>
            <div className='fixed -bottom-60 -left-60'>
                <svg width="663" height="549" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                    <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                    <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                </svg>
            </div>

            <div className='fixed -bottom-80 -right-80'>
                <svg width="805" height="673" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                    <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                    <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                </svg>
            </div>
        </>
    )
}

export default Circles