import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getAllReviewsByCompany, getCompanyStatistics } from '../../services/service'
import { BarChart } from '../Charts/BarChart'
import { DoughnutChart } from '../Charts/DoughnutChart'
import { groupBy } from '../../services/constant'
import Liquid from './Liquid'


function Statistics({ companyDetails, title }) {

    const [activeX, setActiveX] = useState('clients');
    const [activeY, setActiveY] = useState('projects');
    const [single, setSingle] = useState('review')
    let { data: reviewRes } = useQuery('getAllReviewsByCompany', () => getAllReviewsByCompany(companyDetails?.companyName), {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })
    let { isLoading: graphLoading, data: graphRes } = useQuery('getCompanyStatistics', () => getCompanyStatistics(companyDetails?.companyName), {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (activeX == 'sectors') {
            setActiveY('reviews')
        }
    }, [activeX])

    let reviewArr = groupBy(graphRes?.data?.singleDoughnut || [], data => data.rating)
    if (reviewArr) {
        reviewArr = Object.keys(reviewArr).map(key => (
            {
                industryServices: key,
                value: reviewArr[key]?.length || 1
            }
        ))
    }
    let sectorArr = groupBy(graphRes?.data?.singleDoughnut || [], data => data.sectorOfIndustry)
    if (sectorArr) {
        sectorArr = Object.keys(sectorArr).map(key => (
            {
                industryServices: key,
                value: sectorArr[key]?.length || 1
            }
        ))
    }
    let typeArr = groupBy(graphRes?.data?.singleDoughnut || [], data => data.typeOfIndustry)
    if (typeArr) {
        typeArr = Object.keys(typeArr).map(key => (
            {
                industryServices: key,
                value: typeArr[key]?.length || 1
            }
        ))
    }


    let client_projects = graphRes?.data?.client_projects?.map(item => ({
        label: item?.companyCountry,
        value: item.projects
    }));
    let client_reviews = graphRes?.data?.client_reviews?.map(item => ({
        label: item?.companyCountry,
        value: item.reviews
    }));
    let sector_projects = graphRes?.data?.sector_projects?.map(item => ({
        label: item?.sectorOfIndustry,
        value: item.projects
    }));

    return (
        <>

            <section className='px-14 w-full pb-14'>

                <div className=''>
                    <h1 className='text-2xl font-semibold'>
                        {title}
                    </h1>
                </div>

                <section className='p-10'>
                    <div className='relative max-w-max mx-auto'>
                        <div className='bg-white rounded border-2 border-bermuda py-2 px-16 max-w-max z-20 relative'>
                            <span className='text-2xl font-bold'>Double Axis</span>
                        </div>
                        <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                        </div>
                        <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                        </div>
                    </div>

                    <div className='flex justify-center items-end gap-10 max-w-3xl mx-auto py-10'>
                        <div className='w-full max-w-xs'>
                            <h1 className='font-medium text-xl pb-1'>X-axis</h1>
                            <div className='relative group w-full max-w-[260px] flex items-center p-2 justify-between bg-white rounded-lg border-2 border-bermuda'>
                                <span className='capitalize'>{activeX}</span>
                                <span>
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301996 1.66667 1.26425 0 2.80385 0L13.1962 0C14.7358 0 15.698 1.66667 14.9282 3L9.73205 12Z" fill="black" />
                                    </svg>
                                </span>

                                <div className='absolute top-10 left-0 w-full bg-white rounded-lg shadow-lg z-10 p-4 hidden group-hover:inline-block'>
                                    <h1 className='cursor-pointer py-1 border-b' onClick={() => setActiveX('clients')}>Clients</h1>
                                    <h1 className='cursor-pointer py-1' onClick={() => setActiveX('sectors')}>Sectors</h1>
                                </div>
                            </div>
                        </div>

                        <div className='w-full max-w-xs'>
                            <h1 className='font-medium text-xl pb-1'>Y-axis</h1>
                            <div className='relative group w-full max-w-[260px] flex items-center p-2 justify-between bg-white rounded-lg border-2 border-bermuda'>
                                <span className='capitalize'>{activeY}</span>
                                <span>
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301996 1.66667 1.26425 0 2.80385 0L13.1962 0C14.7358 0 15.698 1.66667 14.9282 3L9.73205 12Z" fill="black" />
                                    </svg>
                                </span>
                                <div className='absolute top-10 left-0 w-full bg-white rounded-lg shadow-lg z-10 p-4 hidden group-hover:inline-block'>
                                    {activeX != 'sectors' && <h1 className='cursor-pointer py-1 border-b' onClick={() => setActiveY('projects')}>Projects</h1>}
                                    <h1 className='cursor-pointer py-1' onClick={() => setActiveY('reviews')}>Reviews</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    {graphLoading ?
                        <div className='w-full min-h-[250px] grid place-items-center'>
                            <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                            </div>
                        </div>
                        : <div className='max-w-max bg-lightpink rounded-lg mx-auto border-2 border-bermuda'>
                            <div className="border-2 border-bermuda rounded-lg bg-white p-8 max-w-max transform -translate-x-2.5 -translate-y-2.5">
                                <h1 className='text-2xl font-medium mb-4'>Client region served</h1>
                                <BarChart width={800} height={400} containerClass={'mt-8'} graphData={
                                    activeX == 'clients' && activeY == 'projects'
                                        ?
                                        client_projects
                                        :
                                        activeX == 'clients' && activeY == 'reviews'
                                            ?
                                            client_reviews
                                            :
                                            activeX == 'sectors' && activeY == 'projects'
                                                ?
                                                sector_projects
                                                :
                                                []
                                } />
                            </div>
                        </div>}
                </section>

                <section className='grid grid-cols-2 max-w-6xl mx-auto'>
                    <div className='max-w-lg p-8'>
                        <div className='relative max-w-max mx-auto'>
                            <div className='bg-white rounded border-2 border-bermuda py-2 px-16 max-w-max z-20 relative'>
                                <span className='text-2xl font-semibold'>Single Axis</span>
                            </div>
                            <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                            </div>
                        </div>
                        <div className='flex items-end justify-between w-full my-4'>
                            <div className='w-full'>
                                <h1 className='font-medium text-xl pb-1'>Classification</h1>
                                <div className='relative group w-full flex items-center p-2 justify-between bg-white rounded-lg border-2 border-bermuda'>
                                    <span className='capitalize'>{single}</span>
                                    <span>
                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301996 1.66667 1.26425 0 2.80385 0L13.1962 0C14.7358 0 15.698 1.66667 14.9282 3L9.73205 12Z" fill="black" />
                                        </svg>
                                    </span>
                                    <div className='absolute top-10 left-0 w-full bg-white rounded-lg shadow-lg z-40 p-4 hidden group-hover:inline-block'>
                                        <h1 className='cursor-pointer py-1 border-b' onClick={() => setSingle('review')}>Reviews</h1>
                                        <h1 className='cursor-pointer py-1 border-b' onClick={() => setSingle('sector')}>Sectors</h1>
                                        <h1 className='cursor-pointer py-1 border-b' onClick={() => setSingle('type')}>Types</h1>
                                        <h1 className='cursor-pointer py-1' onClick={() => setSingle('service')}>Services</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='relative'>
                            <div className='bg-white rounded border-2 border-bermuda p-2 transform -translate-x-1 -translate-y-1 flex flex-col items-center justify-center h-80 z-20 relative'>
                                <h1 className='text-lg font-medium text-center py-2 capitalize'>{single} analysis</h1>
                                <DoughnutChart initialData={single == 'sector' ? sectorArr : single == 'type' ? typeArr : single == 'review' ? reviewArr : graphRes?.data.serviceAnalysis} width={250} height={250} redraw={true} />
                            </div>
                            <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-1 translate-y-1 z-10'>
                            </div>
                        </div>
                    </div>
                    {reviewRes?.data?.overallClientRating && <Satisfaction reviewData={reviewRes?.data?.overallClientRating} />}

                </section>
            </section>
        </>
    )
}

export default Statistics


const Satisfaction = ({ reviewData }) => {
    const [satisfaction, setSatisfaction] = useState('factors')

    let reviewArr = reviewData || [];
    let graphRating = [];
    let topServices = [];
    let impressed = [];
    let improve = [];
    let ftImprove = [];
    let ftImpressed = [];
    let totalImpressed = 0
    let totalImprove = 0
    let totalFTImpressed = 0
    let totalFTImprove = 0


    for (let i = 0; i < reviewArr.length; i++) {
        for (let j = 0; j < reviewArr[i]?.graphRating?.length; j++) {
            let temp = { typeOfReview: reviewArr[i].graphRating[j].typeOfReview }
            if (i == 0) {
                temp.rating = reviewArr[i].graphRating[j].rating
            }
            else {
                temp.rating = reviewArr[i - 1].graphRating[j].rating + reviewArr[i].graphRating[j].rating
            }
            graphRating[j] = temp
        }
        ftImprove.push(reviewArr[i].improvementReviewComment)
        ftImpressed.push(reviewArr[i].positiveReviewComment)
    }

    for (let i of graphRating) {
        i.rating /= reviewArr.length
    }

    const data = reviewArr.reduce((acc, crr) => {
        const temp = acc || crr
        topServices.push(...crr.offeringTags)
        impressed.push(crr.serviceImpressed)
        improve.push(crr.serviceImprove)
        temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
        return temp
    }, {})

    let elementCounts = {}
    let obj = []


    elementCounts = {}
    obj = []
    impressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    impressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of impressed) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalImpressed += i[1]
    }
    data.impressed = obj[0]

    elementCounts = {}
    obj = []
    improve.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    improve = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of improve) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalImprove += i[1]
    }
    data.improve = obj[0]


    elementCounts = {}
    obj = []
    ftImpressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    ftImpressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of ftImpressed) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalFTImpressed += i[1]
    }
    data.ftImpressed = obj[0]

    elementCounts = {}
    obj = []
    ftImprove.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    ftImprove = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of ftImprove) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalFTImprove += i[1]
    }
    data.ftImprove = obj[0]

    data.totalImprove = totalImpressed
    data.totalImpressed = totalImprove
    data.totalFTImprove = totalFTImpressed
    data.totalFTImpressed = totalFTImprove
    data.avgRating = Number(data.avgRating / reviewArr.length).toFixed(1)

    impressed = Math.round((data?.impressed?.value / data?.totalImpressed) * 100)
    improve = Math.round((data?.improve?.value / data?.totalImprove) * 100)
    ftImpressed = Math.round((data?.ftImpressed?.value / data?.totalFTImpressed) * 100)
    ftImprove = Math.round((data?.ftImprove?.value / data?.totalFTImprove) * 100)


    return (
        <div className='max-w-lg p-8'>
            <div className='relative max-w-max mx-auto'>
                <div className='bg-white rounded border-2 border-bermuda py-2 px-16 max-w-max z-20 relative'>
                    <span className='text-2xl font-semibold'>Satisfaction</span>
                </div>
                <div className=' bg-lightblue rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                </div>
            </div>
            <div className='flex items-end justify-between w-full my-4'>
                <div className='w-full'>
                    <h1 className='font-medium text-lg text-gray-800 pb-1 inline-block min-w-max'>Type of Impression</h1>
                    <div className='relative group w-full mx-auto flex items-center p-2 justify-between bg-white rounded-lg border-2 border-bermuda'>
                        <span className='capitalize'>{satisfaction}</span>
                        <span>
                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301996 1.66667 1.26425 0 2.80385 0L13.1962 0C14.7358 0 15.698 1.66667 14.9282 3L9.73205 12Z" fill="black" />
                            </svg>
                        </span>
                        <div className='absolute top-10 left-0 w-full bg-white rounded-lg shadow-lg z-40 p-4 hidden group-hover:inline-block'>
                            <h1 className='cursor-pointer py-1 border-b' onClick={() => setSatisfaction('factors')}>Factors</h1>
                            <h1 className='cursor-pointer py-1' onClick={() => setSatisfaction('services')}>Services</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className='relative'>
                <div className='bg-white rounded border-2 border-bermuda p-2 transform -translate-x-1 -translate-y-1 flex justify-center gap-6 h-80 z-20 relative'>
                    <div>
                        <LiquidBox top={satisfaction == 'factors' ? isNaN(ftImpressed) ? 0 : ftImpressed : isNaN(impressed) ? 0 : impressed} i={1 + satisfaction} service={satisfaction == 'factors' ? data?.ftImpressed?.industryServices : data?.impressed?.industryServices} type="were impressed" color={'rgba(29,180,64,1)'} />
                    </div>
                    <div className='relative transform translate-y-32'>
                        <LiquidBox top={satisfaction == 'factors' ? 100 - isNaN(ftImprove) ? 0 : ftImprove : 100 - isNaN(improve) ? 0 : improve} i={2 + satisfaction} service={satisfaction == 'factors' ? data?.ftImprove?.industryServices : data?.improve?.industryServices} type="were impressed (wanted improvement)" color={'rgba(248,192,51,1)'} />
                    </div>
                </div>
                <div className=' bg-lightblue rounded border-2 border-bermuda absolute inset-0 transform translate-x-1 translate-y-1 z-10'>
                </div>
            </div>
        </div>
    )
}

const LiquidBox = ({ top, i, service, type, color }) => {
    return (
        <>
            <div className='relative' dangerouslySetInnerHTML={{
                __html: `<style>
            .liquid_${i} {
                position: relative;
                width: 170px;
                height: 170px;
                background: ${color};
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid ${color}
            }
            
            .liquid_${i}:before {
                content: '';
                position: absolute;
                width: 200%;
                height: 200%;
                top: -${105 + top}%;
                left: -50%;
                border-radius: 40%;
                background: #fff;
                opacity: 0.8;
                animation: animate 20s linear infinite;
            }
            
            @keyframes animate {
                0% {
                    transform: rotate(0deg);
                }
            
                100% {
                    transform: rotate(360deg);
                }
            }
            
            .liquid_${i}:after {
                content: '';
                position: absolute;
                width: 200%;
                height: 200%;
                top: -${105 + top}%;
                left: -50%;
                border-radius: 40%;
                background: #fff;
                opacity: 0.9;
                animation: animate2 8s linear infinite;
            }
            
            @keyframes animate2 {
                0% {
                    transform: rotate(360deg);
                }
            
                100% {
                    transform: rotate(0deg);
                }
            }
            
            .liquid_${i} .inner {
                position: absolute;
                width: 200%;
                height: 200%;
                top: -${105 + top}%;
                left: -50%;
                border-radius: 45%;
                background: #fff;
                opacity: 1;
                animation: animate3 12s linear infinite;
            }
            
            @keyframes animate3 {
                0% {
                    transform: rotate(0deg);
                }
            
                100% {
                    transform: rotate(360deg);
                }
            }
            </style>`}}>
            </div>

            <Liquid i={i} service={service} value={top} type={type} />
        </>
    )
}