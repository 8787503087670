import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { adminDetails, selectAdminDetails } from '../../slices/adminSlice'
import { openLink, useClickOutside } from '../../services/constant'

function TopBar({ title }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const adminInfo = useSelector(selectAdminDetails);
    const ref = useRef();
    const [open, setOpen] = useState(false)

    useClickOutside(() => setOpen(false), ref);

    return (
        <div className='bg-white p-4 flex items-center gap-4 justify-between pr-10 sticky top-0 z-50'>
            <h1 className='text-2xl font-medium'>{title}</h1>

            <div className='flex items-center gap-6'>
                <div className='bg-bermuda rounded max-w-max'>
                    <button onClick={() => openLink(`https://www.cornerqube.com`)} className='bg-lightgreen font-medium py-2 px-4 flex items-center gap-2 rounded transform -translate-x-1 -translate-y-1'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 20L2 15.5M2 15.5L6.5 11M2 15.5H15.5M15.5 2L20 6.5M20 6.5L15.5 11M20 6.5H6.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>Switch to Corner Qube</span>
                    </button>
                </div>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-slate-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                    </svg>
                </span>
                <div className='flex items-center gap-2 relative' ref={ref}>
                    <div className='w-12 h-12 overflow-hidden rounded bg-white p-1 shadow grid place-items-center cursor-pointer' onClick={() => setOpen(!open)}>
                        {
                            adminInfo?.companyIcon ?
                                <img src={adminInfo?.companyIcon} className="w-full h-full object-contain" alt="" />
                                : <span className='text-2xl font-semibold text-green-500'>{adminInfo?.companyAdminName ? adminInfo?.companyAdminName?.[0].toUpperCase() : ''}</span>
                        }
                    </div>
                    <div onClick={() => setOpen(!open)}>
                        <h1 className='font-medium text-gray-700 cursor-pointer'>{adminInfo?.companyAdminName}</h1>
                        <p className='text-sm text-gray-400 cursor-pointer'>Admin portal</p>
                    </div>
                    {open ? <div className='absolute p-3 top-14 right-0 bg-white custom-shadow w-full min-w-[10rem] rounded-lg z-[200]'>

                        <div onClick={() => openLink(`https://www.cornerqube.com`)} className='border-b py-1 font-medium flex items-center justify-between text-gray-500 hover:text-gray-800 cursor-pointer'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 20L2 15.5M2 15.5L6.5 11M2 15.5H15.5M15.5 2L20 6.5M20 6.5L15.5 11M20 6.5H6.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Client Portal</span>
                        </div>
                        <div onClick={() => { localStorage.setItem('corner_qube', ''); dispatch(adminDetails(null)); window.location.replace('/login') }} className='flex items-center justify-between py-1 font-medium text-gray-500 hover:text-gray-800 cursor-pointer'>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.89535 9.23C7.45785 9.23 7.11192 9.57 7.11192 10C7.11192 10.42 7.45785 10.77 7.89535 10.77H14V15.55C14 18 11.9753 20 9.47238 20H4.51744C2.02471 20 0 18.01 0 15.56V4.45C0 1.99 2.03488 0 4.52762 0H9.49273C11.9753 0 14 1.99 14 4.44V9.23H7.89535ZM17.6302 6.5402L20.5502 9.4502C20.7002 9.6002 20.7802 9.7902 20.7802 10.0002C20.7802 10.2002 20.7002 10.4002 20.5502 10.5402L17.6302 13.4502C17.4802 13.6002 17.2802 13.6802 17.0902 13.6802C16.8902 13.6802 16.6902 13.6002 16.5402 13.4502C16.2402 13.1502 16.2402 12.6602 16.5402 12.3602L18.1402 10.7702H14.0002V9.2302H18.1402L16.5402 7.6402C16.2402 7.3402 16.2402 6.8502 16.5402 6.5502C16.8402 6.2402 17.3302 6.2402 17.6302 6.5402Z" fill="#E04F5F" />
                            </svg>
                            <span>Logout</span>
                        </div>
                    </div> : <></>}
                </div>
            </div>
        </div>
    )
}

export default TopBar