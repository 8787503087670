import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getEnquiry } from '../../services/service'
import moment from 'moment'
import { useClearEnquiry } from '../../hooks'
import { useClickOutside } from '../../services/constant'

function EnquiryHome() {
    const { isLoading, data: EnquiryRes } = useQuery('getEnquiry', getEnquiry, { refetchOnWindowFocus: false, refetchInterval: 30000 })

    const { isLoading: clearing, mutate } = useClearEnquiry()
    const [search, setSearch] = useState('')
    const [filterModal, setFilterModal] = useState(false);
    const [filters, setFilters] = useState({
        reason1: 'General Enquiry',
        reason2: 'Financial Enquiry',
        reason3: 'Service Enquiry',
        withNote: true,
        withoutNote: true,
    })

    const filterRef = useRef()

    useClickOutside(() => setFilterModal(false), filterRef)

    const validateFilter = (item) => {
        let searchBoolean = item.email?.toLowerCase().includes(search?.toLowerCase()) || item?.description?.toLowerCase().includes(search?.toLowerCase());
        let reasonBoolean = [filters.reason1, filters.reason2, filters.reason3].includes(item?.reason)
        let noteBoolean = false;
        if (filters.withNote) {
            if (item?.description) {
                noteBoolean = true
            }
        }
        if (filters.withoutNote) {
            if (!item?.description)
                noteBoolean = true
        }
        return searchBoolean && reasonBoolean && noteBoolean
    }

    return (
        <>
            <section className=''>

                <div className='p-5 flex items-center justify-between gap-4 w-full sticky top-20 bg-body z-20'>
                    <div className='flex items-center bg-white rounded w-full max-w-md px-3 shadow-md'>
                        <input type="text" placeholder='Search in Email or Description' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>

                    <div ref={filterRef} className='relative'>
                        <button className='py-2 px-6 rounded shadow font-medium bg-white' onClick={() => setFilterModal(!filterModal)}>Filter</button>
                        {
                            filterModal && <div className='absolute bg-white custom-shadow rounded min-w-max top-12 right-0 py-2 px-4'>

                                <div>
                                    <div className='flex items-start justify-between gap-10'>
                                        <h1 className='font-medium'>Reason</h1>
                                        <span onClick={() => setFilters({
                                            reason1: 'General Enquiry',
                                            reason2: 'Financial Enquiry',
                                            reason3: 'Service Enquiry',
                                            withNote: true,
                                            withoutNote: true,
                                        })} className='text-xs cursor-pointer bg-red-300 font-medium rounded-full py-1 px-3'>Clear Filter</span>
                                    </div>
                                    <button onClick={() => setFilters({ ...filters, reason1: filters.reason1 == 'option1' ? '' : 'option1' })} className='flex items-center gap-2 mb-1 outline-none'>
                                        <span className={`w-3 h-3 ${filters.reason1 == 'option1' ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                        </span>
                                        <span className='text-sm'>
                                            Option 1
                                        </span>
                                    </button>

                                    <button onClick={() => setFilters({ ...filters, reason2: filters.reason2 == 'option2' ? '' : 'option2' })} className='flex items-center gap-2 mb-1 outline-none'>
                                        <span className={`w-3 h-3 ${filters.reason2 == 'option2' ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                        </span>
                                        <span className='text-sm'>
                                            Option 2
                                        </span>
                                    </button>

                                    <button onClick={() => setFilters({ ...filters, reason3: filters.reason3 == 'option3' ? '' : 'option3' })} className='flex items-center gap-2 mb-1 outline-none'>
                                        <span className={`w-3 h-3 ${filters.reason3 == 'option3' ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                        </span>
                                        <span className='text-sm'>
                                            Option 3
                                        </span>
                                    </button>

                                </div>
                                <div className='mt-2'>
                                    <h1 className='font-medium'>Description</h1>
                                    <button onClick={() => setFilters({ ...filters, withNote: !filters.withNote })} className='flex items-center gap-2 mb-1 outline-none'>
                                        <span className={`w-3 h-3 ${filters.withNote ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                        </span>
                                        <span className='text-sm'>
                                            With Description
                                        </span>
                                    </button>
                                    <button onClick={() => setFilters({ ...filters, withoutNote: !filters.withoutNote })} className='flex items-center gap-2 mb-1 outline-none'>
                                        <span className={`w-3 h-3 ${filters.withoutNote ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                        </span>
                                        <span className='text-sm'>
                                            Without Description
                                        </span>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md mx-5'>
                    <h1 className='text-2xl font-medium text-gray-600 capitalize'>Enquiry</h1>
                    <div className='relative min-h-[20dvh]'>
                        {isLoading || !EnquiryRes?.data
                            ?
                            <>
                                <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                    <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                    </div>
                                </div>
                            </>
                            :
                            <></>}
                        {
                            (!isLoading && !EnquiryRes?.data?.data?.filter(item => validateFilter(item))?.length) ?
                                <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>No enquiry requested</h1>
                                : <></>
                        }
                        <table className='w-full my-2' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                            <thead className='text-lg text-gray-300 border-b sticky top-40 bg-white'>
                                <tr className=''>
                                    <td className='pt-4 pb-2 border-b text-center'>SNo.</td>
                                    <td className='pt-4 pb-2 border-b'>Email</td>
                                    <td className='pt-4 pb-2 border-b text-center'>Reason</td>
                                    <td className='pt-4 pb-2 border-b text-center min-w-[300px]'>Description</td>
                                    <td className='pt-4 pb-2 border-b text-center'></td>
                                </tr>
                            </thead>
                            <>

                                <tbody>
                                    {
                                        EnquiryRes?.data?.data?.filter(item => validateFilter(item))?.map((item, i) => <tr className='group' key={i}>
                                            <td className='text-center border-b group-last:border-b-0'>{i + 1}</td>
                                            <td className='leading-5 border-b group-last:border-b-0'><p>{item.email}<br /><span className='text-xs text-gray-600'>{moment(item.createdAt).fromNow()}</span></p></td>
                                            <td className='text-center border-b group-last:border-b-0 px-3'>{item.reason}</td>
                                            <td className='text text-xs border-b group-last:border-b-0'>{item.description || <span className='italic text-sm'>No description was provided</span>}</td>
                                            <td className='text-center'>
                                                <button disabled={clearing} onClick={() => mutate(item.id)} className='rounded bg-lightblue py-1.5 px-5 font-medium'>Clear</button>
                                            </td>
                                        </tr>)
                                    }

                                </tbody>
                            </>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EnquiryHome