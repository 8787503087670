import React, { useState, useEffect, useRef } from 'react';
import searchbanner from '../images/search-banner.svg';
import NavBar from '../Components/NavBar/NavBar';
import overview from '../images/OVERVIEW.svg';
import stats from '../images/STATS.svg';
import feedback from '../images/FEEDBACK02.svg';
import compare from '../images/compare.svg';
import signinpopup from '../images/signinpopup3.svg';
import PROJECT from '../images/PORTFOLIO.svg';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../slices/adminSlice';
import ReviewPopUp from '../Components/Reusable/ReviewPopUp';
import GetInTouch from '../Components/Reusable/GetInTouch';
import { useHistory, useParams } from 'react-router-dom';
import { getCompanyDetails, getProjects, getSignUpRefData } from '../services/service';
import { useQuery } from 'react-query';
import { fetchPlace, openLink } from '../services/constant';
import Overview from '../Components/Company/Overview';
import Statistics from '../Components/Company/Statistics';
import Reviews from '../Components/Company/Reviews';
import Loader from '../Components/Loader/Loader'
import Projects from '../Components/Company/Projects';
import { toast } from 'react-hot-toast';
import cross from '../images/cross.svg';


const values = [
    {
        "id": 0, "value": "Overview", "content": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
    },
    {
        "id": 1, "value": "Stats", "content": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
    },
    {
        "id": 2, "value": "Reviews", "content": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
    },
    {
        "id": 3, "value": "Projects", "content": "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."
    },

]
const colorsbg = ["lightviolet", "babyblue", "lightpink", "lightgreen", "babycream"]
const colors = ["bg-lightgreen", "bg-lightviolet", "bg-blueshade", "bg-frenchblue", "bg-peach"]
const HexagonClipPath = { clipPath: `polygon(50% 0, 95% 30%, 95% 70%, 50% 100%, 5% 70%, 5% 30%)`, display: 'grid', placeItems: 'center' }

export default function Company() {
    const params = useParams()
    const history = useHistory()
    const adminInfo = useSelector(selectAdminDetails)
    const [addReview, setAddReview] = useState(false)
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [effect, setEffect] = useState(false)
    const [formData, setFormData] = useState()
    const [search, setSearch] = useState('')
    const formRef = useRef()
    const [id, setId] = useState(0)

    const [isReview, setIsReview] = useState(0)
    const [getInTouchActive, setGetInTouchActive] = useState(0);
    const [companyFormData, setCompanyFormData] = useState({})
    const [selectedProject, setSelectedProject] = useState();
    const [projectDetails, setProjectDetails] = useState();
    const [compareData, setCompareData] = useState([])
    const [location, setLocation] = useState("");
    const [locationModal, setLocationModal] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [specificCompany, setSpecificCompany] = useState({})

    let { refetch: fetchProjects, data: projectRes } = useQuery('getProjects', () => getProjects(params.companyName, adminInfo?.companyId), {
        refetchOnWindowFocus: false, refetchInterval: 600000, enabled: false
    })

    let { isLoading, data: companyRes, refetch: fetchCompanyDetails } = useQuery('getCompanyDetails', () => getCompanyDetails(params.companyName), {
        refetchOnWindowFocus: false,
        onError: (e) => {
            console.log(e)
        }
    })
    let companyDetails = companyRes?.data?.companyDetails

    useEffect(() => {
        if (companyDetails?.companyName !== params?.companyName) {
            fetchCompanyDetails()
        }
    }, [params?.companyName])

    let { data: SignUpRefRes } = useQuery('getSignUpRefData', getSignUpRefData, {
        refetchOnMount: false, refetchOnWindowFocus: false,
        onError: (e) => console.log(e)
    })
    let refData = SignUpRefRes?.data

    useEffect(() => {
        if (selectedProject) {
            let item = projectRes?.data?.projects?.find(item => item.id == selectedProject)
            setProjectDetails(item)
        }
    }, [selectedProject])

    const handleCompanyFormData = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target)
        let values = { ...Object.fromEntries(data.entries()), ...formData }
        setCompanyFormData(values)

        if (!values.name || !values.email || !values.position || !values.companyName || !location || !values.sectorOfIndustry || !values.typeOfIndustry) {
            return toast.error("Fill all the details", { id: 'error' });
        }
        else {
            handleAddReview()
        }
    }

    const handleEnquiry = (e) => {
        e.preventDefault();
        if (!email) return toast.error("Please add a valid email", { id: 'error' })
        if (!reason) return toast.error("Please select a valid reason", { id: 'error' })
        if (email && reason)
            setGetInTouchActive(1);
    }


    const handleAddReview = () => {
        if (adminInfo?.uuid) {
            setAddReview({ projectId: selectedProject })
        }
        else {
            if (companyFormData?.companyName) {
                setAddReview(1)
            }
            else
                toast.error('Please fill all the details', { id: 'error' })
        }
    }

    const closePopUp = () => {
        setSelectedProject('');
        formRef.current.reset()
        setProjectDetails();
        setIsReview(0)
        setAddReview(false);
    }

    const closeGetInTouch = () => {
        setEmail('');
        setReason('');
        setGetInTouchActive(0)
    }

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        let data = localStorage.getItem('compare')
        if (data) {
            let compareArr = JSON.parse(data) || [];
            setCompareData(compareArr)
        }
    }, [])

    const handleLocationChange = async (e) => {
        setLocation(e.target.value);
        setLocationModal(true)
        if (!location) return;

        const res = await fetchPlace(location);

        !autocompleteCities.includes(e.target.value) &&
            res.data &&
            setAutocompleteCities(res.data.map((place) => place.location));
    };

    if (!companyDetails || isLoading) return <Loader />

    return (
        <>
            <div>
                <NavBar />
                <img src={searchbanner} className="w-full" />

                <div className='bg-coolgray relative'>
                    <div className='flex text-lg text-medium max-w-max px-12 pt-8 hover:text-blue-500 cursor-pointer' onClick={() => history.length > 3 ? history.goBack() : history.push('/')}>Back to search </div>

                    <div className='pb-6 pt-3 px-12 text-3xl font-semibold'>{companyDetails?.companyName}</div>
                    <div className='absolute left-1/2 -top-7 rounded-lg shadow-lg bg-white transform -translate-x-1/2'><img src={companyDetails?.companyIcon} className="h-20 p-3" /></div>
                    <div className='px-8 grid grid-cols-3 gap-10'>
                        <div className='px-5'>
                            <div className='pb-2 flex items-center'>
                                <div className='text-gray-400 text-lg font-medium tracking-tight'>Contact Email</div>
                                <div className='text-gray-400 px-5 text-lg'>&nbsp;:</div>
                                <div className='text-lightgray font-medium text-lg tracking-tight'> {companyDetails?.companyEmail}</div>
                            </div>
                            <div className='py-2 flex items-center'>
                                <div className='text-gray-400 text-lg font-medium tracking-tight'>Company size</div>
                                <div className='text-gray-400 px-5 text-lg'>:</div>
                                <div className='text-lightgray text-lg font-medium tracking-tight'>{companyDetails?.companyEmployeeScale}</div>
                            </div>
                            <div className='py-2 flex items-center'>
                                <div className='text-gray-400 text-lg font-medium tracking-tight'>Min. Project Cost</div>
                                <div className='text-gray-400 px-5 text-lg'>:</div>
                                <div className='text-lightgray text-lg font-medium tracking-tight'>{companyDetails?.minimumProjectCost}</div>
                            </div>
                            <div className='py-2 flex items-center'>
                                <div className='text-gray-400 text-lg font-medium tracking-tight'>Headquarters</div>
                                <div className='text-gray-400 px-5 text-lg'>:</div>
                                <span className='text-lightgray font-medium text-lg tracking-tight'>{companyDetails?.headQuartered}</span>
                            </div>
                        </div>
                        <div className='px-5'>
                            <div className='px-5'>
                                <div className='pb-2 flex items-center'>
                                    <div className='text-gray-400 text-lg font-medium tracking-tight'>Industry</div>
                                    <div className='ml-5 text-gray-400 px-5 text-lg'>:</div>
                                    <div className='text-lightgray font-medium text-lg tracking-tight'>{companyDetails?.sectorOfIndustry}</div>
                                </div>
                                <div className='py-2 flex items-center'>
                                    <div className='text-gray-400 text-lg font-medium  tracking-tight'>Type</div>
                                    <div className='ml-12 text-gray-400 px-5 text-lg'>:</div>
                                    <div className='text-lightgray text-lg font-medium tracking-tight'>{companyDetails?.typeOfIndustry}</div>
                                </div>
                                <div className='py-2 flex items-center'>
                                    <div className='text-gray-400 text-lg font-medium  tracking-tight'>Founded</div>
                                    <div className='ml-4 text-gray-400 px-5 text-lg'>:</div>
                                    <div className='text-lightgray text-lg font-medium tracking-tight'>{companyDetails?.companyYearOfEstablishment}</div>
                                </div>
                                {companyDetails?.companyWebsite && <div className='py-2 flex items-center'>
                                    <div className='text-gray-400 text-lg font-medium  tracking-tight'>Website</div>
                                    <div className='ml-6 text-gray-400 px-5 text-lg'>:</div>
                                    <div className='text-lightgray text-lg font-medium tracking-tight cursor-pointer hover:text-blue-500'><p onClick={() => openLink(companyDetails?.companyWebsite)} className="productWebsite-link">{companyDetails?.companyWebsite} <i className="fa fa-external-link" aria-hidden="true"></i></p></div>
                                </div>}
                            </div>
                        </div>

                        <div className="ml-14 -mt-12 z-40 rounded  w-10/12 h-66 border-2 bg-cream border-bermuda">
                            <div className="p-4 rounded w-full h-full bg-gray-50 text-black-500 border-black border-2 transform -translate-x-2 -translate-y-3">
                                <center className="py-1 text-2xl font-semibold">Get in touch</center>
                                <form onSubmit={handleEnquiry}>
                                    <div>
                                        <input type="email" aria-describedby="text"
                                            className="mt-2 rounded w-full h-12 text-black-500 border-black border-2 px-2"
                                            placeholder="Enter your Email"
                                            pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <select type="text" aria-describedby="text"
                                        className="mt-2 rounded w-full h-12 text-black-500 border-black border-2 px-2"
                                        title='minimum 6 characters required'
                                        placeholder="We are seeking out for..."
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    >
                                        <option value="" disabled selected>Select Reason</option>
                                        <option value="General Enquiry">General Enquiry</option>
                                        <option value="Financial Enquiry">Financial Enquiry</option>
                                        <option value="Service Enquiry">Service Enquiry</option>
                                    </select>
                                    <div className="mt-8 ml-1 z-40 rounded w-full h-12 text-black-500 bg-bermuda border-bermuda border-2 ">
                                        <button disabled={!companyDetails?.companyId} type='submit' className="z-50 rounded text-white w-full h-12 border-2  bg-lightbrown border-bermuda px-4 transform -translate-x-1 -translate-y-2">ASK ENQUIRY</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>

                <div className='flex justify-between py-10 px-16'>
                    <div className="relative rounded-sm bg-palegreen w-full max-w-5xl h-36 border-2 border-bermuda">
                        <div className=" bg-white border-bermuda w-full h-auto transform -translate-x-3 -translate-y-2 left-0 flex items-center">
                            <div onClick={() => setId(0)} className={`${id == 0 ? "bg-palegreen" : ""} p-8  cursor-pointer text-lg spacing-0 border-bermuda border-2 hover:bg-palegreen flex-grow flex flex-col items-center justify-center`}><img src={overview} className="w-12" /> Overview</div>
                            <div onClick={() => setId(1)} className={`${id == 1 ? "bg-palegreen" : ""} p-8 cursor-pointer text-lg border-bermuda border-l-0 focus:bg-palegreen border-2 hover:bg-palegreen flex-grow flex flex-col items-center justify-center`}><img src={stats} className="w-10" /> Statistics</div>
                            <div onClick={() => setId(2)} className={`${id == 2 ? "bg-palegreen" : ""} p-8 cursor-pointer text-lg border-bermuda border-l-0 focus:bg-palegreen border-2 hover:bg-palegreen flex-grow flex flex-col items-center justify-center`}><img src={feedback} className="w-10" /> Reviews </div>
                            <div onClick={() => setId(3)} className={`${id == 3 ? "bg-palegreen" : ""} p-8 cursor-pointer text-lg border-bermuda border-l-0 focus:bg-palegreen border-2 hover:bg-palegreen flex-grow flex flex-col items-center justify-center`}><img src={PROJECT} className="w-10" /> Projects </div>
                        </div>
                    </div>
                    <div className='mt-2 ml-10'>
                        <div className='w-48 h-12 grid grid-cols-7 gap-3'>
                            <div className={`relative rounded bg-bermuda ${compareData.includes(companyDetails?.companyName) ? 'col-span-5' : 'col-span-7'}`}>
                                <div className={`${compareData.includes(companyDetails?.companyName) ? 'bg-lightgreen' : 'bg-bermuda'} rounded-lg w-full h-full border-2 border-bermuda`}>
                                </div>
                                <button className={`${effect && "animate-dip"
                                    } border-2 focus:outline-none ${compareData.includes(companyDetails?.companyName) ? 'bg-white' : 'bg-lightgreen'} absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`} onClick={() => {
                                        if (!compareData.includes(companyDetails?.companyName) && compareData?.length < 3) {
                                            setCompareData([...compareData, companyDetails?.companyName]);
                                            localStorage.setItem('compare', JSON.stringify([...compareData, companyDetails?.companyName]))
                                            toast.success("Added to compare", { id: 'success' })
                                        }
                                        else if (compareData.includes(companyDetails?.companyName)) {
                                            history.push(`/compare/companyCompareData/?companyName=${compareData}`)
                                        }
                                        else if (compareData?.length >= 3) {
                                            toast.error("Cannot add more than 3 companies to compare", { id: 'error' })
                                        }
                                    }}
                                >
                                    <span className="flex items-center justify-center gap-2">
                                        <img src={compare} style={{ width: "20px" }} />
                                        {compareData.includes(companyDetails?.companyName) ? 'Compare' : 'Add To Compare'}</span></button>
                            </div>
                            {
                                compareData?.includes(companyDetails?.companyName) && <div className='relative h-12 col-span-2 cursor-pointer' onClick={() => {
                                    let arr = compareData.filter(item => item != companyDetails?.companyName);
                                    setCompareData(arr);
                                    localStorage.setItem('compare', JSON.stringify(arr));
                                    toast.success("Removed from compare", { id: 'success' });
                                }}>
                                    <div className='bg-white rounded border-2 border-bermuda z-20 absolute top-0 left-0 w-full h-full transform -translate-x-1.5 -translate-y-1.5 grid place-items-center'>
                                        <img src={cross} className="w-10 h-10 cursor-pointer" />
                                    </div>
                                    <div className=' bg-red-300 rounded border-2 border-bermuda relative z-10 w-full h-full'>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="relative rounded bg-lightgreen w-48 h-12 mt-4">
                            <div className=" bg-bermuda-50 rounded w-full h-full border-2 border-bermuda">
                            </div>
                            <button disabled={companyDetails?.companyId == adminInfo?.companyId} onClick={() => { if (adminInfo?.uuid) { fetchProjects() }; setIsReview(adminInfo?.uuid ? -2 : -1); }} className={`border-2 focus:outline-none bg-gray-50 disabled:bg-gray-300 absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                            >Write Review</button>
                        </div>
                    </div>
                </div>

                {/* Non logged in user pre-review popup  */}
                <div className={`fixed top-0 left-0 right-0 w-full bg-black bg-opacity-40 flex items-center justify-center z-[100] p-4 overflow-x-hidden overflow-y-auto md:inset-0 ${isReview == -1 ? '' : 'hidden'}`}>
                    <div className="relative w-full max-w-6xl">
                        <div className="relative bg-white rounded-lg shadow pt-6 h-[650px]">
                            <button onClick={() => setIsReview(0)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>

                            <div className='relative max-w-max mx-auto'>
                                <div className='bg-white rounded border-2 border-bermuda py-2 px-8 max-w-max z-20 relative'>
                                    <span className='text-2xl font-bold'>Write Review</span>
                                </div>
                                <div className=' bg-lightgreen rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                </div>
                                <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                </div>
                            </div>

                            <div className='p-10 w-full grid grid-cols-2 gap-2'>
                                <form ref={formRef} onSubmit={handleCompanyFormData}>
                                    <div>
                                        <div className='flex items-start gap-6'>
                                            <div>
                                                <label>Name</label>
                                                <input type="text" aria-describedby="name"
                                                    name='name'
                                                    className="rounded w-full h-10 text-black-500 border-black border-2 px-4"
                                                />
                                            </div>
                                            <div>
                                                <label>Position in Firm</label>
                                                <select name="position" value={formData?.position || ""} className="rounded w-full h-10 text-black-500 border-black border-2 px-1 outline-none"
                                                    onChange={handleChange} >
                                                    <option value="" selected disabled>Select Position</option>
                                                    {refData?.companyRole?.map((data, i) => (<option key={i} value={data.id}>{data.companyRole}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <label>Official Email Address</label>
                                            <input type="email" aria-describedby="text"
                                                className="rounded w-full h-10 text-black-500 border-black border-2 px-4"
                                                name='email'
                                            />
                                        </div>
                                        <div className='mt-3'>
                                            <label>Company Name</label>
                                            <input type="text" aria-describedby="text"
                                                className="rounded w-full h-10 text-black-500 border-black border-2 px-4"
                                                name='companyName'
                                            />
                                        </div>
                                        <div className='mt-3 relative'>
                                            <label>Company Head Quarters</label>
                                            <input type="text" aria-describedby="text"
                                                className="rounded w-full h-10 text-black-500 border-black border-2 px-4 capitalize"
                                                name="companyHeadQuarter"
                                                value={location}
                                                onChange={(e) => handleLocationChange(e)}
                                            />
                                            {location.length > 1 && locationModal ?
                                                <div style={{ zIndex: 60, }} className='w-full max-h-44 overflow-y-auto bg-white border border-gray-200 rounded absolute left-0 top-16 shadow-md'>
                                                    {autocompleteCities.length == 0 ? <div className='py-1 px-4'>
                                                        No Results found</div> :
                                                        <div className='p-1 z-10'>
                                                            {autocompleteCities.map((data, i) =>
                                                            (
                                                                <div key={i} className="border-b last:border-none cursor-pointer p-2 hover:bg-blue-100 rounded capitalize" onClick={() => { setLocation(data); setLocationModal(false) }}>
                                                                    {data}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                </div> : ""
                                            }
                                        </div>
                                        <div className='flex items-start gap-6 mt-3'>
                                            <div className='relative'>
                                                <label>Industry sector</label>
                                                <input type="text" aria-describedby="text"
                                                    className="rounded w-full h-10 text-black-500 border-black border-2 px-4"
                                                    placeholder="Select sector"
                                                    value={search || (formData?.sectorOfIndustry ? refData?.industrySector[Number(formData?.sectorOfIndustry) - 1]?.sectorOfIndustry : '')}
                                                    onChange={(e) => { setSearch(e.target.value); setFormData({ ...formData, sectorOfIndustry: '' }) }}
                                                />
                                                {search ? <div className='absolute top-16 left-0 w-full p-2 z-20 bg-white max-h-40 overflow-y-auto rounded shadow-lg'>
                                                    {refData?.industrySector?.map((data, i) => (
                                                        data.sectorOfIndustry?.toLowerCase()?.includes(search?.toLowerCase()) && <p className='px-2 pb-1 cursor-pointer' onClick={() => { setSearch(''); setFormData({ ...formData, sectorOfIndustry: data.id }) }} value={data.id}>{data.sectorOfIndustry}</p>
                                                    ))}
                                                </div> : <></>}
                                            </div>
                                            <div className='relative'>
                                                <label>Industry type</label>
                                                <select name="typeOfIndustry" value={formData?.typeOfIndustry} className="rounded w-full h-10 text-black-500 border-black border-2 px-1 outline-none"
                                                    onChange={handleChange} >
                                                    <option value="" selected disabled>Select industry type</option>
                                                    <option value="One Person Company">One Person Company</option>
                                                    {refData?.industryType?.map((data, i) => (<option key={i} value={data.id}>{data.typeOfIndustry}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-8 flex flex-row justify-center items-center'>
                                        <div className="relative bg-bermuda rounded-md">
                                            <button type='submit' className='py-2 px-5 border-2 border-bermuda text-lg rounded-md bg-babycream transform -translate-x-1 -translate-y-1'>
                                                Write review
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div className='relative'>
                                    <img src={signinpopup} className="px-4 w-full object-cover" />
                                    <div className='absolute inset-0 p-8'>
                                        <div className=' bg-white max-w-max font-medium text-xl p-1'>The Fastest and Easiest B2B review Platform.</div>
                                        <div className='max-w-max bg-white font-medium text-lg my-4 p-1'>Join our community of 500+ firms across the Globe.</div>
                                        <div className='max-w-max bg-white font-medium text-xl my-6 p-1'>Get started now for free!</div>
                                        <div className="relative bg-bermuda rounded max-w-max mt-8 ml-auto">
                                            <button
                                                className={`border-2 py-2 px-8 font-medium bg-white rounded transform -translate-x-1 -translate-y-1 border-bermuda`}
                                            >Signup</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  logged in user pre-review popup  */}
                <div className={`fixed top-0 left-0 right-0 w-full bg-black bg-opacity-40 flex items-center justify-center z-[100] p-4 overflow-x-hidden overflow-y-auto md:inset-0 ${isReview == -2 ? '' : 'hidden'}`}>
                    <div className="relative w-full max-w-6xl">
                        <div className="relative bg-white rounded-lg shadow pt-6  h-[650px]">
                            <button onClick={() => setIsReview(0)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>

                            <div className='relative max-w-max mx-auto'>
                                <div className='bg-white rounded border-2 border-bermuda py-2 px-8 max-w-max z-20 relative'>
                                    <span className='text-2xl font-bold'>Write Review</span>
                                </div>
                                <div className=' bg-lightgreen rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                </div>
                                <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                </div>
                            </div>

                            <div className='p-10 w-full flex flex-col justify-between min-h-[540px]'>
                                <div className='w-full grid grid-cols-2 items-center gap-10 max-w-4xl mx-auto'>
                                    <div className='text-lg font-medium text-right'>Choose a project for which you are giving a feedback</div>
                                    <div className="relative rounded bg-bermuda w-full max-w-xs h-10">
                                        <div className="text-base text-medium relative">
                                            <input type="text" aria-describedby="text"
                                                className="transform -translate-x-1 -translate-y-1 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 focus:ring-bermuda focus:border-bermuda appearance-none"
                                                placeholder="Search or choose a project"
                                                value={search || projectDetails?.projectName || ''}
                                                onChange={(e) => { setSearch(e.target.value); setSelectedProject(); setProjectDetails() }}
                                            />
                                            {search ? <div className='absolute top-12 left-0 w-full p-2 z-20 bg-white max-h-40 overflow-y-auto rounded shadow-lg'>
                                                {
                                                    projectRes?.data?.projects?.filter(item => item?.projectName?.toLowerCase()?.includes(search?.toLowerCase()))?.length ?
                                                        projectRes?.data?.projects?.filter(item => item?.projectName?.toLowerCase()?.includes(search?.toLowerCase())).map((data, i) => (
                                                            <p key={i} className='px-2 pb-1 cursor-pointer' onClick={() => { setSearch(''); setSelectedProject(data.id) }} value={data.id}>{data.projectName}</p>
                                                        ))
                                                        :
                                                        <p className='px-2 pb-1 text-gray-400'>No Project</p>
                                                }
                                            </div> : <></>}
                                        </div>
                                        {selectedProject && <svg onClick={() => { setSelectedProject(''); setProjectDetails(); setSearch('') }} className='absolute top-2.5 right-4 cursor-pointer' width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6414 9.2907L10.932 9.99858L11.6414 10.7065L18.8011 17.8506L18.8032 17.8526C18.8656 17.9145 18.9151 17.9881 18.9488 18.0691C18.9826 18.1502 19 18.2372 19 18.325C19 18.4129 18.9826 18.4999 18.9488 18.581C18.9151 18.662 18.8656 18.7356 18.8032 18.7975L18.8032 18.7974L18.7974 18.8033C18.7356 18.8656 18.662 18.9151 18.581 18.9489C18.5 18.9826 18.4131 19 18.3253 19C18.2375 19 18.1506 18.9826 18.0696 18.9489C17.9885 18.9151 17.915 18.8656 17.8532 18.8033L17.8511 18.8012L10.708 11.6404L10 10.9307L9.29202 11.6404L2.14892 18.8012L2.14685 18.8033C2.08502 18.8656 2.01146 18.9151 1.93043 18.9489C1.8494 18.9826 1.76249 19 1.67471 19C1.58694 19 1.50003 18.9826 1.41899 18.9489C1.33797 18.9151 1.26441 18.8656 1.20257 18.8033L1.20259 18.8032L1.19677 18.7975C1.13443 18.7356 1.08494 18.662 1.05117 18.581C1.01739 18.4999 1 18.4129 1 18.325C1 18.2372 1.01739 18.1502 1.05117 18.0691C1.08494 17.9881 1.13443 17.9145 1.19678 17.8526L1.19885 17.8506L8.3586 10.7065L9.06803 9.99858L8.3586 9.2907L1.19968 2.14741C1.19957 2.1473 1.19946 2.1472 1.19936 2.14709C1.07354 2.02109 1.00284 1.85026 1.00284 1.67211C1.00284 1.49381 1.07366 1.32284 1.19968 1.19681C1.32569 1.07078 1.49657 1 1.67471 1C1.85272 1 2.02348 1.07067 2.14946 1.19652C2.14955 1.19661 2.14965 1.19671 2.14975 1.19681L9.29202 8.35675L10 9.06648L10.708 8.35675L17.8503 1.19681C17.8503 1.19675 17.8504 1.19669 17.8504 1.19663C17.9764 1.07071 18.1472 1 18.3253 1C18.5034 1 18.6743 1.07078 18.8003 1.19681C18.9263 1.32284 18.9972 1.49381 18.9972 1.67211C18.9972 1.85031 18.9264 2.02118 18.8005 2.14719C18.8005 2.14726 18.8004 2.14734 18.8003 2.14741L11.6414 9.2907Z" stroke="black" strokeWidth="2" />
                                        </svg>}
                                    </div>
                                </div>

                                <div className='w-full max-w-2xl mx-auto border border-bermuda bg-pinkshade rounded'>
                                    <div className='bg-white w-full min-h-[240px] transform -translate-x-2 -translate-y-2 border border-bermuda rounded grid place-items-center p-6'>
                                        {
                                            projectDetails ? <>
                                                <h1 className='w-full text-2xl font-medium'>{projectDetails?.projectName}</h1>
                                                <p>{projectDetails?.description}</p>
                                                <div className='flex items-center justify-between font-semibold w-full'>
                                                    <span>Engagement duration: {projectDetails?.duration} month(s)</span>
                                                    <span>Project cost: ${projectDetails?.cost}</span>
                                                </div>
                                            </>
                                                :
                                                <span className='max-w-lg text-lg text-center text-gray-400'>If no project is selected, Review will be on overall company engagement </span>
                                        }
                                    </div>
                                </div>

                                <div className='flex items-center justify-end'>
                                    <div className='bg-lightpeach rounded border-2 border-bermuda'>
                                        <button className='bg-lightgreen rounded border-2 border-bermuda py-2 px-8 transform -translate-x-1 -translate-y-1' onClick={handleAddReview}>
                                            <span className='text-xl font-medium'>Review Company</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {id == 0 ?
                    <Overview companyDetails={companyDetails} title="Overview" />
                    :
                    id == 1 ?
                        <Statistics companyDetails={companyDetails} title="Statistics" />
                        :
                        id == 2 ?
                            <Reviews companyDetails={companyDetails} setId={setId} title="Reviews" setSpecificCompany={setSpecificCompany} />
                            :
                            <Projects companyDetails={companyDetails} title="Reviews" setSpecificCompany={setSpecificCompany} specificCompany={specificCompany} />
                }

            </div >

            {
                addReview ?
                    <ReviewPopUp externalData={{ name: companyFormData?.name, companyName: companyFormData?.companyName, sectorOfIndustry: refData?.industrySector?.find(item => item.id == companyFormData?.sectorOfIndustry)?.sectorOfIndustry, typeOfIndustry: refData?.industryType?.find(item => item.id == companyFormData?.typeOfIndustry)?.typeOfIndustry || 'One Person Company', position: refData?.companyRole?.find(item => item.id == companyFormData?.position)?.companyRole, }} reviewerCompany={companyFormData?.companyName || adminInfo?.companyName} adminId={adminInfo?.uuid} companyName={params.companyName} initial={1} projectId={addReview?.projectId} projectDuration={projectDetails?.duration || ''} reviewType={addReview?.projectId ? 'Project' : 'company'} closePopUp={closePopUp} />
                    :
                    <></>
            }
            {
                getInTouchActive ? <GetInTouch getInTouchActive={getInTouchActive} email={email} reason={reason} closeGetInTouch={closeGetInTouch} companyId={companyDetails?.companyId} /> : <></>
            }
        </>
    )
}


