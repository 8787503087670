

import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        // grid: false,
        y: {
            grid: {
                color: 'transparent',
            },
        },
        x: {
            grid: {
                drawOnChartArea: true,
                color: 'rgba(0,0,0,0.08)',
                drawBorder: false,
                borderDash: [5, 6]
            }
        }
    }

};


export function HorizontalBarChart({ initialData = [], redraw = false }) {
    const data = {
        labels: initialData?.map(item => item?.sectorOfIndustry),
        datasets: [
            {
                data: initialData?.map((item) => item.vendorCount || 0.01),
                borderColor: '#165DFF',
                barThickness: 15, backgroundColor: '#165DFF',
            },
            {
                data: initialData?.map((item) => item.clientCount || 0.01),
                borderColor: '#14C9C9',
                barThickness: 15, backgroundColor: '#14C9C9',
            },
        ],
    };
    return <Bar options={options} data={data} redraw={redraw} />;
}
