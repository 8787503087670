import React from 'react'
function Liquid({ i, value, type, service }) {
    return (
        <>
            <div className={`liquid_${i}`}><div className="inner"></div>

                <div className='absolute transform w-full h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-[10px] 2xl:text-xs text-center z-20'>
                    <p className='font-medium'>{service}</p>
                    <h1 className='text-xl font-medium'>{value}%</h1>
                    <p className='max-w-[150px]'>{type}</p>
                </div>
            </div>
        </>
    )
}

export default Liquid