import React, { useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import { config } from '../../services/config';
import axios from 'axios';
import { Link, Redirect, useHistory } from "react-router-dom";
import { toast } from 'react-hot-toast';

export default function ForgotPassword() {
    const history = useHistory();
    const [email, setEmail] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    const [registerFlow, setRegisterFlow] = useState(false)

    const forgotPassword = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "registerFlow": registerFlow
        }
        axios.post(config.adminUrl + '/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    history.push("/verifyotp", { email: email, registerFlow: registerFlow })
                } else {
                    toast.error(msg)
                }
            })
            .catch(function (error) {
                toast.error(error.message)
            });
    }

    return (
        <div className="h-screen w-full grid place-items-center">
            <img src={logo} className="cursor-pointer" style={{ position: "absolute", top: "10px", left: "4rem", width: "120px" }} onClick={() => history.push('/')} />

            <div className="border-none rounded py-4 w-full">
                <div className='mx-auto w-full max-w-2xl border-2 border-bermuda rounded-xl p-10 px-20'>
                    <center className="text-3xl font-semibold">Forgot Password</center>
                    <center className="mt-2 text-base font-normal">Enter the registered email address,<br />
                        You'll receive a link to reset password.</center>
                    <div className="mt-10 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                        <input type="text" aria-describedby="text"
                            className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <span id="msg" className="text-sm text-red-500 text-left w-full"></span>
                    <div className='grid grid-cols-3 gap-6 mt-6'>
                        <div className="z-40 rounded w-full text-black-500 bg-bermuda">
                            <button className={` z-50 rounded w-full border-2 bg-lightpink border-bermuda px-4 py-2 text-lg transform -translate-x-1 -translate-y-1`}
                                onClick={() => history.push('/')}
                            >Cancel</button>
                        </div>
                        <div className="z-40 rounded w-full text-black-500 bg-bermuda col-span-2">
                            <button className={` z-50 rounded w-full border-2 bg-cream border-bermuda px-4 py-2 text-lg transform -translate-x-1 -translate-y-1`}
                                onClick={forgotPassword}
                            >Submit</button>
                        </div>
                    </div>
                    <center className="font-medium mt-6">Remembered you password? <span className='text-blue-500 cursor-pointer' onClick={() => history.push('/login')}>Login here</span></center>
                </div>
            </div>
        </div>
    );
}







