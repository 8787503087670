import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getPlan, getPlans } from '../../services/service'
import usePayment from '../../hooks/usePayment'
import PaymentSuccess from '../Reusable/PaymentSuccess'
import { useSelector } from 'react-redux'
import { selectAdminDetails, selectAdminPlan } from '../../slices/adminSlice'
import InvoicePopup from '../Reusable/ViewInvoicePopup'


function Billing() {
    const adminInfo = useSelector(selectAdminDetails);
    const adminPlan = useSelector(selectAdminPlan);
    const [show, setShow] = useState(0)
    const [plan, setPlan] = useState();
    const [upgradePlan, setUpgradePlan] = useState()
    let { data: planRes, isLoading } = useQuery('getPlan', getPlan, {
        refetchOnWindowFocus: false,
        onSuccess: res => setPlan(res?.data?.data[0] || null)
    })

    const { createOrder, success, setSuccess } = usePayment()

    const closeSuccess = () => {
        setSuccess(false);
        window.location.reload();
    }


    const { data: PlanRes } = useQuery('getPlans', getPlans, { retry: false, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: 600000 })

    const checkPlan = (i) => {
        if (i == 0) {
            return true
        }
        else if (i == 1) {
            return adminInfo?.typeOfIndustry != 0
        }
        else if (i == 2) {
            return ![1, 2].includes(adminInfo?.typeOfIndustry)
        }
        else if (i == 3) {
            return ![3, 4].includes(adminInfo?.typeOfIndustry)
        }
    }

    useEffect(() => {
        if (PlanRes?.data?.data) {
            let item = PlanRes?.data?.data.find((el, i) => !checkPlan(i));
            if ((!adminPlan || adminPlan?.price == 0)) {
                setUpgradePlan(item)
            }
        }
    }, [PlanRes?.data?.data])

    return (
        <>
            {success ? <PaymentSuccess {...success} closeSuccess={closeSuccess} /> : <></>}

            <section className='flex items-start gap-5 relative'>
                {isLoading
                    ?
                    <>
                        <div className='absolute w-full min-h-[250px] grid place-items-center'>
                            <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='w-full max-w-xs sticky top-24 p-5'>
                            <div className='bg-lightgreen rounded-2xl transform translate-x-2 translate-y-2'>
                                <div className='bg-pinkshade p-5 pb-0 rounded-2xl transform -translate-x-2 -translate-y-2'>
                                    <h1 className='text-7xl font-medium text-center'>{moment(plan?.validUpto).diff(moment(), 'days') + 1}</h1>
                                    <p className='text-3xl text-center'>Days Left</p>
                                    <p className='mt-4'>{(!adminPlan || adminPlan?.price == 0) ? 'Eligible for' : 'Your plan'}:</p>
                                    <p className='font-medium text-lg mb-4 capitalize'>{(!adminPlan || adminPlan?.price == 0) ? upgradePlan?.title : plan?.title} plan</p>

                                    <div className='flex items-center justify-between'>
                                        <img src={plan?.icon} className='h-20' alt="" />
                                        {
                                            adminPlan?.paymentAmount == 0
                                                ?
                                                <div className='bg-bermuda rounded-md max-w-max cursor-pointer' onClick={() => createOrder({ planId: upgradePlan?.id })}>
                                                    <button className='py-1.5 px-3 rounded-md bg-lightgreen transform -translate-x-1 -translate-y-1'>Upgrade Now!</button>
                                                </div>
                                                :
                                                ((adminInfo?.userRole?.toLowerCase() == 'subadmin' || adminInfo?.userRole?.toLowerCase() == 'admin') && moment(plan?.validUpto).diff(moment(), 'days') < 31)
                                                    ? <div className='bg-bermuda rounded-md max-w-max cursor-pointer' onClick={() => createOrder({ planId: plan.planId })}>
                                                        <button className='py-1.5 px-3 rounded-md bg-lightgreen transform -translate-x-1 -translate-y-1'>Renew Now!</button>
                                                    </div>
                                                    : <></>}
                                    </div>
                                </div>
                            </div>

                            <div className='bg-cream rounded-2xl transform translate-x-2 translate-y-2 mt-10'>
                                <div className='bg-[#FFF8E3] p-5 rounded-2xl transform -translate-x-2 -translate-y-2'>
                                    <h1 className='text-2xl font-medium'>Benefits {(!adminPlan || adminPlan?.price == 0) ? <span className='font-normal text-sm'>(after upgrading)</span> : ''}</h1>
                                    <ul className='list-disc w-full text-sm pl-4 py-1'>
                                        {
                                            (!adminPlan || adminPlan?.price == 0)
                                                ?
                                                upgradePlan?.items?.map((list, i) => <li className='list-outside text-gray-800 mt-1' key={i}>{list}</li>)
                                                :
                                                plan?.items?.map((list, i) => <li className='list-outside text-gray-800 mt-1' key={i}>{list}</li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {
                            (planRes?.data?.data?.length == 1 || show) ?
                                <div className='flex-grow text-center py-5 pr-10 h-[85dvh] overflow-y-auto'>
                                    <InvoicePopup canGoBack={planRes?.data?.data?.length == 1} data={planRes?.data?.data[planRes?.data?.data?.length == 1 ? 0 : show - 1]} goBack={() => setShow(0)} />
                                </div>
                                :
                                <div className='flex-grow text-center pr-10 h-[82dvh] overflow-y-auto'>
                                    <h1 className='text-3xl font-medium sticky top-0 bg-body pt-5 pb-3 text-left'>Billing</h1>
                                    <div>
                                        <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                                            <thead className='sticky top-16 font-medium'>
                                                <tr>
                                                    <td className='bg-lightviolet p-2 rounded-tl-2xl'>Date</td>
                                                    <td className='bg-lightviolet p-2'>Plan</td>
                                                    <td className='bg-lightviolet p-2'>Currency</td>
                                                    <td className='bg-lightviolet p-2'>Bill Amount</td>
                                                    <td className='bg-lightviolet p-2'>Paid on</td>
                                                    <td className='bg-lightviolet p-2 rounded-tr-2xl'>Action</td>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    planRes?.data?.data?.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2 rounded-l-md`}>{moment(item.orderOn).format('DD/MM/YY')}</td>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2`}>{item.title}</td>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2`}>{item.paymentCurrency}</td>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2`}>{item.paymentAmount}</td>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2`}>{moment(item.updatedAt).format('DD/MM/YY')}</td>
                                                                <td className={`${i % 2 == 0 ? 'bg-[#FAF0FF]' : 'bg-[#F5E2FF]'} p-2 rounded-l-md cursor-pointer`} onClick={() => setShow(i + 1)}>
                                                                    <div className='flex items-center justify-center gap-2'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                                        </svg>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }
                    </>
                }
            </section>
        </>
    )
}

export default Billing