import React from 'react'

function PaymentSuccess({ icon, planName, closeSuccess }) {
  return (
    <section className='fixed inset-0 z-[100000] grid place-items-center p-5'>
      <div className='fixed inset-0 bg-black bg-opacity-80' onClick={() => closeSuccess()}></div>

      <div className='relative w-full max-w-3xl bg-white rounded-xl'>
        <Design />
        <div className='absolute inset-0 bg-transparent z-10 p-10 flex flex-col items-center justify-between'>

          <h1 className='text-4xl font-semibold bg-white py-1.5 px-10 rounded-lg capitalize'>Welcome {planName}!</h1>

          <p className='text-xl max-w-lg text-center bg-white py-2 px-10 rounded-lg transform -translate-y-12'>You are now on Corner Qube’s <span className='capitalize'>{planName}</span> plan! </p>

          <button onClick={() => closeSuccess()} className='py-2 px-8 rounded-lg bg-lightviolet text-xl font-medium' style={{ boxShadow: '4px 4px 0px rgba(0,0,0,0.8)' }}>Close</button>

          {icon ? <img src={icon} className='h-32 absolute left-0 bottom-0' alt="" /> : <></>}
        </div>
      </div>
    </section>
  )
}

export default PaymentSuccess


const Design = () => {
  return (
    <svg className='w-full h-full object-cover' viewBox="0 0 792 450" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M527.908 359.852H352.223V449.549H527.908V359.852Z" fill="#B9DDB5" />
      <path d="M440.211 359.289H527.67V269.797C479.362 269.797 440.211 309.853 440.211 359.289Z" fill="#F8B7C6" />
      <path d="M483.719 360.041H527.903V314.643C503.494 314.643 483.719 334.961 483.719 360.041Z" fill="#FEE8A7" />
      <path d="M132.512 180.047H176.315L176.201 135.029C152.249 135.029 132.512 155.295 132.512 180.047Z" fill="#F8B7C6" />
      <path d="M264.188 0.0898438H176.305V90.0441H264.188V0.0898438Z" fill="white" />
      <mask id="mask0_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="176" y="0" width="89" height="91">
        <path d="M264.188 0.0898438H176.305V90.0441H264.188V0.0898438Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2310_9527)">
        <path d="M176.31 33.5032C190.952 33.5032 204.733 39.3554 215.114 49.9789C225.556 60.669 231.308 74.8971 231.308 90.049C231.308 105.201 225.556 119.429 215.114 130.119C204.733 140.743 190.957 146.595 176.31 146.595C161.663 146.595 147.887 140.743 137.507 130.119C127.064 119.429 121.312 105.201 121.312 90.049C121.312 74.8971 127.064 60.669 137.507 49.9789C147.882 39.3554 161.663 33.5032 176.31 33.5032ZM176.31 23.0273C140.149 23.0273 110.836 53.0312 110.836 90.0443C110.836 127.057 140.149 157.061 176.31 157.061C212.471 157.061 241.784 127.057 241.784 90.0443C241.779 53.0312 212.466 23.0273 176.31 23.0273Z" fill="#FEE8A7" />
      </g>
      <mask id="mask1_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="176" y="0" width="89" height="91">
        <path d="M264.188 0.0898438H176.305V90.0441H264.188V0.0898438Z" fill="white" />
      </mask>
      <g mask="url(#mask1_2310_9527)">
        <path d="M176.31 55.136C194.981 55.136 210.176 70.7974 210.176 90.0492C210.176 109.301 194.986 124.962 176.31 124.962C157.635 124.962 142.445 109.301 142.445 90.0492C142.445 70.7974 157.635 55.136 176.31 55.136ZM176.31 44.6602C151.82 44.6602 131.969 64.9786 131.969 90.0492C131.969 115.12 151.82 135.438 176.31 135.438C200.8 135.438 220.652 115.12 220.652 90.0492C220.652 64.9786 200.795 44.6602 176.31 44.6602Z" fill="#FEE8A7" />
      </g>
      <mask id="mask2_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="176" y="0" width="89" height="91">
        <path d="M264.188 0.0898438H176.305V90.0441H264.188V0.0898438Z" fill="white" />
      </mask>
      <g mask="url(#mask2_2310_9527)">
        <path d="M176.308 75.6536C183.927 75.6536 190.127 82.0819 190.127 89.9864C190.127 97.891 183.927 104.319 176.308 104.319C168.689 104.319 162.49 97.891 162.49 89.9864C162.49 82.0819 168.689 75.6536 176.308 75.6536ZM176.308 67.5586C164.208 67.5586 154.395 77.6011 154.395 89.9864C154.395 102.372 164.204 112.414 176.308 112.414C188.412 112.414 198.222 102.372 198.222 89.9864C198.217 77.5964 188.408 67.5586 176.308 67.5586Z" fill="#FEE8A7" />
      </g>
      <mask id="mask3_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="176" y="0" width="89" height="91">
        <path d="M264.188 0.0898438H176.305V90.0441H264.188V0.0898438Z" fill="white" />
      </mask>
      <g mask="url(#mask3_2310_9527)">
        <path d="M176.308 10.5657C196.845 10.5657 216.164 18.7654 230.706 33.6507C245.315 48.6026 253.363 68.5067 253.363 89.6822C253.363 110.858 245.315 130.762 230.706 145.714C216.164 160.604 196.841 168.799 176.308 168.799C155.775 168.799 136.452 160.599 121.91 145.714C107.301 130.762 99.2532 110.858 99.2532 89.6822C99.2532 68.5067 107.301 48.6026 121.91 33.6507C136.452 18.7654 155.77 10.5657 176.308 10.5657ZM176.308 0.0898438C127.967 0.0898438 88.7773 40.2029 88.7773 89.687C88.7773 139.171 127.967 179.284 176.308 179.284C224.649 179.284 263.834 139.171 263.834 89.687C263.834 40.2029 224.649 0.0898438 176.308 0.0898438Z" fill="#FEE8A7" />
      </g>
      <path d="M88.4072 0.185547H0.367188V89.6732H88.4072V0.185547Z" fill="#B8E3EB" />
      <path d="M88.4072 89.6739H0.367188V0.367188C48.999 0.367188 88.4072 40.3421 88.4072 89.6739Z" fill="#FEE8A7" />
      <path d="M43.0896 89.8788H0.367188V45.3184C23.9664 45.3184 43.0896 65.2606 43.0896 89.8788Z" fill="#F8B7C6" />
      <path d="M131.43 44.9277H87.8789V0.367188C111.935 0.367188 131.43 20.3142 131.43 44.9277Z" fill="#B8E3EB" />
      <path d="M264.187 89.959H176.309V180.009H264.187V89.959Z" fill="#FEE8A7" />
      <path d="M263.954 180.009H176.195V90.5215C224.675 90.5215 263.954 130.577 263.954 180.009Z" fill="#B8E3EB" />
      <path d="M220.134 180.004H176.188V135.029C200.468 135.029 220.134 155.162 220.134 180.004Z" fill="#B9DDB5" />
      <path d="M264.125 180.266H175.566V269.753H264.125V180.266Z" fill="#F8B7C6" />
      <path d="M263.49 180.266H176.031V269.753C224.344 269.753 263.49 229.697 263.49 180.266Z" fill="#B9DDB5" />
      <path d="M219.559 180.242H176.008V224.803C200.069 224.803 219.559 204.86 219.559 180.242Z" fill="white" />
      <path d="M176.037 180.07H88.5781V269.558H176.037V180.07Z" fill="#B9DDB5" />
      <path d="M88.5781 180.07H176.037V269.558C127.725 269.558 88.5781 229.502 88.5781 180.07Z" fill="#FEE8A7" />
      <path d="M132.512 180.047H176.063V224.607C152.002 224.612 132.512 204.665 132.512 180.047Z" fill="#F8B7C6" />
      <path d="M176.291 269.82H88.832V359.608H176.291V269.82Z" fill="#F8B7C6" />
      <path d="M176.289 359.608H88.4062V269.82C136.952 269.82 176.289 310.01 176.289 359.608Z" fill="white" />
      <path d="M132.357 359.627H88.4062V315.066C112.686 315.066 132.357 335.013 132.357 359.627Z" fill="#FEE8A7" />
      <path d="M176.196 360.336H88.5078V449.824H176.196V360.336Z" fill="#F8B7C6" />
      <path d="M176.289 359.541H88.4062V449.629C136.952 449.629 176.289 409.306 176.289 359.541Z" fill="#B8E3EB" />
      <path d="M131.406 359.727H87.8555V404.287C111.917 404.287 131.406 384.34 131.406 359.727Z" fill="white" />
      <path d="M352.291 0.0898438H264.832V89.5775H352.291V0.0898438Z" fill="#B9DDB5" />
      <path d="M351.409 89.5775H264.188V0.0898438C312.367 0.0898438 351.409 40.1457 351.409 89.5775Z" fill="#F8B7C6" />
      <path d="M351.984 359.875H264.32V449.61H351.984V359.875Z" stroke="#C1E0B8" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M264.226 359.775H176.195V449.511H264.226V359.775Z" fill="#F8B7C6" />
      <path d="M264.207 449.609H352.109V359.717C303.549 359.717 264.207 399.954 264.207 449.609Z" fill="#B8E3EB" />
      <path d="M307.477 89.6158H264.188V45.041C288.101 45.041 307.477 64.9928 307.477 89.6158Z" fill="white" />
      <path d="M87.417 359.879H0.605469V449.633H87.417V359.879Z" stroke="#C1E0B8" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M88.4062 449.633H0.623322V359.693C49.117 359.693 88.4062 399.953 88.4062 449.633Z" fill="#B8E3EB" />
      <path d="M44.6992 359.76H88.4074V404.254C64.2653 404.254 44.6992 384.335 44.6992 359.76Z" fill="#B9DDB5" />
      <path d="M88.3746 269.82H0.625V359.736H88.3746V269.82Z" fill="#B9DDB5" />
      <path d="M0.625 359.736H88.3746V269.82C39.8999 269.82 0.625 310.067 0.625 359.736Z" fill="#FEE8A7" />
      <path d="M44.6992 359.759H88.3931V314.984C64.2605 314.984 44.6992 335.022 44.6992 359.759Z" fill="#F8B7C6" />
      <path d="M132.312 90.002H176.197V135.034C151.955 135.034 132.312 114.877 132.312 90.002Z" fill="#B9DDB5" />
      <path d="M176.106 135.033H132.336V179.594C156.516 179.594 176.106 159.651 176.106 135.033Z" fill="#F8B7C6" />
      <path d="M43.0896 224.849H0.367188V179.879C23.9664 179.879 43.0896 200.007 43.0896 224.849Z" fill="#B8E3EB" />
      <path d="M43.0896 224.85H0.367188V269.82C23.9664 269.82 43.0896 249.692 43.0896 224.85Z" fill="#B8E3EB" />
      <path d="M88.4062 180.07H132.51V135.033C108.144 135.033 88.4062 155.194 88.4062 180.07Z" fill="#FEE8A7" />
      <path d="M88.4062 135.035H132.338V90.4648C108.068 90.4648 88.4062 110.417 88.4062 135.035Z" fill="#B8E3EB" />
      <path d="M352.066 134.752H308.277V89.8105C332.467 89.8153 352.066 109.929 352.066 134.752Z" fill="#B8E3EB" />
      <path d="M352.066 134.754H308.277V179.695C332.467 179.695 352.066 159.582 352.066 134.754Z" fill="#B8E3EB" />
      <path d="M264.32 134.744H308.595V179.686C284.139 179.686 264.32 159.567 264.32 134.744Z" fill="#B8E3EB" />
      <path d="M264.32 134.744H308.595V89.8027C284.139 89.8027 264.32 109.921 264.32 134.744Z" fill="#B8E3EB" />
      <path d="M131.43 44.9277H87.8789V89.8739C111.935 89.8787 131.43 69.7603 131.43 44.9277Z" fill="#B8E3EB" />
      <path d="M131.43 44.9277H176.309V0.367188C151.515 0.367188 131.43 20.3142 131.43 44.9277Z" fill="#F8B7C6" />
      <path d="M131.43 44.9277H176.309V89.8739C151.515 89.8787 131.43 69.7603 131.43 44.9277Z" fill="#F8B7C6" />
      <path d="M88.4084 224.921H43.2812V180.07C68.209 180.07 88.4084 200.146 88.4084 224.921Z" fill="#F8B7C6" />
      <path d="M88.4084 224.922H43.2812V269.773C68.209 269.778 88.4084 249.702 88.4084 224.922Z" fill="#FEE8A7" />
      <path d="M308.305 224.993H352.103V180.199C327.909 180.199 308.305 200.251 308.305 224.993Z" fill="#F8B7C6" />
      <path d="M308.305 224.992H352.103V269.786C327.909 269.786 308.305 249.734 308.305 224.992Z" fill="#F8B7C6" />
      <path d="M308.045 224.993H264.246V180.199C288.441 180.199 308.045 200.251 308.045 224.993Z" fill="#B9DDB5" />
      <path d="M308.045 224.992H264.246V269.786C288.441 269.786 308.045 249.734 308.045 224.992Z" fill="#B9DDB5" />
      <path d="M220.112 270.035C195.913 270.035 176.309 290.106 176.309 314.896C176.309 339.676 195.913 359.756 220.112 359.756C244.311 359.756 264.177 339.676 264.177 314.896C264.172 290.106 244.311 270.035 220.112 270.035ZM220.112 343.171C204.855 343.171 192.499 330.509 192.499 314.896C192.499 299.272 204.855 286.611 220.112 286.611C235.245 286.758 247.725 299.363 247.725 314.896C247.725 330.419 235.245 343.028 220.112 343.171Z" fill="#B8E3EB" />
      <path d="M88.4072 90.2344H0.367188V179.722H88.4072V90.2344Z" fill="#B8E3EB" />
      <path d="M88.4072 89.8789H0.367188V179.724C48.999 179.724 88.4072 139.506 88.4072 89.8789Z" fill="#B9DDB5" />
      <path d="M43.0896 89.8535H0.367188V134.233C23.9664 134.233 43.0896 114.367 43.0896 89.8535Z" fill="white" />
      <path d="M791.294 0.582031H703.254V90.0697H791.294V0.582031Z" fill="#B8E3EB" />
      <path d="M703.594 89.877H791.634V0.570312C742.997 0.570312 703.594 40.5452 703.594 89.877Z" fill="#FEE8A7" />
      <path d="M748.906 90.082H791.629V45.5215C768.029 45.5215 748.906 65.4637 748.906 90.082Z" fill="white" />
      <path d="M791.058 360.246H704.246V450H791.058V360.246Z" stroke="#C1E0B8" stroke-width="0.5" stroke-miterlimit="10" />
      <path d="M703.256 450H791.039V360.06C742.55 360.06 703.256 400.316 703.256 450Z" fill="#B8E3EB" />
      <path d="M746.962 360.127H703.254V404.621C727.396 404.621 746.962 384.707 746.962 360.127Z" fill="#B9DDB5" />
      <path d="M791.523 270.055H703.773V359.971H791.523V270.055Z" fill="#B9DDB5" />
      <path d="M790.656 360.093H702.906V270.172C751.376 270.172 790.656 310.418 790.656 360.093Z" fill="#FEE8A7" />
      <path d="M746.577 360.113H702.883V315.338C727.02 315.338 746.577 335.38 746.577 360.113Z" fill="#F8B7C6" />
      <path d="M745.976 225.25H703.254V179.332C726.853 179.332 745.976 199.884 745.976 225.25Z" fill="#B8E3EB" />
      <path d="M745.976 225.25H703.254V270.22C726.853 270.22 745.976 250.087 745.976 225.25Z" fill="#B8E3EB" />
      <path d="M791.295 224.754H746.168V179.332C771.096 179.332 791.295 199.66 791.295 224.754Z" fill="#F8B7C6" />
      <path d="M791.295 224.754H746.168V270.176C771.096 270.176 791.295 249.844 791.295 224.754Z" fill="#FEE8A7" />
      <path d="M791.448 90.0684H703.465V179.332H791.448V90.0684Z" fill="white" />
      <path d="M703.465 89.7109H791.448V179.332C742.849 179.332 703.465 139.219 703.465 89.7109Z" fill="#B9DDB5" />
      <path d="M748.754 89.6914H791.453V133.957C767.863 133.957 748.754 114.143 748.754 89.6914Z" fill="white" />
      <path d="M239.321 315.337V315.347C239.311 326.051 230.893 334.742 220.469 334.861C220.393 334.87 220.321 334.87 220.245 334.87C209.703 334.87 201.17 326.132 201.16 315.347V315.337C201.16 304.552 209.703 295.805 220.245 295.805C220.321 295.805 220.393 295.805 220.469 295.814C230.902 295.933 239.321 304.633 239.321 315.337Z" fill="#B8E3EB" />
      <path d="M263.816 444.371C261.011 397.02 222.76 359.545 176.552 359.545H176.285V392.968H176.552C206.579 393.263 231.35 418.681 231.35 449.99H263.992C263.992 448.094 263.94 446.223 263.816 444.371Z" fill="#B9DDB5" />
      <path d="M220.35 449.966V449.985H176.309V404.844H176.575C176.751 404.844 176.913 404.844 177.09 404.863C201.032 405.139 220.35 425.234 220.35 449.966Z" fill="white" />
      <path d="M292 269.82C291.99 285.296 279.591 297.853 264.543 298H264.41V314.538H264.543C287.719 314.538 306.895 295.967 308.271 272.53C308.323 271.63 308.352 270.735 308.352 269.825H292V269.82Z" fill="#FEE8A7" />
      <path d="M286.318 269.82C286.299 282.072 276.699 292.01 264.805 292.148C264.714 292.158 264.638 292.158 264.547 292.158H264.414V269.825H286.318V269.82Z" fill="#FEE8A7" />
      <path d="M351.979 298.006C336.732 298.006 324.38 285.383 324.38 269.816H308.199C308.199 270.726 308.228 271.621 308.28 272.521C309.647 295.963 328.684 314.539 351.984 314.539H352.107V298.001H351.979V298.006Z" fill="#B9DDB5" />
      <path d="M352.104 269.82V292.162H351.98C339.886 292.162 330.095 282.163 330.086 269.82H352.104Z" fill="#B9DDB5" />
      <path d="M264.32 331.441C279.567 331.441 291.919 344.064 291.919 359.631H308.1C308.1 358.721 308.071 357.826 308.019 356.926C306.652 333.484 287.614 314.908 264.315 314.908H264.191V331.446H264.32V331.441Z" fill="#B9DDB5" />
      <path d="M264.195 359.631V337.289H264.319C276.414 337.289 286.204 347.289 286.214 359.631H264.195Z" fill="#B9DDB5" />
      <path d="M351.972 314.895C328.692 314.895 309.664 333.437 308.268 356.86C308.216 357.774 308.188 358.693 308.188 359.617C308.188 359.626 308.188 359.626 308.188 359.636H324.368C324.368 359.626 324.368 359.626 324.368 359.617C324.368 344.041 336.72 331.418 351.967 331.418H352.1V314.895H351.972Z" fill="#FEE8A7" />
      <path d="M352.102 337.265V359.636H330.074C330.074 359.627 330.074 359.627 330.074 359.617V359.608C330.074 347.265 339.874 337.256 351.969 337.256C352.012 337.261 352.059 337.261 352.102 337.265Z" fill="#FEE8A7" />
      <path d="M615.552 269.85H527.902V360.042H615.552V269.85Z" fill="white" />
      <mask id="mask4_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="527" y="269" width="89" height="92">
        <path d="M615.552 269.852H527.902V360.044H615.552V269.852Z" fill="white" />
      </mask>
      <g mask="url(#mask4_2310_9527)">
        <path d="M527.903 215.987C556.56 215.987 579.873 240.148 579.873 269.847C579.873 299.546 556.56 323.708 527.903 323.708C499.247 323.708 475.938 299.551 475.938 269.852C475.938 240.153 499.247 215.987 527.903 215.987ZM527.903 202.654C491.838 202.654 462.605 232.739 462.605 269.847C462.605 306.956 491.843 337.04 527.903 337.04C563.969 337.04 593.206 306.956 593.206 269.847C593.206 232.739 563.969 202.654 527.903 202.654Z" fill="#B9DDB5" />
      </g>
      <mask id="mask5_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="527" y="269" width="89" height="92">
        <path d="M615.552 269.852H527.902V360.044H615.552V269.852Z" fill="white" />
      </mask>
      <g mask="url(#mask5_2310_9527)">
        <path d="M527.902 237.677C544.935 237.677 558.792 252.11 558.792 269.852C558.792 287.594 544.935 302.027 527.902 302.027C510.869 302.027 497.013 287.594 497.013 269.852C497.013 252.11 510.869 237.677 527.902 237.677ZM527.902 224.344C503.479 224.344 483.68 244.719 483.68 269.852C483.68 294.984 503.479 315.36 527.902 315.36C552.325 315.36 572.124 294.984 572.124 269.852C572.129 244.719 552.325 224.344 527.902 224.344Z" fill="#B9DDB5" />
      </g>
      <mask id="mask6_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="527" y="269" width="89" height="92">
        <path d="M615.552 269.852H527.902V360.044H615.552V269.852Z" fill="white" />
      </mask>
      <g mask="url(#mask6_2310_9527)">
        <path d="M527.902 260.757C532.602 260.757 536.421 264.861 536.421 269.914C536.421 274.966 532.598 279.07 527.902 279.07C523.203 279.07 519.384 274.966 519.384 269.914C519.384 264.861 523.207 260.757 527.902 260.757ZM527.902 247.424C515.831 247.424 506.051 257.49 506.051 269.914C506.051 282.332 515.836 292.403 527.902 292.403C539.969 292.403 549.754 282.337 549.754 269.914C549.759 257.49 539.974 247.424 527.902 247.424Z" fill="#B9DDB5" />
      </g>
      <mask id="mask7_2310_9527" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="527" y="269" width="89" height="92">
        <path d="M615.552 269.852H527.902V360.044H615.552V269.852Z" fill="white" />
      </mask>
      <g mask="url(#mask7_2310_9527)">
        <path d="M527.9 193.71C547.585 193.71 566.113 201.619 580.069 215.981C594.126 230.447 601.869 249.703 601.869 270.212C601.869 290.716 594.126 309.978 580.069 324.444C566.113 338.805 547.585 346.715 527.9 346.715C508.215 346.715 489.687 338.805 475.73 324.444C461.673 309.978 453.931 290.721 453.931 270.212C453.931 249.708 461.673 230.447 475.73 215.981C489.687 201.619 508.215 193.71 527.9 193.71ZM527.9 180.377C479.687 180.377 440.598 220.595 440.598 270.212C440.598 319.825 479.682 360.047 527.9 360.047C576.117 360.047 615.202 319.83 615.202 270.212C615.202 220.595 576.117 180.377 527.9 180.377Z" fill="#B9DDB5" />
      </g>
      <path d="M703.256 360.061H615.797V449.548H703.256V360.061Z" fill="#B9DDB5" />
      <path d="M615.797 360.061H703.256V449.548C654.943 449.548 615.797 409.497 615.797 360.061Z" fill="#F8B7C6" />
      <path d="M659.727 360.041H703.278V404.602C679.216 404.602 659.727 384.655 659.727 360.041Z" fill="white" />
      <path d="M571.387 404.602H615.685V449.548C591.215 449.548 571.387 429.429 571.387 404.602Z" fill="#FEE8A7" />
      <path d="M527.682 179.77H439.785V269.652H527.682V179.77Z" fill="#B8E3EB" />
      <path d="M439.785 269.654H527.706V180.166C479.141 180.166 439.785 220.222 439.785 269.654Z" fill="#F8B7C6" />
      <path d="M483.719 269.672H527.708V225.111C503.409 225.111 483.719 245.058 483.719 269.672Z" fill="white" />
      <path d="M615.166 179.889H527.707V269.843H615.166V179.889Z" fill="#FEE8A7" />
      <path d="M615.168 269.848H527.785V179.922C576.06 179.922 615.168 220.173 615.168 269.848Z" fill="#B9DDB5" />
      <path d="M571.238 269.867H527.688V225.307C551.744 225.307 571.238 245.254 571.238 269.867Z" fill="#FEE8A7" />
      <path d="M526.582 89.8438H615.17V179.941C566.233 179.941 526.582 139.614 526.582 89.8438Z" fill="#B8E3EB" />
      <path d="M572.129 90.0645H615.68V134.625C591.624 134.625 572.129 114.678 572.129 90.0645Z" fill="white" />
      <path d="M614.936 0.0898438H527.477V89.5775H614.936V0.0898438Z" fill="white" />
      <path d="M527.227 89.9772H615.805V0.0800781C566.873 0.0800781 527.227 40.3169 527.227 89.9772Z" fill="#B9DDB5" />
      <path d="M571.992 90.0401H615.824V45.2559C591.611 45.2559 571.992 65.3028 571.992 90.0401Z" fill="#F8B7C6" />
      <path d="M440.046 449.599H352.105V360.111C400.685 360.111 440.046 400.167 440.046 449.599Z" fill="#FEE8A7" />
      <path d="M527.225 0.490234H439.766V89.9779H527.225V0.490234Z" fill="#F8B7C6" />
      <path d="M527.225 89.627H439.766V179.115H527.225V89.627Z" fill="white" />
      <path d="M439.766 0.490234H527.225V89.9779C478.912 89.9779 439.766 49.922 439.766 0.490234Z" fill="#FEE8A7" />
      <path d="M439.752 0.304688H352.293V89.7923C400.606 89.7923 439.752 49.7364 439.752 0.304688Z" fill="#B9DDB5" />
      <path d="M396.112 449.623H352.223V405.062C376.47 405.062 396.112 425.005 396.112 449.623Z" fill="white" />
      <path d="M703.692 0.119141H616.242V89.783H703.692V0.119141Z" fill="#B9DDB5" />
      <path d="M703.464 89.8781H615.824V0C664.237 0 703.464 40.2321 703.464 89.8781Z" fill="#F8B7C6" />
      <path d="M658.596 90.0691H614.836V45.2324C639.011 45.2324 658.596 65.2985 658.596 90.0691Z" fill="#FEE8A7" />
      <path d="M703.465 89.8789H615.168V179.943C663.947 179.943 703.465 139.63 703.465 89.8789Z" fill="#B8E3EB" />
      <path d="M658.488 90.0645H614.938V134.625C638.994 134.625 658.488 114.678 658.488 90.0645Z" fill="#B9DDB5" />
      <path d="M440.184 449.986H483.735V404.578C459.678 404.578 440.184 424.906 440.184 449.986Z" fill="#F8B7C6" />
      <path d="M440.184 359.289H483.735V404.583C459.678 404.578 440.184 384.307 440.184 359.289Z" fill="#F8B7C6" />
      <path d="M659.727 224.908H703.278V269.469C679.216 269.469 659.727 249.526 659.727 224.908Z" fill="#B9DDB5" />
      <path d="M659.727 224.903H703.278V179.404C679.216 179.404 659.727 199.77 659.727 224.903Z" fill="#B9DDB5" />
      <path d="M483.668 404.077H527.771V359.607C503.405 359.603 483.668 379.507 483.668 404.077Z" fill="white" />
      <path d="M483.668 404.078H527.771V448.729C503.405 448.724 483.668 428.739 483.668 404.078Z" fill="white" />
      <path d="M396.069 269.939V359.494C371.775 359.494 352.09 339.452 352.09 314.714C352.09 289.982 371.775 269.939 396.069 269.939Z" fill="#B8E3EB" />
      <path d="M439.888 314.729C439.888 339.461 420.279 359.504 396.07 359.504V269.949C420.279 269.949 439.888 289.991 439.888 314.729Z" fill="#F8B7C6" />
      <path d="M571.387 404.601H615.795V359.684C591.262 359.684 571.387 379.788 571.387 404.601Z" fill="#FEE8A7" />
      <path d="M571.387 404.502H527.836V449.548C551.892 449.548 571.387 429.387 571.387 404.502Z" fill="#B9DDB5" />
      <path d="M571.387 404.602H527.836V360.041C551.892 360.041 571.387 379.988 571.387 404.602Z" fill="#B9DDB5" />
      <path d="M615.168 224.703H659.524V269.968C635.024 269.968 615.168 249.707 615.168 224.703Z" fill="#F8B7C6" />
      <path d="M615.168 224.702H659.533V179.332C635.029 179.332 615.168 199.641 615.168 224.702Z" fill="#F8B7C6" />
      <path d="M395.699 224.822H352.148V269.821C376.205 269.821 395.699 249.679 395.699 224.822Z" fill="#B8E3EB" />
      <path d="M395.699 224.849H352.148V180.018C376.205 180.013 395.699 200.084 395.699 224.849Z" fill="#FEE8A7" />
      <path d="M395.488 224.822H440.001V269.868C415.411 269.868 395.488 249.707 395.488 224.822Z" fill="#B9DDB5" />
      <path d="M395.488 224.822H440.001V179.775C415.411 179.771 395.488 199.937 395.488 224.822Z" fill="#B9DDB5" />
      <path d="M483.847 178.928C507.908 178.928 527.398 158.99 527.398 134.367C527.398 109.754 507.908 89.8066 483.847 89.8066C459.786 89.8066 440.039 109.754 440.039 134.367C440.039 158.99 459.786 178.928 483.847 178.928ZM483.847 106.282C499.018 106.282 511.303 118.858 511.303 134.367C511.303 149.886 499.018 162.461 483.847 162.461C468.8 162.319 456.391 149.795 456.391 134.367C456.391 118.953 468.8 106.425 483.847 106.282Z" fill="#FEE8A7" />
      <path d="M703.256 269.605H615.797V359.703H703.256V269.605Z" fill="#F8B7C6" />
      <path d="M615.797 359.683H703.256V270.195C654.943 270.195 615.797 310.246 615.797 359.683Z" fill="#B8E3EB" />
      <path d="M659.727 359.703H703.278V315.143C679.216 315.143 659.727 335.09 659.727 359.703Z" fill="white" />
      <path d="M462.078 134.376V134.367C462.088 122.158 471.697 112.248 483.592 112.115C483.682 112.105 483.758 112.105 483.849 112.105C495.882 112.105 505.62 122.072 505.629 134.367V134.376C505.629 146.676 495.877 156.647 483.849 156.647C483.758 156.647 483.682 156.647 483.592 156.637C471.683 156.504 462.078 146.585 462.078 134.376Z" fill="#B9DDB5" />
      <path d="M395.946 93.0439C397.35 116.372 416.516 134.838 439.668 134.838H439.801V118.372H439.668C424.621 118.229 412.212 105.705 412.212 90.2773H395.86C395.855 91.2106 395.884 92.1297 395.946 93.0439Z" fill="#F8B7C6" />
      <path d="M412.211 179.772C412.221 164.353 424.62 151.839 439.668 151.696H439.801V135.221H439.668C416.492 135.221 397.316 153.72 395.94 177.077C395.888 177.972 395.859 178.867 395.859 179.772H412.211Z" fill="#B9DDB5" />
      <path d="M352.465 151.685C367.635 151.685 379.921 164.26 379.921 179.769H396.016C396.016 178.865 395.987 177.97 395.935 177.074C394.573 153.718 375.64 135.209 352.46 135.209H352.336V151.685H352.465Z" fill="#B9DDB5" />
      <path d="M352.472 134.857C375.633 134.857 394.557 116.381 395.947 93.0439C396 92.1297 396.028 91.2154 396.028 90.2964C396.028 90.2869 396.028 90.2869 396.028 90.2773H379.933C379.933 90.2869 379.933 90.2869 379.933 90.2964C379.933 105.815 367.648 118.391 352.477 118.391H352.344V134.857H352.472Z" fill="#F8B7C6" />
      <path d="M615.797 360.189H703.256V270.701C654.943 270.701 615.797 310.757 615.797 360.189Z" fill="#B8E3EB" />
      <path d="M659.727 360.008H703.278V315.447C679.216 315.447 659.727 335.389 659.727 360.008Z" fill="#B9DDB5" />
    </svg>
  )
}