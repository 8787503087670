import moment from 'moment'
import React, { useState } from 'react'
import ReviewStar from '../../images/reviewstar.svg'
import WarnPopUp from '../Reusable/WarnPopUp'
import { useDeleteProject } from '../../hooks'

const Project = ({ adminInfo, item, handleProjectClick }) => {
    const [popUp, setPopUp] = useState(false);

    let { isLoading, mutate: deleteProject } = useDeleteProject('getProjectsByAdmin', () => setPopUp(false))

    return (
        <>
            {popUp ? <WarnPopUp disabled={isLoading} onOk={() => deleteProject(item?.id)} onCancel={() => setPopUp(false)} heading={'Are you sure to delete this project?'} description={'The action is irreversible'} /> : <></>}
            <div className='rounded-lg relative'>
                {adminInfo?.uuid == item?.addedBy ? <span onClick={() => setPopUp(true)} className='text-red-400 cursor-pointer bg-white p-0.5 rounded-full absolute -top-3 -right-3 z-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </span> : <></>}
                {item?.confidential && <span className='flex items-center gap-1 text-xs absolute -top-4 left-1/2 transform -translate-x-1/2 z-10 p-2 bg-white text-orange-600'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 9V6.5C13 5.70435 12.6839 4.94129 12.1213 4.37868C11.5587 3.81607 10.7956 3.5 10 3.5C9.20435 3.5 8.44129 3.81607 7.87868 4.37868C7.31607 4.94129 7 5.70435 7 6.5V9M6.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V10.5C15 10.1022 14.842 9.72064 14.5607 9.43934C14.2794 9.15804 13.8978 9 13.5 9H6.5C6.10218 9 5.72064 9.15804 5.43934 9.43934C5.15804 9.72064 5 10.1022 5 10.5V15C5 15.3978 5.15804 15.7794 5.43934 16.0607C5.72064 16.342 6.10218 16.5 6.5 16.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Confidential Project</span>
                </span>}
                <div className='p-4 rounded-lg custom-shadow relative w-full h-full overflow-hidden'>
                    {item?.draft ? <div className='absolute top-3 -left-8 bg-cream text-orange-800 text-sm text-center p-1 transform -rotate-45 w-28 z-10 shadow-lg'>Draft</div> : ''}

                    <div className='flex items-center justify-between'>
                        <h1 className='font-medium text-gray-600'>{item?.companyName}</h1>
                        <p className='min-w-max text-xs text-right'>{item?.reviewCount?.count || 0} review(s) provided</p>
                    </div>
                    <div className='flex items-start justify-between w-full'>
                        <div className='flex items-center gap-2 cursor-pointer' onClick={() => handleProjectClick(item)}>
                            <div className='w-20 h-20 rounded-md overflow-hidden'>
                                <img className='w-full h-full object-contain filter drop-shadow' src={item.logo || adminInfo?.companyIcon} alt="" />
                            </div>
                            <div>
                                <h1 className='text-xl font-medium cursor-pointer'>{item.projectName}</h1>
                                <div className='flex items-center text-xs text-violet-800 min-w-max'><img className='w-7 h-7 m-1' src={ReviewStar} alt="" /> <span className='text-xl'>{item?.clientReview?.rating ? Number(item?.clientReview?.rating).toFixed(1) : 0}</span>&nbsp;<span>Client Rating ({item?.clientReview?.count || 0})</span></div>
                                <div className='flex items-center text-xs text-sky-800 min-w-max'><img className='w-7 h-7 m-1' src={ReviewStar} alt="" /> <span className='text-xl'>{item?.vendorReview?.rating ? Number(item?.vendorReview?.rating).toFixed(1) : 0}</span>&nbsp;<span>Vendor Rating ({item?.vendorReview?.count || 0})</span></div>
                            </div>
                        </div>

                        <div className='text-xs text-right'>
                            <p>{moment(item.createdAt).format('DD-MMM-YY')}</p>
                        </div>
                    </div>

                    <h1 className='font-medium mt-2 text-lg text-center'>{item.vendors?.length || 0}/5 Vendor(s) added</h1>
                    <p className='text-xs leading-relaxed text-center my-1 line-clamp-2 h-16'>{item.description || 'No Description Added'}</p>

                    <div className='flex justify-between items-center text-sm'>
                        <div>
                            <p>Engagement duration: <span className='font-medium'>{item.duration} month(s)</span></p>
                            <p>Project cost: <span className='font-medium'>${item.cost}</span></p>
                        </div>

                        <button onClick={() => handleProjectClick(item)} className='bg-lightgreen text-green-900 rounded-lg shadow-lg py-2 px-3 text-sm font-medium'>
                            View Details
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Project