import React, { useEffect, useRef, useState } from 'react'
import { uploadImage, useClickOutside } from '../../services/constant';
import { FileUploader } from 'react-drag-drop-files';
import { useAddProject, useResendProjectVendorInvite } from '../../hooks';
import AddVendorAndClient from '../Vendor/AddVendorAndClient';
import { toast } from 'react-hot-toast';
import moment from 'moment';
const colorsbg = ["lightviolet", "babyblue", "lightpink", "lightgreen", "babycream"]

var venId;

function AddProject({ project, setAddProject, adminInfo, myCompany, heading, disabled = false }) {
    var toastId = ''
    const [companies, setCompanies] = useState(myCompany)
    const [uploadLogo, setUploadLogo] = useState(project?.logo || null)
    const [formData, setFormData] = useState({
        name: project?.projectName || '',
        duration: project?.duration || '',
        projectCost: project?.cost || '',
        description: project?.description || '',
        confidential: Boolean(project?.confidential) || false
    })
    const [companyName, setCompanyName] = useState('');
    const [vendors, setVendors] = useState(project?.vendors || []);
    const [delVendors, setDelVendors] = useState([])
    const [addVendor, setAddVendor] = useState(false)

    const projectRef = useRef()
    useClickOutside((e) => {
        if (!disabled) {
            if (project?.draft || !project.hasOwnProperty('draft')) {
                saveAsDraft(e)
            }
        }
    }, projectRef)

    useEffect(() => {
        if (project?.vendors) {
            let vendorArr = project?.vendors?.map(item => item.vendorId)
            let newComp = myCompany.filter(item => !vendorArr.includes(item.companyId))
            setCompanies(newComp)
        }
    }, [project?.vendors])

    const handleConfidential = () => {
        setFormData({ ...formData, confidential: !formData.confidential });
        setUploadLogo(adminInfo?.companyIcon)
    }

    const handleFormData = (e) => setFormData({ ...formData, [e.target.id]: e.target.value })

    const removeVendor = (item, i) => {
        let arr = [...vendors];
        arr.splice(i, 1);
        if (item?.id) {
            setDelVendors([...delVendors, item])
        }
        let company = myCompany.find(data => data.companyName == item.companyName)
        setVendors(arr)
        if (company)
            setCompanies([...companies, company])
    }

    const { isLoading: adding, mutate: createProject } = useAddProject(() => { toast.dismiss(toastId); setAddProject(false) })

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData?.description?.length > 200) {
            return toast.error('project description must be upto 200 characters', { id: 'error' })
        }
        else if (!formData?.projectCost || !formData?.name || !formData?.duration) {
            return toast.error('Please fill all the details', { id: 'error' })
        }
        let data = { ...formData, duration: Math.abs(formData?.duration), projectCost: Math.abs(formData?.projectCost), logo: uploadLogo, vendors: vendors, companyId: adminInfo?.companyId, delVendors: delVendors, draft: false };
        if (!data.logo) {
            data.logo = adminInfo?.companyIcon
        }
        if (project?.id) {
            data.docId = project?.id
        }
        createProject(data)
    }

    const saveAsDraft = (e) => {
        e?.preventDefault();
        let data = { ...formData, duration: Math.abs(formData?.duration), projectCost: Math.abs(formData?.projectCost), logo: uploadLogo, vendors: vendors, companyId: adminInfo?.companyId, delVendors: delVendors, draft: true };
        if (project?.id) {
            data.docId = project?.id
        }
        toastId = toast.loading('Saving Project Info')
        createProject(data)
    }

    const handleUploadLogo = async (file) => {
        if (file) {
            const res = await uploadImage(file);
            if (res) {
                setUploadLogo(res)
            }
            setUploadLogo(res)
        }
    };

    const checkCompany = (item, i) => {
        let index = delVendors.findIndex(el => el.vendorId == item.adminAddedCompanyId);
        if (index > -1) {
            setVendors([...vendors, delVendors[index]])
            let arr = [...delVendors];
            arr.splice(index, 1)
            setDelVendors(arr)
        }
        else
            setVendors([...vendors, { companyId: item.companyId, companyName: item?.companyName, services: item.offeringTags, status: 0 }])
        setCompanyName('');
        let arr = [...companies]
        let idx = arr.findIndex(item => item.companyName.toLowerCase().includes(item.companyName.toLowerCase()))
        if (idx > -1) {
            arr.splice(idx, 1);
            setCompanies(arr)
        }
    }

    const { mutate } = useResendProjectVendorInvite(() => { }, () => {
        if (venId) {
            let idx = vendors.findIndex(el => el.id == venId);
            if (idx > -1) {
                let arr = [...vendors];
                arr[idx].status = 2;
                setVendors([...arr]);
            }
        }
    });
    const resendInvite = (id) => {
        let idx = vendors.findIndex(el => el.id == id);
        if (idx > -1) {
            let arr = [...vendors];
            arr[idx].status = 0;
            setVendors([...arr]);
            venId = id
            mutate({ id, updatedAt: new Date().toISOString() })
        }
    }

    const handleService = (i, id) => {
        let arr = [...vendors];
        let ot = arr[i].services;
        let idx = ot.findIndex(item => item.id == id)
        if (idx > -1) {
            ot[idx].selected = !Boolean(ot?.[idx]?.selected)
        }
        arr[i].services = ot;
        setVendors(arr)
    }
    return (
        <>
            <section className='p-4'>
                <div className='px-4 bg-white rounded-lg shadow-lg' ref={projectRef}>
                    {heading && <div className='flex items-center gap-4 py-6 sticky top-20 text-gray-700 bg-white z-10'>
                        <svg onClick={(e) => {
                            if (!disabled) {
                                if (project?.draft || !project.hasOwnProperty('draft')) {
                                    saveAsDraft(e)
                                }
                            }
                            setAddProject(false)
                        }} className='cursor-pointer' width="40" height="20" viewBox="0 0 49 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939339 10.9393ZM49 10.5L2 10.5V13.5L49 13.5V10.5Z" fill="currentColor" />
                        </svg>
                        <h1 className='text-2xl font-medium'>{heading}</h1>
                    </div>}

                    <div className='flex items-start gap-3'>
                        <div className='bg-lightgreen rounded-full w-9 h-9 grid place-items-center'>
                            <span className='text-xl font-medium'>1</span>
                        </div>
                        <div className='flex-grow max-w-4xl'>
                            <div className='flex items-start justify-between'>
                                <div>
                                    <h1 className='text-xl font-medium'>Project Details</h1>
                                    {
                                        disabled
                                            ? project?.confidential ?
                                                <h1>This project is <span className='text-rose-600'>confidential</span></h1>
                                                :
                                                <h1>This project is <span className='text-green-600'>not confidential</span></h1>
                                            :
                                            <></>
                                    }
                                    {!disabled && <div className='flex items-center gap-2'>
                                        <p className='text-base'>Is this project confidential?</p>
                                        <label className="switch">
                                            <input type="checkbox" disabled={disabled} checked={formData?.confidential} defaultChecked={formData?.confidential} onChange={handleConfidential} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>}
                                </div>
                                {disabled ? <></> :
                                    (project?.draft || !project.hasOwnProperty('draft')) ?
                                        <button disabled={adding} onClick={(e) => saveAsDraft(e)} className='py-2 px-5 rounded shadow-lg bg-cream disabled:bg-gray-300 text-orange-800 text-sm font-medium'>Save as Draft</button>
                                        :
                                        ''
                                }
                            </div>
                            <div className='flex gap-8 items-start px-4 py-8'>
                                <div className='flex flex-col items-center justify-center gap-1'>
                                    <h1 className='text-lg font-medium'>Project logo</h1>
                                    <div className='w-32 h-32 flex flex-col items-center justify-center cursor-pointer relative p-1 my-1'>
                                        {
                                            (formData?.confidential || disabled)
                                                ?
                                                <>{uploadLogo ? <img src={uploadLogo} className="w-full h-full object-contain rounded filter drop-shadow" /> :
                                                    <div className='w-32 h-32 flex flex-col items-center justify-center rounded custom-shadow overflow-hidden'>
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.5454 4.72651C18.7237 5.19551 19.0842 6.82884 19.566 7.35384C20.0479 7.87884 20.7374 8.05734 21.1189 8.05734C23.1465 8.05734 24.7904 9.70117 24.7904 11.7277V18.4885C24.7904 21.2068 22.5854 23.4118 19.867 23.4118H8.13036C5.41086 23.4118 3.20703 21.2068 3.20703 18.4885V11.7277C3.20703 9.70117 4.85086 8.05734 6.87853 8.05734C7.25886 8.05734 7.94837 7.87884 8.43137 7.35384C8.9132 6.82884 9.27253 5.19551 10.4509 4.72651C11.6304 4.25751 16.367 4.25751 17.5454 4.72651Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M20.4101 11.0833H20.4206" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.7067 15.316C17.7067 13.2673 16.0466 11.6072 13.9979 11.6072C11.9492 11.6072 10.2891 13.2673 10.2891 15.316C10.2891 17.3647 11.9492 19.0248 13.9979 19.0248C16.0466 19.0248 17.7067 17.3647 17.7067 15.316Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <p className='font-medium'>Upload Logo</p>
                                                    </div>}
                                                </>
                                                :
                                                <FileUploader
                                                    multiple={false}
                                                    // disabled={formData?.confidential || disabled}
                                                    name="uploadLogo"
                                                    handleChange={(e) => formData?.confidential ? {} : handleUploadLogo(e)}
                                                    types={['jpg', 'jpeg', 'png', 'webp']}
                                                    children={
                                                        <>{uploadLogo ? <img src={uploadLogo} className="w-full h-full object-contain rounded filter drop-shadow cursor-pointer" /> :
                                                            <div className='w-32 h-32 flex flex-col items-center justify-center rounded custom-shadow overflow-hidden cursor-pointer'>
                                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5454 4.72651C18.7237 5.19551 19.0842 6.82884 19.566 7.35384C20.0479 7.87884 20.7374 8.05734 21.1189 8.05734C23.1465 8.05734 24.7904 9.70117 24.7904 11.7277V18.4885C24.7904 21.2068 22.5854 23.4118 19.867 23.4118H8.13036C5.41086 23.4118 3.20703 21.2068 3.20703 18.4885V11.7277C3.20703 9.70117 4.85086 8.05734 6.87853 8.05734C7.25886 8.05734 7.94837 7.87884 8.43137 7.35384C8.9132 6.82884 9.27253 5.19551 10.4509 4.72651C11.6304 4.25751 16.367 4.25751 17.5454 4.72651Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M20.4101 11.0833H20.4206" stroke="#130F26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.7067 15.316C17.7067 13.2673 16.0466 11.6072 13.9979 11.6072C11.9492 11.6072 10.2891 13.2673 10.2891 15.316C10.2891 17.3647 11.9492 19.0248 13.9979 19.0248C16.0466 19.0248 17.7067 17.3647 17.7067 15.316Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <h1 className='font-medium'>Upload Logo</h1>
                                                            </div>}
                                                        </>
                                                    }
                                                />
                                        }
                                        {(project?.confidential || formData?.confidential) ? <div className='absolute inset-0 w-full h-full bg-gray-900 bg-opacity-30 cursor-not-allowed rounded z-[1]'></div> : <></>}
                                    </div>
                                    {disabled ? <></> : <p className='italic w-full text-sm'>Default: company logo</p>}
                                </div>

                                <div className='flex-grow'>
                                    {disabled ? <div className='flex flex-col gap-1'>
                                        <label className='font-medium text-lg'>Project Owner</label>
                                        <div>
                                            <input disabled={true} value={project?.companyName} type="text" className={`custom-shadow rounded p-2 max-w-xs outline-none`} />
                                        </div>
                                    </div> : <></>}

                                    <div className={'flex flex-col gap-1 ' + (disabled ? ' mt-4' : '')}>
                                        <label className='font-medium text-lg'>Project name</label>
                                        <div>
                                            <input disabled={(disabled)} id='name' value={formData.name} onChange={handleFormData} type="text" className={`custom-shadow rounded p-2 max-w-xs outline-none`} />
                                        </div>
                                        {(project?.confidential || formData?.confidential) && <p className='italic text-xs w-full text-gray-500'>Display Name: confidential.</p>}
                                    </div>


                                    <div className='flex gap-10 mt-4'>
                                        <div className='flex flex-col gap-1'>
                                            <label className='font-medium text-lg'>Engagement Duration</label>
                                            <div>
                                                <input disabled={disabled} id='duration' value={formData.duration} onChange={(e) => (e.target.value < 999 && e.target.value >= 0 && e.target.value?.toString() != '00') && handleFormData(e)} type="number" maxLength={3} max={999} placeholder='Enter value' className='bg-white custom-shadow appearance-none rounded p-2 max-w-[120px] outline-none text-right mr-1' />
                                                <span className='text-lg font-medium'> Months</span>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <label className='font-medium text-lg'>Project Cost</label>
                                            <div>
                                                <span className='text-lg font-medium'>$ </span>
                                                <input disabled={disabled} id='projectCost' value={formData.projectCost} onChange={(e) => e.target.value >= 0 && e.target.value?.toString() != '00' && handleFormData(e)} type="number" placeholder='Enter value in USD' className='bg-white shadow-md border-2 border-gray-50 rounded p-2 max-w-xs outline-none ml-1' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-1 mt-6'>
                                        <label className='font-medium text-lg'>Project Description</label>
                                        <div className='relative'>
                                            <textarea disabled={(disabled)} id="description" value={formData.description} onChange={(e) => handleFormData(e)} className={`custom-shadow rounded p-2 h-40 w-full resize-none outline-none bg-white`}></textarea>
                                            <span className='text-sm absolute bottom-4 right-4'>{formData.description.length}/200</span>
                                        </div>
                                        {(project?.confidential || formData?.confidential) && <p className='italic w-full text-sm text-gray-500'>Display content: This is confidential.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='flex items-start gap-3'>
                        <div className='bg-lightgreen rounded-full w-9 h-9 grid place-items-center'>
                            <span className='text-xl font-medium'>2</span>
                        </div>
                        <div className='flex-grow relative max-w-4xl'>
                            <span className='absolute top-2 right-2 text-xl font-medium'>{vendors.filter(el => el.status !== 2).length}/5</span>
                            <h1 className='text-xl font-medium'>Vendor Details</h1>
                            <p className='text-xs text-gray-400'>Vendors must be part of your vendor list. Add upto 5 Vendors per project</p>

                            {
                                vendors.filter(el => el.status !== 2).map((item, i) =>
                                    <div className='pt-4 last:pb-6'>
                                        <div className='flex items-center justify-between'>
                                            <h1 className='text-xl font-medium mb-2'>Vendor {i + 1}/5</h1>
                                            {(!disabled && item.status == 0) && <svg onClick={() => removeVendor(item, i)} className='cursor-pointer' width="28" height="28" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M26.2042 6.77215C26.7067 6.77215 27.125 7.18935 27.125 7.72022V8.21105C27.125 8.729 26.7067 9.15912 26.2042 9.15912H4.79706C4.29332 9.15912 3.875 8.729 3.875 8.21105V7.72022C3.875 7.18935 4.29332 6.77215 4.79706 6.77215H8.56319C9.32822 6.77215 9.99402 6.22836 10.1661 5.46112L10.3633 4.58021C10.6699 3.38026 11.6786 2.58331 12.8331 2.58331H18.1669C19.3088 2.58331 20.3289 3.38026 20.6241 4.51692L20.8351 5.45983C21.006 6.22836 21.6718 6.77215 22.4381 6.77215H26.2042ZM24.2909 24.7148C24.6841 21.0503 25.3725 12.3446 25.3725 12.2568C25.3976 11.9907 25.3109 11.7388 25.1388 11.536C24.9541 11.3462 24.7205 11.2338 24.463 11.2338H6.54684C6.28806 11.2338 6.04184 11.3462 5.871 11.536C5.69764 11.7388 5.61222 11.9907 5.62478 12.2568C5.62709 12.2729 5.65179 12.5796 5.69309 13.0922C5.87654 15.3698 6.38751 21.7131 6.71769 24.7148C6.95134 26.9261 8.40227 28.3159 10.5039 28.3663C12.1257 28.4037 13.7964 28.4166 15.5049 28.4166C17.1141 28.4166 18.7484 28.4037 20.4205 28.3663C22.595 28.3288 24.0446 26.9635 24.2909 24.7148Z" fill="#BC0025" />
                                                <line x1="22.4243" y1="13.5657" x2="20.5657" y2="26.5757" stroke="white" strokeLinecap="round" />
                                                <line x1="10.4343" y1="26.5757" x2="8.57576" y2="13.5657" stroke="white" strokeLinecap="round" />
                                                <line x1="15.5" y1="13.5" x2="15.5" y2="26.5" stroke="white" strokeLinecap="round" />
                                            </svg>}
                                        </div>
                                        {!disabled && <label className='text-base font-medium'>Selected Vendor {item.status == 2 ? <span className='text-red-400 text-sm'>(Rejected)</span> : item.status == 1 ? <span className='text-blue-400 text-sm'>(Accepted)</span> : <span className='text-orange-400 text-sm'>(Pending)</span>}</label>}
                                        <div className='w-full relative py-1'>
                                            <input type="text" disabled name='name' value={item.companyName} className='bg-white w-full max-w-sm outline-none rounded p-2 custom-shadow' />
                                        </div>

                                        {!disabled && <div className='flex items-center my-3 gap-1'>
                                            <svg width="26" height="18" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.39904 22.4784L0.525897 13.7169C0.189147 13.3837 0 12.932 0 12.4611C0 11.9902 0.189147 11.5385 0.525897 11.2053L1.60391 10.1388C1.9412 9.80609 2.39833 9.61922 2.87495 9.61922C3.35157 9.61922 3.80872 9.80609 4.14601 10.1388L10.5961 16.5075L25.7864 0.558362C26.1133 0.215699 26.5644 0.0151824 27.0407 0.000827163C27.5171 -0.0135281 27.9797 0.159452 28.3271 0.481795L29.4342 1.51408C29.7814 1.83708 29.9846 2.28309 29.9992 2.75407C30.0137 3.22505 29.8384 3.68246 29.5117 4.02575L11.984 22.4387C11.819 22.6125 11.6203 22.7517 11.3997 22.8481C11.1791 22.9445 10.9412 22.9961 10.7001 22.9998C10.4589 23.0035 10.2194 22.9592 9.99594 22.8697C9.77244 22.7801 9.56945 22.647 9.39904 22.4784Z" fill="url(#paint0_linear_249_3159)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_249_3159" x1="25.3917" y1="1.27239" x2="7.07375" y2="18.5224" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#E3B900" />
                                                        <stop offset="1" stop-color="#47B000" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <h1>This <span className='capitalize'>vendor</span> is part of your vendor list</h1>
                                        </div>}

                                        {(project?.id) ?
                                            item?.tags?.length
                                                ? <div className='py-2'>
                                                    <h1 className='text-base font-medium mb-1'>Utilized services</h1>
                                                    <div className='flex items-center flex-wrap gap-3'>
                                                        {item.tags?.map((tag, i) => <span key={i} className={`text-sm py-1.5 px-3 cursor-pointer rounded-full max-w-max border-2 border-bermuda bg-${colorsbg[Math.abs((i - colorsbg.length) % colorsbg.length)]}`}>{tag.industryServices}</span>)}
                                                    </div>
                                                </div>
                                                :
                                                <p className='pt-1'>No Utilized service specified</p>
                                            : <></>}

                                        {(item?.services?.length || !project?.id) && <div className='py-4'>
                                            <h1 className='text-lg font-medium mb-1'>Select services utilized</h1>
                                            <div className='p-4 flex items-center flex-wrap gap-3 border rounded-lg shadow'>
                                                {
                                                    item?.services?.map((service) => <div key={service.id} onClick={() => handleService(i, service.id)} className={`py-2 px-4 cursor-pointer rounded-full ${service?.selected ? 'bg-lightblue' : 'bg-white'} text-sm flex items-center gap-2 max-w-max border-2 border-bermuda`}>
                                                        <span>{service.industryServices}</span>
                                                        {
                                                            service?.selected ?
                                                                <span>
                                                                    <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.6414 9.2907L10.932 9.99858L11.6414 10.7065L18.8011 17.8506L18.8032 17.8526C18.8656 17.9145 18.9151 17.9881 18.9488 18.0691C18.9826 18.1502 19 18.2372 19 18.325C19 18.4129 18.9826 18.4999 18.9488 18.5809C18.9151 18.662 18.8656 18.7356 18.8032 18.7975L18.8032 18.7974L18.7974 18.8033C18.7356 18.8656 18.662 18.9151 18.581 18.9489C18.5 18.9826 18.4131 19 18.3253 19C18.2375 19 18.1506 18.9826 18.0696 18.9489C17.9885 18.9151 17.915 18.8656 17.8532 18.8033L17.8511 18.8012L10.708 11.6404L10 10.9307L9.29202 11.6404L2.14892 18.8012L2.14685 18.8033C2.08501 18.8656 2.01146 18.9151 1.93044 18.9489L2.31505 19.8719L1.93043 18.9489C1.8494 18.9826 1.76249 19 1.67471 19C1.58694 19 1.50003 18.9826 1.41899 18.9489L1.03437 19.8719L1.41899 18.9489C1.33797 18.9151 1.26441 18.8656 1.20257 18.8033L1.2026 18.8032L1.19677 18.7975C1.13443 18.7356 1.08494 18.662 1.05117 18.581C1.01739 18.4999 1 18.4129 1 18.325C1 18.2372 1.01739 18.1502 1.05117 18.0691C1.08494 17.9881 1.13443 17.9145 1.19677 17.8526L1.19885 17.8506L8.3586 10.7065L9.06803 9.99858L8.3586 9.2907L1.19968 2.14741C1.19957 2.1473 1.19946 2.1472 1.19936 2.14709C1.07354 2.02109 1.00284 1.85026 1.00284 1.67211C1.00284 1.49381 1.07366 1.32284 1.19968 1.19681C1.32569 1.07078 1.49657 1 1.67471 1C1.85271 1 2.02345 1.07066 2.14943 1.19649C2.14953 1.19659 2.14964 1.1967 2.14975 1.19681L9.29202 8.35675L10 9.06648L10.708 8.35675L17.8503 1.19681C17.8504 1.19668 17.8505 1.19655 17.8506 1.19642C17.9766 1.07063 18.1473 1 18.3253 1C18.5034 1 18.6743 1.07078 18.8003 1.1968C18.9263 1.32284 18.9972 1.49381 18.9972 1.67211C18.9972 1.85021 18.9265 2.021 18.8007 2.147C18.8006 2.14714 18.8005 2.14727 18.8003 2.14741L11.6414 9.2907Z" stroke="#333333" strokeWidth="2" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <svg width="14" height="14" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.6606 12.3378L14.6595 13.34L15.6617 13.3389L25.7761 13.3278L25.779 13.3278C25.8668 13.3275 25.9538 13.3445 26.035 13.378C26.1163 13.4114 26.1901 13.4606 26.2522 13.5227C26.3143 13.5849 26.3635 13.6587 26.3969 13.7399C26.4304 13.8211 26.4474 13.9081 26.4471 13.9959H26.447L26.4471 14.0041C26.4474 14.0919 26.4304 14.1789 26.397 14.2601C26.3636 14.3413 26.3144 14.415 26.2523 14.4771C26.1903 14.5392 26.1165 14.5883 26.0354 14.6217C25.9542 14.6552 25.8672 14.6722 25.7794 14.6718L25.7764 14.6718L15.6621 14.6593L14.6596 14.6581L14.6608 15.6606L14.6733 25.7749L14.6733 25.7779C14.6737 25.8657 14.6567 25.9527 14.6233 26.0338L15.5479 26.4146L14.6233 26.0338C14.5898 26.115 14.5407 26.1888 14.4786 26.2508C14.4165 26.3129 14.3428 26.3621 14.2616 26.3955L14.6424 27.3202L14.2616 26.3955C14.1805 26.4289 14.0935 26.4459 14.0056 26.4456L14.0056 26.4455L13.9974 26.4456C13.9096 26.4459 13.8226 26.4289 13.7414 26.3954C13.6602 26.362 13.5864 26.3128 13.5242 26.2507C13.4621 26.1885 13.4129 26.1147 13.3795 26.0335C13.346 25.9523 13.329 25.8653 13.3294 25.7775L13.3294 25.7745L13.3404 15.6602L13.3415 14.658L12.3393 14.6591L2.22612 14.6701C2.22597 14.6701 2.22582 14.6701 2.22567 14.6701C2.04761 14.67 1.87682 14.5992 1.75085 14.4732C1.62477 14.3472 1.55396 14.1762 1.55394 13.998C1.55393 13.8197 1.62471 13.6489 1.75068 13.5229C1.87654 13.397 2.04724 13.3263 2.22529 13.3262C2.22544 13.3262 2.22559 13.3262 2.22574 13.3262L12.3389 13.3387L13.3414 13.3399L13.3402 12.3374L13.3277 2.22423C13.3277 2.22405 13.3277 2.22387 13.3277 2.22369C13.3278 2.04566 13.3986 1.87501 13.5244 1.74917C13.6504 1.6232 13.8213 1.55242 13.9995 1.55243C14.1777 1.55245 14.3487 1.62326 14.4748 1.74934C14.6007 1.87528 14.6715 2.04601 14.6717 2.22403C14.6717 2.22422 14.6717 2.22442 14.6717 2.22461L14.6606 12.3378Z" stroke="#333333" strokeWidth="2" />
                                                                    </svg>
                                                                </span>
                                                        }
                                                    </div>)
                                                }
                                            </div>
                                        </div>}
                                    </div>
                                )
                            }

                            {
                                (vendors.length < 5 && !disabled) ? <div className='py-4'>
                                    <div className='flex items-center justify-between'>
                                        <h1 className='text-xl font-medium mb-2'>Vendor {vendors.filter(el => el.status !== 2).length + 1}/5</h1>
                                    </div>
                                    <label className='text-base font-medium'>Search for Vendor</label>
                                    <div className='w-full relative py-1 max-w-md'>
                                        <input type="text" name='name' value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} className='bg-white w-full max-w-md outline-none rounded p-2 custom-shadow' />
                                        {companyName &&
                                            <>
                                                <svg onClick={() => { setCompanyName('') }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-400 cursor-pointer absolute top-3 right-2.5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>

                                                <div className='bg-white rounded-lg shadow-lg absolute top-12 left-0 w-full max-w-md'>
                                                    {
                                                        companies.map(item => item?.companyName?.toLowerCase()?.includes(companyName?.toLowerCase()) && <div key={item.id} className='flex w-full items-center gap-2 py-2 px-4 cursor-pointer hover:bg-gray-50' onClick={() => checkCompany(item)}><img src={item?.companyIcon} className='w-8 h-8 rounded-full object-cover' alt="" /> <div className='flex-grow'><h1>{item.companyName}</h1><p className='text-xs italic text-gray-400 text-right'>{item?.sectorOfIndustry}</p></div></div>)
                                                    }
                                                </div>
                                            </>
                                        }

                                        {companyName?.length > 2 ? !companies.filter(item => item.companyName.toLowerCase().includes(companyName.toLowerCase()))?.length ?
                                            <>
                                                <div className='w-full my-3 flex items-center gap-6'>
                                                    <label className='font-medium text-xs'>This Vendor is not part of your Vendor list, Add vendor before sending project request.</label>
                                                    <button className='bg-lightgreen text-green-800 rounded-3xl py-2 px-4 min-w-max text-xs font-medium' onClick={() => setAddVendor(true)}>Add New Vendor</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </> : <></>}
                                    </div>
                                </div> : <></>
                            }

                            {disabled ? <></> : <div className='max-w-4xl flex items-center justify-end py-6'>
                                <button disabled={adding} onClick={(e) => handleSubmit(e)} className='py-2 px-5 rounded shadow-lg bg-lightgreen disabled:bg-gray-300 text-black font-medium'>{project?.id ? 'Update' : 'Publish'} Project</button>
                            </div>}
                        </div>
                    </div>
                </div>

                {disabled ? <></> : <div className='p-5 bg-[#FFEFEF] rounded-lg shadow-lg mt-10'>
                    <h1 className='text-xl text-[#FF4469] italic'>Rejected vendor invites</h1>
                    {
                        vendors.filter(el => el.status == 2)?.length
                            ?
                            vendors.filter(el => el.status == 2).map(item => <div className='border-b last:border-b-0 border-[#FFA5A5] py-4 flex items-center justify-between max-w-[59rem]'>
                                <div className='flex-grow'>
                                    <h1 className='text-lg'>{item.companyName}</h1>
                                    <p className='font-light italic text-sm leading-none'>{moment(item.createdAt).format('DD/MM/YY')}</p>
                                </div>
                                <button className='bg-cream text-orange-800 py-1.5 px-6 rounded-lg text-base font-medium shadow-md' onClick={() => resendInvite(item.id)}>Invite again</button>
                            </div>) :
                            <h1 className='text-base italic my-3'>No Reject Request</h1>
                    }
                </div>}
            </section>
            {addVendor && <AddVendorAndClient adminInfo={adminInfo} heading={'vendor'} popUp={addVendor} setPopUp={setAddVendor} />
            }
        </>
    )
}

export default AddProject