import React, { useRef, useState } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import "chartjs-plugin-dragdata";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
);

export default function RadarChart({ width, height, draggable, initialData = [], heading, borderColor, backgroundColor, containerClass, containerStyle, graphData = [], setGraphData }) {

    const data = {
        labels: graphData?.map(item => item.typeOfReview || item.name),
        datasets: [
            {
                label: heading,
                data: graphData?.map(item => item?.rating || 1),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    };


    let options = {
        miterLimit: 5,
        interaction: {
            mode: 'nearest'
        },
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        // onClick: (e, element, ...data) => {
        //     console.log(e.x, e.y)

        // },
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    backdropPadding: {
                        x: 4,
                        y: 8
                    }
                }
            }
        }
        ,
        onHover: function (e) {
            if (draggable) {
                const point = e.chart.getElementsAtEventForMode(
                    e,
                    "nearest",
                    { intersect: true },
                    false
                );
                if (point.length) {
                    let userPointsValue = e.chart.config._config.data.datasets[0].data;
                    //   setPoints(userPointsValue);
                    e.native.target.style.cursor = "grab";
                } else e.native.target.style.cursor = "default";
            }
        },
        plugins: {
            legend: { display: false },
            labels: {
                render: 'label',
                fontColor: ['rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)']
            }
        }
    }

    if (draggable) {
        options.plugins.dragData = {
            round: 1,
            showTooltip: true,
            onDragStart: function (e) {
            },
            onDrag: function (e, datasetIndex, index, value) {
                if (draggable)
                    e.target.style.cursor = 'grabbing'
            },
            onDragEnd: function (e, datasetIndex, index, value) {
                if (draggable) {
                    e.target.style.cursor = 'default'
                    let arr = [...graphData]
                    arr[index].rating = value < 1 ? 1 : Math.round(value);
                    arr[index].reviewTypeId = arr[index].id;
                    setGraphData(arr)
                }
            },
        }
    }


    return <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}><Radar options={options} redraw={draggable} data={data} /></div>;
}
