import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getCompanyDocuments } from '../../services/service'
import { preview, selectCompanyDetails } from '../../slices/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import uploadicon from '../../images/uploadicon.svg';
import { FileUploader } from 'react-drag-drop-files';
import { uploadImage } from '../../services/constant';
import { useUpdateCompanyInformation, useUpdateDocs } from '../../hooks';
import { selectAdminDetails, setIntoAdminDetails } from '../../slices/adminSlice';
import toast from 'react-hot-toast';

const fileTypes = ["PDF"];

function CompanyDocument({ disabled, adminInfo, companyName }) {
    const dispatch = useDispatch()
    const [registrationCertificate, setRegistrationCertificate] = useState(null)
    const [taxReference, setTaxReference] = useState(null)
    const [gstCertificate, setGstCertificate] = useState(null)
    const [boardOfDirectors, setBoardOfDirectors] = useState(null)
    const [idProof, setIdProof] = useState("")
    const [proofOfEmployment, setProofOfEmployment] = useState("")
    const admin = useSelector(selectAdminDetails)
    const [edit, setEdit] = useState('')
    const [isVerify, setIsVerify] = useState(false)

    const { isLoading } = useQuery('getCompanyDocuments', getCompanyDocuments, {
        refetchOnWindowFocus: false,
        onSuccess: res => {
            for (let el of res?.data?.data) {
                if (el.documentType == 'registration certificate') {
                    setRegistrationCertificate({
                        name: el.documentData.split('/').pop(),
                        url: el.documentData
                    })
                }
                else if (el.documentType == 'tax reference') {
                    setTaxReference({
                        name: el.documentData.split('/').pop(),
                        url: el.documentData
                    })
                }
                else if (el.documentType == 'gst certificate') {
                    setGstCertificate({
                        name: el.documentData.split('/').pop(),
                        url: el.documentData
                    })
                }
                else if (el.documentType == 'board of directors') {
                    setBoardOfDirectors({
                        name: el.documentData.split('/').pop(),
                        url: el.documentData
                    })
                }
            }
        }
    });

    useEffect(() => {
        if (admin) {
            if (admin?.idProof)
                setIdProof({
                    name: admin.idProof.split('/').pop(),
                    url: admin.idProof
                })
            if (admin?.proofOfEmployment)
                setProofOfEmployment({
                    name: admin.proofOfEmployment.split('/').pop(),
                    url: admin.proofOfEmployment
                })
        }
    }, [admin])

    let { isLoading: updating, mutate } = useUpdateCompanyInformation(() => { })
    let { isLoading: updatingDocs, mutate: updateDocs } = useUpdateDocs(() => { })



    const handleCompanyDocs = async (file, documentType) => {
        toast("Uploading File...", { id: 'info' })
        let documentData = [];
        documentData.push({ documentType, documentData: await uploadImage(file) })

        let data = {
            companyId: admin?.companyId,
            adminId: admin?.uuid,
            documentData
        }
        updateDocs(data)
    }
    const handleMyDocs = async (file, documentType) => {
        toast("Uploading File...", { id: 'info' })

        let url = await uploadImage(file)

        let data = {
            companyId: admin?.companyId,
            [documentType]: url
        }

        dispatch(setIntoAdminDetails({ [documentType]: url }))
        mutate(data)
    }


    const viewDoc = (url, type) => {
        // dispatch(preview({ isOpen: true, pdfUrl: url, title: type }))
    }


    if (isLoading) {
        return (
            <div className='relative w-full min-h-[250px] grid place-items-center'>
                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                </div>
            </div>
        )
    }

    return (
        <>
            <section className='p-10'>
                <h1 className='text-3xl font-medium mb-5'>Company Documents</h1>

                <div className='flex flex-wrap gap-x-8 gap-y-5'>

                    <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                        <div className='flex items-end justify-between w-full'>
                            <h1 className='font-medium text-lg capitalize'>Registration Cert.</h1>
                            {disabled ? <></> : edit == 'registration certificate' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div> :
                                <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('registration certificate')}>
                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>}
                        </div>
                        {
                            disabled ? <h1 className='py-2 px4 text-center bg-white w-full mt-1'>Not Uploaded</h1> :
                                (edit == 'registration certificate') ?
                                    <FileUploader
                                        multiple={false}
                                        handleChange={file => handleCompanyDocs(file, 'registration certificate')}
                                        name="registrationCertificate"
                                        types={fileTypes}
                                        children={
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{registrationCertificate?.name || 'Click or drop your file'}</h1>
                                            </div>
                                        }
                                    />
                                    :
                                    !registrationCertificate
                                        ?
                                        <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center italic'>Not Uploaded</h1>
                                        </div>
                                        :
                                        <div onClick={() => viewDoc(registrationCertificate?.url, 'registration certificate')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center'>{registrationCertificate?.name}</h1>
                                        </div>
                        }
                    </div>

                    <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                        <div className='flex items-end justify-between w-full'>
                            <h1 className='font-medium text-lg capitalize'>tax reference</h1>
                            {disabled ? <></> : edit == 'tax reference' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                                :
                                <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('tax reference')}>
                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>}
                        </div>
                        {
                            disabled ? <h1 className='py-2 px4 text-center bg-white w-full mt-1'>Not Uploaded</h1> :
                                (edit == 'tax reference') ?
                                    <FileUploader
                                        multiple={false}
                                        handleChange={file => handleCompanyDocs(file, 'tax reference')}
                                        name="taxReference"
                                        types={fileTypes}
                                        children={
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{taxReference?.name || 'Click or drop your file'}</h1>
                                            </div>
                                        }
                                    />
                                    :
                                    !taxReference
                                        ?
                                        <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center italic'>Not Uploaded</h1>
                                        </div>
                                        :
                                        <div onClick={() => viewDoc(taxReference?.url, 'tax reference')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center'>{taxReference?.name}</h1>
                                        </div>
                        }
                    </div>
                    <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                        <div className='flex items-end justify-between w-full'>
                            <h1 className='font-medium text-lg capitalize'>GST certificate</h1>
                            {disabled ? <></> : edit == 'gst certificate' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div> :
                                <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('gst certificate')}>
                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>}
                        </div>
                        {
                            disabled ? <h1 className='py-2 px-4 text-center bg-white w-full mt-1'>Not Uploaded</h1> :
                                (edit == 'gst certificate') ?
                                    <FileUploader
                                        multiple={false}
                                        handleChange={file => handleCompanyDocs(file, 'gst certificate')}
                                        name="gstCertificate"
                                        types={fileTypes}
                                        children={
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{gstCertificate?.name || 'Click or drop your file'}</h1>
                                            </div>
                                        }
                                    />
                                    :
                                    !gstCertificate
                                        ?
                                        <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center italic'>Not Uploaded</h1>
                                        </div>
                                        :
                                        <div onClick={() => viewDoc(gstCertificate?.url, 'gst certificate')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center'>{gstCertificate?.name}</h1>
                                        </div>
                        }
                    </div>
                    <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                        <div className='flex items-end justify-between w-full'>
                            <h1 className='font-medium text-lg capitalize'>board of directors</h1>
                            {disabled ? <></> : edit == 'board of directors' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                                :
                                <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('board of directors')}>
                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>}
                        </div>
                        {
                            disabled ? <h1 className='py-2 px4 text-center bg-white w-full mt-1'>Not Uploaded</h1> :
                                (edit == 'board of directors') ?
                                    <FileUploader
                                        multiple={false}
                                        handleChange={file => handleCompanyDocs(file, 'board of directors')}
                                        name="boardOfDirectors"
                                        types={fileTypes}
                                        children={
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{boardOfDirectors?.name || 'Click or drop your file'}</h1>
                                            </div>
                                        }
                                    />
                                    :
                                    !boardOfDirectors
                                        ?
                                        <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center italic'>Not Uploaded</h1>
                                        </div>
                                        :
                                        <div onClick={() => viewDoc(boardOfDirectors?.url, 'board of directors')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                            <h1 className='text-center'>{boardOfDirectors?.name}</h1>
                                        </div>
                        }
                    </div>

                </div>


                {
                    disabled ?

                        <>
                            <h1 className='text-3xl font-medium mt-10 mb-5'>Your Documents</h1>

                            <div className='flex flex-wrap gap-x-8 gap-y-6'>
                                <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                                    <div className='flex items-end justify-between w-full'>
                                        <h1 className='font-medium text-lg capitalize'>ID Proof</h1>
                                        {edit == 'idProof' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div> :
                                            <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('idProof')}>
                                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>}
                                    </div>
                                    {(edit == 'idProof') ?
                                        <FileUploader
                                            multiple={false}
                                            handleChange={file => handleMyDocs(file, 'idProof')}
                                            name="idProof"
                                            types={fileTypes}
                                            children={
                                                <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                    <h1 className='text-center'>{idProof?.name || 'Click or drop your file'}</h1>
                                                </div>
                                            }
                                        />
                                        :
                                        !idProof
                                            ?
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center italic'>Not Uploaded</h1>
                                            </div>
                                            :
                                            <div onClick={() => viewDoc(idProof?.url, 'ID proof')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{idProof?.name}</h1>
                                            </div>
                                    }
                                </div>
                                <div className='rounded-lg w-[300px] flex flex-col items-center justify-center'>
                                    <div className='flex items-end justify-between w-full'>
                                        <h1 className='font-medium text-lg capitalize'>Proof of employment</h1>
                                        {edit == 'proofOfEmployment' ? <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('')}>
                                            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 18L18 6M6 6L18 18" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div> :
                                            <div className='p-1 bg-cream-dark rounded-lg cursor-pointer' onClick={() => setEdit('proofOfEmployment')}>
                                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487ZM16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>}
                                    </div>
                                    {(edit == 'proofOfEmployment') ?
                                        <FileUploader
                                            multiple={false}
                                            handleChange={file => handleMyDocs(file, 'proofOfEmployment')}
                                            name="proofOfEmployment"
                                            types={fileTypes}
                                            children={
                                                <div style={{ wordBreak: 'break-all' }} className={`bg-[#FAEFE5] grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-dashed border-gray-500 cursor-pointer`}>
                                                    <h1 className='text-center'>{proofOfEmployment?.name || 'Click or drop your file'}</h1>
                                                </div>
                                            }
                                        />
                                        :
                                        !proofOfEmployment
                                            ?
                                            <div style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center italic'>Not Uploaded</h1>
                                            </div>
                                            :
                                            <div onClick={() => viewDoc(proofOfEmployment?.url, 'proof of employment')} style={{ wordBreak: 'break-all' }} className={`bg-white grid place-items-center rounded-lg w-[300px] h-28 p-5 my-1 border-2 border-gray-500 cursor-pointer`}>
                                                <h1 className='text-center'>{proofOfEmployment?.name}</h1>
                                            </div>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <></>
                }
                <div className='pt-10 flex items-center justify-between w-full max-w-7xl'>
                    <button></button>
                    <button onClick={() => setIsVerify(true)} className='bg-lightgreen text-green-800 font-medium rounded text-lg py-2 px-8 shadow-lg'>Verify Documents</button>
                </div>

            </section>
            {isVerify ? <section className='fixed inset-0 grid place-items-center' style={{ zIndex: 102 }}>
                <div className='bg-black bg-opacity-60 fixed inset-0' onClick={() => setIsVerify(false)}></div>
                <div className='bg-white rounded-lg w-full max-w-2xl relative p-7 pt-10 flex flex-col items-center gap-1'>
                    <img className='w-16 h-16' src="/verifyIcon.svg" alt="" />
                    <h1 className='text-3xl font-medium'>Request verification</h1>
                    <p className='text-xl text-center'>Would you like to request for verification for these documents?</p>
                    <p className='text-lg mt-4'>Verifying documents gives your company the</p>
                    <div className='flex items-center gap-3'>
                        <img className='h-8' src="/verified.svg" alt="" />
                        <p className='text-lg'>Stamp</p>
                    </div>
                    <div className='pt-10 flex items-center justify-between w-full'>
                        <button onClick={() => setIsVerify(false)} className='bg-cream-dark text-orange-800 font-medium rounded text-lg py-2 px-8 shadow-lg'>Do not Verify</button>
                        <button onClick={() => setIsVerify(false)} className='bg-lightgreen text-green-800 font-medium rounded text-lg py-2 px-8 shadow-lg'>Verify</button>
                    </div>
                </div>
            </section> : <></>}
        </>
    )
}

export default CompanyDocument