import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            // display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                drawOnChartArea: true,
                color: 'rgba(0,0,0,0.08)',
                drawBorder: false,
                borderDash: [5, 6]
            },
        },
        x: {
            beginAtZero: true,
            display: true,
            position: 'left',
            grid: {
                color: 'transparent',
            }
        }
    }
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export function DoubleBarChart({ labels, firstData, secondData, label1, label2 }) {

    const data = {
        labels,
        datasets: [
            {
                label: label1 || 'Yours',
                data: firstData,
                backgroundColor: 'rgba(22, 92, 255, 1)',
                barThickness: 30,
            },
            {
                label: label2 || 'Others',
                data: secondData,
                backgroundColor: 'rgba(20, 201, 201, 1)',
                barThickness: 30,
            },
        ],
    };
    return <Bar width={600} height={200} redraw={true} options={options} data={data} />;
}
