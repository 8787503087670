import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'

function UploadBanner({ onClose, handleImage }) {
    const [file, setFile] = useState()

    return (
        <section className='fixed inset-0 grid place-items-center z-40'>
            <div className='bg-black bg-opacity-80 fixed inset-0' onClick={() => onClose()}></div>

            <div className='relative bg-white rounded-lg w-full max-w-3xl p-5 flex flex-col gap-10'>
                <h1 className='text-3xl font-medium border-b-2 border-lightgreen max-w-max pb-1'>
                    Upload new banner
                </h1>

                <div className='w-full max-w-xl mx-auto fileArea'>
                    <FileUploader
                        multiple={false}
                        types={['jpg', 'jpeg', 'png', 'svg', 'JPG', 'JPEG', 'PNG']}
                        handleChange={e => setFile(e)}
                        children={
                            <div className='w-full max-w-xl h-40 grid place-items-center border-2 border-dashed border-gray-800 rounded-lg relative'>
                                {
                                    file
                                        ?
                                        <div className='w-full max-w-xl h-full overflow-hidden'>
                                            <img className='w-full max-w-xl h-full object-cover rounded-lg' src={URL.createObjectURL(file)} alt="" />
                                        </div>

                                        :
                                        <div>
                                            <h1>Click or drop and drag file here</h1>
                                            <span className='absolute -bottom-8 left-1/2 transform -translate-x-1/2'>1000px</span>
                                            <span className='absolute -right-10 top-1/2 transform -translate-y-1/2 rotate-90'>250px</span>
                                        </div>
                                }
                            </div>
                        }
                    />
                </div>

                <div className='flex items-center justify-between w-full mt-10'>
                    <button onClick={() => onClose()} className='bg-cream-dark text-orange-800 font-medium rounded text-lg py-2 px-8 shadow-lg'>Cancel</button>
                    <button onClick={() => {handleImage({
                        target: {
                            name: 'companyBanner',
                            files: [file]
                        }
                    }); onClose()}} className='bg-lightgreen text-green-800 font-medium rounded text-lg py-2 px-8 shadow-lg'>Upload</button>
                </div>
            </div>
        </section>
    )
}

export default UploadBanner