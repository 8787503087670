import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAdminDetails } from '../../slices/adminSlice';
import { useQuery } from 'react-query';
import { getOrganizationAndAccessRoleData, myReviews } from '../../services/service';
import ResetPassword from '../Reusable/ResetPassword';
import { useGetReviewDetails, useUpdateProfile } from '../../hooks';
import { toast } from 'react-hot-toast';
import ReviewStar from '../../images/reviewstar.svg'
import { useClickOutside } from '../../services/constant';
import fillRating from '../../images/reviewstar.svg';
import emptyRating from '../../images/rating_empty.svg';


function MyProfile() {
    let { data: AccessRes } = useQuery('getOrganizationAndAccessRoleData', getOrganizationAndAccessRoleData, {
        staleTime: 600000,
        refetchOnWindowFocus: false,
    })
    let { isLoading, data: ReviewRes } = useQuery('myReviews', myReviews, {
        staleTime: 600000,
        refetchOnWindowFocus: false,
    })
    const adminInfo = useSelector(selectAdminDetails)
    const [data, setData] = useState({
        companyAdminName: '',
        companyAdminEmail: '',
        companyRoleId: '',
        userRoleId: ''
    })
    const [resetPassword, setResetPassword] = useState(false)
    const [search, setSearch] = useState('')
    const [filterModal, setFilterModal] = useState(false);
    const [rating, setRating] = useState(0);

    const [filters, setFilters] = useState({
        overall: true,
        project: true,
    })

    const filterRef = useRef()

    useClickOutside(() => setFilterModal(false), filterRef)

    useEffect(() => {
        setData({
            companyAdminName: adminInfo?.companyAdminName,
            companyAdminEmail: adminInfo?.companyAdminEmail,
            companyRoleId: adminInfo?.companyRoleId,
            userRoleId: adminInfo?.userRoleId
        })
    }, [adminInfo])

    const closePopUp = () => setResetPassword(false)

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    let { isLoading: updating, profileUpdate } = useUpdateProfile();

    const updateProfile = () => {
        if (data?.companyAdminName?.length < 3) {
            return toast.error("Invalid Name, must be greater than 3 character")
        }
        if (data?.companyAdminName == adminInfo?.companyAdminName && data?.companyRoleId == adminInfo?.companyRoleId) {
            return toast('No change detected', { id: 'info' })
        }
        profileUpdate(data)
    }

    const { mutate } = useGetReviewDetails();
    const getReviewDetailsById = (id) => {
        mutate(id)
    }

    const validateFilter = (item) => {
        let searchBoolean = item?.companyName?.toLowerCase().includes(search?.toLowerCase()) || item?.sectorOfIndustry?.toLowerCase().includes(search?.toLowerCase()) || item?.projectName?.toLowerCase().includes(search?.toLowerCase());
        let ratingBoolean = false;
        let typeBoolean = false;

        if (rating > 0) {
            if (Number(item?.rating || 0) <= rating) {
                ratingBoolean = true
            }
            else {
                ratingBoolean = false
            }
        }
        else if (rating == 0) {
            ratingBoolean = true
        }

        if (filters.overall) {
            if (!item?.projectId) {
                typeBoolean = true
            }
        }
        if (filters.project) {
            if (item?.projectId)
                typeBoolean = true
        }

        return ratingBoolean && typeBoolean && searchBoolean
    }

    return (
        <section className='p-5'>
            <h1 className='pt-6 text-2xl font-medium px-10'>Your Information</h1>

            <div className='grid grid-cols-2 gap-x-10 gap-y-5 py-5 px-10'>
                <div>
                    <h1 className='font-medium mb-2 text-gray-400'>Name</h1>
                    <input type="text" name='companyAdminName' value={data?.companyAdminName} onChange={handleChange} className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                </div>
                <div>
                    <h1 className='font-medium mb-2'>Email</h1>
                    <input disabled type="email" name='companyAdminEmail' value={data?.companyAdminEmail} className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                </div>
                <div>
                    <h1 className='font-medium mb-2'>Designation</h1>
                    <div className={`pr-2 rounded shadow-md bg-white`}>
                        <select name='companyRoleId' onChange={handleChange} value={data?.companyRoleId} className='bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100'>
                            <option value="" selected disabled>Choose Company Role</option>
                            {
                                AccessRes?.data?.roleInOrganization?.map(item => <option key={item.id} value={item.id}>{item.companyRole}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <h1 className='font-medium mb-2'>Access Right</h1>
                    <div className={`pr-2 rounded shadow-md bg-gray-100`}>
                        <select disabled name='userRoleId' value={data?.userRoleId} className='bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100'>
                            <option value="" selected disabled>Choose Role</option>
                            {
                                AccessRes?.data?.accessRight?.map(item => <option key={item.id} value={item.id}>{item.role}</option>)
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='px-10 flex items-center gap-10 py-4'>
                <button onClick={updateProfile} disabled={updating} className='py-2 px-5 bg-lightblue text-sky-600 font-medium rounded-md shadow-lg shadow-sky-50'>Update Info</button>
                <button onClick={() => setResetPassword(true)} className='py-2 px-5 bg-cream text-yellow-600 font-medium rounded-md shadow-lg shadow-yellow-50'>Change Password</button>
            </div>
            {resetPassword && <ResetPassword progress={5} closePopUp={closePopUp} />}

            <div className='py-5 flex items-center justify-between gap-4 w-full sticky top-20 bg-body z-20'>
                <div className='flex items-center bg-white rounded w-full max-w-md px-3 shadow-md'>
                    <input type="text" placeholder='Search Company, Sector, Project' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>

                <div ref={filterRef} className='relative'>
                    <button className='py-2 px-6 rounded shadow font-medium bg-white' onClick={() => setFilterModal(!filterModal)}>Filter</button>
                    {
                        filterModal && <div className='absolute bg-white custom-shadow rounded min-w-max top-12 right-0 py-3 px-4'>
                            <div className=''>
                                <div className='flex items-start justify-between gap-10 mb-1'>
                                    <h1 className='font-medium'>Type</h1>
                                    <span onClick={() => {
                                        setFilters({
                                            overall: true,
                                            project: true,
                                        });
                                        setRating(0);
                                    }} className='text-xs cursor-pointer bg-red-300 font-medium rounded-full py-1 px-3'>Clear Filter</span>
                                </div>
                                <button onClick={() => setFilters({ ...filters, overall: !filters.overall })} className='flex items-center gap-2 mb-1 outline-none'>
                                    <span className={`w-3 h-3 ${filters.overall ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                    </span>
                                    <span className='text-sm'>
                                        Overall
                                    </span>
                                </button>
                                <button onClick={() => setFilters({ ...filters, project: !filters.project })} className='flex items-center gap-2 mb-1 outline-none'>
                                    <span className={`w-3 h-3 ${filters.project ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                    </span>
                                    <span className='text-sm'>
                                        Project
                                    </span>
                                </button>
                            </div>
                            <div className='mt-4'>
                                <h1 className='font-medium mb-1'>Rating</h1>
                                <span className='mt-2 flex gap-2 justify-center items-center'>
                                    <span className='h-max w-max rounded'>{rating >= 1 ? <img src={fillRating} onClick={() => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />
                                        : <img src={emptyRating} onClick={() => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                    <span className='h-max w-max rounded'>{rating >= 2 ? <img src={fillRating} onClick={() => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />
                                        : <img src={emptyRating} onClick={() => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                    <span className='h-max w-max rounded'>{rating >= 3 ? <img src={fillRating} onClick={() => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />
                                        : <img src={emptyRating} onClick={() => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                    <span className='h-max w-max rounded'>{rating >= 4 ? <img src={fillRating} onClick={() => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />
                                        : <img src={emptyRating} onClick={() => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                    <span className='h-max w-max rounded'>{rating >= 5 ? <img src={fillRating} onClick={() => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />
                                        : <img src={emptyRating} onClick={() => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />}</span>

                                </span>
                                <p className='mt-2 text-sm text-center'>{rating == 0 ? 'Choose rating' : rating + ' and below'}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='min-h-[50dvh] bg-white rounded-lg custom-shadow relative'>
                <h1 className='text-2xl font-medium px-6 py-4'>Your Reviews</h1>
                <Table validateFilter={validateFilter} isLoading={isLoading} data={ReviewRes?.data?.data} getReviewDetailsById={getReviewDetailsById} />
            </div>
        </section>
    )
}

export default MyProfile

const Table = ({ isLoading, data, getReviewDetailsById, validateFilter }) => {
    return (
        <div className='relative px-5'>
            {isLoading ? <div className='absolute w-full min-h-[250px] grid place-items-center'>
                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                </div>
            </div>
                :
                !data?.filter(item => validateFilter(item))?.length ? <h1 className='capitalize w-full absolute top-20 text-3xl text-center font-medium text-gray-300'>No review Yet!</h1> : <></>
            }
            <table className={`w-full transition-all duration-500}`}>
                <thead className='sticky top-40 bg-white'>
                    <tr>
                        <td className='p-2 text-gray-400'>S No.</td>
                        <td className='p-2 text-gray-400'>Company Name</td>
                        <td className='p-2 text-gray-400'>Sector</td>
                        <td className='p-2 text-gray-400'>Review Type</td>
                        <td className='p-2 text-gray-400'>Project</td>
                        <td className='p-2 text-gray-400'>Rating</td>
                        <td className='p-2 text-gray-400'></td>
                    </tr>
                </thead>
                <tbody>
                    {data?.filter(item => validateFilter(item))?.map((item, i) => <tr key={i}>
                        <td className='p-2'>{i + 1}</td>
                        <td className='p-2'>{item.companyName}</td>
                        <td className='p-2'>{item.sectorOfIndustry}</td>
                        <td className='p-2'>{item.projectId ? 'Project' : 'Overall'}{item.type == 1 ? '(Client)' : '(Vendor)'}</td>
                        <td className='p-2'>{item.projectName || <span className='italic'>Overall</span>}{item.type == 1 ? '(Client)' : '(Vendor)'}</td>
                        <td className='p-2'>
                            <div className='flex items-center gap-1'>
                                <img className='w-7 h-7' src={ReviewStar} alt="" />
                                <span>{Number(item.rating).toFixed(1)}</span>
                            </div>
                        </td>
                        <td className='p-2'><button className='py-1.5 px-4 rounded bg-lightblue' onClick={() => getReviewDetailsById(item.id)}>View Review</button></td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}
