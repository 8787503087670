import React, { useEffect, useState } from "react";
import logo from '../../images/corner-cube-logo.svg';
import { Link, useHistory } from "react-router-dom";
import welcome1 from '../../images/welcome1.svg'
import { decodeToken } from "../../services/constant";
import { useSendOtp } from "../../hooks";
import { useSelector } from "react-redux";
import { selectAdminDetails } from "../../slices/adminSlice";


export default function Welcome() {
    const history = useHistory();
    const [emailVerify, setEmailVerify] = useState(false)
    let user = decodeToken()
    const adminInfo = useSelector(selectAdminDetails)

    useEffect(() => {
        if (adminInfo)
            if (adminInfo?.progress <= 2) {
                setEmailVerify(true)
            }
        if (!user?.progress) history.push('/login')
    }, [history])

    return (
        <>

            <section className="h-screen w-full relative">
                <Link to="/"><img src={logo} className="absolute top-3 left-6" style={{ width: "120px" }} /></Link>

                <div className="w-full max-w-[60rem] mx-auto flex flex-col items-center justify-center gap-5 pt-16 z-40 relative">
                    <img src={welcome1} alt="" />

                    <h1 className="text-lightbrown text-3xl font-semibold">Your Firm’s Profile Is Under Review</h1>
                    <p className="text-lg">For any queries contact Qube@cornerqube.com</p>
                    <Link to="/" className="rounded bg-bermuda max-w-max">
                        <button className="text-lg font-medium bg-lightgreen py-2 px-5 rounded transform -translate-x-1 -translate-y-1">
                            Continue to Corner Qube
                        </button>
                    </Link>
                </div>
                <div className='fixed -bottom-60 -left-60'>
                    <svg width="663" height="549" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                        <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                        <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                    </svg>
                </div>

                <div className='fixed -bottom-80 -right-80'>
                    <svg width="805" height="673" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                        <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                        <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                    </svg>
                </div>
            </section>
            {emailVerify && <EmailVerify email={user?.adminEmail} />}
        </>
    )
}


const EmailVerify = ({ email }) => {

    const { mutate: sendOtp } = useSendOtp()

    const sendVerificationEmail = (e) => {
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "registerFlow": true,
        }
        sendOtp(payload);
    }

    return (
        <>
            <div className='fixed inset-0 w-full min-h-screen overflow-hidden grid place-items-center bg-black bg-opacity-50 z-50'>
                <div className='w-full bg-white max-w-xl rounded-lg flex flex-col items-center justify-center gap-6 py-10'>
                    <svg className='rounded-full shadow-lg' width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="75" cy="75" r="75" fill="#E6BCFA" />
                        <path d="M61.1843 103.639L39.2972 80.7833C38.4666 79.914 38 78.7357 38 77.5072C38 76.2788 38.4666 75.1005 39.2972 74.2312L41.9563 71.4491C42.7883 70.5811 43.9159 70.0936 45.0915 70.0936C46.2672 70.0936 47.3948 70.5811 48.2268 71.4491L64.1369 88.0631L101.606 46.4566C102.413 45.5627 103.525 45.0396 104.7 45.0022C105.875 44.9647 107.017 45.416 107.874 46.2569L110.604 48.9498C111.461 49.7924 111.962 50.9559 111.998 52.1845C112.034 53.4132 111.601 54.6064 110.795 55.502L67.5607 103.536C67.1536 103.989 66.6634 104.352 66.1193 104.604C65.5752 104.855 64.9883 104.99 64.3935 104.999C63.7986 105.009 63.2079 104.894 62.6566 104.66C62.1053 104.426 61.6046 104.079 61.1843 103.639Z" fill="white" />
                    </svg>
                    <p>Your Email Verification is pending<br />please verify your email to continue!</p>

                    <div onClick={sendVerificationEmail} className='rounded bg-bermuda max-w-max'>
                        <button className='bg-lightviolet rounded py-2 px-10 text-lg font-medium transform -translate-x-1 -translate-y-1'>Verify Email</button>
                    </div>
                </div>
            </div>
        </>
    )
}