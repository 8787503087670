import React, { useEffect, useState } from 'react'
import ReviewStar from '../../images/reviewstar.svg'
import { useGetReviewDetails, useHideReview } from '../../hooks';
import moment from 'moment';

function DetailedFeedback({ data, closeFeedback }) {
    const [active, setActive] = useState('project');
    const [projectHighlight, setProjectHighlight] = useState(data?.projects?.filter(item => !item.hide)?.length || 0);
    const [companyHighlight, setCompanyHighlight] = useState(data?.companies?.filter(item => !item.hide)?.length || 0);
    const [search, setSearch] = useState('');

    return (
        <div>
            <div className='flex items-center gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer" onClick={closeFeedback}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                <h1 className='text-3xl font-medium text-gray-800'>{data?.companyName}</h1>
            </div>

            <div className='py-4 z-10 bg-body sticky top-20 flex items-start gap-4 w-full'>
                <div className='flex items-center bg-white rounded max-w-sm px-3 shadow-md'>
                    <input type="text" placeholder='Search Reviewer' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>

                <div className='flex items-start gap-4 w-full'>
                    <button onClick={() => setActive('project')} className={`rounded-lg font-medium shadow-md bg-lightviolet text-purple-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'project' ? 'opacity-100' : 'opacity-50'}`}>Project</button>
                    <button onClick={() => setActive('company')} className={`rounded-lg font-medium shadow-md bg-lightblue text-sky-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'company' ? 'opacity-100' : 'opacity-50'}`}>Company</button>
                    <div className='ml-auto text-right pr-6'>
                        <h1 className='text-xl'>
                            {companyHighlight + projectHighlight}/10
                        </h1>
                        <p className='text-sm'>Highlighted reviews</p>

                    </div>
                </div>
            </div>

            <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
                <h1 className='text-2xl font-medium text-gray-600 capitalize'>{active} Feedbacks</h1>
                <div className='relative min-h-[20dvh]'>
                    {!data
                        ?
                        <>
                            <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                </div>
                            </div>
                        </>
                        :
                        active == 'project' ?
                            data?.projects?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.length ? <></> : <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>No {active} Feedbacks</h1>
                            :
                            data?.companies?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.length ? <></> : <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>No {active} Feedbacks</h1>
                    }
                    <table className='w-full my-2' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                        <thead className='text-lg text-gray-300 border-b sticky top-36 bg-white'>
                            <tr className=''>
                                <td className='pt-4 pb-2 border-b text-center'>SNo.</td>
                                <td className='pt-4 pb-2 border-b'>Reviewer</td>
                                <td className='pt-4 pb-2 border-b text-center'>Designation</td>
                                <td className='pt-4 pb-2 border-b text-center'>Project</td>
                                <td className='pt-4 pb-2 border-b text-center'>Avg Rating</td>
                                <td className='pt-4 pb-2 border-b text-center'></td>
                                <td className='pt-4 pb-2 border-b text-center'>Highlight</td>
                            </tr>
                        </thead>
                        <>
                            <tbody>
                                {
                                    active == 'project' ?
                                        data?.projects?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.map((item, i) => <Row hidden={item.hide} key={i} i={i} item={item} setHighlight={setProjectHighlight} canHighlight={Number(companyHighlight + projectHighlight) < 10} />)
                                        :
                                        data?.companies?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.map((item, i) => <Row hidden={item.hide} key={i} i={i} item={item} setHighlight={setCompanyHighlight} canHighlight={Number(companyHighlight + projectHighlight) < 10} />)
                                }
                            </tbody>
                        </>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default DetailedFeedback


const Row = ({ item, i, setHighlight, hidden, canHighlight }) => {
    const [checked, setChecked] = useState(null)
    const { mutate } = useGetReviewDetails();
    const { isLoading, mutate: hide } = useHideReview((hide) => setHighlight(prev => !hide ? prev + 1 : prev - 1))

    const getReviewDetailsById = (id) => {
        mutate(id)
    }

    return (
        <tr >
            <td className='text-center'>{i + 1}</td>
            <td className='leading-5'><p>{item.companyAdminName}<br /><span className='text-xs text-gray-600'>{moment(item.updatedAt).fromNow()}</span></p></td>
            <td className='text-center'>{item.companyRole}</td>
            <td className='text-center'><h1 className='min-w-max'>{item.projectName || <span className='italic'>Overall</span>}</h1></td>
            <td className='text-center'>{item.rating && <img className='w-7 h-7 m-1 inline-block' src={ReviewStar} alt="" />} {Number(item.rating).toFixed(1) || '-'}</td>
            <td className='max-w-[80px]'>
                <button onClick={() => getReviewDetailsById(item.id)} className='py-2 px-4 text-sm font-medium tracking-wide outline-none rounded w-32 flex items-center justify-center gap-1 bg-lightblue disabled:bg-gray-300'>
                    <span>View Review</span>
                </button>
            </td>
            <td className='grid place-items-center p-2'>
                {
                    (checked == null ? hidden == false : checked == true) ?
                        <label className="switch2">
                            <input type="checkbox" disabled={isLoading} checked={true} onChange={() => { if (canHighlight) { setChecked(false); hide({ reviewId: item.id, hide: true }) } }} />
                            <span className="slider round"></span>
                        </label>
                        :
                        (checked == null ? hidden == true : checked == false) ?
                            <label className="switch2">
                                <input type="checkbox" disabled={isLoading} checked={false} onChange={() => { if (canHighlight) { setChecked(true); hide({ reviewId: item.id, hide: false }) } }} />
                                <span className="slider round"></span>
                            </label>
                            : ''

                }


            </td>
        </tr>
    )
}
