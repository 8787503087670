import React from 'react'

import { useSelector } from 'react-redux'
import { selectAdminDetails } from '../../slices/adminSlice'
import { useLocation, useParams } from 'react-router-dom'
import CompanyProfile from '../Profile/CompanyProfile'
import CompanyDocument from '../Profile/CompanyDocument'


function CompanyProfileHome() {
    const loc = useLocation()
    const params = useParams()
    const adminInfo = useSelector(selectAdminDetails);
    let disabled = !adminInfo?.userRole?.toLowerCase()?.includes('admin')

    return (
        <>
            {
                loc?.state?.showDocument ?
                    <>
                        <CompanyDocument disabled={disabled} adminInfo={adminInfo} companyName={params?.companyName} />
                    </>
                    :
                    <CompanyProfile disabled={disabled} companyName={params?.companyName} />
            }
        </>
    )
}

export default CompanyProfileHome