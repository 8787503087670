import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getAllFeedbacks, getSignUpRefData } from '../../services/service'
import moment from 'moment'
import { useGetReviewDetails } from '../../hooks'
import { useClickOutside } from '../../services/constant'
import fillRating from '../../images/reviewstar.svg';
import emptyRating from '../../images/rating_empty.svg';
import ReviewStar from '../../images/reviewstar.svg'


function ReviewHome() {
    let { isLoading, data: feedbackRes } = useQuery('getAllFeedbacks', getAllFeedbacks, { refetchOnWindowFocus: false, staleTime: 30000 })

    let { data: SignUpRefRes } = useQuery('getSignUpRefData', getSignUpRefData, {
        refetchOnMount: false, refetchOnWindowFocus: false,
        onError: (e) => console.log(e)
    })
    let refData = SignUpRefRes?.data

    const [search, setSearch] = useState('')
    const [filterModal, setFilterModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [typeOfFirm, setTypeOfFirm] = useState(['One Person Company', 'Micro', 'Small', 'Medium', 'Large'])
    const [roleType, setRoleType] = useState([])
    const [active, setActive] = useState('overall')

    const filterRef = useRef()

    useClickOutside(() => setFilterModal(false), filterRef)

    const { mutate } = useGetReviewDetails();
    const getReviewDetailsById = (id) => {
        mutate(id)
    }


    useEffect(() => {
        if (refData) {
            setRoleType(refData?.companyRole?.map(item => item?.companyRole))
        }
    }, [refData])

    const validateFilter = (item) => {
        let searchBoolean = item?.companyName?.toLowerCase().includes(search?.toLowerCase()) || item?.companyAdminName?.toLowerCase().includes(search?.toLowerCase()) || item?.projectName?.toLowerCase().includes(search?.toLowerCase());
        let ratingBoolean = false;
        let typeBoolean = false;
        let firmBoolean = false;
        let activeFilter = false;

        if (rating > 0) {
            if (Number(item?.rating || 0) <= rating) {
                ratingBoolean = true
            }
            else {
                ratingBoolean = false
            }
        }
        else if (rating == 0) {
            ratingBoolean = true
        }

        if (typeOfFirm.includes(item.typeOfIndustry)) {
            firmBoolean = true
        }
        // if (typeOfFirm.includes(0)) {
        //     if (Number(item.typeOfIndustry) == 1) {
        //         firmBoolean = true
        //     }
        // }
        if (roleType.includes(item.companyRole)) {
            typeBoolean = true
        }

        if (active == 'overall') {
            if (!item?.projectName) {
                activeFilter = true
            }
        }
        else if (active == 'project') {
            if (item?.projectName) {
                activeFilter = true
            }
        }
        else if (active == 'external') {
            if (item?.externalData) {
                activeFilter = true
            }
        }

        return ratingBoolean && firmBoolean && searchBoolean && activeFilter && typeBoolean
    }

    const validateFilterExternal = (item) => {
        let searchBoolean = item?.externalData?.companyName?.toLowerCase().includes(search?.toLowerCase()) || item?.externalData?.name?.toLowerCase().includes(search?.toLowerCase()) || item?.projectName?.toLowerCase().includes(search?.toLowerCase());
        let ratingBoolean = false;
        let typeBoolean = false;
        let firmBoolean = false;
        let activeFilter = false

        if (rating > 0) {
            if (Number(item?.rating || 0) <= rating) {
                ratingBoolean = true
            }
            else {
                ratingBoolean = false
            }
        }
        else if (rating == 0) {
            ratingBoolean = true
        }

        if (typeOfFirm.includes(item?.externalData?.typeOfIndustry)) {
            firmBoolean = true
        }
        if (roleType.includes(item?.externalData?.position)) {
            typeBoolean = true
        }

        if (active == 'external') {
            if (item?.externalData) {
                activeFilter = true
            }
        }

        return ratingBoolean && searchBoolean && firmBoolean && activeFilter && typeBoolean
    }

    const handleFirm = (id) => {
        let arr = [...typeOfFirm]
        let idx = arr.findIndex(item => item == id);
        if (idx > -1) {
            arr.splice(idx, 1)
        }
        else {
            arr.push(id)
        }
        setTypeOfFirm(arr)
    }
    const handleRole = (id) => {
        let arr = [...roleType]
        let idx = arr.findIndex(item => item == id);
        if (idx > -1) {
            arr.splice(idx, 1)
        }
        else {
            arr.push(id)
        }
        setRoleType(arr)
    }

    return (
        <>
            <section className=''>
                <div className='sticky top-20 bg-body z-20'>

                    <div className='p-5 flex items-center justify-between gap-4 w-full'>
                        <div className='flex items-center bg-white rounded w-full max-w-md px-3 shadow-md'>
                            <input type="text" placeholder='Search Name, Company or Project' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>

                        <div ref={filterRef} className='relative'>
                            <button className='py-2 px-6 rounded shadow font-medium bg-white' onClick={() => setFilterModal(!filterModal)}>Filter</button>
                            {
                                filterModal && <div className='absolute bg-white custom-shadow rounded min-w-max top-12 right-0 py-2 px-4'>
                                    <div className='mt-4'>
                                        <div className='flex items-start justify-between gap-10'>
                                            <h1 className='font-medium'>Overall Rating</h1>
                                            <span onClick={() => {
                                                setTypeOfFirm(['One Person Company', 'Micro', 'Small', 'Medium', 'Large']);
                                                setRoleType(refData?.companyRole?.map(item => item?.companyRole))
                                                setRating(0);
                                            }} className='absolute top-3 right-3 text-xs cursor-pointer bg-red-300 font-medium rounded-full py-1 px-3'>Clear Filter</span>
                                        </div>
                                        <span className='mt-2 flex gap-2 justify-center items-center'>
                                            <span className='h-max w-max rounded'>{rating >= 1 ? <img src={fillRating} onClick={() => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={() => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 2 ? <img src={fillRating} onClick={() => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={() => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 3 ? <img src={fillRating} onClick={() => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={() => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 4 ? <img src={fillRating} onClick={() => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={() => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 5 ? <img src={fillRating} onClick={() => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={() => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />}</span>

                                        </span>
                                        <p className='mt-2 text-sm text-center'>{rating == 0 ? 'Choose rating' : rating + ' and below'}</p>
                                    </div>
                                    <div className='mt-2'>
                                        <h1 className='font-medium'>Position</h1>
                                        <div className='grid grid-cols-2 gap-2 py-1'>
                                            {
                                                refData?.companyRole?.map((item, i) => <button key={i} onClick={() => handleRole(item.companyRole)} className='flex items-center gap-2 outline-none'>
                                                    <span className={`w-3 h-3 ${roleType.includes(item?.companyRole) ? 'bg-lightgreen' : 'bg-gray-300'}`}>
                                                    </span>
                                                    <span className='text-sm'>
                                                        {item.companyRole}
                                                    </span>
                                                </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <h1 className='font-medium'>Type</h1>
                                        <div className='flex items-center justify-around px-2 gap-2'>
                                            <div className='max-w-[95px]'>
                                                <button onClick={() => handleFirm('One Person Company')} className='flex items-center justify-start text-left gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes('One Person Company') ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        One Person<br/>Company
                                                    </span>
                                                </button>
                                            </div>
                                            <div className='w-0.5 h-12 bg-gray-300'>

                                            </div>
                                            <div className='grid grid-cols-2 gap-3'>
                                                <button onClick={() => handleFirm('Micro')} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes('Micro') ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Micro
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm('Small')} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes('Small') ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Small
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm('Medium')} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes('Medium') ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Medium
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm('Large')} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes('Large') ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Large
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='flex items-center gap-4 px-5 pb-4'>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightblue text-sky-700 font-medium ${active == 'overall' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('overall')}>Overall</button>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightviolet text-rose-900 font-medium ${active == 'project' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('project')}>Project</button>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-lightgreen text-green-900 font-medium ${active == 'external' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActive('external')}>External</button>
                    </div>
                </div>
                <div className='px-4 min-h-[60dvh] bg-white rounded-lg shadow-md mx-5'>
                    <div className='relative min-h-[20dvh]'>
                        {isLoading || !feedbackRes?.data
                            ?
                            <>
                                <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                    <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                    </div>
                                </div>
                            </>
                            :
                            <></>}
                        {
                            (!isLoading && !feedbackRes?.data?.data?.filter(item => active == 'external' ? validateFilterExternal(item) : validateFilter(item))?.length) ?
                                <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>No enquiry requested</h1>
                                : <></>
                        }
                        <table className='w-full' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                            <thead className='text-lg text-gray-300 border-b sticky top-40 bg-white'>
                                <tr className=''>
                                    <td className='pt-4 pb-2 border-b text-center'>SNo.</td>
                                    <td className='pt-4 pb-2 border-b'>Name</td>
                                    <td className='pt-4 pb-2 border-b text-center'>Company Name</td>
                                    <td className='pt-4 pb-2 border-b text-center'>Position In Firm</td>
                                    <td className='pt-4 pb-2 border-b text-center'>Overall Rating</td>
                                    <td className='pt-4 pb-2 border-b text-center'></td>
                                </tr>
                            </thead>
                            <>

                                <tbody>
                                    {
                                        feedbackRes?.data?.data?.filter(item => active == 'external' ? validateFilterExternal(item) : validateFilter(item))?.map((item, i) => <Row key={i} item={item} i={i} getReviewDetailsById={getReviewDetailsById} />)
                                    }

                                </tbody>
                            </>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReviewHome

const Row = ({ item, i, getReviewDetailsById }) => (
    <tr className='group'>
        <td className='text-center border-b group-last:border-b-0'>{i + 1}</td>
        <td className='leading-5 border-b group-last:border-b-0'><p>{item.companyAdminName || item?.externalData?.name}<br /><span className='text-xs text-gray-600'>{moment(item.createdAt).fromNow()}</span></p></td>
        <td className='leading-5 border-b group-last:border-b-0 text-center'><p>{item.companyName || item?.externalData?.companyName}<br /><span className='text-xs text-gray-600'>{item.typeOfIndustry || item?.externalData?.typeOfIndustry}</span></p></td>
        <td className='text-center border-b group-last:border-b-0 px-3'>{item.companyRole || item?.externalData?.position}</td>
        <td className='text-center border-b group-last:border-b-0 px-3'>
            <div className='flex items-center gap-1 justify-center'>
                <img className='w-6 h-6' src={ReviewStar} alt="" />
                <span>{Number(item.rating).toFixed(1)}</span>
            </div>
            {/* {Number(item.rating).toFixed(1)} */}
        </td>
        <td className='text-center'>
            <button className='py-1.5 px-4 rounded bg-lightblue text-sm min-w-max' onClick={() => getReviewDetailsById(item.id)}>View Review</button>
        </td>
    </tr>
)