import React, { useState } from 'react';
import barleycir from '../../images/barleywhitecircles.svg';
import greencir from '../../images/greencircles.svg';
import logo from '../../images/corner-cube-logo.svg';
import welcome from '../../images/welcome.svg';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';

export default function WelcomeNew() {

    return (
        <div>
            <img src={logo} style={{ position: "absolute", top: "10px", left: "4rem", width: "130px" }} />
            <center style={{ color: "#E3B900" }} className="mt-8 text-4xl font-medium">Welcome to Corner Qube!</center>
            <center style={{ color: "#47B000" }} className="mt-3 text-3xl font-semibold">Threadality Technologies</center>
            <div className='mt-2 w-full px-60'>
                <img src={welcome} className="w-full" />
            </div>
            <center>
                <div className='mt-8'>
                    <div style={{ float: "left", marginLeft: "28%" }} className="z-40 rounded-sm w-72 h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className={`z-50 rounded-sm w-full h-10 border-2 font-bold bg-signuppb border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                        >&nbsp;&nbsp;Continue to Corner Qube&nbsp;&nbsp;</button>
                    </div>
                    <div style={{ float: "right", marginRight: "28%" }} className="z-40 rounded-sm w-72 h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className={`z-50 rounded-sm w-full h-10 border-2 font-bold bg-lightgreen border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                        >&nbsp;&nbsp;Continue to Admin Panel&nbsp;&nbsp;</button>
                    </div>
                </div>
            </center>
            <img src={barleycir} className="w-72 absolute left-0 bottom-0"/>
            <img src={greencir} className="w-72 absolute right-0 bottom-0"/>

        </div>
    );
}










