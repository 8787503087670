// import React from 'react';
// import {
//   Chart as ChartJS,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

// ChartJS.register(
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend
// );

// export default function StackedBar({ items }) {
//   console.log("item", items)
//   const data = {
//     labels: items.map((data) => data.factor),
//     datasets: [
//       {
//         data: items.map((data) => data.factorAvgRating),
//         backgroundColor: '#99CED5',
//         borderColor: '#4A9FAB',
//         borderWidth: 1,
//       },
//     ],
//   };
//   return <Bar data={data}
//     options={{
//       scales: {
//         r: {
//           ticks: {
//             display: false // Hides the labels in the middel (numbers)
//           }
//         }
//       },
//       plugins: {
//         legend: { display: false }
//       }
//     }} />;
// }
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  indexAxis: 'y',
  scales: {
    x: {
      ticks: {
        display: false,
      },
      stacked: true,
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      stacked: true,
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

// const labels = ['January'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [2,5,6],
//       backgroundColor: 'rgb(255, 99, 132)',
//     },
//   ],
// };

export default function StackedBarChart({ items }) {

  const data = {
    labels: ["Rating"],
    datasets: [
      {
        label: '5 Star',
        data: [items[4].count],
        backgroundColor: '#67AC5B',
        barThickness: 80,
      },
      {
        label: '4 Star',
        data: [items[3].count],
        backgroundColor: '#9BE391',
        barThickness: 80,
      },
      {
        label: '3 Star',
        data: [items[2].count],
        backgroundColor: '#F6C344',
        barThickness: 80,
      },
      {
        label: '2 Star',
        data: [items[1].count],
        backgroundColor: '#F6C6D0',
        barThickness: 80,
      },
      {
        label: '1 Star',
        data: [items[0].count],
        backgroundColor: '#EE7A90',
        barThickness: 80,
      },
    ],
  };
  return <Bar height={200} options={options} data={data} />;
}
