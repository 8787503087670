import React, { useRef, useState } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
);

export default function DynamicRadarChart({ width, height, labels = [], containerClass, containerStyle, dataset = [] }) {

    const data = {
        labels: labels,
        datasets: dataset,
    };


    let options = {
        miterLimit: 5,
        interaction: {
            mode: 'nearest'
        },
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    backdropPadding: {
                        x: 4,
                        y: 8
                    }
                }
            }
        },
        plugins: {
            legend: { display: false },
            labels: {
                render: 'label',
                fontColor: ['rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)', 'rgba(255,0,0,1)']
            }
        }
    }



    return <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}><Radar options={options} data={data} redraw={false} /></div>;
}
