import React, { useEffect, useState } from 'react'
import Reviews from './Reviews'
import AddProject from './AddProject'
import { useQuery } from 'react-query'
import { getAllReviewsByProject } from '../../services/service'
import { useDispatch } from 'react-redux'
import { setMyReviews, setProjectReviewData } from '../../slices/reviewSlice'
import ReviewPopUp from '../Reusable/ReviewPopUp'
import RequestFeedback from '../Vendor/RequestFeedback'
import { toast } from 'react-hot-toast'

function ProjectDetail({ search, adminInfo, active, heading, selectedProject, setSelectedProject, myCompany }) {
    const dispatch = useDispatch();
    let { isLoading } = useQuery('getAllReviewsByProject', () => getAllReviewsByProject(selectedProject?.id), {
        refetchOnMount: true, refetchOnWindowFocus: false, retry: false, onSuccess: (res) => {
            if (res.data?.status == true) {
                dispatch(setProjectReviewData(res.data.data));
                dispatch(setMyReviews(res.data.myReviews))
            }
        }
    })
    const [activeTab, setActiveTab] = useState('details');
    const [addReview, setAddReview] = useState(null);
    const [requestFeedback, setRequestFeedback] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [vendor, setVendor] = useState('')

    useEffect(() => {
        let main = document.getElementById('main');
        main.scrollTop = 0;
    }, [])

    const closePopUp = () => {
        setAddReview(false)
    }

    const reviewNow = () => {
        let me = selectedProject?.vendors?.find(item => item?.vendorId == adminInfo?.companyId)
        setAddReview({ adminAddedCompanyId: selectedProject?.companyId, adminCompanyName: selectedProject?.companyName, companyId: selectedProject?.companyId, companyName: selectedProject?.companyName, projectId: selectedProject?.id, heading: selectedProject?.companyId == adminInfo?.companyId ? 'client' : 'vendor', initialOfferingTags: me?.tags, projectDuration: selectedProject?.duration })
    }

    const reviewVendor = () => {
        let me = selectedProject?.vendors?.find(item => item?.companyName == vendor)
        setAddReview({ adminAddedCompanyId: adminInfo?.companyId, adminCompanyName: adminInfo?.companyName, projectId: selectedProject?.id, companyId: vendor, companyName: vendor, heading: selectedProject?.companyId == adminInfo?.companyId ? 'client' : 'vendor', initialOfferingTags: me?.tags, projectDuration: selectedProject?.duration })
    }

    return (
        <>
            <div className='p-4 py-6 min-h-[400px] bg-white rounded-lg shadow-md'>
                <div className='flex items-center gap-4 text-gray-700 bg-white z-10'>
                    <svg onClick={() => setSelectedProject()} className='cursor-pointer' width="40" height="20" viewBox="0 0 49 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939339 10.9393ZM49 10.5L2 10.5V13.5L49 13.5V10.5Z" fill="currentColor" />
                    </svg>
                    <h1 className='text-2xl font-medium'>{heading}/{selectedProject?.projectName}</h1>
                </div>

                <div className='flex items-center gap-8 p-4'>
                    <button className={`py-1.5 px-5 text-lg rounded-lg shadow-md bg-lightgreen text-green-800 font-medium ${activeTab == 'details' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActiveTab('details')}>Details</button>
                    <button className={`py-1.5 px-5 text-lg rounded-lg shadow-md bg-cream text-gray-900 font-medium ${activeTab == 'reviews' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActiveTab('reviews')}>Reviews</button>
                    <button className={`py-1.5 px-5 text-lg rounded-lg shadow-md bg-lightpink text-pink-900 font-medium ${activeTab == 'my_reviews' ? 'opacity-100' : 'opacity-50'}`} onClick={() => setActiveTab('my_reviews')}>My Reviews</button>

                    {activeTab == 'my_reviews' ? <button className={`py-1.5 px-5 text-lg rounded-lg shadow-md bg-cream text-orange-800 font-medium ml-auto `} onClick={() => selectedProject?.companyId == adminInfo?.companyId ? setIsReview(true) : reviewNow()}>Review Now</button>
                        :
                        <button className={`py-1.5 px-5 text-lg rounded-lg shadow-md bg-cream text-orange-800 font-medium ml-auto `} onClick={() => setRequestFeedback(true)}>Request Feedback</button>
                    }
                </div>

                <section>
                    {activeTab == 'details' ? <AddProject disabled={!Boolean((selectedProject.companyId == adminInfo?.companyId) && (adminInfo?.userRole?.toLowerCase()?.includes('admin')))} project={selectedProject} setAddProject={setSelectedProject} adminInfo={adminInfo} myCompany={myCompany} />
                        :
                        <Reviews search={search?.toLowerCase()} adminInfo={adminInfo} active={active} activeTab={activeTab} project={selectedProject} />
                    }
                </section>
            </div>
            {
                addReview ?
                    <ReviewPopUp fromProject={true} reviewerCompany={adminInfo?.companyName} adminId={adminInfo?.uuid} companyId={addReview?.companyId} companyName={addReview?.companyName} initial={1} projectId={addReview?.projectId} projectDuration={selectedProject?.duration} reviewType={addReview?.projectId ? 'Project' : 'company'} heading={addReview?.heading} closePopUp={closePopUp} requestId={addReview.id} initialOfferingTags={addReview?.initialOfferingTags} />
                    :
                    <></>
            }
            {requestFeedback && <RequestFeedback initialCompany={selectedProject?.companyId == adminInfo?.companyId ? requestFeedback : ({ companyName: selectedProject?.companyName, companyId: selectedProject?.companyId })} adminInfo={adminInfo} heading={selectedProject?.companyId == adminInfo?.companyId ? 'vendor' : 'client'} popUp={requestFeedback} setPopUp={setRequestFeedback} openOther={() => { setRequestFeedback(false) }} myCompany={selectedProject?.vendors?.map(item => { return { companyName: item?.companyName, companyId: item?.vendorId, companyIcon: item?.companyIcon, sectorOfIndustry: item?.sectorOfIndustry } })} initialProject={selectedProject?.projectName} />}

            <div className={`fixed top-0 left-0 right-0 w-full bg-black bg-opacity-40 flex items-center justify-center z-[100] p-4 overflow-x-hidden overflow-y-auto md:inset-0 ${isReview ? '' : 'hidden'}`}>
                <div className="relative w-full max-w-6xl">
                    <div className="relative bg-white rounded-lg shadow pt-6  h-[650px]">
                        <button onClick={() => { setVendor(''); setIsReview(false) }} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>

                        <div className=' bg-cream rounded border-2 border-bermuda max-w-max mx-auto'>
                            <div className=' bg-lightgreen rounded border-2 border-bermuda transform -translate-x-1 -translate-y-1'>
                                <div className='bg-white rounded border-2 border-bermuda py-2 px-8 transform -translate-x-1 -translate-y-1'>
                                    <span className='text-2xl font-bold'>Write Review</span>
                                </div>
                            </div>
                        </div>

                        <div className='p-10 w-full flex flex-col justify-between min-h-[540px]'>
                            <div className='w-full grid grid-cols-2 items-center gap-10 max-w-4xl mx-auto'>
                                <div className='text-lg font-medium text-right'>Choose a vendor for which you are giving a feedback</div>
                                <div className="relative rounded bg-bermuda w-full max-w-xs h-10">
                                    <div className="text-base text-medium">
                                        <select className="transform -translate-x-1 -translate-y-1 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 focus:ring-bermuda focus:border-bermuda appearance-none cursor-pointer"
                                            value={vendor} onChange={(e) => setVendor(e.target.value)}>
                                            <option selected value='' disabled>Choose a vendor</option>
                                            <>{selectedProject?.vendors?.map((data, i) => (
                                                <option key={i} value={data.companyName}>{data.companyName}</option>
                                            ))}
                                            </>
                                        </select>
                                    </div>
                                    {vendor && <svg onClick={() => { setVendor(''); }} className='absolute top-2.5 right-4 cursor-pointer' width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6414 9.2907L10.932 9.99858L11.6414 10.7065L18.8011 17.8506L18.8032 17.8526C18.8656 17.9145 18.9151 17.9881 18.9488 18.0691C18.9826 18.1502 19 18.2372 19 18.325C19 18.4129 18.9826 18.4999 18.9488 18.581C18.9151 18.662 18.8656 18.7356 18.8032 18.7975L18.8032 18.7974L18.7974 18.8033C18.7356 18.8656 18.662 18.9151 18.581 18.9489C18.5 18.9826 18.4131 19 18.3253 19C18.2375 19 18.1506 18.9826 18.0696 18.9489C17.9885 18.9151 17.915 18.8656 17.8532 18.8033L17.8511 18.8012L10.708 11.6404L10 10.9307L9.29202 11.6404L2.14892 18.8012L2.14685 18.8033C2.08502 18.8656 2.01146 18.9151 1.93043 18.9489C1.8494 18.9826 1.76249 19 1.67471 19C1.58694 19 1.50003 18.9826 1.41899 18.9489C1.33797 18.9151 1.26441 18.8656 1.20257 18.8033L1.20259 18.8032L1.19677 18.7975C1.13443 18.7356 1.08494 18.662 1.05117 18.581C1.01739 18.4999 1 18.4129 1 18.325C1 18.2372 1.01739 18.1502 1.05117 18.0691C1.08494 17.9881 1.13443 17.9145 1.19678 17.8526L1.19885 17.8506L8.3586 10.7065L9.06803 9.99858L8.3586 9.2907L1.19968 2.14741C1.19957 2.1473 1.19946 2.1472 1.19936 2.14709C1.07354 2.02109 1.00284 1.85026 1.00284 1.67211C1.00284 1.49381 1.07366 1.32284 1.19968 1.19681C1.32569 1.07078 1.49657 1 1.67471 1C1.85272 1 2.02348 1.07067 2.14946 1.19652C2.14955 1.19661 2.14965 1.19671 2.14975 1.19681L9.29202 8.35675L10 9.06648L10.708 8.35675L17.8503 1.19681C17.8503 1.19675 17.8504 1.19669 17.8504 1.19663C17.9764 1.07071 18.1472 1 18.3253 1C18.5034 1 18.6743 1.07078 18.8003 1.19681C18.9263 1.32284 18.9972 1.49381 18.9972 1.67211C18.9972 1.85031 18.9264 2.02118 18.8005 2.14719C18.8005 2.14726 18.8004 2.14734 18.8003 2.14741L11.6414 9.2907Z" stroke="black" strokeWidth="2" />
                                    </svg>}

                                </div>
                            </div>

                            <div className='w-full max-w-2xl mx-auto border border-bermuda bg-pinkshade rounded'>
                                <div className='bg-white w-full min-h-[240px] transform -translate-x-2 -translate-y-2 border border-bermuda rounded grid place-items-center p-6'>
                                    <h1 className='w-full text-2xl font-medium'>{selectedProject?.projectName}</h1>
                                    <p>{selectedProject?.description}</p>
                                    <div className='flex items-center justify-between font-semibold w-full'>
                                        <span>Engagement duration: {selectedProject?.duration} month(s)</span>
                                        <span>Project cost: ${selectedProject?.cost}</span>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-end'>
                                <div className='bg-lightpeach rounded border-2 border-bermuda'>
                                    <button className='bg-lightgreen rounded border-2 border-bermuda py-2 px-8 transform -translate-x-1 -translate-y-1' onClick={() => { if (vendor) { setVendor(''); setIsReview(false); reviewVendor() } else { toast('Please select a vendor', { id: 'info' }) } }}>
                                        <span className='text-xl font-medium'>Review Company</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ProjectDetail
