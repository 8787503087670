import React from "react";
import { Route, Redirect } from "react-router-dom";
import { decodeToken } from "./services/constant";

const PrivateRoute = ({ component: Component, verified, path, ...rest }) => {
  const auth = decodeToken();

  const progress = auth?.progress

  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? (
          <Redirect to="/login" />
        ) : auth && progress ?

          (
            <Component {...props} />
          )
          : (
            <Redirect to="/" />
          )
      }
    />
  );
};

export default PrivateRoute;