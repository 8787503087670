import React, { useState, useEffect, useRef } from 'react';
import foundedon from '../images/foundedon.svg';
import icon from '../images/icon1.svg';
import maps from '../images/maps.svg';
import reviewstar from '../images/reviewstar.svg';
import minprojectcost from '../images/minprojectcost.svg';
import compare from '../images/compare.svg';
import globe from '../images/globe.svg';
import cross from '../images/cross.svg';
import buildingbanner from '../images/building banner-02.png';
import NavBar from '../Components/NavBar/NavBar';
import { Link, useLocation, useHistory } from "react-router-dom";
import useDebounce, { fetchPlace, useClickOutside } from '../services/constant';
import { searchCompanyAndTag, searchCompanyAndTagDetails } from '../services/service';
import { useQuery } from 'react-query';
import RadarChart from '../Components/Charts/RadarChart';
import { toast } from 'react-hot-toast';
import MultiRangeSlider from "multi-range-slider-react";
import '../Components/Reusable/Multislider.css'
import fillRating from '../images/reviewstar.svg';
import emptyRating from '../images/rating_empty.svg';

var key = process.env.REACT_APP_MAP_API_KEY;
const colors = ["lightviolet", "babyblue", "lightpink", "babycream"]
let arr = [
    { id: 1, name: "Delivery Mng." },
    { id: 2, name: "Contract Mng." },
    { id: 3, name: "Vendor Mng." },
    { id: 4, name: "Stakeholder Mng." },
    { id: 5, name: "Cybersec. Mng." },
    { id: 6, name: "Resource Mng." },
]

const sortList = [
    {
        id: 1,
        text: 'Default'
    },
    {
        id: 2,
        text: 'No. of feedback: highest to lowest'
    },
    {
        id: 3,
        text: 'No. of feedback: lowest to highest'
    },
    {
        id: 4,
        text: 'Rating: high to low'
    },
    {
        id: 5,
        text: 'Rating: low to high'
    }
]

function useGetQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function InformationTechnology() {
    const history = useHistory()

    const [compareBtn, setCompareBtn] = useState(false)
    const [allCompanies, setAllCompanies] = useState([])
    const [clearBtn, setClearBtn] = useState(false)
    const [effect1, setEffect1] = useState(false);
    const [addCompany, setAddCompany] = useState([])
    let query = useGetQuery();

    const [searchValue, setSearchValue] = useState(query.get('q')?.trim() || '')
    const [searchResults, setSearchResults] = useState([]);
    const [searchModal, setSearchModal] = useState(false);

    const [location, setLocation] = useState(query.get('location')?.trim() || "");
    const [locationModal, setLocationModal] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([]);

    const [filterModal, setFilterModal] = useState(false);
    const [sortOpen, setSortOpen] = useState(true)
    const [filterOpen, setFilterOpen] = useState(false)
    const [compareData, setCompareData] = useState([])
    const [sortOption, setSortOption] = useState(1);
    const [rating, setRating] = useState(0);
    const [typeOfFirm, setTypeOfFirm] = useState([0, 1, 2, 3, 4, 5])
    const [minValue, set_minValue] = useState(310);
    const [maxValue, set_maxValue] = useState(21000);
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };

    const searchRef = useRef()
    const locationRef = useRef()
    const filterRef = useRef()
    useClickOutside(() => searchModal ? setSearchModal(false) : locationModal ? setLocationModal(false) : setFilterModal(false), searchModal ? searchRef : locationModal ? locationRef : filterRef)

    const onAddingCompany = (data) => {
        if (addCompany.length < 3) {
            setAddCompany([...addCompany, data])
        }
    }
    const onRemovingCompany = (data) => {
        if (addCompany.length <= 3) {
            var newArr = []
            let arr = compareData.filter(item => data.companyName !== item)
            setCompareData(arr)
            newArr = addCompany.filter(function (item) {
                return item.companyId !== data.companyId
            })
            setAddCompany(newArr)
        }
    }

    const navigateToCompareScreen = () => {
        history.push(`/compare/companyCompareData/?companyName=${addCompany.map((item, i) => item.companyName)}`)
    }
    const onClearAllCompanies = () => {
        setAddCompany([])
    }


    let { isFetching, data: CompanyRes, refetch: refetchCompanies } = useQuery('searchCompanyAndTagDetails', () => searchCompanyAndTagDetails(query.get('q'), query.get('location')), { refetchOnWindowFocus: false })

    useEffect(() => {
        let data = localStorage.getItem('compare')
        if (data) {
            let compareArr = JSON.parse(data) || [];
            setCompareData(compareArr)
        }
    }, [localStorage.getItem('compare')])

    useEffect(() => {
        refetchCompanies();
    }, [query.get('q'), query.get('location')])


    const { refetch } = useQuery('searchCompanyAndTag', () => searchCompanyAndTag(searchValue), {
        refetchOnMount: false, refetchOnWindowFocus: false, enabled: false, onSuccess: (response) => {
            if (response.data.status === true) {
                if (searchValue.length !== 0) {
                    setSearchResults(response.data.searchCompanyOrTag)
                    setSearchModal(true)
                } else {
                    setSearchResults([]);
                    setSearchModal(false)
                }

            }
        }
    })

    let debouncedSearch = useDebounce(searchValue, 400)

    useEffect(() => {
        if (debouncedSearch) {
            refetch()
        }
        else {
            setSearchResults([]);
            setSearchModal(false)
        }
    }, [debouncedSearch])

    const handleSubmit = (e) => {
        e.preventDefault();
        searchPage();
    }

    const searchPage = () => {
        history.push(`/searchresults/infotech?location=${location}&q=${searchValue}`)
    }

    const handleLocationChange = async (e) => {
        setLocation(e.target.value);
        setLocationModal(true)
        if (!location) return;

        const res = await fetchPlace(location);
        !autocompleteCities.includes(e.target.value) &&
            res.data &&
            setAutocompleteCities(res.data.map((place) => place.location));
    };

    const handleFirm = (id) => {
        let arr = [...typeOfFirm]
        let idx = arr.findIndex(item => item == id);
        if (idx > -1) {
            arr.splice(idx, 1)
        }
        else {
            arr.push(id)
        }
        setTypeOfFirm(arr)
    }

    const validateFilter = (item) => {
        let ratingBoolean = false;
        let costBoolean = false;
        let firmBoolean = false;

        if (rating > 0) {
            if (Number(item?.avgRating || 0) <= rating) {
                ratingBoolean = true
            }
            else {
                ratingBoolean = false
            }
        }
        else if (rating == 0) {
            ratingBoolean = true
        }
        let number = item?.minimumProjectCost?.match(/\d+/g);

        if (Number(number[0]) >= minValue && Number(number[0]) <= maxValue) {
            costBoolean = true
        }

        if (typeOfFirm.includes(Number(item.typeOfIndustry))) {
            firmBoolean = true
        }
        if (typeOfFirm.includes(0)) {
            if (Number(item.typeOfIndustry) == 1) {
                firmBoolean = true
            }
        }
        return ratingBoolean && costBoolean && firmBoolean
    }

    return (
        <>
            <NavBar />

            <img src={buildingbanner} className="w-full h-[16rem]" />

            {/* <div className="z-30 rounded absolute top-36 left-2/4 transform -translate-x-2/4 -translate-y-2/4 h-10 text-black-500">
                <div className='w-full rounded h-10 bg-lightgreen border-bermuda border-2'>
                    <div className="z-40 rounded relative w-full transform -translate-x-1 -translate-y-2 h-10 text-black-500 bg-lightpink border-bermuda border-2 ">
                        <button className="z-50 rounded w-full h-10 border-2 font-semibold text-lg tracking-tightest bg-white border-bermuda px-10 transform -translate-x-1 -translate-y-2">Information Technology</button>
                    </div>
                </div>
            </div> */}
            <div className='flex items-center gap-3 px-14 font-medium py-3 hover:text-blue-500 max-w-max cursor-pointer' onClick={() => history.push('/')}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                <span>Go to home page</span>
            </div>
            <section className='px-10 2xl:px-14'>
                <div className='text-xl font-semibold'>{CompanyRes?.data?.searchCompanyOrTag?.length || 0} Registered Firms Results</div>
                <div className='text-lg font-medium'>Search Results for "{`${query.get('q')?.trim() || ''}`}"</div>

                <div className='py-4 flex items-center w-full gap-6 relative'>
                    <form onSubmit={handleSubmit} className='flex items-center gap-6 flex-grow text-sm'>
                        <div className="z-50 rounded w-full  max-w-lg h-10 border-2 bg-lightpink border-bermuda relative group" ref={searchRef}>
                            <div className='flex items-center gap-1 rounded w-full h-10 !border-bermuda hover:border-bermuda opacity-100 border-2 px-2 transform -translate-x-1 -translate-y-2 bg-white'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                                <input type="text" aria-describedby="text"
                                    placeholder="Search by Services or industry or company name"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    className="text-black outline-none z-60 flex-grow"
                                />
                            </div>
                            {/* {searchModal ? */}
                            <div style={{ zIndex: 50 }} className='w-full bg-white border border-gray-200 rounded absolute left-0 top-12 shadow-md hidden group-focus-within:block'>
                                <div >
                                    {searchResults.length == 0 ? <div className='py-1 px-4'>
                                        No Results found</div> :
                                        <div className='p-3'>
                                            {searchResults.slice(0, 5).map((data, i) =>
                                            (
                                                <div style={{ cursor: "pointer" }} className="border-underline last:border-none" onClick={() => setSearchModal(false)}>
                                                    <Link to={{
                                                        pathname: `/company/${data.companyName}`,
                                                        state: {
                                                            id: data.companyId
                                                        }
                                                    }} className="link" >
                                                        <div className='flex gap-2'>
                                                            <img src={data.companyIcon == null ? data.companyIcon : data.companyIcon} className={`w-10 h-10 object-cover rounded flex-shrink-0 ${data.companyIcon == null ? "search-p-img" : "search-p-img_"}`} />
                                                            <div className={`${data.companyIcon == null ? "flex search-t-gap w-full" : "flex search-t-gap_ "} flex flex-col w-full`} >
                                                                <span className="companyName">{data.companyName}</span>
                                                                <p className='text-right text-xs'>in {data.sectorOfIndustry}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>}
                                </div>
                            </div>
                            {/* : "" */}
                            {/* } */}
                        </div>

                        <div className="z-50 rounded w-full max-w-xs h-10 border-2 bg-lightgreen border-bermuda relative" ref={locationRef}>
                            <div className='flex items-center gap-1 rounded w-full h-10 !border-bermuda hover:border-bermuda opacity-100 border-2 px-2 transform -translate-x-1 -translate-y-2 bg-white'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500 flex-shrink-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                                <input type="text" aria-describedby="text"
                                    placeholder="Search by City, State or Country"
                                    value={location}
                                    onChange={(e) => handleLocationChange(e)}
                                    className="text-black outline-none z-60 flex-grow capitalize"
                                />
                            </div>
                            {location.length > 1 && locationModal ?
                                <div style={{ zIndex: 60, }} className='w-full max-h-44 overflow-y-auto bg-white border border-gray-200 rounded absolute left-0 top-12 shadow-md'>
                                    {autocompleteCities.length == 0 ? <div className='py-1 px-4'>
                                        No Results found</div> :
                                        <div className='p-1 z-10'>
                                            {autocompleteCities.map((data, i) =>
                                            (
                                                <div className="border-b last:border-none cursor-pointer p-2 hover:bg-blue-100 rounded capitalize" onClick={() => { setLocation(data); setLocationModal(false) }}>
                                                    {data}
                                                </div>
                                            ))}
                                        </div>}
                                </div> : ""
                            }
                        </div>

                        <div className='bg-bermuda rounded-lg max-w-max'>
                            <button type='submit' disabled={!searchValue && !location} className='py-2 px-6 text-base rounded-lg border-2 border-bermuda bg-lightviolet disabled:bg-gray-300 font-medium transform -translate-x-1 -translate-y-1'>Search</button>
                        </div>
                    </form>

                    <div className='bg-bermuda rounded-lg max-w-max ml-auto' ref={filterRef}>
                        <button onClick={() => setFilterModal(!filterModal)} className='py-2 px-4 rounded-lg bg-cream font-medium transform -translate-x-1 -translate-y-1 flex items-center gap-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                            </svg>
                            <span>Sort & Filter</span>
                        </button>
                        {filterModal && <div className='w-full bg-white border border-gray-200 rounded absolute right-0 top-14 custom-shadow max-w-xs p-3 z-[1001]'>
                            <div>
                                <div className='flex items-center justify-between py-1 border-b border-gray-800 font-medium cursor-pointer' onClick={() => { setSortOpen(!sortOpen); if (!sortOpen) { setFilterOpen(false) } }}>
                                    <span>Sort by</span>
                                    {
                                        sortOpen ?
                                            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.2321 1C9.46225 -0.333332 7.53775 -0.333333 6.76795 1L1.13879 10.75C0.368986 12.0833 1.33124 13.75 2.87084 13.75H14.1292C15.6688 13.75 16.631 12.0833 15.8612 10.75L10.2321 1Z" fill="black" />
                                            </svg>
                                            :
                                            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.76795 13C7.53775 14.3333 9.46225 14.3333 10.2321 13L15.8612 3.25C16.631 1.91667 15.6688 0.25 14.1292 0.25L2.87084 0.25C1.33123 0.25 0.368984 1.91667 1.13878 3.25L6.76795 13Z" fill="black" />
                                            </svg>
                                    }
                                </div>
                                {sortOpen && <div>
                                    {
                                        sortList.map(item => (
                                            <div onClick={() => setSortOption(item.id)} className={`flex items-center justify-between gap-2 ${sortOption == item.id ? 'text-gray-900' : 'text-gray-400'} cursor-pointer pt-1`}>
                                                <p>{item.text}</p>
                                                {sortOption == item.id && <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.8125 7.96875L6.5625 11.7188L12.1875 3.28125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>}
                                            </div>
                                        ))
                                    }
                                </div>}
                            </div>
                            <div className='py-4'>
                                <div className='flex items-center justify-between py-1 border-b border-gray-800'>
                                    <span className='cursor-pointer font-medium' onClick={() => setFilterOpen(!filterOpen)}>Filter by</span>
                                    <button className='flex items-center gap-1 px-2 py-0.5 rounded-full bg-red-200 hover:bg-red-300' onClick={() => {
                                        setRating(0);
                                        setTypeOfFirm([0, 1, 2, 3, 4, 5]);
                                        set_minValue(310);
                                        set_maxValue(21000);
                                    }}>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.75 11.25L11.25 3.75M3.75 3.75L11.25 11.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className='text-sm'>Clear All</span>
                                    </button>
                                    <span className='cursor-pointer' onClick={() => { setFilterOpen(!filterOpen); if (!filterOpen) { setSortOpen(false) } }}>
                                        {
                                            filterOpen ?
                                                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.2321 1C9.46225 -0.333332 7.53775 -0.333333 6.76795 1L1.13879 10.75C0.368986 12.0833 1.33124 13.75 2.87084 13.75H14.1292C15.6688 13.75 16.631 12.0833 15.8612 10.75L10.2321 1Z" fill="black" />
                                                </svg>
                                                :
                                                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.76795 13C7.53775 14.3333 9.46225 14.3333 10.2321 13L15.8612 3.25C16.631 1.91667 15.6688 0.25 14.1292 0.25L2.87084 0.25C1.33123 0.25 0.368984 1.91667 1.13878 3.25L6.76795 13Z" fill="black" />
                                                </svg>
                                        }
                                    </span>
                                </div>
                                {filterOpen && <div className='pt-2'>
                                    <div className='mb-6'>
                                        <h1>Rating</h1>
                                        <span className='mt-2 flex gap-2 justify-center items-center'>
                                            <span className='h-max w-max rounded'>{rating >= 1 ? <img src={fillRating} onClick={(e, i) => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={(e, i) => setRating(1)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 2 ? <img src={fillRating} onClick={(e, i) => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={(e, i) => setRating(2)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 3 ? <img src={fillRating} onClick={(e, i) => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={(e, i) => setRating(3)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 4 ? <img src={fillRating} onClick={(e, i) => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={(e, i) => setRating(4)} style={{ width: "24px", cursor: "pointer" }} />}</span>
                                            <span className='h-max w-max rounded'>{rating >= 5 ? <img src={fillRating} onClick={(e, i) => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />
                                                : <img src={emptyRating} onClick={(e, i) => setRating(5)} style={{ width: "24px", cursor: "pointer" }} />}</span>

                                        </span>
                                        <p className='mt-2 text-sm text-center'>{rating == 0 ? 'Choose rating' : rating + ' and below'}</p>
                                    </div>
                                    <div className='mb-6'>
                                        <h1>Minimum Project cost range</h1>
                                        <span>
                                            <MultiRangeSlider
                                                min={310}
                                                max={21000}
                                                step={5}
                                                minValue={minValue}
                                                maxValue={maxValue}
                                                label={false}
                                                ruler={false}
                                                style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                                                thumbLeftColor="#BFEBF2"
                                                thumbRightColor="#BDF0BC"
                                                barInnerColor='#777'
                                                onInput={(e) => {
                                                    handleInput(e);
                                                }}
                                            />
                                        </span>
                                        <div className='flex items-center justify-between px-2'>
                                            <span className='text-xs'>{'>'}$300</span>
                                            <span className='text-xs'>{'>'}$5000</span>
                                            <span className='text-xs'>{'>'}$10000</span>
                                            <span className='text-xs'>{'>'}$15000</span>
                                            <span className='text-xs'>{'>'}$20000</span>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <h1>Type Of Firm</h1>
                                        <div className='flex items-center justify-around px-2'>
                                            <div className='max-w-[95px]'>
                                                <button onClick={() => handleFirm(0)} className='flex items-center justify-start text-left gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes(0) ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        One Person Company
                                                    </span>
                                                </button>
                                            </div>
                                            <div className='w-0.5 h-12 bg-gray-300'>

                                            </div>
                                            <div className='grid grid-cols-2 gap-3'>
                                                <button onClick={() => handleFirm(1)} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes(1) ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Micro
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm(2)} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes(2) ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Small
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm(3)} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes(3) ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Medium
                                                    </span>
                                                </button>
                                                <button onClick={() => handleFirm(4)} className='flex items-center gap-2'>
                                                    <span className={`w-4 h-4 ${typeOfFirm.includes(4) ? 'bg-lightgreen' : 'bg-gray-300'} rounded flex-shrink-0`}></span>
                                                    <span className='text-xs'>
                                                        Large
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>

                {
                    isFetching ?
                        <div className='relative w-full min-h-[350px] grid place-items-center'>
                            <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                            </div>
                        </div>
                        :
                        CompanyRes?.data?.searchCompanyOrTag?.filter(item => validateFilter(item))?.length ? CompanyRes?.data?.searchCompanyOrTag?.sort((a, b) => {
                            if (sortOption == 2) {
                                return Number(b.numberOfFeedback || 0) - Number(a.numberOfFeedback || 0)
                            }
                            else if (sortOption == 3) {
                                return Number(a.numberOfFeedback || 0) - Number(b.numberOfFeedback || 0)
                            }
                            else if (sortOption == 4) {
                                return Number(b.avgRating || 0) - Number(a.avgRating || 0)
                            }
                            else if (sortOption == 5) {
                                return Number(a.avgRating || 0) - Number(b.avgRating || 0)
                            }
                            else if (sortOption == 1) {
                                return Number(a.avgRating || 0) - Number(b.avgRating || 0)
                            }
                        })?.filter(item => validateFilter(item))?.map((data, idx) => {
                            if (compareData.includes(data.companyName) && addCompany.findIndex(item => item.companyName == data.companyName) < 0) {
                                onAddingCompany(data); setCompareBtn(data.companyId);
                            }
                            return <AddCompany compareData={compareData} setCompareData={setCompareData} data={data} idx={idx} compareBtn={compareBtn} onAddingCompany={onAddingCompany} setCompareBtn={setCompareBtn} addCompany={addCompany} />
                        }
                        )
                            :
                            <div className='min-h-[350px]'>
                                <h1 className='text-3xl font-medium text-gray-400 text-center mt-10'>No Company Found</h1>
                            </div>
                }
            </section>
            {addCompany?.length > 0 && <div className='flex w-[97%] items-center justify-around rounded-t-lg gap-3 bg-white border-2 p-4 border-bermuda z-[100] fixed bottom-0 left-1/2 transform -translate-x-1/2'>
                {addCompany.map((item, i) => (
                    <div key={i} className='relative z-[100] w-full max-w-sm border-2 p-2 border-bermuda'>
                        <div className='absolute -top-3.5 -right-3.5' onClick={() => onRemovingCompany(item)}>  <img src={cross} className="w-10 cursor-pointer" /></div>
                        <div className='flex items-center gap-2 p-2'><img src={item.companyIcon} className="w-6" /><span className='line-clamp-1'>{item.companyName}</span></div>
                        <center className='flex'><img src={reviewstar} className="w-6" /> &nbsp;<span className='ml-1 font-semibold mt-0.5 text-lg'>{item.avgRating ? Number(item.avgRating).toFixed(1) : 0}</span></center>
                        <center className='flex text-base'>{item.companyWebsite} &nbsp;&nbsp;|&nbsp;&nbsp; {item.numberOfFeedback ? item.numberOfFeedback : 0} feedbacks</center>
                    </div>
                ))}
                {Array(3 - addCompany?.length).fill(1).map((item, i) => (
                    <div key={i} className='border-2 border-dashed w-full max-w-sm h-28 p-2 m-2'></div>
                ))}
                <div className=''>
                    <div className="mt-1 z-[90] relative rounded bg-bermuda w-36 h-10">
                        <div className="z-50 bg-lightgreen rounded w-full h-full border-2 border-bermuda">
                        </div>
                        <button className={`${clearBtn && "animate-dip"
                            } border-2 z-[60] focus:outline-none bg-white absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                            onClick={() => { setClearBtn(true); onClearAllCompanies(); setCompareData([]); localStorage.removeItem('compare') }}
                            onAnimationEnd={() => setClearBtn(false)}
                        >Clear all</button>
                    </div>
                    <div className="mt-4 z-[100] relative rounded bg-lightgreen w-36 h-10">
                        <div className="z-50 bg-bermuda rounded w-full h-full border-2 border-bermuda">
                        </div>

                        <button disabled={addCompany.length == 1 ? true : false}
                            className={`
                                } border-2 z-[60] focus:outline-none bg-lightgreen absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                            onClick={() => addCompany.length > 1 ? navigateToCompareScreen(addCompany) : toast("Add more company to compare", { id: 'info' })}
                        >
                            Compare</button>
                    </div>
                </div>
            </div>}
        </>
    )
}

const AddCompany = ({ data, idx, compareBtn, onAddingCompany, setCompareBtn, addCompany, compareData, setCompareData }) => {
    const history = useHistory()
    return (
        <div className='py-4 w-full'>
            <div className={`z-50 rounded-lg w-full border-2 border-bermuda bg-${colors[Math.abs((idx - colors.length) % colors.length)]}`}>
                <div className="z-[60] rounded-lg w-full bg-white text-black-500 border-black border-2 p-4 transform -translate-x-2 -translate-y-2 flex items-start">
                    <div className='flex-1'>
                        <div className='flex items-center mt-4 text-xl font-semibold cursor-pointer' onClick={() => history.push(`/company/${data.companyName}`)}><img src={data.companyIcon} className="w-6" /> &nbsp; {data.companyName} &nbsp;&nbsp; <img src={reviewstar} className="w-7" /> &nbsp;<span className='ml-1 font-semibold mt-0.5 text-lg'>{data.avgRating ? Number(data.avgRating).toFixed(1) : 0}</span>
                        </div>
                        <div className='mt-3 flex text-base'><img src={globe} className="w-6" /> &nbsp;&nbsp;{data.companyWebsite} &nbsp;&nbsp;|&nbsp;&nbsp; {data.numberOfFeedback ? data.numberOfFeedback : 0} feedbacks</div>
                        <div className='mt-3 w-full flex flex-wrap'>
                            <div className='flex w-2/12 items-center text-center text-base font-semibold'><img src={foundedon} className="w-8 h-8" /> &nbsp; {data.companyYearOfEstablishment}</div>
                            <div className='flex w-2/12 items-center text-center text-base font-semibold'><img src={icon} className="w-8 h-8" /> &nbsp; {data.companyEmployeeScale}</div>
                            <div className='flex w-4/12 items-center text-center text-base font-semibold'><img src={maps} className="w-7 h-7" /> &nbsp; {data.headQuartered}</div>
                            <div className='flex w-3/12 items-center text-center text-base font-semibold'><img src={minprojectcost} className="w-9 h-9" /> &nbsp; {data.minimumProjectCost}</div>
                        </div>
                        <div className='flex flex-wrap gap-4 max-w-3xl py-4'>
                            {data?.industryServices?.map((item, i) => {
                                return (
                                    <div className={`flex rounded-md px-4 h-9 text-black text-sm font-semibold py-2 bg-${colors[Math.abs((i - colors.length) % colors.length)]}`}>{item}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <div className='flex w-full'>
                            <div className="z-[90] relative rounded bg-bermuda w-48 h-10">
                                <div className={`z-50 ${compareData.includes(data?.companyName) ? 'bg-lightgreen' : 'bg-bermuda'} rounded w-full h-full border-2 border-bermuda`}>
                                </div>
                                <button className={` border-2 z-[60] ${compareData.includes(data?.companyName) ? 'bg-white' : 'bg-lightgreen'} absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                                    onClick={() => {
                                        if (!compareData.includes(data?.companyName) && compareData?.length < 3) {
                                            onAddingCompany(data); setCompareBtn(data.companyId);
                                            setCompareData([...compareData, data?.companyName]);
                                            localStorage.setItem('compare', JSON.stringify([...compareData, data?.companyName]))
                                            toast.success("Added to compare", { id: 'success' })
                                        }
                                        else if (compareData.includes(data?.companyName)) {
                                            history.push(`/compare/companyCompareData/?companyName=${addCompany.map((item, i) => item.companyName)}`)
                                        }
                                        else if (compareData?.length >= 3) {
                                            toast.error("Cannot add more than 3 companies to compare", { id: 'error' })
                                        }
                                    }}
                                ><span className="flex items-center justify-center gap-2">
                                        <img src={compare} style={{ width: "20px" }} />
                                        <span>{compareData.includes(data?.companyName) ? 'Compare' : 'Add to Compare'}</span>
                                    </span>
                                </button>
                            </div>
                            <Link to={{
                                pathname: `/company/${data.companyName}`,
                                state: {
                                    id: data.companyId
                                }
                            }} className="link" >
                                <div className="ml-4 z-[100] relative rounded bg-lightgreen w-36 h-10">
                                    <div className="z-50 bg-bermuda rounded w-full h-full border-2 border-bermuda">
                                    </div>
                                    <button className={`border-2 z-[60] focus:outline-none bg-lightgreen absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                                    >Overview</button>
                                </div>
                            </Link>
                        </div>
                        <div className='grid place-items-center'>
                            <RadarChart width={320} height={250} draggable={false} heading={''} initialData={data?.review?.graphRating ? data?.review?.graphRating?.map(item => { return { name: item.typeOfReview, rating: item.rating || 1 } }) : arr} graphData={data?.review?.graphRating ? data?.review?.graphRating?.map(item => { return { name: item.typeOfReview, rating: item.rating || 1 } }) : arr} backgroundColor={'rgba(239 ,185 ,255 ,0.6)'} borderColor={'rgb(222, 22, 255)'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}