import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            // display: false
        },
        title: {
            display: false,
        },

    },
    scales: {
        y: {
            beginAtZero: true,
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                color: 'transparent',
            },
        },
        x: {
            beginAtZero: true,
            display: true,
            position: 'left',
            grid: {
                color: 'transparent',
            }
        },
    }
};


export function DoubleLineChart({ labels, data1, data2 }) {
    const data = {
        labels,
        datasets: [
            {
                label: 'Feedbacks on Mine',
                data: data1,
                backgroundColor: 'rgba(22, 92, 255, 1)',
                borderColor: 'rgba(22, 92, 255, 1)'
            },
            {
                label: 'Feedbacks on Others',
                data: data2,
                backgroundColor: 'rgba(20, 201, 201, 1)',
                borderColor: 'rgba(20, 201, 201, 1)'
            },
        ],
    };

    return <Line width={300} height={100} options={options} data={data} redraw={false} />;
}
