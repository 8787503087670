import React, { useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import axios from 'axios';
import { config } from '../../services/config';
import { Link, Redirect, useHistory } from "react-router-dom";
import { useSendOtp } from '../../hooks';
import { toast } from 'react-hot-toast';

export default function VerifyOtp(props) {
    const history = useHistory()
    const [email, setEmail] = useState(props.location.state.email)
    const [registerFlow, setRegisterFlow] = useState(props.location.state.registerFlow)
    const [otp1, setOtp1] = useState("")
    const [otp2, setOtp2] = useState("")
    const [otp3, setOtp3] = useState("")
    const [otp4, setOtp4] = useState("")
    const [otp5, setOtp5] = useState("")
    const [otp6, setOtp6] = useState("")
    const [authKey, setAuthKey] = useState("")

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    const handleSubmit = (event) => {
        const data = new FormData(event.target);
        event.preventDefault()
        if (props.location.state.name) {
            verifyOtp();
        } else if (props.location.state.name || props.location.state.registerFlow === true) {
            verifyOtp();
        }
        else {
            verifyForgotPasswordEmail();
        }
    }
    const verifyForgotPasswordEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "otp": otp1 + otp2 + otp3 + otp4 + otp5 + otp6
        }
        axios.post(config.adminUrl + '/forgotPassword/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    console.log("Forgot Password Email verified successfully!!")
                    history.push('/resetpassword', { email: email, authKey: authKey })
                    setAuthKey(response.data.authkey)
                } else {
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var self = this;
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "otp": otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
            "verify": props.location.state.verify
        }
        axios.post(config.adminUrl + '/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    toast.success("Email verified successfully!!", { id: 'success' })
                    localStorage.setItem('corner_qube', response.data.accessToken)
                    window.location.pathname = '/welcome'
                } else {
                    toast.error(response.data.message, { id: 'error' })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const { mutate: sendOtp } = useSendOtp()

    const resendOtp = (e) => {
        var payload = {
            "companyAdminEmail": email,
            "registerFlow": registerFlow
        }
        sendOtp(payload)
    }


    return (
        <div className="h-screen sm:flex-row">
            <div className="border-none rounded py-4 w-full">
                <Link to="/"><img src={logo} style={{ width: "120px", top: "1rem", position: "fixed", left: "5rem" }} /></Link>
                <div className='ml-auto mr-auto mt-32 w-2/6 border-2 rounded-xl px-4 py-12'>
                    <center className="text-2xl font-semibold mt-4">Verify your Identity</center>
                    <center className="login">
                        <div className="mt-3 font_family">Please enter the otp we sent to email.</div>
                        <form onSubmit={handleSubmit} className="mt-12 font_family">
                            <div className="form-group verify">
                                <input
                                    name="otp1"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp1}
                                    onChange={(e) => setOtp1(e.target.value)}
                                    tabIndex="1" maxLength={1} onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp2"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp2}
                                    onChange={(e) => setOtp2(e.target.value)}
                                    tabIndex="2" maxLength={1} onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp3"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp3}
                                    onChange={(e) => setOtp3(e.target.value)}
                                    tabIndex="3" maxLength="1" onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp4"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp4}
                                    onChange={(e) => setOtp4(e.target.value)}
                                    tabIndex="4" maxLength={1} onKeyUp={e => inputfocus(e)}
                                />

                                <input
                                    name="otp5"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp5}
                                    onChange={(e) => setOtp5(e.target.value)}
                                    tabIndex="5" maxLength={1} onKeyUp={e => inputfocus(e)}
                                />
                                <input
                                    name="otp6"
                                    type="text"
                                    autoComplete="off"
                                    className="form-control border-2 text-center digit-width"
                                    value={otp6}
                                    onChange={(e) => setOtp6(e.target.value)}
                                    tabIndex="6" maxLength={1} onKeyUp={e => inputfocus(e)}
                                />
                            </div>
                            <div className='px-10 ml-3'>
                                <div className="mt-16 z-40 rounded w-full h-12 text-black-500 bg-bermuda border-bermuda border-2 ">
                                    <button type='submit' className={`z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda px-4 transform -translate-x-1.5 -translate-y-2.5`}
                                    >Verify</button>
                                </div>
                            </div>
                            <center>
                                <div id="err-msg" className="errormsg"></div>
                                <div id="otp" className="errormsg"></div>
                                <p className="mt-12">Didn't receive Otp ?<span onClick={resendOtp} style={{ color: "black", cursor: "pointer" }} > Send again</span></p>
                            </center>
                        </form>
                    </center>
                    {/* <center className="text-sm font-semibold mt-8">Login here</center> */}

                </div>
            </div>
        </div>
    );
}


