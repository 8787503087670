import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useFeedbackRequest } from '../../hooks';
import { getCompanyUsers, getProjects } from '../../services/service';
import ReviewPopUp from '../Reusable/ReviewPopUp';

function RequestFeedback({ adminInfo, heading, popUp, setPopUp, openOther, myCompany, initialCompany, initialProject }) {

    const companies = useSelector((state) => initialProject ? myCompany : state.company.companyList)
    const [companyName, setCompanyName] = useState('');
    const [present, setPresent] = useState(false);
    const [projectName, setProjectName] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([])
    const [addReview, setAddReview] = useState(false);

    const [data, setData] = useState({
        companyName: initialCompany?.companyName || '',
        note: '',
        projectName: initialProject || ''
    })
    const { isLoading, data: companyUsersRes, refetch } = useQuery('getCompanyUsers', () => getCompanyUsers(initialCompany?.companyName || data?.companyName), { enabled: false, refetchOnWindowFocus: false });
    const { data: ProjectRes, refetch: projectFetch } = useQuery('getProjects', () => getProjects(adminInfo?.companyName, present?.companyId), { staleTime: 300000, refetchOnMount: false, refetchOnWindowFocus: false, retryDelay: 30000, enabled: false })

    const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value })

    const checkCompany = (comp) => {
        setData({ ...data, companyName: comp });
        setCompanyName('');
        let i = myCompany.find(item => item.companyName.toLowerCase().includes(comp.toLowerCase()));
        if (i) {
            setPresent(i)
        }
    }

    useEffect(() => {
        if (initialCompany?.companyName) {
            checkCompany(initialCompany?.companyName)
        }
    }, [initialCompany?.companyName])

    useEffect(() => {
        if (present || initialCompany?.companyName) {
            refetch()
            if (present)
                projectFetch()
        }
    }, [present, initialCompany?.companyName, adminInfo])

    const selectUser = (id) => {

        if (selectedUsers.includes(id)) {
            let arr = [...selectedUsers];
            let i = arr.findIndex(item => item == id);
            if (i > -1) {
                arr.splice(i, 1);
                setSelectedUsers(arr)
            }
        }
        else {
            if (selectedUsers.length == 15) {
                toast('You can select upto 15 reviewers', { id: 'info' })
            }
            else
                setSelectedUsers([...selectedUsers, id])
        }
    }

    const { isLoading: sending, mutate: sendRequest } = useFeedbackRequest(() => {
        setData({
            companyName: '',
            note: '',
            projectName: ''
        });
        setPopUp(false)
    })

    const postRequest = (e) => {
        e.preventDefault();
        let payload = {
            companyName: data.companyName,
            note: data.note,
            projectName: data.projectName,
            reviewers: selectedUsers,
            adminAddedCompanyId: adminInfo?.companyId,
            requestType: heading,
            companyId: heading == 'vendor' ? present?.companyId : initialCompany?.companyId,
        };
        sendRequest(payload)
    }

    const closePopUp = () => {
        setAddReview(false)
    }

    const reviewNow = (item) => {
        setAddReview({ companyId: item?.companyId, companyName: item?.companyName, heading: heading == 'vendor' ? 'client' : 'vendor', initialOfferingTags: item?.offeringTags })
    }

    return (
        <>
            <div className='fixed inset-0 min-h-screen overflow-hidden bg-transparent grid place-items-center z-50'>
                <div className='fixed inset-0 min-h-screen overflow-hidden bg-black bg-opacity-50 cursor-pointer' onClick={() => setPopUp(false)}>
                </div>
                <form onSubmit={postRequest} className='bg-white min-h-[600px] rounded-lg p-10 z-10 w-full max-w-4xl flex flex-col relative overflow-hidden'>
                    <svg onClick={() => setPopUp(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-800 cursor-pointer absolute top-5 right-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <h1 className='text-4xl font-semibold text-lightbrown capitalize pb-2'>Request Feedback from {heading}</h1>
                    <div className='flex-grow'>
                        <div className='grid grid-cols-6 items-center max-w-2xl mx-auto gap-8 pt-6 pb-2 z-20 relative'>
                            <label className='col-span-2 text-lg font-medium text-right min-w-max'>Company Name</label>
                            <div className='w-full col-span-4 relative'>
                                <input type="text" disabled={initialCompany?.companyName} name='name' value={companyName || data.companyName} onChange={(e) => { setPresent(false); setData({ ...data, companyName: '' }); setCompanyName(e.target.value) }} className='bg-white w-full outline-none rounded p-2 shadow border-2 border-gray-100' />
                                {(data?.companyName || companyName) &&
                                    <>
                                        {!initialCompany?.companyName && <svg onClick={() => { setPresent(false); setData({ ...data, companyName: '' }); setCompanyName('') }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-400 cursor-pointer absolute top-2.5 right-2.5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        }
                                        {companyName && <div className='bg-white rounded-lg shadow-lg absolute top-12 left-0 w-full'>
                                            {
                                                companies.filter(item => (item?.companyName != adminInfo?.companyName && item.companyName.toLowerCase().includes(companyName.toLowerCase()))).map(item => <div key={item.id} className='flex items-center gap-2 py-2 px-4 cursor-pointer hover:bg-gray-50' onClick={() => checkCompany(item.companyName)}><img src={item?.companyIcon} className='w-8 h-8 rounded-full object-cover' alt="" /> <div className='flex-grow'><h1>{item.companyName}</h1><p className='text-xs italic text-gray-400 text-right'>{item?.sectorOfIndustry}</p></div></div>)
                                            }
                                        </div>}
                                    </>
                                }
                            </div>
                        </div>


                        {data.companyName ?
                            (initialCompany?.companyName || present) ? <>
                                <div className='flex items-center justify-center my-3 gap-1'>
                                    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.39904 22.4784L0.525897 13.7169C0.189147 13.3837 0 12.932 0 12.4611C0 11.9902 0.189147 11.5385 0.525897 11.2053L1.60391 10.1388C1.9412 9.80609 2.39833 9.61922 2.87495 9.61922C3.35157 9.61922 3.80872 9.80609 4.14601 10.1388L10.5961 16.5075L25.7864 0.558362C26.1133 0.215699 26.5644 0.0151824 27.0407 0.000827163C27.5171 -0.0135281 27.9797 0.159452 28.3271 0.481795L29.4342 1.51408C29.7814 1.83708 29.9846 2.28309 29.9992 2.75407C30.0137 3.22505 29.8384 3.68246 29.5117 4.02575L11.984 22.4387C11.819 22.6125 11.6203 22.7517 11.3997 22.8481C11.1791 22.9445 10.9412 22.9961 10.7001 22.9998C10.4589 23.0035 10.2194 22.9592 9.99594 22.8697C9.77244 22.7801 9.56945 22.647 9.39904 22.4784Z" fill="url(#paint0_linear_249_3159)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_249_3159" x1="25.3917" y1="1.27239" x2="7.07375" y2="18.5224" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#E3B900" />
                                                <stop offset="1" stop-color="#47B000" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <h1>This <span className='capitalize'>{heading}</span> is part of your {heading} list</h1>
                                </div>

                                <div className='grid grid-cols-6 items-center justify-center max-w-3xl mx-auto gap-8 py-2'>
                                    <label className='text-lg col-span-2 font-medium text-right min-w-max'>Add Note</label>
                                    <div className='w-full relative col-span-4'>
                                        <textarea type="text" name='note' value={data?.note} onChange={handleChange} placeholder="Enter a note to set context for feedback" className='bg-white w-full text-sm h-20 resize-none outline-none rounded p-2 shadow border-2 border-gray-100'> </textarea>
                                    </div>
                                </div>
                                <div className='grid grid-cols-6 items-center justify-center max-w-3xl mx-auto gap-8 py-2 z-20 relative'>
                                    <label className='col-span-2 text-lg font-medium text-right min-w-max leading-5'>Choose Project<br /><span className='text-xs'>{initialProject ? '' : 'or Company review'}</span></label>
                                    <div className='w-full col-span-4 relative'>
                                        <input type="text" name='project' disabled={Boolean(initialProject)} value={initialProject || projectName || data.projectName} onChange={(e) => { setData({ ...data, projectName: '' }); setProjectName(e.target.value) }} placeholder="Choose project. Leave blank for overall review" className='bg-white w-full outline-none rounded p-2 shadow border-2 border-gray-100' />
                                        {projectName &&
                                            <>
                                                <svg onClick={() => setProjectName('')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-400 cursor-pointer absolute top-2.5 right-2.5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>

                                                <div className='bg-white rounded-lg shadow-lg absolute top-12 left-0 w-full'>
                                                    {
                                                        ProjectRes?.data?.projects?.filter(item => item?.projectName.toLowerCase().includes(projectName.toLowerCase() || ''))?.map(item => <h1 key={item.id} className='py-2 px-4 cursor-pointer' onClick={() => { setData({ ...data, projectName: item.projectName }); setProjectName('') }}>{item.projectName}</h1>)
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='grid grid-cols-6 items-start justify-center max-w-3xl mx-auto gap-8 py-2'>
                                    <label className='text-lg col-span-2 font-medium text-right min-w-max'>Choose Reviewer<br /><span className='text-base'>{selectedUsers.length}/15</span></label>
                                    <div className='w-full relative col-span-4 px-3 py-4 grid gap-x-2 gap-y-3 grid-cols-2 bg-white shadow-lg border-2 border-gray-100 rounded-lg max-h-32 overflow-y-auto'>
                                        {
                                            isLoading
                                                ?
                                                <p className='text-gray-500 text-sm'>Loading ...</p>
                                                :
                                                <>
                                                    {companyUsersRes?.data?.companyUsers?.map((user, i) => <div key={i} className='flex gap-1 cursor-pointer' onClick={() => selectUser(user.uuid)}>
                                                        <div className={`w-4 h-4 flex-shrink-0 rounded-sm border border-bermuda ${selectedUsers.includes(user.uuid) ? 'bg-lightgreen' : 'bg-white'}`}>
                                                        </div>
                                                        <p className='text-xs text-gray-500 min-w-max'>{user.companyAdminName} {"("}{user.companyRole}{")"}</p>
                                                    </div>)}
                                                </>
                                        }
                                    </div>
                                </div>
                            </>
                                :
                                <>
                                    <div className='max-w-xl mx-auto flex items-center gap-6'>
                                        <label className='font-medium'>This Vendor is not part of your Vendor list,
                                            would you like to send an request to this firm?</label>
                                        <button className='bg-lightgreen text-green-800 rounded-3xl py-2 px-4 min-w-max text-sm font-medium' onClick={openOther}>Send request</button>
                                    </div>
                                    <h1 className='p-6 text-center font-medium mx-auto max-w-sm'>Add Client to Client list before sending a Request.</h1>
                                </>
                            :
                            <></>
                        }
                    </div>
                    <div className='flex items-center justify-between pt-6 gap-10 z-10 relative'>
                        <span>
                            {initialCompany?.id && <button onClick={() => reviewNow(initialCompany)} type='button' className='bg-lightblue disabled:bg-gray-300 text-sky-500 rounded-lg shadow-xl font-medium text-lg py-2 px-6'>
                                Review Firm
                            </button>}
                        </span>
                        <button type='submit' disabled={!data.companyName || !selectedUsers.length || sending} className='bg-lightgreen disabled:bg-gray-300 text-green-900 rounded-lg shadow-xl font-medium text-lg py-2 px-6'>
                            Send Request
                        </button>
                    </div>


                    <div className='absolute -bottom-48 -left-48'>
                        <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#BDF0BC" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#BDF0BC" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#BDF0BC" />
                        </svg>
                    </div>
                </form>
            </div>

            {
                addReview ?
                    <ReviewPopUp reviewerCompany={adminInfo?.companyName} adminId={adminInfo?.uuid} companyId={addReview?.companyId} companyName={addReview?.companyName} initial={1} projectId={addReview?.projectId} reviewType={addReview?.projectId ? 'Project' : 'company'} heading={addReview?.requestType || 'client'} closePopUp={closePopUp} requestId={addReview.id} initialOfferingTags={addReview?.initialOfferingTags} projectDuration={addReview?.projectDuration} />
                    :
                    <></>
            }
        </>
    )
}

export default RequestFeedback