import React, { useState } from 'react'
import AddProject from '../Project/AddProject';
import MyProjects from '../Project/MyProjects'
import ProjectDetail from '../Project/ProjectDetail';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../../slices/adminSlice';
import { getAllClientStatus, getAllVendorStatus, getAssignedProjects, getProjectsByAdmin, projectFeedbackRequestList, projectRequest } from '../../services/service';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import * as ProjectRequest from '../Project/Projects';
import ReviewPopUp from '../Reusable/ReviewPopUp';

function Projects() {
    const adminInfo = useSelector(selectAdminDetails)
    const { data: vendorRes, refetch } = useQuery('getAllVendorStatus', getAllVendorStatus, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, enabled: false })
    const { isLoading: projectLoading, data: projectRes, isFetching } = useQuery('getProjectsByAdmin', getProjectsByAdmin, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })
    const { isLoading: apLoading, data: AssignedProjectRes } = useQuery('getAssignedProjects', getAssignedProjects, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })

    const [active, setActive] = useState('projects');
    const [reqType, setReqType] = useState('feedbacks')
    const [addProject, setAddProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState();
    const [addReview, setAddReview] = useState(null);
    const [search, setSearch] = useState('')
    const { data: ProjectRequestRes, isLoading, refetch: projectReqRefetch } = useQuery(reqType == 'projects' ? 'projectRequest' : 'projectFeedbackRequest', reqType == 'projects' ? projectRequest : projectFeedbackRequestList, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })

    useEffect(() => {
        if (adminInfo?.companyId) {
            refetch();
        }
    }, [adminInfo?.companyId])

    useEffect(() => {
        if (reqType) {
            projectReqRefetch()
        }
    }, [reqType])

    const handleProjectClick = (project) => {
        setSelectedProject(project)
    }

    const closePopUp = () => {
        setAddReview(false)
    }

    const reviewNow = (item) => {
        let me = item?.vendors?.find(item => item?.vendorId == adminInfo?.companyId)
        setAddReview({ companyId: item?.adminAddedCompanyId, companyName: item?.adminCompanyName, projectId: item?.projectId, heading: item?.adminAddedCompanyId == adminInfo?.companyId ? 'client' : 'vendor', id: item?.id, initialOfferingTags: me?.tags, projectDuration: item?.projectDuration })
    }
    if (addProject) return <AddProject disabled={false} heading={addProject?.id ? 'Edit Project' : 'Create Project'} adminInfo={adminInfo} project={addProject} setAddProject={setAddProject} myCompany={vendorRes?.data?.vendors || []} />

    return (
        <>
            <div className='bg-body sticky top-20 z-30'>
                <div className='p-4 flex items-center justify-between gap-4 w-full'>
                    <div className='flex items-center bg-white rounded w-full max-w-md px-3 shadow-md'>
                        <input type="text" placeholder={`Search ${selectedProject ? 'Reviewer' : 'Project'}`} value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                </div>

                <div className='flex items-center gap-6 px-4 pb-4'>
                    <button className={`py-2 px-5 text-lg rounded-lg shadow-md bg-lightblue text-sky-700 font-medium ${active == 'projects' ? 'opacity-100' : 'opacity-50'}`} onClick={() => { setActive('projects'); setSelectedProject() }}>Your Projects</button>
                    <button className={`py-2 px-5 text-lg rounded-lg shadow-md bg-lightviolet text-orange-900 font-medium ${active == 'assigned' ? 'opacity-100' : 'opacity-50'}`} onClick={() => { setActive('assigned'); setSelectedProject() }}>Assigned</button>
                    <div className='relative group'>
                        <button className={`py-2 px-5 text-base rounded-lg shadow-md bg-cream text-orange-900 font-medium ${active == 'requests' ? 'opacity-100' : 'opacity-50'}`} onClick={() => { setActive('requests'); setSelectedProject(); setReqType('feedbacks') }}>Requests</button>
                        <div className={`group-hover:border flex flex-col absolute top-9 2xl:top-10 left-1/2 transform -translate-x-1/2 rounded-lg h-0 group-hover:h-auto overflow-hidden`}>
                            <button className='py-2 px-4 font-medium bg-orange-50 text-orange-800 hover:text-orange-900 rounded-t-lg border-2 border-orange-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('feedbacks'); setSelectedProject() }}>Feedbacks</button>
                            <button className='py-2 px-4 font-medium bg-orange-50 text-orange-800 hover:text-orange-900 rounded-b-lg border-2 border-t-0 border-orange-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('projects'); setSelectedProject() }}>Projects</button>
                        </div>
                    </div>
                    <button disabled={!adminInfo?.userRole?.toLowerCase()?.includes('admin')} onClick={() => { refetch(); setAddProject(true) }} className='py-2 px-5 rounded-lg ml-auto shadow-md bg-lightgreen disabled:bg-gray-300 text-green-900 font-medium'>Create Project</button>
                </div>
            </div>

            <section className='px-4'>
                {selectedProject ? <ProjectDetail search={search.toLowerCase()} adminInfo={adminInfo} active={active} heading={active == 'projects' ? 'My Projects' : 'Assigned Projects'} selectedProject={selectedProject} setSelectedProject={setSelectedProject} myCompany={vendorRes?.data?.vendors || []} />
                    : <>
                        {active == 'projects' ? <MyProjects search={search.toLowerCase()} adminInfo={adminInfo} heading={"My Projects"} isLoading={projectLoading} projects={projectRes?.data?.projects} handleProjectClick={handleProjectClick} /> : <></>}
                        {active == 'assigned' ? <MyProjects search={search.toLowerCase()} adminInfo={adminInfo} heading={"Assigned Projects"} isLoading={apLoading} projects={AssignedProjectRes?.data?.projects} handleProjectClick={handleProjectClick} /> : <></>}
                        {active == 'requests' ? <ProjectRequest.default search={search.toLowerCase()} reviewNow={reviewNow} adminInfo={adminInfo} heading={reqType == 'projects' ? 'Project Requests' : 'Feedback Requests'} reqType={reqType} projects={ProjectRequestRes?.data?.projects || []} isLoading={isLoading} /> : <></>}
                    </>}
            </section>
            {
                addReview ?
                    <ReviewPopUp reviewerCompany={adminInfo?.companyName} adminId={adminInfo?.uuid} companyId={addReview?.companyId} companyName={addReview?.companyName} initial={1} projectId={addReview?.projectId} reviewType={addReview?.projectId ? 'Project' : 'company'} heading={addReview?.heading} closePopUp={closePopUp} requestId={addReview.id} initialOfferingTags={addReview?.initialOfferingTags} projectDuration={addReview?.projectDuration} />
                    :
                    <></>
            }
        </>
    )
}

export default Projects
