import React from 'react';
import { RadialBarChart, RadialBar, Legend } from 'recharts';


const RadialChart = ({ width, height, inner, outer }) => {

    // Sample data
    const data = [
        { name: inner.label || 'A', x: inner?.value, fill: inner?.color || "#FF748F" },
        { name: outer.label || 'B', x: outer?.value, fill: outer?.color || "#F8C646" },
        { name: 'B', x: 360, fill: "none" },
    ];


    return (
        <RadialBarChart width={width || 220} height={height || 220} data={data} innerRadius="65%" barSize={6} outerRadius="100%" startAngle={90} endAngle={-180}>
            <RadialBar minAngle={15} dataKey="x" allowReorder="yes" />
        </RadialBarChart>
    );
}

export default RadialChart;