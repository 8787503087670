import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getCompanyDetails, getSignUpRefData } from '../../services/service'
import { bgPalette, fetchPlace, uploadImage, useClickOutside } from '../../services/constant'
import axios from 'axios'
import { config } from '../../services/config'
import { useUpdateCompanyInformation } from '../../hooks'
import { toast } from 'react-hot-toast'
import WarnPopUp from '../Reusable/WarnPopUp'
import UploadBanner from './UploadBanner'
import searchbanner from '../../images/search-banner.svg';
import { useDispatch, useSelector } from 'react-redux'
import { selectAdminDetails, setIntoAdminDetails } from '../../slices/adminSlice'


function CompanyProfile({ disabled, companyName }) {
    const dispatch = useDispatch();
    const iconRef = useRef();
    const bannerRef = useRef();
    let { isLoading, data: companyRes, refetch } = useQuery('getCompanyDetails', () => getCompanyDetails(companyName), {
        refetchOnWindowFocus: false,
        onError: (e) => {
            console.log(e)
        }
    })

    let { data: SignUpRefRes } = useQuery('getSignUpRefData', getSignUpRefData, {
        refetchOnMount: false, refetchOnWindowFocus: false,
        onError: (e) => console.log(e)
    })

    const [company, setCompany] = useState({})
    const [search, setSearch] = useState('')
    const [isBanner, setIsBanner] = useState(false)
    const adminInfo = useSelector(selectAdminDetails);

    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [services, setServices] = useState([]);
    const [location, setLocation] = useState("");
    const [locationModal, setLocationModal] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [popUp, setPopUp] = useState(false);

    const moreRef = useRef()
    const companyRef = useRef()
    useClickOutside(() => searchResults?.length ? setSearchResults([]) : watchChange(), searchResults?.length ? moreRef : companyRef)

    const handleRemoveService = (id) => {
        let arr = [...services];
        let index = arr.findIndex(item => item.id == id)
        if (index > -1) {
            setSuggestions([...suggestions, { ...arr[index] }])
            arr.splice(index, 1)
            setServices(arr)
        }
    }
    const handleRemoveSuggestion = (id) => {
        let arr = [...suggestions];
        let index = arr.findIndex(item => item.id == id)
        if (index > -1) {
            arr.splice(index, 1)
            setSuggestions(arr)
        }
    }
    const handleRemoveItems = (item) => {
        setSearchValue("")
        setServices([...services, { id: item.id, industryServices: item.industryServices }])
        setSearchResults([])
    }

    const setCompanyInfo = () => {
        let details = companyRes?.data?.companyDetails;
        if (details) {
            setCompany({
                companyId: details?.companyId,
                companyName: details?.companyName || '',
                companyEmail: details?.companyEmail || '',
                companyWebsite: details.companyWebsite || '',
                industrySector: details?.sectorOfIndustryId || '',
                typeOfIndustry: details?.typeOfIndustryId || '',
                typeOfFirm: details?.typeOfFirm || '',
                companyYearOfEstablishment: details?.companyYearOfEstablishment || '',
                companyEmployeeScale: details?.companyEmployeeScaleId || '',
                minimumProjectCost: details?.minimumProjectCostId || '',
                headQuartered: details?.headQuartered || '',
                offeringTags: details?.offeringTag || '',
                aboutUs: details?.aboutUs || '',
                companyBanner: details?.companyBanner || '',
                companyIcon: details?.companyIcon || ''
            });

            setLocation(details?.headQuartered || '')

            if (details?.companyOfferingTags?.length)
                setServices(details?.companyOfferingTags)
        }
    }
    useEffect(() => {
        setCompanyInfo();
    }, [companyRes])

    const handleChange = (e) => {
        setCompany({ ...company, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (companyRes?.data?.companyDetails)
            searchTags();
    }, [companyRes?.data?.companyDetails])

    const onChangeHandler = (e) => {
        setSearchValue(e.target.value);
        let array = suggestions.filter(item => item.industryServices.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearchResults(array)
    };

    const searchTags = () => {
        axios.get(config.adminUrl + `/searchOfferingTagByAdmin`)
            .then(function (response) {
                if (response.data.status === true) {

                    let arr = [...response.data.searchOfferingTag];

                    if (companyRes?.data?.companyDetails?.companyOfferingTags?.length) {
                        for (let i of companyRes?.data?.companyDetails?.companyOfferingTags) {
                            let index = arr.findIndex(item => item.id == i.id);
                            arr.splice(index, 1)
                        }
                    }
                    setSuggestions(arr)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    let { isLoading: updating, mutate } = useUpdateCompanyInformation(() => { refetch(); dispatch(setIntoAdminDetails({ typeOfIndustry: parseInt(company?.typeOfIndustry) })); setPopUp(false) })

    const updateCompany = () => {

        if (!company?.industrySector) {
            toast.error("Please provide Industry Sector", { id: 'error' })
        }
        else if (!location.trim()) {
            toast.error("Please provide company Location", { id: 'error' })
        }
        else if (!services?.length) {
            toast.error("At least One service is required ", { id: 'error' })
        }
        else if (!company?.aboutUs?.trim()) {
            toast.error("Please add Company Info", { id: 'error' })
        }
        else {
            let locationArr = location?.split(",")
            mutate({
                ...company,
                aboutUs: company.aboutUs?.trim(),
                headQuartered: location,
                companyCountry: locationArr.length ? locationArr[locationArr.length - 1] : '',
                offeringTagId: services.map(item => item.id)
            })
        }
    }

    const watchChange = () => {
        let details = companyRes?.data?.companyDetails;

        let Boolean = false;

        if (company.companyEmail != details.companyEmail) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.industrySector != details.sectorOfIndustryId) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.typeOfIndustry != details.typeOfIndustryId) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.companyEmployeeScale != details?.companyEmployeeScaleId) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.minimumProjectCost != details?.minimumProjectCostId) {
            if (!Boolean)
                Boolean = true;
        }
        if (location != details?.headQuartered) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.aboutUs != details?.aboutUs) {
            if (!Boolean)
                Boolean = true;
        }
        if (company.companyWebsite != details?.companyWebsite) {
            if (!Boolean)
                Boolean = true;
        }

        if (details?.companyOfferingTags?.length || services.length) {
            let arr1 = details?.companyOfferingTags.map(item => item.id)
            let arr2 = services.map(item => item.id);

            for (let i of arr1) {
                if (!arr2.includes(i))
                    if (!Boolean)
                        Boolean = true;
            }
            for (let i of arr2) {
                if (!arr1.includes(i))
                    if (!Boolean)
                        Boolean = true;
            }
        }
        if (Boolean) {
            setPopUp(true)
        }
    }
    const handleImage = async (e) => {
        let name = e.target.name;
        let file = e.target.files[0];
        if (file.size > 80000000) {
            return toast.error("Uploaded image must be less than 10 MB", { id: 'error' })
        }
        if (file.type?.includes('image')) {
            let tId = toast.loading("Uploading Image")
            let url = await uploadImage(file)
            toast.dismiss(tId)
            if (url && name) {
                setCompany({ ...company, [name]: url })
                mutate({
                    ...company,
                    [name]: url
                });
                dispatch(setIntoAdminDetails({ companyIcon: url }));
            }
        }
        else {
            return toast.error("Uploaded file must be an image", { id: 'error' })
        }
    }

    const handleLocationChange = async (e) => {
        setLocation(e.target.value);
        setLocationModal(true)
        if (!location) return;

        const res = await fetchPlace(location);

        !autocompleteCities.includes(e.target.value) &&
            res.data &&
            setAutocompleteCities(res.data.map((place) => place.location));
    };

    const checkAccessCount = () => {
        if (adminInfo?.typeOfIndustry > 2) {
            return 10
        }
        else if (adminInfo?.typeOfIndustry == 1) {
            return 7
        }
        else {
            return 3
        }
    }

    if (isLoading) {
        return (
            <div className='relative w-full min-h-[250px] grid place-items-center'>
                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                </div>
            </div>
        )
    }

    return (
        <>
            {isBanner ? <UploadBanner onClose={() => setIsBanner(false)} handleImage={handleImage} /> : <></>}
            <section ref={companyRef}>
                <div className='relative h-60'>
                    <img className='w-full h-full object-cover' src={company?.companyBanner || searchbanner} alt="" />
                    {!disabled && <span onClick={() => setIsBanner(true)} className='p-1 rounded-full bg-white absolute top-4 right-4 shadow-lg cursor-pointer'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.0205 6.00098L16.4728 10.1881" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>}
                    <div className='group absolute w-28 h-28 -bottom-10 left-1/2 transform -translate-x-1/2 rounded custom-shadow cursor-pointer bg-white overflow-hidden' onClick={() => iconRef.current?.click()}>
                        <div className='bg-black bg-opacity-80 absolute inset-0 z-10 flex-col items-center justify-center p-2 gap-2  hidden group-hover:flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                            </svg>
                            <h1 className='text-xs text-white text-center'>Upload new logo</h1>
                        </div>
                        <img className='p-2 rounded object-contain custom-shadow w-full h-full' src={company?.companyIcon} alt="" />
                    </div>
                </div>
                <input disabled={disabled} ref={bannerRef} type='file' accept='image/*' name="companyBanner" onChange={handleImage} className='hidden' />
                <input disabled={disabled} ref={iconRef} type='file' accept='image/*' name="companyIcon" onChange={handleImage} className='hidden' />

                <section className='py-10 px-10 2xl:px-16'>
                    <h1 className='text-2xl font-medium'>Company Profile</h1>
                    <div className='grid grid-cols-2 gap-x-10 gap-y-5 py-5'>
                        <div>
                            <h1 className='font-medium mb-2 text-gray-400'>Company Name</h1>
                            <input type="text" value={company?.companyName} disabled className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Company Contact Email</h1>
                            <input disabled={disabled} type="email" name='companyEmail' onChange={handleChange} value={company?.companyEmail} className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Company Website</h1>
                            <input disabled={disabled} type="text" name='companyWebsite' onChange={handleChange} placeholder='https://www.example.com' value={company?.companyWebsite || ''} className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Industry Sector</h1>
                            <div className='bg-white rounded shadow-md relative'>
                                <input disabled={disabled} type="text" aria-describedby="text"
                                    className="bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100"
                                    placeholder="Select sector"
                                    value={search || SignUpRefRes?.data?.industrySector[Number(company.industrySector) - 1]?.sectorOfIndustry}
                                    onChange={(e) => { setSearch(e.target.value); setCompany({ ...company, industrySector: '' }) }}
                                />
                                {search ? <div className='absolute top-12 left-0 w-full p-2 z-20 bg-white max-h-40 overflow-y-auto rounded shadow-lg'>
                                    {SignUpRefRes?.data?.industrySector?.map((data, i) => (
                                        data.sectorOfIndustry?.toLowerCase()?.includes(search?.toLowerCase()) && <p className='px-2 pb-1 cursor-pointer' onClick={() => { setSearch(''); setCompany({ ...company, industrySector: data.id }) }} value={data.id}>{data.sectorOfIndustry}</p>
                                    ))}
                                </div> : <></>}
                            </div>
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Industry Type</h1>
                            {/* <div className={`pr-2 rounded shadow-md ${disabled ? 'bg-gray-100' : 'bg-white'}`}>
                                <select disabled={disabled} name='typeOfIndustry' onChange={handleChange} value={company?.typeOfIndustry} className='bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100'>
                                    <option value="" selected disabled>Select industry type</option>
                                    {SignUpRefRes?.data?.industryType?.map((data, i) => (<option value={data.id}>{data.typeOfIndustry}</option>))}
                                </select>
                            </div> */}
                            <input type="text" value={SignUpRefRes?.data?.industryType[company?.typeOfIndustry]?.typeOfIndustry} disabled className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2 text-gray-400'>Type Of Firm</h1>
                            <input type="text" value={SignUpRefRes?.data?.typeOfFirm[company?.typeOfFirm - 1]?.typeOfFirm} disabled className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2 text-gray-400'>Year Of Establishment</h1>
                            <input type="text" placeholder='1994' value={company?.companyYearOfEstablishment} disabled className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100' />
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Number Of Employees</h1>
                            <div className={`pr-2 rounded shadow-md ${disabled ? 'bg-gray-100' : 'bg-white'}`}>
                                <select disabled={disabled} name='companyEmployeeScale' onChange={handleChange} value={company?.companyEmployeeScale} className='bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100'>
                                    <option value={''} selected={company?.companyEmployeeScale == '1'} disabled>Number of Employees</option>
                                    {SignUpRefRes?.data?.numberOfEmployee?.slice(company?.typeOfFirm == 2 ? 1 : 0, SignUpRefRes?.data?.numberOfEmployee?.length)?.map((data, i) => (
                                        <option value={data.id} selected={company?.companyEmployeeScale == data?.id}>{data.numberOfEmployee}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Minimum Project Amount</h1>
                            <div className={`pr-2 rounded shadow-md ${disabled ? 'bg-gray-100' : 'bg-white'}`}>
                                <select disabled={disabled} name='minimumProjectCost' onChange={handleChange} value={company?.minimumProjectCost} className='bg-white w-full rounded p-3 focus:outline-none disabled:bg-gray-100'>
                                    <option value="" selected disabled>Min Project Cost</option>
                                    {SignUpRefRes?.data?.minimumProjectCost?.map((data, i) => (
                                        <option value={data.id}>{data.minimumProjectCost}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <h1 className='font-medium mb-2'>Location</h1>
                            <div className='bg-white rounded relative'>
                                {location?.length > 2 && <div className='p-1 absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer z-10 bg-white' onClick={() => { setLocation(''); setLocationModal(false) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>}
                                <input disabled={disabled} placeholder='Your location' name='headQuartered' type="text"
                                    value={location}
                                    onChange={(e) => handleLocationChange(e)}
                                    className='bg-white w-full p-3 rounded focus:outline-none shadow-md disabled:bg-gray-100 capitalize' />
                                {location.length > 1 && locationModal ?
                                    <div style={{ zIndex: 60, }} className='w-full max-h-44 overflow-y-auto bg-white border border-gray-200 rounded absolute left-0 top-11 shadow-md'>
                                        {autocompleteCities.length == 0 ? <div className='py-1 px-4'>
                                            No Results found</div> :
                                            <div className='p-1 z-10'>
                                                {autocompleteCities.map((data, i) =>
                                                (
                                                    <div className="border-b last:border-none cursor-pointer p-2 hover:bg-blue-100 rounded capitalize" onClick={() => { setLocation(data); setLocationModal(false) }}>
                                                        {data}
                                                    </div>
                                                ))}
                                            </div>}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>

                    <div className='py-5'>
                        <h1 className='font-medium mb-2'>Services you expertise in <span className=' text-gray-500 ml-2'>({services?.length}/{checkAccessCount()})</span></h1>
                        <div className='p-4 bg-white rounded shadow-md flex flex-wrap items-center gap-3'>
                            <div className="rounded w-full h-28 bg-white text-black-500 flex flex-wrap gap-3 items-start ">
                                {services.map((data, i) => (
                                    <div key={i} className={`cursor-pointer inline-flex items-center rounded-full justify-center border-bermuda px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm border-2 ${bgPalette[Math.abs((i - bgPalette.length) % bgPalette.length)]}`}>
                                        <span>{data.industryServices}</span>
                                        {!disabled && <svg onClick={() => handleRemoveService(data.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>}

                                    </div>
                                ))}
                                {(services.length < checkAccessCount() && !disabled) ?
                                    <form className='relative z-40'>
                                        <input list="searchForTags" type="text" aria-describedby="text"
                                            className='p-2 border-none outline-none'
                                            value={searchValue}
                                            onChange={e => onChangeHandler(e)}
                                            placeholder="Enter Service"
                                        />
                                        {searchResults.length ? <div className="absolute top-12 bg-white rounded-lg shadow-lg p-2 left-0 w-full min-w-max max-h-[240px] overflow-y-auto z-20" id="searchForTags" ref={moreRef}>
                                            {searchResults.map((item, idx) => {
                                                return (
                                                    <h1 onClick={() => { handleRemoveItems(item); handleRemoveSuggestion(item.id) }} className="min-w-max p-2 border-b last:border-none cursor-pointer" key={idx}>{item.industryServices}</h1>
                                                )

                                            })}
                                        </div> : <></>}
                                    </form> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <h1 className='font-medium mb-2'>About Us</h1>
                        <div className='rounded shadow-md relative'>
                            <textarea disabled={disabled} placeholder='About the company' value={company?.aboutUs} name='aboutUs' onChange={(e) => e.target.value.length <= 1000 && handleChange(e)} className='bg-white w-full h-60 p-3 rounded focus:outline-none disabled:bg-gray-100 resize-none text-sm'>
                            </textarea>
                            <p className='font-medium text-gray-400 text-sm absolute bottom-5 right-5'>{company?.aboutUs?.length}/1000</p>
                        </div>
                    </div>

                    {!disabled && <div className='py-6'>
                        <button className='bg-lightgreen text-green-800 font-medium rounded text-lg py-2 px-5 shadow-lg' disabled={updating} onClick={() => setPopUp(true)}>Update Profile</button>
                    </div>}
                </section>
            </section>

            {popUp ? <div className='fixed inset-0 min-h-screen overflow-hidden grid place-items-center z-50'>
                <div className='fixed inset-0 min-h-screen overflow-hidden grid place-items-center bg-black bg-opacity-70 cursor-pointer' onClick={() => setPopUp(false)}>
                </div>
                <div className='p-10 bg-white rounded-lg z-10 flex flex-col items-center justify-center w-full max-w-3xl min-h-[550px] relative'>
                    <span className='absolute top-3 right-3 cursor-pointer' onClick={() => setPopUp(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 text-gray-800">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <svg width="214" height="215" viewBox="0 0 214 215" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="14.75" y="15.75" width="198.5" height="198.5" rx="5.25" fill="#F4BCC7" stroke="black" stroke-width="1.5" />
                        <rect x="7.75" y="9.75" width="198.5" height="198.5" rx="5.25" fill="#BDF0BC" stroke="black" stroke-width="1.5" />
                        <rect x="0.75" y="0.75" width="198.5" height="198.5" rx="5.25" fill="white" stroke="black" stroke-width="1.5" />
                        <rect x="10" y="82" width="180" height="36" rx="10" fill="#333333" />
                    </svg>
                    <h1 className='text-3xl font-medium my-8'>Would you like to save the changes made?</h1>

                    <div className='flex items-center gap-8 justify-between p-8 pb-0 w-full'>
                        <button className='bg-pinkshade text-pink-800 font-medium py-2.5 px-8 rounded text-lg min-w-[150px]' onClick={() => { setCompanyInfo(); setPopUp(false) }}>Discard</button>
                        <button className='bg-lightgreen text-green-800 font-medium py-2.5 px-8 rounded text-lg min-w-[150px]' disabled={updating} onClick={() => updateCompany()}>Save Changes</button>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default CompanyProfile