import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar'
import useDebounce from '../../services/constant'
import { getServices } from '../../services/service'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

const colors = ["bg-lightbrown", "bg-lightgreen", "bg-lightviolet", "bg-lightpink", "bg-lightblue", "bg-peach", "bg-cream", "bg-babycream", "bg-babyblue", "bg-lightturquoise"]

export default function () {
    const history = useHistory()
    const [filteredServices, setFilteredServices] = useState([])
    const [search, setSearch] = useState('');
    const [service, setService] = useState('')
    const [tags, setTags] = useState([])
    const { isLoading, data: serviceRes } = useQuery('getServices', getServices, { staleTime: 6000000, retry: false, refetchOnWindowFocus: false })

    useEffect(() => {
        if (serviceRes?.data?.services) {
            setFilteredServices(serviceRes?.data?.services || [])
        }
    }, [serviceRes?.data])

    const debounced = useDebounce(search, 500);

    useEffect(() => {
        if (debounced) {
            let data = serviceRes?.data?.services?.filter(item => item.sectorOfIndustry?.toLowerCase().includes(search.toLowerCase()))
            setFilteredServices(data)
        } else {
            setFilteredServices(serviceRes?.data?.services || [])
        }
    }, [debounced])

    useEffect(() => {
        if (service?.id) {
            let arr = serviceRes?.data?.tags?.filter(item => item?.industrySectorId == service?.id || item.industrySectorId == 0)
            setTags(arr)
        }

    }, [service?.id])

    return (
        <>
            <NavBar />

            <div className='p-6 flex flex-col items-center justify-center w-full max-w-2xl mx-auto'>
                <div className=' bg-lightgreen rounded border-2 border-bermuda max-w-max'>
                    <div className=' bg-lightviolet rounded border-2 border-bermuda transform -translate-x-1 -translate-y-1'>
                        <div className='bg-white rounded border-2 border-bermuda py-2 px-10 transform -translate-x-1 -translate-y-1'>
                            <span className='text-3xl font-medium'>Industry Services</span>
                        </div>
                    </div>
                </div>
                <h1 className='text-2xl font-medium my-4'>Explore services based on Industry</h1>
            </div>

            <section className='w-full max-w-7xl mx-auto p-5 xl:px-10 2xl:px-0'>
                <div className=''>
                    <div className='bg-bermuda rounded max-w-md'>
                        <div className='flex items-center gap-2 py-2 px-3 rounded bg-white border-2 border-bermuda transform -translate-x-1 -translate-y-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                            <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full bg-transparent pr-4 outline-none placeholder:text-sm' placeholder='Search by Services or industry' />
                        </div>
                    </div>
                </div>

                {isLoading
                    ?
                    <>
                        <div className='relative w-full min-h-[250px] grid place-items-center'>
                            <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    </>
                }
                <div className='flex flex-wrap items-center gap-6 justify-center py-10'>
                    {
                        filteredServices?.map((item, i) => <div key={item.id} onClick={() => setService(item)} className="relative select-none">
                            <div className={`py-2 px-5 cursor-pointer rounded border-2 border-bermuda min-w-max ${service.id == item?.id ? colors[Math.abs((i - colors.length) % colors.length)] : 'bg-white'} z-10 relative transform hover:-translate-x-1 hover:-translate-y-1 transition-transform ease-out duration-150`}>
                                {item.sectorOfIndustry}
                            </div>
                            <div className={`absolute inset-0 w-full h-full rounded border-2 border-bermuda ${service.id == item?.id ? 'bg-bermuda' : colors[Math.abs((i - colors.length) % colors.length)]}`}></div>
                        </div>)
                    }
                </div>

                <p className='text-xl text-center'>{filteredServices?.length ? 'Select an Industry from Above to view Services' : 'No matched industry found'}</p>

            </section>

            {service ? <section className='w-full max-w-7xl mx-auto p-5 xl:px-10 2xl:px-0'>
                <h1 className='text-3xl font-medium'>{service?.sectorOfIndustry}</h1>

                <div className='flex items-center justify-center gap-8 p-5'>
                    <div className='bg-lightblue rounded-lg border-2 border-bermuda w-full max-w-[220px]'>
                        <div className='bg-white p-2 rounded-lg transform -translate-x-1 -translate-y-1 border-2 border-bermuda'>
                            <h1 className='font-medium text-center text-xl'>{service?.companies}</h1>
                            <p className='text-xs font-medium text-right pt-2'>Firms in this sector</p>
                        </div>
                    </div>
                    <div className='bg-lightblue rounded-lg border-2 border-bermuda w-full max-w-[220px]'>
                        <div className='bg-white p-2 rounded-lg transform -translate-x-1 -translate-y-1 border-2 border-bermuda'>
                            <h1 className='font-medium text-center text-xl'>{tags?.length || 0}</h1>
                            <p className='text-xs font-medium text-right pt-2'>Services provided</p>
                        </div>
                    </div>
                    <div className='bg-lightblue rounded-lg border-2 border-bermuda w-full max-w-[220px]'>
                        <div className='bg-white p-2 rounded-lg transform -translate-x-1 -translate-y-1 border-2 border-bermuda'>
                            <h1 className='font-medium text-center text-xl'>{service?.rank} <span className='font-normal text-xs'>out of {service?.total}</span></h1>
                            <p className='text-xs font-medium text-right pt-2'>Most search industry</p>
                        </div>
                    </div>
                </div>

                <h1 className='text-lg italic text-right mt-6 mx-6 py-1'>Click on a service for quick search</h1>
                <div className='rounded-3xl border-2 border-bermuda bg-lightblue rounded-tr-none mx-5'>
                    <div className='rounded-3xl border-2 border-bermuda bg-white p-5 rounded-tr-none transform -translate-x-1.5 -translate-y-1.5'>
                        <h1 className='text-xl font-medium'>Services offered</h1>
                        <div className='grid grid-cols-3 p-5 text-lg'>
                            {
                                tags?.map(item => <h1 key={item.id} className='p-2 cursor-pointer hover:text-blue-500' onClick={() => history.push(`/searchresults/infotech/?q=${item.industryServices}`)}>{item?.industryServices}</h1>)
                            }
                        </div>
                    </div>
                </div>
            </section> : <></>}
        </>
    )
}

