import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../services/config';
import logo from '../../images/corner-cube-logo.svg';

export default class ThanksForRegistering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            registerFlow: this.props.location.state.registerFlow,
            name: this.props.location.state.firstName,
            // email: "",
            // registerFlow: false,
            // name: "",
            redirect: false,
            verify: false
        }
    }
    componentDidMount() {
        console.log("props")
    }
    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "companyAdminEmail": this.state.email.toLowerCase(),
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.adminUrl + '/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Otp sent successfully!!")
                    self.setState({verify:true})
                } else {
                    console.log(response.data.message)
                    // document.getElementById('status')?.innerText = msg
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log(error);
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    verify = () => {
        this.setState({ redirect: true })

    }
    render() {
        const { redirect, verify } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/emailsent",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        if (verify) {
            return <Redirect
                to={{
                    pathname: "/verifyotp",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            // <div>
            //     <img alt="" src={fedduplogo} className="logo-login" />
            //     <center><Card className="cards-width"> <center>
            //         <p className="forgot-text">Thanks For Registering</p>
            //         <p className="forgot-text-sm">We’ve sent an email to verify the account.</p><img alt="" src={forgotImg} className="forgotImg" /></center><br /><br />
            //         <Form className="forgot-email">
            //             <center>
            //                 <div style={{ width: "80%" }}>
            //                     <Button
            //                         variant="default" size="md" block
            //                         type="submit"
            //                         className="login_button"
            //                         onClick={this.verify}
            //                     >
            //                         Verify
            //                     </Button><br />
            //                 </div><br />
            //                 <span className="login-here" onClick={this.sendOtp}>Resend email</span>

            //             </center>
            //         </Form><br />
            //     </Card>
            //     </center>
            // </div>
            <div className="h-screen sm:flex-row">
            <div className="border-none rounded py-4 w-full">
                <img src={logo} style={{ width: "120px", marginLeft: "6rem" }} />
                <div className='ml-auto mr-auto mt-24 w-2/6 border-2 rounded-xl p-10'>
                    <center className="text-2xl font-semibold">Thanks For Registering</center>
                    <center className="mt-3 text-base font-normal">We’ve sent an email to verify the account.</center>
                  
                    <div className="mt-12 z-40 rounded w-full h-12 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className={`z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda px-4 transform -translate-x-1.5 -translate-y-2.5`}
                  onClick={this.sendOtp}>Verify</button>
                    </div>
                    <center onClick={this.sendOtp} className="text-sm font-semibold mt-8">Resend Email</center>
                </div>
            </div>
        </div>
        );
    }
}
