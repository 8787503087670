import React, { useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import axios from 'axios';
import { config } from '../../services/config';
import toast from 'react-hot-toast';
import { encrypt } from '../../services/constant';

const validPasswordRegex =
    /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
function ResetPassword(props) {
    const [email, setEmail] = useState(props.location.state.email)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    const [passwordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [loading, setLoading] = useState(false)

    const resetPassword = (e) => {
        setLoading(true);

        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var encryptedPassword = encrypt(password);
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "password": encryptedPassword,
        }

        axios.post(config.adminUrl + '/forgotPassword/resetPassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    toast.success("Password reset success!!", { id: 'success' });
                    window.location.pathname = '/login'
                } else {
                    console.log(response.data.message)
                    toast.error(response.data?.message || "Unable to reset password", { id: 'err' })
                }
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                toast.error("Unable to reset password", { id: 'err' })
            });
    }

    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Minimum 6 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError)
        return confirmPasswordError === "";
    }
    const validateField = (name) => {
        let isValid = false;
        if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword")
            isValid = validateConfirmPassword();
        return isValid;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let formFileds = [
            "password",
            "confirmPassword"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid password</div>"
        } else {
            resetPassword()
        }
    }

    return (
        <div className="h-screen p-5 w-full flex flex-col">
            <img src={logo} style={{ width: "120px", marginLeft: "6rem" }} />
            <div className='flex-grow flex flex-col items-center justify-center'>
                <div className='w-full max-w-lg border-2 rounded-xl p-10'>
                    <center className="text-2xl font-semibold">Reset Password</center>
                    <center className="mt-3 text-base font-normal">Your new password must be different from <br />previous used password
                    </center>
                    <div className="mt-10 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                        <input type="password" aria-describedby="text"
                            className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                            placeholder="Enter New Password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {<p className='text-red-500 text-xs italic h-8 mt-1'>{passwordError || ''}</p>}
                    <div className="mt-6 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                        <input type="password" aria-describedby="text"
                            className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                            placeholder="Re-enter Password"
                            name="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />

                    </div>
                    {<p className='text-red-500 text-xs italic h-8 mt-1'>{confirmPasswordError || ''}</p>}

                    <div className="mt-12 z-40 rounded w-full h-12 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className={`${loginBtn && "animate-loginDip"
                            } z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda px-4 transform -translate-x-1.5 -translate-y-2.5`}
                            onClick={(e) => { setLoginBtn(true); handleSubmit(e) }}
                            onAnimationEnd={() => setLoginBtn(false)}>Submit</button>
                    </div>
                    <center className="text-sm font-semibold mt-8">Login here</center>
                </div>
            </div>
        </div>
    );
}
export default ResetPassword;