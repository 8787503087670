import React, { useState } from 'react'
import WarnPopUp from '../Reusable/WarnPopUp'
import ReviewStar from '../../images/reviewstar.svg'
import feedback from '../../images/FEEDBACK02.svg';

function ClientAndVendor({ search, heading, isLoading, data, handleRevoke, revoking, setRequestFeedback }) {
    const [popUp, setPopUp] = useState(false)

    return (
        <>
            <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
                <h1 className='text-2xl font-medium text-gray-600 capitalize'>Your {heading}</h1>
                <div className='relative min-h-[20dvh]'>
                    {isLoading || !data
                        ?
                        <>
                            <div className='absolute w-full min-h-[250px] grid place-items-center'>
                                <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                                </div>
                            </div>
                        </>
                        :
                        <></>}
                    <table className='w-full my-2' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                        <thead className='text-lg text-gray-300 border-b sticky top-36 bg-white'>
                            <tr className=''>
                                <td className='pt-4 pb-2 border-b text-center'>SNo.</td>
                                <td className='pt-4 pb-2 border-b'>Company Name</td>
                                <td className='pt-4 pb-2 border-b text-center'>Sector</td>
                                <td className='pt-4 pb-2 border-b text-center'>Industry Type</td>
                                <td className='pt-4 pb-2 border-b text-center'>Avg Duration</td>
                                <td className='pt-4 pb-2 border-b text-center'>Avg Rating</td>
                                <td className='pt-4 pb-2 border-b text-center'>Reviews</td>
                                <td className='pt-4 pb-2 border-b text-center'></td>
                            </tr>
                        </thead>
                        <>
                            {
                                (!isLoading && !data?.filter(item => item.companyName?.toLowerCase().includes(search)).length) ?
                                    <h1 className='capitalize w-full absolute top-24 text-3xl text-center font-medium text-gray-300'>{heading} List is empty</h1>
                                    : <></>
                            }
                            <tbody>
                                {
                                    data?.filter(item => item.companyName?.toLowerCase().includes(search))?.map((item, i) => <tr key={i}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td>{item.companyName}</td>
                                        <td className='text-center'>{item.sectorOfIndustry}</td>
                                        <td className='text-center'>{item.typeOfIndustry}</td>
                                        <td className='text-center'>{item.avgDuration || '-'}</td>
                                        <td className='text-center'>{item.avgRating && <img className='w-7 h-7 m-1 inline-block' src={ReviewStar} alt="" />} {item.avgRating || '-'}</td>
                                        <td className='text-center'>{item.reviewsCount || 0}</td>
                                        <td className='text-center'>
                                            <div className='flex items-center gap-4'>
                                                <span title='Request Feedback' className='w-10 h-10 p-2.5 rounded-full bg-cream cursor-pointer' onClick={() => setRequestFeedback(item)}>
                                                    <img src={feedback} className="w-full h-full" />
                                                </span>
                                                <span title={`Remove ${heading}`} onClick={() => !revoking && setPopUp({ ...item, requestType: heading })} className='rounded-full p-2.5 w-10 h-10 bg-lightpink text-rose-900 cursor-pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </>
                    </table>
                </div>
            </div>
            {popUp ? <WarnPopUp heading={`Are you sure to remove the ${heading}?`} onCancel={() => setPopUp(false)} disabled={revoking} onOk={() => { handleRevoke(popUp); setPopUp(false) }} /> : <></>}
        </>
    )
}

export default ClientAndVendor