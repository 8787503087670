import React from 'react'

function WarnPopUp({ disabled, onOk, onCancel, heading, description }) {
    return (
        <div className='fixed inset-0 min-h-screen overflow-hidden grid place-items-center z-50'>
            <div className='fixed inset-0 min-h-screen overflow-hidden grid place-items-center bg-black bg-opacity-70 cursor-pointer' onClick={() => onCancel()}>
            </div>
            <div className='p-10 bg-white rounded-lg z-10 flex flex-col items-center justify-center w-full max-w-3xl min-h-[550px] relative'>
                <span className='absolute top-3 right-3 cursor-pointer' onClick={() => onCancel()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 text-gray-800">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
                <svg width="214" height="215" viewBox="0 0 214 215" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="14.75" y="15.75" width="198.5" height="198.5" rx="5.25" fill="#F4BCC7" stroke="black" stroke-width="1.5" />
                    <rect x="7.75" y="9.75" width="198.5" height="198.5" rx="5.25" fill="#BDF0BC" stroke="black" stroke-width="1.5" />
                    <rect x="0.75" y="0.75" width="198.5" height="198.5" rx="5.25" fill="white" stroke="black" stroke-width="1.5" />
                    <rect x="10" y="82" width="180" height="36" rx="10" fill="#333333" />
                </svg>
                <h1 className='text-3xl font-medium mt-8'>{heading}</h1>
                <p className='mb-8 mt-3'>{description}</p>

                <div className='flex items-center gap-8 justify-between p-8 pb-0 w-full'>
                    <button className='bg-pinkshade text-pink-800 font-medium py-2.5 px-8 rounded text-lg min-w-[150px]' onClick={() => onCancel()}>Cancel</button>
                    <button className='bg-lightgreen text-green-800 font-medium py-2.5 px-8 rounded text-lg min-w-[150px]' disabled={disabled} onClick={() => onOk()}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default WarnPopUp