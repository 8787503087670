import React from 'react'
import Project from './Project'

function MyProjects({ search, adminInfo, heading, projects, handleProjectClick, isLoading }) {

    return (
        <div className='p-4 min-h-[60dvh] bg-white rounded-lg shadow-md'>
            <h1 className='text-2xl font-medium text-gray-600'>{heading}</h1>
            <div className='relative min-h-[20dvh]'>
                {isLoading
                    ?
                    <>
                        <div className='absolute w-full min-h-[250px] grid place-items-center'>
                            <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                            </div>
                        </div>
                    </>
                    :
                    <div className=' p-5 grid grid-cols-2 gap-8 2xl:gap-x-12'>
                        {
                            projects?.filter(item => item.projectName?.toLowerCase().includes(search))?.map((item, i) => <Project adminInfo={adminInfo} handleProjectClick={handleProjectClick} key={i} item={item} />)
                        }
                    </div>}

                {!isLoading && !projects?.filter(item => item.projectName?.toLowerCase().includes(search))?.length ? <h1 className='capitalize text-3xl text-center font-medium text-gray-300 mt-8'>{heading} is empty</h1> : <></>}
            </div>
        </div>
    )
}

export default MyProjects