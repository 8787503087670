import React, { useState } from 'react';
// import logo from '../logo.svg';
import signin from '../../images/abstract BLOCK-04.png';
import logo from '../../images/corner-cube-logo.svg';
import banner from '../../images/banner tetris -01.png';
import rightarrow from '../../images/rightarrow.svg';
import { Link } from 'react-router-dom';

export default function Sector2() {
    const [name, setName] = useState("")
    const [gender, setGender] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState("")
    const [title, setTitle] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    return (
        <div className="h-screen w-full">
            <div className='absolute -right-5 -top-9 w-32 h-32 rounded-full bg-lightgreen'></div>

            <div className="border-none rounded py-4">
                <img src={logo} style={{ marginLeft: "4rem", width: "120px" }} />
                <div className='px-80'>
                    <div className="flex items-center">
                        <div className="flex items-center text-bermuda relative">
                            <div className=" transition duration-500 ease-in-out">
                                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm16.28-2.72a.75.75 0 00-1.06-1.06l-5.97 5.97-2.47-2.47a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l6.5-6.5z" fill="#bdf0bc" /></svg>
                            </div>
                            <div className="absolute top-0 -ml-10 text-center mt-12 w-32 text-sm font-semibold text-bermuda">Industry & size</div>
                        </div>
                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-lightgreen -mr-2"></div>
                        <div className="flex items-center text-bermuda relative">
                            <div className=" transition duration-500 ease-in-out">

                                <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#bdf0bc" />
                                </svg>
                            </div>
                            <div className="absolute top-0 -ml-12 text-center mt-12 w-32 text-sm font-medium text-bermuda">Organisation setup</div>
                        </div>
                        <div className="-ml-2 flex-auto border-t-2 transition duration-500 ease-in-out border-gray-400"></div>
                        <div className="flex items-center text-gray-500 relative">
                            <div className="rounded-full transition duration-500 ease-in-out h-4 w-4 py-1 border-2 bg-gray-400 border-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail ">

                                </svg>
                            </div>
                            <div className="absolute top-0 -ml-14 text-center mt-10 w-32 text-sm font-medium text-bermuda">Upload certificates</div>
                        </div>
                    </div>
                </div><br /><br /><br />
                <div className='grid grid-cols-2 grid-gap-2 pl-24 pr-24'>
                    <div className='px-16'>
                        <label className='mt-20'>Title of business</label>
                        <div className="mt-4 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                placeholder="Title"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='mt-12'></div>
                        <label>Employee scale</label>
                        <div className="mt-4 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                placeholder="EmployeeScale"
                                onChange={(e) => setGender(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='px-16'>
                        <label className='mt-20'>Website</label>
                        <div className="mt-4 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                placeholder="Website"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='mt-12'></div>
                        <label >Founded on</label>
                        <div className="mt-4 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                placeholder="Founded on"
                                onChange={(e) => setDob(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <br /><br />

                <label className='mt-20 px-40'>Tell us more about your organisation</label>
                <center className='px-40'>
                    <div className="mt-4 z-50 rounded w-full h-28 border-2 bg-lightgreen border-bermuda">
                        <textarea rows={4} type="text" aria-describedby="text"
                            className="z-[60] rounded w-full h-28 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                            placeholder=""
                            style={{ resize: "none" }}
                            onChange={(e) => setDob(e.target.value)}
                        />
                    </div>
                </center>
            </div>
            <div className="mt-2 mx-40" style={{ display: "flex", float: "right" }}> <div className='w-8 h-8 bg-lightgreen rounded-full'><img src={rightarrow} className="rotate-180" style={{ marginLeft: "5px", marginTop: "5px", width: "20px" }} /></div>
                <Link to="/sector3"><div className='ml-2 w-8 h-8 bg-lightgreen rounded-full'><img src={rightarrow} style={{ marginLeft: "5px", marginTop: "5px", width: "20px" }} /></div></Link>
            </div>
        </div>
    );
}
