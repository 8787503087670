import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart({ width, height, containerStyle, containerClass, initialData = [], redraw = false }) {
    const data = {
        labels: initialData?.map(item => item?.industryServices),
        datasets: [
            {
                label: '',
                data: initialData?.map(item => item?.value || 1),
                backgroundColor: [
                    '#306FFF',
                    '#30C9C9',
                    '#F7C034',
                    '#7B3DD1',
                    '#469AFA',
                    '#D92ED9',
                    '#FF8D1F'
                ],
                borderColor: [
                    '#306FFF',
                    '#30C9C9',
                    '#F7C034',
                    '#7B3DD1',
                    '#469AFA',
                    '#D92ED9',
                    '#FF8D1F'
                ],
                borderWidth: 1,
            },
        ],
    };

    let options = {
        plugins: {
            legend: { display: false },
        }
    }

    return <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}><Doughnut width={width} height={height} data={data} options={options} redraw={redraw} /></div>;
}