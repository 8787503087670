import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useAddUsersForAccessPortal, useValidateEmail } from '../../hooks';
import useDebounce, { isValidEmail } from '../../services/constant';
import { getOrganizationAndAccessRoleData, getUsersList } from '../../services/service';
import { selectAdminDetails } from '../../slices/adminSlice';
import { selectAccessRightList, selectRoleInOrganizationList, selectUsersList, setAccessRights, setOrganizationAndAccessRoleData, usersList } from '../../slices/companySlice';
import { useParams } from 'react-router-dom';

export default function Dashboard() {
    const dispatch = useDispatch()
    let { isLoading } = useQuery('user-list', getUsersList, { staleTime: 600000, refetchOnWindowFocus: false, refetchOnMount: false, onSuccess: (res) => dispatch(usersList(res.data.users)) })
    useQuery('getOrganizationAndAccessRoleData', getOrganizationAndAccessRoleData, {
        staleTime: 600000,
        refetchOnMount: false, refetchOnWindowFocus: false,
        onSuccess: (res) => {
            dispatch(setOrganizationAndAccessRoleData(res.data))
        },
        onError: (e) => console.log(e)
    })
    const adminInfo = useSelector(selectAdminDetails)
    const usersData = useSelector(selectUsersList);
    const { companyName } = useParams()
    const [addUser, setAddUser] = useState();
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState('')

    const closePopUp = () => {
        setAddUser();
        setSuccess(true)
    }
    useEffect(() => {
        if (adminInfo) {
            if (window.location.pathname == '/admin')
                window.location.replace(`/admin/access`)
        }
    }, [adminInfo])

    const checkRestriction = () => {
        if (adminInfo?.typeOfIndustry > 2) {
            return (usersData.length + 1) < 16
        }
        else if (adminInfo?.typeOfIndustry == 1) {
            return (usersData.length + 1) < 6
        }
        else {
            return (usersData.length + 1) < 2
        }
    }

    const checkAccessCount = () => {
        if (adminInfo?.typeOfIndustry > 2) {
            return 15
        }
        else if (adminInfo?.typeOfIndustry == 1) {
            return 5
        }
        else {
            return 2
        }
    }


    return (
        <>
            <main className='p-5'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center bg-white rounded w-full max-w-sm px-3 shadow-md'>
                        <input type="text" placeholder='Search User' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>

                    <div className='flex items-center gap-4'>
                        <div className='py-2 px-4 cursor-pointer rounded bg-cream text-yellow-600 font-medium text-lg'>
                            <span>{(usersData?.length || 0) + 1}/{checkAccessCount()}</span>
                        </div>
                        {((adminInfo?.userRole?.toLowerCase() == 'subadmin' || adminInfo?.userRole?.toLowerCase() == 'admin')) && (checkRestriction()) && <div className='py-2 px-4 cursor-pointer rounded bg-lightgreen text-green-600 font-medium text-lg' onClick={() => setAddUser(true)}>
                            <span>+ Add User</span>
                        </div>}
                    </div>
                </div>

                <div className='p-4 my-4 min-h-[500px] bg-white rounded-lg shadow-md'>
                    <h1 className='text-3xl font-medium text-gray-600'>User Access</h1>
                    <div className='relative max-h-[70dvh]'>
                        {isLoading
                            ?
                            <>
                                <div className='absolute w-full min-h-[300px] grid place-items-center'>
                                    <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-14 h-14'>

                                    </div>
                                </div>
                            </>
                            :
                            <></>}
                        <table className='w-full my-6' style={{ borderCollapse: 'separate', borderSpacing: '10px' }}>
                            <thead className='text-lg text-gray-300 font-medium border-b sticky top-20 bg-white'>
                                <tr>
                                    <td>SNo.</td>
                                    <td>Name</td>
                                    <td>Role</td>
                                    <td>Added Date</td>
                                    <td>Access Right</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <>
                                <tbody>
                                    <tr>
                                        <td>{1}</td>
                                        <td>{adminInfo?.companyAdminName}</td>
                                        <td>{adminInfo?.companyRole}</td>
                                        <td>{moment(adminInfo?.createdAt).format('DD-MM-YYYY')}</td>
                                        <td>{adminInfo?.userRole}</td>
                                        {adminInfo?.userRole?.toLowerCase()?.includes('admin') ? <td></td> : <></>}
                                    </tr>

                                    {usersData?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.map((item, i) => <tr key={i}>
                                        <td>{i + 1 + 1}</td>
                                        <td>{item.companyAdminName}</td>
                                        <td>{item.companyRole}</td>
                                        <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                        <td>{item.userRole}</td>
                                        {adminInfo?.userRole?.toLowerCase()?.includes('admin') ? <td>
                                            {
                                                adminInfo?.userRole?.toLowerCase() == 'admin' ?
                                                    <span className='text-blue-500 cursor-pointer' onClick={() => setAddUser(item)}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.7476 20.4428H21.0002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.021 6.00098L16.4732 10.1881" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                    :
                                                    (adminInfo?.userRole?.toLowerCase() == 'subadmin' || adminInfo?.userRole?.toLowerCase() == 'admin') ?

                                                        <span className='text-blue-500 cursor-pointer' onClick={() => setAddUser(item)}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.7476 20.4428H21.0002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M11.021 6.00098L16.4732 10.1881" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        :
                                                        ''
                                            }
                                        </td> : <></>}
                                    </tr>)}
                                </tbody>
                            </>
                        </table>
                    </div>
                </div>
            </main >
            {addUser && <AddUser setAddUser={setAddUser} adminInfo={adminInfo} addUser={addUser} closePopUp={closePopUp} />
            }

            {
                success && <div className='fixed inset-0 w-full min-h-screen overflow-hidden bg-transparent grid place-items-center z-40'>
                    <div onClick={() => setSuccess(false)} className='fixed inset-0 w-full h-full bg-black bg-opacity-50 cursor-pointer'>
                    </div>

                    <div className='relative bg-white overflow-hidden rounded-lg p-10 max-w-4xl flex flex-col items-center justify-center'>
                        <img src="https://res.cloudinary.com/greyffiti/image/upload/v1684325809/SquareEarth.svg" className=" max-w-xl" alt='' />
                        <p className='text-lg text-green-600 z-10 text-center mb-10'>We have sent an email to the user(s) added with an temporary password, this can be changed when they login for the first time.</p>
                        <button onClick={() => setSuccess(false)} disabled={isLoading} type='submit' className='bg-lightgreen text-green-600 rounded-lg shadow-xl font-medium text-lg py-2 px-6 z-10'>
                            Continue
                        </button>

                        <div className='absolute -bottom-48 -left-48'>
                            <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                                <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                                <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                            </svg>
                        </div>

                        <div className='absolute -bottom-48 -right-48'>
                            <svg width="500" height="400" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                                <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                                <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                            </svg>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}


const AddUser = ({ setSuccess, setAddUser, adminInfo, addUser, closePopUp }) => {
    let { isLoading, mutate } = useAddUsersForAccessPortal(addUser?.uuid ? 'update' : 'add', () => addUser?.uuid ? setAddUser() : closePopUp())
    const accessRight = useSelector(selectAccessRightList)
    const roleInOrganization = useSelector(selectRoleInOrganizationList)
    const [data, setData] = useState({
        name: addUser?.companyAdminName || '',
        email: addUser?.companyAdminEmail || '',
        role: addUser?.companyRoleId || '',
        right: addUser?.userRoleId || '',
    })

    let { mutate: validateEmail, data: emailRes } = useValidateEmail()

    const debounced = useDebounce(data.email, 400)
    useEffect(() => {
        if (debounced?.includes('@') && debounced !== addUser?.companyAdminEmail)
            validateEmail({ email: debounced })
    }, [debounced])

    const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value })

    const handleSubmit = (e) => {
        e.preventDefault();
        // let data = new FormData(e.target)
        // let values = Object.fromEntries(data.entries())
        if (!emailRes?.data?.status) {
            return toast.error(emailRes?.data?.message || 'Please enter a valid email', { id: 'error' })
        }
        let { name, email, role, right } = data
        if (!name) return toast.error('Invalid Name', { id: 'error' })
        if (!isValidEmail(email)) return toast.error('Invalid Email', { id: 'error' })
        if (!role) return toast.error('Select a company role', { id: 'error' })
        if (!right) return toast.error('Please select an access right', { id: 'error' })

        let formData = {
            name,
            email: email.toLowerCase(),
            roleInOrganization: Number(role),
            accessRight: Number(right),
            companyId: adminInfo?.companyId,
            adminId: adminInfo?.uuid
        }
        if (addUser?.uuid) {
            formData.uuid = addUser?.uuid
        }
        var addUsersDataArr = [formData]

        mutate({ addUsersDataArr })
    }

    return (
        <>
            <div className='fixed inset-0 z-40 w-full min-h-screen overflow-hidden bg-transparent grid place-items-center'>
                <div className='fixed inset-0 w-full h-full bg-black bg-opacity-50 cursor-pointer' onClick={() => setAddUser()}>
                </div>

                <form onSubmit={handleSubmit} className='bg-white p-10 rounded-lg w-full max-w-5xl z-10 relative overflow-hidden min-h-[600px]'>
                    <span className='absolute top-3 right-3 cursor-pointer' onClick={() => setAddUser()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 text-gray-800">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <h1 className='text-3xl font-medium text-lightbrown'>{addUser?.uuid ? 'Update' : 'Add'} User</h1>
                    <p className='font-medium text-lightbrown text-lg'>Added users will have access to Admin portal </p>

                    <div className='grid grid-cols-2 place-items-end gap-6 py-14'>
                        <div className='flex items-center gap-2'>
                            <label className='text-lg font-medium text-right min-w-max'>Name</label>
                            <input type="text" name='name' disabled={Boolean(addUser?.uuid)} value={data.name} onChange={handleChange} className='bg-white disabled:bg-gray-100 w-80 outline-none rounded p-2 shadow border-2 border-gray-100' />
                        </div>
                        <div className='flex items-center gap-2'>
                            <label className='text-lg font-medium text-right min-w-max'>Email</label>
                            <input type="email" name='email' disabled={Boolean(addUser?.uuid)} value={data.email} onChange={handleChange} className='bg-white disabled:bg-gray-100 w-80 outline-none rounded p-2 shadow border-2 border-gray-100' />
                        </div>
                        <div className='flex items-center gap-2'>
                            <label className='text-lg font-medium text-right min-w-max'>Role in firm</label>
                            <select name="role" value={data.role} onChange={handleChange} className='bg-white w-80 outline-none rounded p-2 shadow border-2 border-gray-100'>
                                <option value="" selected disabled>Choose Role</option>
                                {
                                    roleInOrganization.map(item => <option key={item.id} value={item.id}>{item.companyRole}</option>)
                                }
                            </select>
                        </div>
                        <div className='flex items-center gap-2'>
                            <label className='text-lg font-medium text-right min-w-max'>Access Right</label>
                            <select name="right" value={data?.right} onChange={handleChange} className='bg-white w-80 outline-none rounded p-2 shadow border-2 border-gray-100'>
                                <option value="" selected disabled>Choose Access</option>
                                {
                                    accessRight.map(item => <option key={item.id} value={item.id}>{item.role}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className='flex items-center justify-end py-6 z-10 relative'>
                        <button disabled={isLoading} type='submit' className='bg-lightgreen rounded-lg shadow-xl font-medium text-lg py-2 px-6'>
                            {addUser?.uuid ? 'Update' : 'Add'} User
                        </button>
                    </div>

                    <div className='absolute -bottom-48 -left-48'>
                        <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                        </svg>
                    </div>

                    <div className='absolute -bottom-48 -right-48'>
                        <svg width="500" height="400" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                            <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                            <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                        </svg>
                    </div>
                </form>
            </div>
        </>
    )
}