import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: { display: false },
    },
    scales: {
        // grid: false,
        y: {
            beginAtZero: true,
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                drawOnChartArea: true,
                color: 'rgba(0,0,0,0.08)',
                drawBorder: false,
                borderDash: [5, 6]
            },
        },
        x: {
            beginAtZero: true,
            display: true,
            position: 'left',
            grid: {
                color: 'transparent',
            }
        }
    }
};


export function BarChart({ width, height, containerClass, containerStyle, graphData, redraw = true }) {
    const data = {
        labels: graphData?.map(item => item?.label),
        datasets: [
            {
                label: 'Dataset Label',
                data: graphData?.map(item => item.value),
                backgroundColor: '#165DFF',
                maxBarThickness: 50,
                borderRadius: 2
            },
        ],
    };
    return <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}>
        <Bar options={options} redraw={redraw} data={data} />
    </div>
}
