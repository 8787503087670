import './App.css';
import './index.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from "react-hot-toast";

function App() {

  return (
    <div>
      <div><Toaster containerStyle={{ textAlign: 'center' }} /></div>
      <Router>
        <Routes />
      </Router>
    </div >
  );
}

export default App;

