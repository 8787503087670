import React from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "./Components/Reusable/Sidebar";
import TopBar from "./Components/Reusable/TopBar";
import { decodeToken } from "./services/constant";

const AdminRoute = ({ component: Component, verified, path, title, ...rest }) => {
    const auth = decodeToken();

    const progress = auth?.progress

    return (
        <Route
            {...rest}
            render={(props) =>
                !auth ? (
                    <Redirect to="/login" />
                ) : auth && progress ?

                    (
                        <AdminComponent component={Component} title={title} />
                    )
                    : (
                        <Redirect to="/" />
                    )
            }
        />
    );
};

export default AdminRoute;

const AdminComponent = ({ component: Component, title: title }) => {

    return (
        <>
            <section className='h-screen w-full flex items-stretch'>
                <Sidebar />
                <div id="main" className='flex-grow overflow-y-auto bg-[#F8F9FD]' style={{ height: '100dvh' }}>
                    <TopBar title={title} />
                    <Component />
                </div>
            </section>
        </>
    )
}