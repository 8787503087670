
import React, { useEffect } from 'react'

const Venn = ({ dataset, colors }) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.id = 'venn__chart'
    script.innerHTML = `

         data = ChartVenn.extractSets(${dataset},
           {}
        )
     
         config = {
            type: 'venn',
            data,
            options: ${JSON.stringify(colors, null, 3)},
            plugins: {
                tooltip: {
                  usePointStyle: true,
                  callbacks: {
                    title() {
                      // Title doesn't make sense for scatter since we format the data as a point
                      return '';
                    },
                    label(item) {
                      const labels = item.chart.data.labels;
                      const d = item.chart.data.datasets?.[item.datasetIndex].data?.[item.dataIndex];
                      return 'Hello';
                    },
                  },
                },
              },
        };
        

        // render init block
         myChart = new Chart(
            document.getElementById('myChart'),
            config
        );
       `;
    document.body.appendChild(script);

  }, [])

  return (
    <></>
  );
};

export default Venn