import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { feedbackRequestList, getAllClientStatus, getAllReviewsByAdminCompany, getAllVendorStatus } from '../../services/service'
import { selectAdminDetails } from '../../slices/adminSlice'
import AddVendorAndClient from '../Vendor/AddVendorAndClient'
import Feedbacks from '../Vendor/Feedback'
import RequestFeedback from '../Vendor/RequestFeedback'
import Requests from '../Vendor/Requests'
import ClientAndVendor from '../Vendor/ClientAndVendor'
import { useRevokeRequest } from '../../hooks'
import DetailedFeedback from '../Vendor/DetailedFeedback'
import { DoughnutChart } from '../Charts/DoughnutChart'
import { groupBy } from '../../services/constant'

function Clients() {
  const adminInfo = useSelector(selectAdminDetails);
  const { data: vendorRes } = useQuery('getAllVendorStatus', getAllVendorStatus, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, refetchOnMount: false, retry: false })
  const { isLoading, data: clientRes } = useQuery('getAllClientStatus', getAllClientStatus, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, refetchOnMount: false })
  const { data: requestRes } = useQuery('feedbackRequestList', () => feedbackRequestList('vendor'), { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, refetchOnMount: false })
  const { isLoading: feedLoading, data: feedRes } = useQuery('vendorFeedbacks', () => getAllReviewsByAdminCompany(1), { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, refetchOnMount: false })
  const [active, setActive] = useState('clients');
  const [reqType, setReqType] = useState('feedbacks')
  const [addClient, setAddClient] = useState(false)
  const [requestFeedback, setRequestFeedback] = useState(false);
  const [detailedFeedback, setDetailedFeedback] = useState();
  const [search, setSearch] = useState('')

  let industrySectors = groupBy(clientRes?.data?.industrySectors || [], data => data.sectorOfIndustry)
  let serviceTags = groupBy(clientRes?.data?.serviceTags || [], data => data.industryServices)

  let { isLoading: revoking, mutate: revoke } = useRevokeRequest('getAllClientStatus')
  const handleRevoke = (item) => {
    let payload = {
      docId: item.id,
      requestType: item.requestType,
      type: 'remove'
    }
    revoke(payload)
  }

  const handleFeedback = (item) => {
    setDetailedFeedback(item)
  }

  const closeFeedback = () => {
    setActive('feedbacks')
    setDetailedFeedback()
  }

  return (
    <>
      <main className='p-5 relative'>
        {
          detailedFeedback
            ?
            <DetailedFeedback data={detailedFeedback} closeFeedback={closeFeedback} />
            :
            <>
              <div className='grid grid-cols-2 gap-5 p-2'>
                <div className='w-full bg-white shadow rounded-lg h-72 p-3'>
                  <h1 className='text-xl font-medium text-center'>Clients Services</h1>
                  <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                      Object.keys(serviceTags || {})?.length ?
                        <DoughnutChart initialData={Object.keys(serviceTags || {})?.map(item => { return { industryServices: item, value: serviceTags[item]?.length || 0 } })} width={200} height={200} />
                        :
                        !isLoading &&
                        <h1 className='text-center text-blue-400 pt-10'>No data available</h1>
                    }
                  </div>
                </div>
                <div className='w-full bg-white shadow rounded-lg h-72 p-3'>
                  <h1 className='text-xl font-medium text-center'>Clients Sectors</h1>
                  <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                      Object.keys(industrySectors || {})?.length ?
                        <DoughnutChart initialData={Object.keys(industrySectors || {})?.map(item => { return { industryServices: item, value: industrySectors[item]?.length } })} width={200} height={200} />
                        :
                        !isLoading &&
                        <h1 className='text-center text-blue-400 pt-10'>No data available</h1>
                    }
                  </div>
                </div>
              </div>

              <div className='py-4 z-10 bg-body sticky top-20 flex items-center gap-4 w-full'>
                <div className='flex items-center bg-white rounded max-w-sm px-3 shadow-md'>
                  <input type="text" placeholder='Search Company' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </div>

                <div className='flex items-center gap-4'>
                  <button onClick={() => setActive('clients')} className={`rounded-lg font-medium shadow-md bg-lightblue text-sky-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'clients' ? 'opacity-100' : 'opacity-50'}`}>Your Clients</button>
                  <button onClick={() => setActive('feedbacks')} className={`rounded-lg font-medium shadow-md bg-lightviolet text-purple-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'feedbacks' ? 'opacity-100' : 'opacity-50'}`}>Feedbacks</button>
                  <div className='relative group'>
                    <button onClick={() => setActive('requests')} className={`rounded-lg font-medium shadow-md bg-lightpink text-pink-900 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'requests' ? 'opacity-100' : 'opacity-50'}`}>Requests</button>
                    <div className='group-hover:border flex flex-col absolute top-9 2xl:top-10 left-1/2 transform -translate-x-1/2 rounded-lg h-0 group-hover:h-auto overflow-hidden'>
                      <button className='py-2 px-4 font-medium bg-pink-100 text-pink-800 hover:text-pink-900 rounded-t-lg border-2 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('feedbacks') }}>Feedbacks</button>
                      <button className='py-2 px-4 font-medium bg-pink-100 text-pink-800 hover:text-pink-900 rounded-b-lg border-2 border-t-0 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('clients') }}>Clients</button>
                    </div>
                  </div>
                </div>

                <div className='flex items-center gap-4 ml-auto'>
                  {adminInfo?.userRole?.toLowerCase()?.includes('admin') && <button onClick={() => setAddClient(true)} className={`rounded-lg font-medium shadow-md bg-lightgreen text-green-800 py-2 px-4 min-w-max text-sm 2xl:text-base`}>+ Add Client</button>}
                  <button onClick={() => setRequestFeedback(true)} className={`rounded-lg font-medium shadow-md bg-cream text-yellow-900 py-2 px-4 min-w-max text-sm 2xl:text-base`}>Request Feedback</button>
                </div>
              </div>

              {active == 'clients' ? <ClientAndVendor search={search?.toLowerCase()} setRequestFeedback={setRequestFeedback} handleRevoke={handleRevoke} revoking={revoking} heading={'client'} isLoading={isLoading} data={clientRes?.data?.clients} /> : <></>}
              {active == 'feedbacks' ? <Feedbacks search={search?.toLowerCase()} closeFeedback={closeFeedback} handleFeedback={handleFeedback} isLoading={feedLoading} heading={'client'} data={feedRes?.data?.data} /> : <></>}
              {active == 'requests' ? <Requests search={search?.toLowerCase()} from={'client'} adminInfo={adminInfo} heading={'client'} reqType={reqType} isLoading={isLoading} data={reqType == 'feedbacks' ? requestRes?.data?.requests : vendorRes?.data?.vendorRequests} /> : <></>}

              {addClient && <AddVendorAndClient adminInfo={adminInfo} heading={'client'} popUp={addClient} setPopUp={setAddClient} />}
              {requestFeedback && <RequestFeedback initialCompany={requestFeedback} adminInfo={adminInfo} heading={'client'} popUp={requestFeedback} setPopUp={setRequestFeedback} openOther={() => { setAddClient(true); setRequestFeedback(false) }} myCompany={clientRes.data.clients} />}
            </>
        }
      </main>
    </>
  )
}

export default Clients