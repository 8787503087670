import React, { useState } from 'react';
import {Link } from 'react-router-dom';
import signin from '../../images/abstract BLOCK-04.png';
import logo from '../../images/corner-cube-logo.svg';
import rightarrow from '../../images/rightarrow.svg';

export default function ThanksforRegistering() {
    return (
        <div className="h-screen w-[95%]">
            <img src={logo} alt="Logo" className="h-14 w-56" />
            <div className='overflow-x-hidden'><div className='absolute -right-4 -top-9 w-32 h-32 rounded-full bg-lightgreen'></div></div>
         <div className='py-60 items-center text-center justify-center'>
            <div className='text-3xl'>Thank you "UserName"!</div>
            <div className='mt-2 text-3xl'>for Registering with CornerQube</div>
            <div className='mt-6 text-base text-gray-500'>"Our admin team will verify details and get back to you in 24 hrs"</div>
         </div>
        </div>

    );
}
