import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companyDetails: null,
    users: [],
    accessRights: [],
    rolesInOrganization: [],
    companyList: [],
    projectList: [],
    preview: {
        isOPen: false,
        pdfUrl: '',
        title: '',
        projectInfo: null
    }
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        companyDetails: (state, action) => {
            state.companyDetails = action.payload
        },
        usersList: (state, action) => {
            state.users = action.payload
        },
        setOrganizationAndAccessRoleData: (state, action) => {
            state.accessRights = action.payload.accessRight || [];
            state.rolesInOrganization = action.payload.roleInOrganization || []
        },
        companyList: (state, action) => {
            state.companyList = action.payload || []
        },
        projectList: (state, action) => {
            state.projectList = action.payload || []
        },
        preview: (state, action) => {
            if (action.payload)
                state.preview = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { companyDetails, usersList, setOrganizationAndAccessRoleData, companyList, projectList, preview } = companySlice.actions

export const selectCompanyDetails = (state) => state.company.companyDetails

export const selectUsersList = (state) => state.company.users
export const selectAccessRightList = (state) => state.company.accessRights
export const selectRoleInOrganizationList = (state) => state.company.rolesInOrganization
export const selectCompanyList = (state) => state.company.companyList
export const selectProjectList = (state) => state.company.projectList
export const selectPreview = (state) => state.company.preview

export default companySlice.reducer