import React, { useState } from 'react'
import ReviewStar from '../../images/reviewstar.svg'
import { useSelector } from 'react-redux'
import { selectProjectByCompany, selectProjectMyClientReview, selectProjectMyReview } from '../../slices/reviewSlice'
import { useGetReviewDetails } from '../../hooks'

function Reviews({ search, adminInfo, active, activeTab, project }) {
    const reviews = useSelector((state) => {
        if (activeTab == 'my_reviews')
            return selectProjectMyReview({ state, adminId: adminInfo?.uuid })
        else if (activeTab == 'reviews' && active == 'assigned')
            return selectProjectMyClientReview({ state, companyId: project?.companyId })
        else
            return selectProjectByCompany({ state, companyId: project?.companyId })
    });

    const companyWise = true
    // const companyWise = (activeTab == 'reviews')

    const { mutate } = useGetReviewDetails();
    const getReviewDetailsById = (id) => {
        mutate(id)
    }

    if (!reviews) return <Loader />

    return (
        <div className='min-h-[50dvh] pt-2 relative'>

            {companyWise ?
                <>
                    {
                        Object?.keys(reviews)?.length ?
                            Object.keys(reviews)?.map((company, i) => <CompanyTable mine={activeTab == 'my_reviews'} key={i} i={i} search={search?.toLowerCase()} company={company} data={reviews[company]} getReviewDetailsById={getReviewDetailsById} />)
                            :
                            <h1 className='capitalize w-full absolute top-20 text-3xl text-center font-medium text-gray-300'>No review Yet!</h1>
                    }
                </>
                :
                <>
                    {
                        <Table data={reviews} search={search?.toLowerCase()} getReviewDetailsById={getReviewDetailsById} />
                    }
                </>}

        </div>
    )
}

export default Reviews

const CompanyTable = ({ mine, search, company, data, i, getReviewDetailsById }) => {
    const [open, setOpen] = useState(i == 0);

    return (
        <>
            <div className='flex items-center justify-between p-1 border-b cursor-pointer my-1 px-5' onClick={() => setOpen(!open)}>
                <h1 className='font-medium'>{mine ? 'Reviewed: ' : ''}{company}</h1>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-8 h-8 cursor-pointer transform transition-transform duration-200 ease-in-out ${open ? 'rotate-180' : 'rotate-0'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </span>
            </div>
            {open && <Table search={search?.toLowerCase()} data={data} getReviewDetailsById={getReviewDetailsById} />}
        </>
    )
}

const Table = ({ search, data, getReviewDetailsById }) => {
    return (
        <div className='relative px-5'>
            {
                !data?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.length ? <h1 className='capitalize w-full absolute top-20 text-3xl text-center font-medium text-gray-300'>No review Yet!</h1> : <></>
            }
            <table className={`w-full transition-all duration-500}`}>
                <thead className='sticky top-20 bg-white'>
                    <tr>
                        <td className='p-2 text-gray-400'>S No.</td>
                        <td className='p-2 text-gray-400'>Reviewer</td>
                        <td className='p-2 text-gray-400'>Designation</td>
                        <td className='p-2 text-gray-400'>Rating</td>
                        <td className='p-2 text-gray-400'></td>
                        <td className='p-2 text-gray-400'></td>
                    </tr>
                </thead>
                <tbody>
                    {data?.filter(item => item.companyAdminName?.toLowerCase().includes(search))?.map((item, i) => <tr key={i}>
                        <td className='p-2'>{i + 1}</td>
                        <td className='p-2'>{item.companyAdminName}</td>
                        <td className='p-2'>{item.companyRole}</td>
                        <td className='p-2'>
                            <div className='flex items-center gap-1'>
                                <img className='w-7 h-7' src={ReviewStar} alt="" />
                                <span>{Number(item.rating).toFixed(1)}</span>
                            </div>
                        </td>
                        <td className='p-2'><button className='py-1.5 px-4 rounded bg-lightblue' onClick={() => getReviewDetailsById(item.id)}>View Review</button></td>
                        <td>{item?.projectName}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

const Loader = () => <>
    <div className='absolute w-full min-h-[250px] grid place-items-center'>
        <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
        </div>
    </div>
</>