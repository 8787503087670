import moment from "moment";

function InvoicePopup({ data, goBack, canGoBack }) {

    return (

        <div className="">
            {
                canGoBack ? <></>
                    :
                    <div className="flex items-center gap-1 cursor-pointer hover:text-blue-500 mb-2" onClick={goBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                        </svg>
                        <span>Back</span>
                    </div>
            }
            <div className="border border-lightgreen max-w-full bg-white p-4">
                <div className="">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <img
                                src={'https://res.cloudinary.com/cornerqube/image/upload/v1700047784/resources/qube_logo.png'}
                                alt="qube logo"
                                className="w-14 h-auto p-1"
                            />
                            <div className="ml-1 text-left">
                                <p className="font-semibold text-2xl">
                                    Corner Qube
                                </p>
                                <p className="text-sm">A Threadality Technologies LLP product</p>
                            </div>
                        </div>
                        <div className="text-right">
                            <p className="font-semibold text-lg">INVOICE</p>
                            <div className="text-sm">
                                <span className="font-semibold">Invoice: </span>
                                <span>#{data?.orderId}</span>
                            </div>
                            <div className="text-sm">
                                <span className="font-semibold">Date: </span>
                                <span>{moment(data?.orderOn).format("DD/MM/YYYY")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between py-6 text-sm">
                        <div className="text-left">
                            <p>Bill To:</p>
                            <p className="font-semibold name">{data?.companyName},</p>
                            <span>{data?.location}</span>
                        </div>
                    </div>
                </div>
                <table className=" w-full">
                    <thead className="bg-lightgreen">
                        <tr className="row text-gray-900 border-b-0 font-semibold">
                            <td scope="col" className="px-2 py-1">NO.</td>
                            <td scope="col" className="px-2 py-1">ITEM DESCRIPTION</td>
                            <td scope="col" className="px-2 py-1">CURR</td>
                            <td scope="col" className="px-2 py-1">PRICE</td>
                            <td scope="col" className="px-2 py-1">TOTAL</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={data?.id} className="row">
                            <td scope="col" className=" p-2">1</td>
                            <td scope="col" className=" p-2">
                                <span className=" p-2font-medium">{data?.title} plan</span>
                            </td>
                            <td scope="col" className=" p-2">{data?.paymentCurrency}($)</td>
                            <td scope="col" className=" p-2">{data?.description}</td>
                            <td scope="col" className=" p-2">${data?.paymentAmount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="flex justify-between items-end py-5 text-sm">
                    <div className="text-left">
                        <p className="font-semibold">Payment Method</p>
                        <p className="text-xs">VISA, MasterCard, UPI, Net banking & Cheques are accepted</p>
                    </div>
                    <div className="flex flex-col gap-1 w-48">
                        <div className="font-semibold flex justify-between pr-1">
                            <p>SUB TOTAL</p>
                            <p>($){data?.paymentAmount && data.paymentAmount.toFixed(2)}</p>
                        </div>
                        <div className="font-semibold flex justify-between pr-1">
                            <span>Discount {0}%</span>
                            <span>($)0</span>
                        </div>
                        <div className="font-semibold flex justify-between pr-1">
                            <p>Tax GST {0}%</p>
                            <p>($)0</p>
                        </div>
                        <div className="font-semibold bg-lightgreen p-1 text-gray-900 flex justify-between gap-2">
                            <span>Total Due</span>
                            <span>($){data?.paymentAmount && data?.paymentAmount.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="text-left my-4">
                        <p className="font-semibold text-sm">Terms & Conditions</p>
                        <p className="text-sm">
                            Please complete the payment before the due date i.e. {moment(data?.validUpto).format("DD/MM/YYYY ")}
                            otherwise your services will be suspended!
                        </p>
                    </div>

                    <div className="bg-gray-300 w-full h-0.5 rounded-full my-3"></div>
                    <div className="text-left">
                        <p className="font-semibold text-sm">Threadality Technologies LLP, Bengaluru, Karnataka, India</p>
                        <p className="text-sm">GSTIN: </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InvoicePopup;