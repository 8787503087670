import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { feedbackRequestList, getAllClientStatus, getAllReviewsByAdminCompany, getAllVendorStatus } from '../../services/service'
import { selectAdminDetails } from '../../slices/adminSlice'
import AddVendorAndClient from '../Vendor/AddVendorAndClient'
import Feedbacks from '../Vendor/Feedback'
import RequestFeedback from '../Vendor/RequestFeedback'
import Requests from '../Vendor/Requests'
import ClientAndVendor from '../Vendor/ClientAndVendor'
import { useRevokeRequest } from '../../hooks'
import DetailedFeedback from '../Vendor/DetailedFeedback'
import { groupBy } from '../../services/constant'
import { DoughnutChart } from '../Charts/DoughnutChart'

function Vendors() {
  const adminInfo = useSelector(selectAdminDetails)
  const { isLoading, data: vendorRes } = useQuery('getAllVendorStatus', getAllVendorStatus, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000, retry: false })
  const { data: clientRes } = useQuery('getAllClientStatus', getAllClientStatus, { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })
  const { data: requestRes } = useQuery('feedbackRequestList', () => feedbackRequestList('client'), { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })
  const { isLoading: feedLoading, data: feedRes } = useQuery('clientFeedbacks', () => getAllReviewsByAdminCompany(2), { staleTime: 300000, refetchOnWindowFocus: false, refetchInterval: 300000 })
  const [active, setActive] = useState('vendors');
  const [addVendor, setAddVendor] = useState(false);
  const [requestFeedback, setRequestFeedback] = useState(false)
  const [reqType, setReqType] = useState('feedbacks')
  const [detailedFeedback, setDetailedFeedback] = useState()
  const [search, setSearch] = useState('')

  let { isLoading: revoking, mutate: revoke } = useRevokeRequest('getAllVendorStatus')
  const handleRevoke = (item) => {
    let payload = {
      docId: item.id,
      requestType: item.requestType,
      type: 'remove'
    }
    revoke(payload)
  }

  let industryTypes = groupBy(vendorRes?.data?.industryTypes || [], data => data.typeOfIndustry)
  let serviceTags = groupBy(vendorRes?.data?.serviceTags || [], data => data.industryServices)

  const handleFeedback = (item) => {
    setDetailedFeedback(item)
  }

  const closeFeedback = () => {
    setActive('feedbacks')
    setDetailedFeedback()
  }

  return (
    <>
      <main className='p-5 relative'>
        {
          detailedFeedback
            ?
            <DetailedFeedback data={detailedFeedback} closeFeedback={closeFeedback} />
            :
            <>
              <div className='grid grid-cols-2 gap-5 p-2'>

                <div className='w-full bg-white shadow rounded-lg h-72 p-3'>
                  <h1 className='text-xl font-medium text-center'>Vendors Services</h1>
                  <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                      Object.keys(serviceTags || {})?.length ?
                        <DoughnutChart initialData={Object.keys(serviceTags || {})?.map(item => { return { industryServices: item, value: serviceTags[item]?.length || 0 } })} width={220} height={220} />
                        :
                        !isLoading &&
                        <h1 className='text-center text-blue-400 pt-10'>No data available</h1>
                    }
                  </div>
                </div>

                <div className='w-full bg-white shadow rounded-lg h-72 p-3'>
                  <h1 className='text-xl font-medium text-center'>Vendors Industry</h1>
                  <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                      Object.keys(industryTypes || {})?.length ?
                        <DoughnutChart initialData={Object.keys(industryTypes || {})?.map(item => { return { industryServices: item, value: industryTypes[item]?.length } })} width={220} height={220} />
                        :
                        !isLoading &&
                        <h1 className='text-center text-blue-400 pt-10'>No data available</h1>
                    }
                  </div>
                </div>
              </div>
              <div className='py-4 z-10 bg-body sticky top-20 flex items-center gap-4 w-full'>
                <div className='flex items-center bg-white rounded max-w-sm px-3 shadow-md'>
                  <input type="text" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} className='flex-grow py-2 px-3 border-none outline-none bg-transparent' />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </div>

                <div className='flex items-center gap-4'>
                  <button onClick={() => setActive('vendors')} className={`rounded-lg font-medium shadow-md bg-lightblue text-sky-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'vendors' ? 'opacity-100' : 'opacity-50'}`}>Your Vendors</button>
                  <button onClick={() => setActive('feedbacks')} className={`rounded-lg font-medium shadow-md bg-lightviolet text-purple-800 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'feedbacks' ? 'opacity-100' : 'opacity-50'}`}>Feedbacks</button>
                  <div className='relative group'>
                    <button onClick={() => setActive('requests')} className={`rounded-lg font-medium shadow-md bg-lightpink text-pink-900 py-2 px-4 min-w-max text-sm 2xl:text-base ${active == 'requests' ? 'opacity-100' : 'opacity-50'}`}>Requests</button>
                    <div className={`group-hover:border flex flex-col absolute top-9 2xl:top-10 left-1/2 transform -translate-x-1/2 rounded-lg h-0 group-hover:h-auto overflow-hidden`}>
                      <button className='py-2 px-4 font-medium bg-pink-50 text-pink-800 hover:text-pink-900 rounded-t-lg border-2 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('feedbacks') }}>Feedbacks</button>
                      <button className='py-2 px-4 font-medium bg-pink-50 text-pink-800 hover:text-pink-900 rounded-b-lg border-2 border-t-0 border-pink-900 min-w-max text-sm 2xl:text-base' onClick={() => { setActive('requests'); setReqType('vendors') }}>Vendors</button>
                    </div>
                  </div>

                </div>

                <div className='flex items-center gap-4 ml-auto'>
                  {adminInfo?.userRole?.toLowerCase()?.includes('admin') && <button onClick={() => setAddVendor(true)} className={`rounded-lg font-medium shadow-md bg-lightgreen text-green-800 py-2 px-4 min-w-max text-sm 2xl:text-base`}>+ Add Vendor</button>}
                  <button onClick={() => setRequestFeedback(true)} className={`rounded-lg font-medium shadow-md bg-cream text-yellow-900 py-2 px-4 min-w-max text-sm 2xl:text-base`}>Request Feedback</button>
                </div>
              </div>

              {active == 'vendors' ? <ClientAndVendor search={search.toLowerCase()} setRequestFeedback={setRequestFeedback} handleRevoke={handleRevoke} revoking={revoking} heading={'vendor'} isLoading={isLoading} data={vendorRes?.data?.vendors} /> : <></>}
              {active == 'feedbacks' ? <Feedbacks search={search.toLowerCase()} closeFeedback={closeFeedback} handleFeedback={handleFeedback} isLoading={feedLoading} heading={'vendor'} data={feedRes?.data?.data} /> : <></>}
              {active == 'requests' ? <Requests search={search.toLowerCase()} from={'vendor'} adminInfo={adminInfo} heading={'vendor'} reqType={reqType} isLoading={isLoading} data={reqType == 'feedbacks' ? requestRes?.data?.requests : clientRes?.data?.clientRequests} /> : <></>}

              {addVendor && <AddVendorAndClient adminInfo={adminInfo} heading={'vendor'} popUp={addVendor} setPopUp={setAddVendor} />}
              {requestFeedback && <RequestFeedback initialCompany={requestFeedback} adminInfo={adminInfo} heading={'vendor'} popUp={requestFeedback} setPopUp={setRequestFeedback} openOther={() => { setAddVendor(true); setRequestFeedback(false) }} myCompany={vendorRes.data.vendors} />}
            </>
        }
      </main>
    </>
  )
}

export default Vendors