import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}


export function BubbleChart({ width, height, containerStyle, containerClass, firstData = [], secondData = [] }) {
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: { display: false },
        },
        scales: {
            // grid: false,
            y: {
                beginAtZero: true,
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    drawOnChartArea: true,
                    color: 'rgba(0,0,0,0.08)',
                    drawBorder: false,
                    borderDash: [5, 6]
                },
            },
            x: {
                beginAtZero: true,
                display: true,
                position: 'left',
                grid: {
                    color: 'transparent',
                }
            }
        }
    };

    let others = secondData.map(item => (
        {
            label: item.companyName,
            data: [{
                x: item.cost,
                y: item.duration,
                r: randomIntFromInterval(17, 25),
            }],
            backgroundColor: 'rgba(22, 93, 255, 0.6)',
            borderColor: 'rgba(22, 93, 255, 0.8)',
        }
    ))
    const data = {
        datasets: [
            {
                label: firstData.map(item => item.companyName) || '',
                data: firstData.map(item => ({
                    x: item.cost,
                    y: item.duration,
                    r: randomIntFromInterval(10, 15),
                })),
                backgroundColor: 'rgba(29, 180, 64, 0.6)',
                borderColor: 'rgba(29, 180, 64, 0.8)',
            },
...others
        ],
    };

    return (
        <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}>
            <Bubble width={width} height={height} data={data} options={options} />
        </div>
    )
}
