
export const config = process.env.REACT_APP_NODE_ENV == 'development' ?
    {
        "apiUrl": "http://localhost:8000/api",
        "adminUrl": "http://localhost:8000/api/admin",
        "companyUrl": "http://localhost:8000/api/company",
    }
    :
    {
        "apiUrl": "https://api.cornerqube.com/api",
        "adminUrl": "https://api.cornerqube.com/api/admin",
        "companyUrl": "https://api.cornerqube.com/api/company",
    }

export const endpoints = {
    getServices: '/getServices',
    industryTypes: '/industryTypes',
    getPopularServices: '/getPopularServices',
    plans: '/plans'
}

export const companyEndpoints = {
    getSignUpRefData: '/getSignUpRefData',
    getOrganizationAndAccessRoleData: '/getOrganizationAndAccessRoleData',
    getCompanies: '/getCompanies',
    getProjects: '/getProjects',
    getCompanyUsers: '/getCompanyUsers',
    getCompanyDetails: '/getCompanyDetails',
    getReviewDetails: '/getReviewDetails',
    getOverallRating: '/getOverallRating',
    getAllReviewsByCompany: '/getAllReviewsByCompany',
    getCompanyVSectorAvgFactorRating: '/getCompanyVSectorAvgFactorRating',
    getClientReviewSector: '/getClientReviewSector',
    getClientReviewOfferingTag: '/getClientReviewOfferingTag',
    getSectorClientReviewAvgDuration: '/getSectorClientReviewAvgDuration',
    getClientReviewType: '/getClientReviewType',
    getTypeWiseClientReviewAvgDuration: '/getTypeWiseClientReviewAvgDuration',
    writeReviewInfo: '/writeReviewInfo',
    companyOfferingTags: '/companyOfferingTags',
    companyCompareData: '/companyCompareData',
    getCompanyStatistics: "/getCompanyStatistics",
    addEnquiry: '/addEnquiry',
    qubeStatistics: '/qubeStatistics'
}

export const adminEndpoints = {
    login: '/login',
    sendOtp: '/sendOtp',
    registerCompany: '/registerCompany',
    addCompanyInformation: '/addCompanyInformation',
    updatePassword: '/updatePassword',
    getAdminDetails: '/getAdminDetails',
    getUsersList: '/getUsersList',
    addUsersForAccessPortal: '/addUsersForAccessPortal',
    updateUsersForAccessPortal: '/updateUsersForAccessPortal',
    searchOfferingTagByAdmin: '/searchOfferingTagByAdmin',
    addVendorByAdmin: '/addVendorByAdmin',
    addClientByAdmin: '/addClientByAdmin',
    getAllVendorStatus: '/getAllVendorStatus',
    getAllClientStatus: '/getAllClientStatus',
    acceptOrRejectRequestForVendor: '/acceptOrRejectRequestForVendor',
    acceptOrRejectRequestForClient: '/acceptOrRejectRequestForClient',
    feedbackRequest: '/feedbackRequest',
    getCompanyProjects: '/getCompanyProjects',
    feedbackRequestList: '/feedbackRequestList',
    createOverallRating: '/createOverallRating',
    searchCompanyAndTag: '/searchCompanyAndTag',
    addProject: '/addProject',
    getProjects: '/getProjects',
    getAssignedProjects: '/getAssignedProjects',
    projectRequest: '/projectRequest',
    getPendingRequests: '/getPendingRequests',
    revokeRequest: '/revokeRequest',
    deleteProject: '/deleteProject',
    getAllReviewsByProject: '/getAllReviewsByProject',
    projectFeedbackRequestList: '/projectFeedbackRequestList',
    getAllReviewsByCompany: '/getAllReviewsByCompany',
    getEnquiry: '/getEnquiry',
    clearEnquiry: '/clearEnquiry',
    updateCompanyInformation: '/updateCompanyInformation',
    getCompanyDocuments: '/getCompanyDocuments',
    updateProfile: '/updateProfile',
    myReviews: '/myReviews',

    reportProjectOverView: '/report/projectOverView',
    reportFactorSplit: '/report/factorSplit',
    reportFactorImpression: '/report/factorImpression',
    reportClientSplit: '/report/clientSplit',
    reportServiceSplit: '/report/serviceSplit',
    reportClientsAdded: '/report/clientsAdded',
    reportVendorsAdded: '/report/vendorsAdded',
    reportGlobe: '/report/globe',
    leaderBoard: '/report/leaderBoard',
    getDashboardData: '/getDashboardData',
    validateEmail: '/validateEmail',
    getBubbleGraphData: '/getBubbleGraphData',

    hideReview: '/hideReview',
    getCompanySearch: '/getCompanySearch',
    searchCompanyAndTagDetails: '/searchCompanyAndTagDetails',
    getAllFeedbacks: '/getAllFeedbacks',
    getAllRequests: '/getAllRequests',

    updateDocs: '/updateDocs',
    resendProjectVendorInvite: '/resendProjectVendorInvite',
    getPlan: '/getPlan',
    requestAgain: '/requestAgain'
}