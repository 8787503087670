import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function DualCharts({ factorAvgRatingArr, sectorFactorAvgRatingArr }) {
  const data = {
    labels: factorAvgRatingArr.map((data) => data.factor),
    datasets: [

      {
        data: sectorFactorAvgRatingArr.map((data) => data.factorAvgRating),
        // backgroundColor: 'lightgrey',
        // borderColor: 'grey',
        // borderWidth: 1,
        fill: true,
        backgroundColor: 'rgba(167,182,255,0.3)',
        borderColor: '#3C5BFF',
        pointBackgroundColor: '#3C5BFF',
        pointHoverBorderColor: '#3C5BFF',
      },
      {
        data: factorAvgRatingArr.map((data) => data.factorAvgRating),
        // backgroundColor: '#99CED5',
        // borderColor: '#4A9FAB',
        // borderWidth: 1,
        fill: true,
        backgroundColor: 'rgba(201, 237, 209, 0.5)',
        borderColor: '#1DB440',
        pointBackgroundColor: '#1DB440',
        pointHoverBorderColor: '#1DB440'
      },
    ],
  };
  return (
    <div>
      <Radar data={data}
      options={{
        scales: {
          r: {
            ticks: {
              display: false // Hides the labels in the middel (numbers)
            }
          }
        },
        plugins: {
          legend: { display: false }
        }
      }} />
      <div className='-mt-6 ml-3 grid grid-cols-2 gap-2'>
        <div className='flex text-sm'><span className='w-4 h-4 rounded-sm mt-0.5' style={{background:"#1DB440"}}></span> &nbsp; Company </div>
        <div className='flex text-sm'><span className='w-4 h-4 rounded-sm mt-0.5' style={{background:"#3C5BFF"}}></span> &nbsp;Industry Avg </div>
      </div><br/>
    </div>
  )
}
