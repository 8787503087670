import React, { useState, useEffect } from 'react';
import plus from '../../images/plus.svg';
import cross from '../../images/cross-black.svg';
import fillRating from '../../images/reviewstar.svg';
import emptyRating from '../../images/rating_empty.svg';
import reviewImage from '../../images/review.svg';
import RadarChart from '../Charts/RadarChart';
import { useOverAllReview } from '../../hooks';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { companyOfferingTags, writeReviewInfo } from '../../services/service';

let arr = [
    { id: 1, name: "Delivery Management" },
    { id: 2, name: "Contract Management" },
    { id: 3, name: "Vendor Management" },
    { id: 4, name: "Stakeholder Management" },
    { id: 5, name: "Cybersecurity Management" },
    { id: 6, name: "Resource Management" },
]

function ReviewPopUp({ externalData, reviewerCompany, adminId, companyId, initial, projectId, reviewType, heading, closePopUp, requestId, companyName, initialOfferingTags, projectDuration }) {
    const [rating, setRating] = useState('')
    const [selected1, setSelected1] = useState(false)
    const [selected2, setSelected2] = useState(false)
    const [selected3, setSelected3] = useState(false)
    const [selected4, setSelected4] = useState(false)
    const [selected5, setSelected5] = useState(false)
    const [pageNo, setPageNo] = useState(1);
    const [workAgainWithCompany, setWorkAgainWithCompany] = useState("");
    const [clientOrVendor, setClientOrVendor] = useState(heading == 'client' ? 1 : 2);
    const [clientArr, setClientArr] = useState([
        { id: 1, name: "Delivery Mng." },
        { id: 2, name: "Contract Mng." },
        { id: 3, name: "Vendor Mng." },
        { id: 4, name: "Stakeholder Mng." },
        { id: 5, name: "Cybersec. Mng." },
        { id: 6, name: "Resource Mng." },
    ])
    const [vendorArr, setVendorArr] = useState([
        { id: 1, name: "Requirements" },
        { id: 2, name: "Processes" },
        { id: 3, name: "Financial" },
        { id: 4, name: "Stakeholder Mng." },
        { id: 5, name: "Communication" },
        { id: 6, name: "Quality Check" },
    ])
    const [recommendTheCompanyToOthers, setRecommendTheCompanyToOthers] = useState("");
    const [recommendType, setRecommendType] = useState([]);
    const [offeringTags, setOfferingTags] = useState([]);
    const [selectedOfferingTags, setSelectedOfferingTags] = useState(initialOfferingTags || [])
    const [isReview, setIsReview] = useState(initial);
    const [formData, setFormData] = useState({
        durationOfEngagement: projectDuration
    });
    const [graphData, setGraphData] = useState(clientOrVendor == 1 ? clientArr : vendorArr);

    let { isLoading: tagsLoading, data: offeringTagRes } = useQuery('companyOfferingTags', () => companyOfferingTags(reviewerCompany), {
        refetchOnWindowFocus: false,
    })

    let { isLoading: infoLoading, data: infoRes } = useQuery('writeReviewInfo', () => writeReviewInfo(companyName), {
        refetchOnMount: true, refetchOnWindowFocus: false, refetchInterval: 600000, onSuccess: (response) => {
            if (response.data.status === true) {
                setClientArr(response.data.clientReviewFactors)
                setVendorArr(response.data.vendorReviewFactors)
                setRecommendType(response.data.recommendTheCompanyType)
                if (clientOrVendor == 1) {
                    setOfferingTags(response.data.offeringTag)
                }
            } else {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                toast.error(msg)
            }
        }
    })

    useEffect(() => {
        if (clientOrVendor == 2) {
            setGraphData(vendorArr)
            if (offeringTagRes?.data?.offeringTags)
                setOfferingTags(offeringTagRes?.data?.offeringTags)
            else
                setOfferingTags(infoRes?.data?.offeringTag)
        }
        else {
            setGraphData(clientArr)
            if (infoRes?.data?.offeringTag)
                setOfferingTags(infoRes?.data?.offeringTag)
        }
    }, [clientOrVendor, offeringTagRes?.data, infoRes?.data])


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const nextPage = () => {
        let totalPages = 3;
        if (pageNo < totalPages) {
            setPageNo(pageNo + 1)
        }
        else {
            setPageNo(1)
        }
    }
    const previousPage = () => {
        if (pageNo > 1) {
            setPageNo(pageNo - 1)
        }
        else {
            setPageNo(3)
        }
    }
    const addToClientArr = (id) => {
        const updated = offeringTags.filter(data => data.id === id)
        setSelectedOfferingTags([...selectedOfferingTags, ...updated])

    }
    const removeFromClientArr = (id) => {
        const updated = selectedOfferingTags.filter(data => data.id !== id)
        setSelectedOfferingTags(updated)
    }

    const { mutate } = useOverAllReview(() => {
        setRating("")
        setRecommendTheCompanyToOthers("")
        setWorkAgainWithCompany("")
        setFormData({})
        setIsReview(6)
    })
    const postReview = (e) => {
        if (!formData?.positiveReviewComment) {
            return toast.error("Please select Factor Impressed", { id: 'error' })
        }
        if (!formData?.improvementReviewComment) {
            return toast.error("Please select Factor Improvement", { id: 'error' })
        }
        if (!formData?.serviceImpressed) {
            return toast.error("Please select Service Impressed", { id: 'error' })
        }
        if (!formData?.serviceImprove) {
            return toast.error("Please select Service Improvement", { id: 'error' })
        }

        let postData = {
            companyName: companyName,
            reviewerCompany: reviewerCompany,
            adminId: adminId,
            "reviewStatus": 1,
            requestId: requestId,

            reviewComment: formData?.review,
            "positiveReviewComment": formData?.positiveReviewComment,
            "improvementReviewComment": formData?.improvementReviewComment,
            serviceImpressed: formData?.serviceImpressed,
            serviceImprove: formData?.serviceImprove,
            durationOfEngagement: formData?.durationOfEngagement,

            costOfEngagement: 1,
            type: clientOrVendor,
            "companyOfferingTag": selectedOfferingTags?.map(item => item.id),
            "recommendTheCompanyToOthers": recommendTheCompanyToOthers,
            "workAgainWithCompany": workAgainWithCompany,
            "rating": rating || 1,
            graphData: graphData,
            externalData: externalData,
        }
        if (projectId) {
            postData.projectId = projectId
        }

        mutate(postData)
    }

    function addRating1(e, val) {
        setSelected1(true)
        setSelected2(false)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function addRating2(e, val) {
        setSelected1(true)
        setSelected2(true)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function addRating3(e, val) {
        setSelected1(true)
        setSelected2(true)
        setSelected3(true)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function addRating4(e, val) {
        setSelected1(true)
        setSelected2(true)
        setSelected3(true)
        setSelected4(true)
        setSelected5(false)
        setRating(val);
    }
    function addRating5(e, val) {
        setSelected1(true)
        setSelected2(true)
        setSelected3(true)
        setSelected4(true)
        setSelected5(true)
        setRating(val);
    }


    return (
        <>

            <div className={`fixed top-0 left-0 right-0 w-full min-h-screen bg-black bg-opacity-40 flex items-center justify-center z-[100] p-4 overflow-hidden md:inset-0 ${isReview > 0 ? '' : 'hidden'}`}>
                <div className="relative w-full max-w-7xl">
                    <div className="relative bg-white rounded-lg shadow h-[700px]">
                        <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => { setIsReview(0); closePopUp(); }}>
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>

                        <div className="px-10 py-2 flex flex-col gap-6 w-full">

                            <div className='w-full h-20 relative max-w-6xl mx-auto my-6'>
                                <div className='flex items-center justify-center'>
                                    <div className='relative max-w-max mx-auto'>
                                        <div className='bg-white rounded border-2 border-bermuda py-2 px-8 max-w-max z-20 relative'>
                                            <span className='text-2xl font-bold'>Write Review</span>
                                        </div>
                                        <div className=' bg-lightgreen rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                        </div>
                                        <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full h-0.5 bg-slate-200 relative rounded mt-10'>
                                    <div className='absolute top-0 left-0 h-full bg-slate-900' style={{ width: `${isReview < 5 ? ((isReview - 1) * 100 / 4) + 2 : 100}%` }}>
                                    </div>
                                    {
                                        ['Information', 'Factor Review', 'Overall rating', 'Additional thoughts', 'Publish'].map((item, i) => (
                                            <div className={`flex flex-col justify-center gap-3 items-center text-bermuda absolute -top-4 transform -translate-x-9 cursor-pointer`} style={{ left: `${(i / 4) * 100}%` }} onClick={() => setIsReview(i + 1)}>
                                                <div className="text-slate-900 transition duration-500 ease-in-out bg-white">
                                                    {
                                                        isReview < i + 1 ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-5 h-5 rounded-full bg-stone-800 mt-1.5">
                                                            </svg>
                                                            :
                                                            isReview == i + 1 ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="red" strokeLinejoin="round">
                                                                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#333333" />
                                                                </svg>
                                                                :
                                                                <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" fill='#333333' d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm16.28-2.72a.75.75 0 00-1.06-1.06l-5.97 5.97-2.47-2.47a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l6.5-6.5z" /></svg>
                                                    }
                                                </div>
                                                <div className="text-center text-sm font-semibold text-bermuda">{item}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className='flex-grow mt-8'>
                                {

                                    isReview == 1 ?
                                        <>
                                            <div className=' grid grid-cols-2 items-center gap-10 pt-8 max-w-4xl mx-auto'>
                                                <div className=' text-lg font-medium text-right'>How were you related to this firm for the {reviewType}?</div>
                                                <div className='flex items-center gap-6'>
                                                    <button className={`border-2 border-bermuda rounded-lg text-lg font-medium py-1 px-5 ${heading ? 'cursor-default' : ''} ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-white'}`} onClick={(e) => !heading && setClientOrVendor(1)}>
                                                        Client
                                                    </button>
                                                    <button className={`border-2 border-bermuda rounded-lg text-lg font-medium py-1 px-5 ${heading ? 'cursor-default' : ''} ${clientOrVendor == 2 ? 'bg-signupBgColor' : 'bg-white'}`} onClick={(e) => !heading && setClientOrVendor(2)}>
                                                        Vendor
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='my-8 grid grid-cols-2 items-center gap-10 max-w-4xl mx-auto'>
                                                <div className='flex text-lg font-medium text-right'>What was the duration of engagement for this {reviewType}?</div>
                                                <div className="flex items-center gap-2 text-lg font-medium">
                                                    <input type="text" className="inline-flex w-20 px-4 outline-none rounded justify-center border-bermuda bg-white py-1.5 text-right text-base font-semibold text-gray-700 shadow-sm focus:outline-none border-2 focus:ring-bermuda focus:border-bermuda"
                                                        name="durationOfEngagement" disabled={projectDuration} value={formData?.durationOfEngagement || ''} onChange={(e) => (e.target.value < 999 && e.target.value >= 0 && e.target.value?.toString() != '00') && handleChange(e)} />
                                                    <div className="font-medium"> month{'('}s{')'}</div>
                                                </div>
                                            </div>
                                            <div className='mt-8 flex flex-col gap-y-6 mx-auto max-w-4xl'>
                                                <label className='text-left text-lg font-medium w-full'>{clientOrVendor == 1 ? "What services did you utilize from this firm" : "what services did you offer"}?</label>
                                                <div className='p-2 '>
                                                    <div className="z-50 rounded  w-full border-2 bg-bermuda border-bermuda">
                                                        <div className="z-[60] rounded w-full h-full bg-white text-black border-black border-2 px-4 transform -translate-x-2 -translate-y-3">
                                                            {
                                                                infoLoading || tagsLoading ? <h1 className='p-4 text-gray-400'>Loading ...</h1> : ''
                                                            }
                                                            {clientOrVendor == 1 ?
                                                                <div className='flex flex-wrap items-center py-4 gap-3'>
                                                                    {offeringTags?.map((data, i) => (
                                                                        <div>
                                                                            {selectedOfferingTags.some((item) => { return data.id == item.id }) ?
                                                                                <div onClick={() => { removeFromClientArr(data.id) }} className={`bg-lightviolet text-sm inline-flex w-auto rounded-full justify-center border-bermuda px-4 py-2 font-medium shadow-sm border-2 cursor-pointer`}>{data.industryServices} &nbsp;
                                                                                    <div className="cursor-pointer"><img src={cross} className="w-5" /></div></div>
                                                                                :
                                                                                <div onClick={() => { addToClientArr(data.id) }} className={`bg-white text-sm inline-flex w-auto rounded-full justify-center border-bermuda px-4 py-2 font-medium shadow-sm border-2 cursor-pointer`}>{data.industryServices} &nbsp;
                                                                                    <div className="cursor-pointer"><img src={plus} className="w-5" /></div></div>
                                                                            }

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                <div className='flex flex-wrap items-center py-4 gap-3'>
                                                                    {offeringTags?.map((data, i) => (
                                                                        <div>
                                                                            {selectedOfferingTags.some((item) => { return data.id == item.id }) ?
                                                                                <div onClick={() => { removeFromClientArr(data.id) }} className={`bg-signupBgColor text-sm inline-flex w-auto rounded-full justify-center border-bermuda px-4 py-2 font-medium shadow-sm border-2 cursor-pointer`}>{data.industryServices} &nbsp;
                                                                                    <div className="cursor-pointer"><img src={cross} className="w-5" /></div></div>
                                                                                :
                                                                                <div onClick={() => { addToClientArr(data.id) }} className={`bg-white text-sm inline-flex w-auto rounded-full justify-center border-bermuda px-4 py-2 font-medium shadow-sm border-2 cursor-pointer`}>{data.industryServices} &nbsp;
                                                                                    <div className="cursor-pointer"><img src={plus} className="w-5" /></div></div>
                                                                            }

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        isReview == 2 ?
                                            <>
                                                <div className='flex flex-col items-center justify-center pb-4'>
                                                    <div className='grid place-items-center'>
                                                        {(clientArr && vendorArr) && <RadarChart width={440} height={400} draggable={true} heading={clientOrVendor == 1 ? "Client" : "Vendor"} initialData={clientOrVendor == 1 ? clientArr : vendorArr} graphData={graphData.sort((a, b) => a.id - b.id)} setGraphData={setGraphData} backgroundColor={clientOrVendor == 1 ? 'rgba(239 ,185 ,255 ,0.6)' : 'rgba(184, 227, 236, 0.6)'} borderColor={clientOrVendor == 1 ? 'rgb(222, 22, 255)' : 'rgb(20 ,201 ,201)'} />}
                                                    </div>
                                                    <p className='text-center text-sm text-gray-600'>Drag and drop the circles to the desired value.<br />
                                                        1 being least satisfied, 5 being most satisfied</p>
                                                </div>
                                            </>
                                            :
                                            isReview == 3 ?
                                                <>
                                                    <div className='max-w-lg mx-auto grid grid-cols-2 items-center gap-2'>
                                                        <div className='text-lg font-semibold'>Overall rating</div>
                                                        <div>
                                                            <center>
                                                                <span className='mt-6 flex flex-row justify-center items-center'>
                                                                    <span className='hover:bg-gray-200 h-max w-max p-2 rounded'>{selected1 ? <img src={fillRating} onClick={(e, i) => addRating1(e, 1)} style={{ width: "40px", cursor: "pointer" }} />
                                                                        : <img src={emptyRating} onClick={(e, i) => addRating1(e, 1)} style={{ width: "40px", cursor: "pointer" }} />}</span>
                                                                    <span className='hover:bg-gray-200 h-max w-max p-2 rounded'>{selected2 ? <img src={fillRating} onClick={(e, i) => addRating2(e, 2)} style={{ width: "40px", cursor: "pointer" }} />
                                                                        : <img src={emptyRating} onClick={(e, i) => addRating2(e, 2)} style={{ width: "40px", cursor: "pointer" }} />}</span>
                                                                    <span className='hover:bg-gray-200 h-max w-max p-2 rounded'>{selected3 ? <img src={fillRating} onClick={(e, i) => addRating3(e, 3)} style={{ width: "40px", cursor: "pointer" }} />
                                                                        : <img src={emptyRating} onClick={(e, i) => addRating3(e, 3)} style={{ width: "40px", cursor: "pointer" }} />}</span>
                                                                    <span className='hover:bg-gray-200 h-max w-max p-2 rounded'>{selected4 ? <img src={fillRating} onClick={(e, i) => addRating4(e, 4)} style={{ width: "40px", cursor: "pointer" }} />
                                                                        : <img src={emptyRating} onClick={(e, i) => addRating4(e, 4)} style={{ width: "40px", cursor: "pointer" }} />}</span>
                                                                    <span className='hover:bg-gray-200 h-max w-max p-2 rounded'>{selected5 ? <img src={fillRating} onClick={(e, i) => addRating5(e, 5)} style={{ width: "40px", cursor: "pointer" }} />
                                                                        : <img src={emptyRating} onClick={(e, i) => addRating5(e, 5)} style={{ width: "40px", cursor: "pointer" }} />}</span>
                                                                </span>
                                                            </center>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-2 max-w-5xl gap-16 mx-auto mt-4 h-60'>
                                                        <div className='border-r-4 border-gray-700'>
                                                            <div className='w-full max-w-sm'>
                                                                <div className='text-base font-medium'>Which factor did the firm impress you the most</div>
                                                                <div className="z-[100] mt-4 relative rounded bg-bermuda w-full h-10">
                                                                    <div className="z-50 bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                                                                    </div>
                                                                    <div className="text-base text-medium">
                                                                        <div className='absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 pr-2'>
                                                                            <select id="designs1" name="positiveReviewComment" value={formData?.positiveReviewComment || ''} className="w-full bg-white px-4 py-2 outline-none border-none"
                                                                                onChange={handleChange}>
                                                                                <option selected value="" disabled>Select any from below</option>
                                                                                {clientOrVendor == "1" ?
                                                                                    <>{clientArr.map((data, i) => (
                                                                                        <option value={data.id}>{data.typeOfReview}</option>
                                                                                    ))}
                                                                                    </>
                                                                                    : <>{vendorArr.map((data, i) => (
                                                                                        <option value={data.id}>{data.typeOfReview}</option>
                                                                                    ))}
                                                                                    </>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='w-full max-w-sm mt-6'>
                                                                <div className='text-base font-medium'>Which factor should the firm improve in</div>
                                                                <div className="z-[100] mt-4 relative rounded bg-bermuda w-full h-10">
                                                                    <div className="z-50 bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                                                                    </div>
                                                                    <div className="text-base text-medium">
                                                                        <div className='absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 pr-2'>
                                                                            <select id="designs1" name="improvementReviewComment" value={formData?.improvementReviewComment || ''} onChange={handleChange} className="w-full bg-white px-4 py-2 outline-none border-none">
                                                                                <option selected value="" disabled >Select any from below</option>
                                                                                {clientOrVendor == "1" ?
                                                                                    <>{clientArr.map((data, i) => (
                                                                                        <option value={data.id}>{data.typeOfReview}</option>
                                                                                    ))}
                                                                                    </>
                                                                                    : <>{vendorArr.map((data, i) => (
                                                                                        <option value={data.id}>{data.typeOfReview}</option>
                                                                                    ))}
                                                                                    </>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='w-full max-w-sm'>
                                                                <div className='text-base font-medium'>{clientOrVendor == 1 ? 'Which service impressed you the most?' : 'Which service did client utilize the most?'}</div>
                                                                <div className="z-[100] mt-4 relative rounded bg-bermuda w-full h-10">
                                                                    <div className="z-50 bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                                                                    </div>
                                                                    <div className="text-base text-medium">
                                                                        <div className='absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 pr-2'>
                                                                            <select id="designs1" name='serviceImpressed' value={formData?.serviceImpressed || ''} onChange={handleChange} className="w-full bg-white px-4 py-2 outline-none border-none">
                                                                                <option selected value="" disabled >Select any from below</option>
                                                                                <>{selectedOfferingTags?.map((data, i) => (
                                                                                    <option value={data.id}>{data.industryServices}</option>
                                                                                ))}
                                                                                </>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='w-full max-w-sm mt-6'>
                                                                <div className='text-base font-medium'>{clientOrVendor == 1 ? 'Which service should the firm improve in?' : 'Which service did client utilize the least?'}</div>
                                                                <div className="z-[100] mt-4 relative rounded bg-bermuda w-full h-10">
                                                                    <div className="z-50 bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                                                                    </div>
                                                                    <div className="text-base text-medium">
                                                                        <div className='absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 inline-flex w-full rounded justify-center border-bermuda bg-white text-sm font-medium text-gray-700 shadow-sm focus:outline-none border-2 pr-2'>
                                                                            <select id="designs1" name="serviceImprove" value={formData?.serviceImprove || ''} onChange={handleChange} className="w-full bg-white px-4 py-2 outline-none border-none">
                                                                                <option selected value="" disabled >Select any from below</option>
                                                                                <>{selectedOfferingTags?.map((data, i) => (
                                                                                    <option value={data.id}>{data.industryServices}</option>
                                                                                ))}
                                                                                </>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                isReview == 4 ?
                                                    <>
                                                        <div className='mt-4 grid grid-cols-3 items-center max-w-6xl mx-auto gap-6'>
                                                            <div className='text-right font-medium'>How likely are you to recommend this company to others?</div>
                                                            <div className='flex items-center gap-5 col-span-2'>

                                                                {recommendType.map((data, i) => (
                                                                    <div className='mt-3 flex items-center gap-2'>
                                                                        <div className={`${data.id == recommendTheCompanyToOthers ? 'bg-black' : 'bg-white'} border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer`} onClick={() => setRecommendTheCompanyToOthers(data.id)}>
                                                                            <div className={`absolute left-1/2 transform -translate-x-1/2 ${i == 0 ? 'top-6' : i == 1 ? 'top-3' : i == 2 ? '-top-3' : i == 3 ? '-top-8' : '-top-11'}`}>
                                                                                {data.id == recommendTheCompanyToOthers ?
                                                                                    <svg width="26" height="30" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                    :
                                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.75" d="M0.929688 18.2913L11.7321 12.5811L22.4776 18.2738L11.7321 23.9987L0.929688 18.2913Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M11.7321 1.16406V12.582L0.929688 18.292V6.87434L11.7321 1.16406Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M22.4586 6.93164L22.4561 18.3496L11.6562 23.9903V12.6393L22.4586 6.93164Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.78" d="M0.929688 6.875L11.7321 12.5827V24.0006L0.929688 18.2929V6.875Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M0.929688 6.87434L11.7321 12.582L22.5344 6.87434L11.7321 1.16406L0.929688 6.87434Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M0.929688 6.87434L11.7321 12.582L22.5344 6.87434L11.7321 1.16406L0.929688 6.87434Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M22.4586 6.93164L22.4561 18.3496L11.6562 23.9903V12.6393L22.4586 6.93164Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <span className={`${data.id == recommendTheCompanyToOthers ? 'text-gray-900' : 'text-gray-500'}`}>
                                                                            {data.typeOfRecommend}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className='mt-10 grid grid-cols-3 items-center max-w-6xl mx-auto gap-6'>
                                                            <div className='text-right font-medium'>How likely are you to work again with {companyName}?</div>
                                                            <div className='flex items-center gap-5 col-span-2'>

                                                                {recommendType.map((data, i) => (
                                                                    <div className='mt-3 flex items-center gap-2'>
                                                                        <div className={`${data.id == workAgainWithCompany ? 'bg-black' : 'bg-white'} border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer`} onClick={() => setWorkAgainWithCompany(data.id)}>
                                                                            <div className={`absolute left-1/2 transform -translate-x-1/2 ${i == 0 ? 'top-6' : i == 1 ? 'top-3' : i == 2 ? '-top-3' : i == 3 ? '-top-8' : '-top-11'}`}>
                                                                                {data.id == workAgainWithCompany ?
                                                                                    <svg className='bg-red-50' width="26" height="30" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                    :
                                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.75" d="M0.929688 18.2913L11.7321 12.5811L22.4776 18.2738L11.7321 23.9987L0.929688 18.2913Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M11.7321 1.16406V12.582L0.929688 18.292V6.87434L11.7321 1.16406Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M22.4586 6.93164L22.4561 18.3496L11.6562 23.9903V12.6393L22.4586 6.93164Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.78" d="M0.929688 6.875L11.7321 12.5827V24.0006L0.929688 18.2929V6.875Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M0.929688 6.87434L11.7321 12.582L22.5344 6.87434L11.7321 1.16406L0.929688 6.87434Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M0.929688 6.87434L11.7321 12.582L22.5344 6.87434L11.7321 1.16406L0.929688 6.87434Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path opacity="0.75" d="M22.4586 6.93164L22.4561 18.3496L11.6562 23.9903V12.6393L22.4586 6.93164Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <span className={`${data.id == workAgainWithCompany ? 'text-gray-900' : 'text-gray-500'}`}>
                                                                            {data.typeOfRecommend}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className='ml-24 mt-4 flex flex-col'>
                                                            <label className='mt-10 text-left font-medium'>Write your Review</label>
                                                            <div className="mt-5 rounded w-11/12 h-36 border-2 bg-bermuda border-bermuda relative">
                                                                <textarea type="text" aria-describedby="text"
                                                                    className="rounded w-full h-36 focus:border-black focus:ring-bermuda text-black-500 border-black border-2 py-2 px-4 transform -translate-x-1.5 -translate-y-2.5 outline-none"
                                                                    placeholder="Let us know your thoughts..."
                                                                    style={{ resize: "none" }}
                                                                    name="review"
                                                                    maxLength={250}
                                                                    title='maximum character limit is 250'
                                                                    value={formData?.review || ''}
                                                                    onChange={(e) => e.target.value.length <= 1000 && handleChange(e)}
                                                                />
                                                                <span className='absolute bottom-4 right-4 text-sm text-gray-500'>{formData?.review?.length}/250</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    isReview == 5 ?
                                                        <>
                                                            <div className='flex items-start gap-4 pt-3'>
                                                                <div className='grid place-items-center'>
                                                                    {(clientArr && vendorArr) && <RadarChart width={480} height={400} draggable={false} heading={'Factor Rating'} initialData={clientOrVendor == 1 ? clientArr : vendorArr} graphData={graphData} setGraphData={setGraphData} backgroundColor={clientOrVendor == 1 ? 'rgba(239 ,185 ,255 ,0.6)' : 'rgba(184, 227, 236, 0.6)'} borderColor={clientOrVendor == 1 ? 'rgb(222, 22, 255)' : 'rgb(20 ,201 ,201)'} />}
                                                                </div>

                                                                <div className='flex-grow border-2 border-bermuda rounded relative p-5 min-h-[26rem]'>
                                                                    <div className='flex items-center justify-end gap-6'>
                                                                        <div className='cursor-pointer' onClick={previousPage}>
                                                                            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M0.935443 10.3563C0.349656 10.9421 0.349656 11.8919 0.935443 12.4777L10.4814 22.0236C11.0672 22.6094 12.0169 22.6094 12.6027 22.0236C13.1885 21.4378 13.1885 20.4881 12.6027 19.9023L4.11742 11.417L12.6027 2.93171C13.1885 2.34592 13.1885 1.39618 12.6027 0.81039C12.0169 0.224604 11.0672 0.224604 10.4814 0.81039L0.935443 10.3563ZM3.125 9.91699H1.9961V12.917H3.125V9.91699Z" fill="black" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='cursor-pointer' onClick={nextPage}>
                                                                            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12.1349 12.3927C12.7207 11.8069 12.7207 10.8572 12.1349 10.2714L2.58894 0.72543C2.00315 0.139643 1.0534 0.139643 0.467617 0.72543C-0.11817 1.31122 -0.11817 2.26096 0.467617 2.84675L8.9529 11.332L0.467617 19.8173C-0.11817 20.4031 -0.11817 21.3528 0.467617 21.9386C1.0534 22.5244 2.00315 22.5244 2.58894 21.9386L12.1349 12.3927ZM10.0742 12.832H11.0742V9.83203H10.0742V12.832Z" fill="black" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        pageNo == 1 ?
                                                                            <div className="text-base text-semibold"><br />
                                                                                <div className=' text-black font-medium text-base'> How were you related to the firm for this project?</div>
                                                                                <div className={`mt-3 text-black font-semibold text-base border-2 border-bermuda max-w-max py-1.5 px-5 rounded-lg ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>{clientOrVendor == 1 ? "Client" : "Vendor"}</div>
                                                                                <div className='mt-5 text-black font-medium text-base'>Duration of current project <span className='font-semibold'>: {formData?.durationOfEngagement} month{'('}s{')'}</span></div>
                                                                                <div className='mt-5 text-black font-medium text-base'>{clientOrVendor == 1 ? "What services did you utilize from this firm" : "what services did you offer"}</div>
                                                                                <div className='mt-3 text-base font-semibold'>{clientOrVendor == 1 ?
                                                                                    <div>{selectedOfferingTags?.map((data, i) => (
                                                                                        <div className={`bg-lightviolet mr-4 mt-3.5 pt-2 inline-flex w-auto rounded-full justify-center border-b-4 border-gray-900 px-6 py-2 text-sm font-medium`}>{data.industryServices}</div>
                                                                                    ))}
                                                                                    </div>
                                                                                    :
                                                                                    <div>{selectedOfferingTags?.map((data, i) => (
                                                                                        <div className={`bg-signupBgColor mr-4 mt-3.5 pt-2 inline-flex w-auto rounded-full justify-center border-b-4 border-gray-900 px-6 py-2 text-sm font-medium`}>{data.industryServices}</div>
                                                                                    ))}
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            pageNo == 2
                                                                                ?
                                                                                <div className="text-base text-semibold">
                                                                                    <span className='font-medium'>Overall rating</span>
                                                                                    {rating == 1 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                                        : (rating == 2 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                                            : (rating == 3 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                                                : (rating == 4 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                                                    : <div className='flex flex-row justify-center items-center'> <img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" />
                                                                                                    </div>
                                                                                                )))}

                                                                                    <div className='grid grid-cols-2 gap-4 mt-3'>
                                                                                        <div className='flex flex-col items-center justify-center gap-2 border-r-2 border-gray-800'>
                                                                                            <div className='text-black font-medium text-base'>Which factor impressed you the most?</div>
                                                                                            <div className={`text-black text-center font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>

                                                                                                {clientOrVendor == 1 ?
                                                                                                    <> {formData?.positiveReviewComment == 1 ? "Delivery Management" : (formData?.positiveReviewComment == 2 ? "Contract Management" : (formData?.positiveReviewComment == 3 ? "Vendor Management" : (formData?.positiveReviewComment == 4 ? "Stakeholder Management" : formData?.positiveReviewComment == 5 ? "Cybersecurity Managament" : "Resource Management")))}
                                                                                                    </> : <>
                                                                                                        {formData?.positiveReviewComment == 1 ? "Requirement" : (formData?.positiveReviewComment == 2 ? "Processes" : (formData?.positiveReviewComment == 3 ? "Financial" : (formData?.positiveReviewComment == 4 ? "Stakeholder Management" : formData?.positiveReviewComment == 5 ? "Communication" : "Quality Check")))}
                                                                                                    </>}
                                                                                            </div>
                                                                                            <div className='mt-5 text-black font-medium text-base'>Which factor should the firm improve in?</div>

                                                                                            <div className={`text-black text-center font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                                                {clientOrVendor == 1 ?
                                                                                                    <>
                                                                                                        {formData?.improvementReviewComment == 1 ? "Delivery Management" : (formData?.improvementReviewComment == 2 ? "Contract Management" : (formData?.improvementReviewComment == 3 ? "Vendor Management" : (formData?.improvementReviewComment == 4 ? "Stakeholder Management" : formData?.improvementReviewComment == 5 ? "Cybersecurity Managament" : "Resource Management")))}
                                                                                                    </> : <>
                                                                                                        {formData?.improvementReviewComment == 1 ? "Requirement" : (formData?.improvementReviewComment == 2 ? "Processes" : (formData?.improvementReviewComment == 3 ? "Financial" : (formData?.improvementReviewComment == 4 ? "Stakeholder Management" : formData?.improvementReviewComment == 5 ? "Communication" : "Quality Check")))}

                                                                                                    </>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='flex flex-col items-center justify-center gap-2'>
                                                                                            <div className='text-black font-medium text-base'>{clientOrVendor == 1 ? 'Which service impressed you the most?' : 'Which service did client utilize the most?'}</div>
                                                                                            {formData?.serviceImpressed && <div className={`text-black text-center font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>

                                                                                                {
                                                                                                    selectedOfferingTags.find(item => item.id == formData.serviceImpressed)?.industryServices
                                                                                                }
                                                                                            </div>}
                                                                                            <div className='mt-5 text-black font-medium text-base'>{clientOrVendor == 1 ? 'Which service should the firm improve in?' : 'Which service did client utilize the least'}</div>

                                                                                            {formData?.serviceImprove && <div className={`text-black text-center font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black ${clientOrVendor == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                                                {
                                                                                                    selectedOfferingTags.find(item => item.id == formData?.serviceImprove)?.industryServices
                                                                                                }
                                                                                            </div>}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="text-base text-semibold">
                                                                                        <div className='text-black font-medium text-base'>How likely are you to recommend this company to others?</div>
                                                                                        <div className='my-6 flex items-center gap-2'>
                                                                                            <div className='border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer bg-black'>
                                                                                                <div className={`absolute left-1/2 transform -translate-x-1/2 ${recommendTheCompanyToOthers == 1 ? 'top-4' : recommendTheCompanyToOthers == 2 ? 'top-2' : recommendTheCompanyToOthers == 3 ? '-top-3' : recommendTheCompanyToOthers == 4 ? '-top-8' : '-top-10'}`}>
                                                                                                    <svg width="22" height="28" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className='text-gray-600'>
                                                                                                {recommendTheCompanyToOthers == 1 ? "Very Unlikely" : (recommendTheCompanyToOthers == 2 ? "Unlikely" : (recommendTheCompanyToOthers == 3 ? "Neutral" : (recommendTheCompanyToOthers == 4 ? "Likely" : "Highly Likely")))}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className='mt-10 text-black font-medium text-base'>How likely are you to work again with {companyName}?</div>
                                                                                        <div className='my-6 flex items-center gap-2'>
                                                                                            <div className='border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer bg-black'>
                                                                                                <div className={`absolute left-1/2 transform -translate-x-1/2 ${workAgainWithCompany == 1 ? 'top-4' : workAgainWithCompany == 2 ? 'top-2' : workAgainWithCompany == 3 ? '-top-3' : workAgainWithCompany == 4 ? '-top-8' : '-top-10'}`}>
                                                                                                    <svg width="22" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className='text-gray-600'>
                                                                                                {workAgainWithCompany == 1 ? "Very Unlikely" : (workAgainWithCompany == 2 ? "Unlikely" : (workAgainWithCompany == 3 ? "Neutral" : (workAgainWithCompany == 4 ? "Likely" : "Highly Likely")))}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='mt-10 text-black font-semibold text-base'>Additional thoughts</div>
                                                                                        <div className='mt-1 text-black text-xs xl:text-sm'>{formData?.review}</div>
                                                                                    </div>
                                                                                </>
                                                                    }

                                                                </div>
                                                            </div>


                                                            <div className=' flex flex-row gap-6 justify-end py-2 items-center absolute bottom-2 right-10'>
                                                                <div className='relative max-w-max'>
                                                                    <button className={`border-2 py-2 px-5 bg-lightgreen z-20 relative border-bermuda rounded flex items-center gap-2`}
                                                                        onClick={postReview}>
                                                                        <span>
                                                                            Publish
                                                                        </span>
                                                                        <span>
                                                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6.26603 15.6371L0.350598 9.54222C0.126098 9.3104 0 8.99619 0 8.6686C0 8.34101 0.126098 8.0268 0.350598 7.79497L1.06927 7.05308C1.29413 6.82163 1.59889 6.69163 1.91663 6.69163C2.23438 6.69163 2.53915 6.82163 2.76401 7.05308L7.06404 11.4835L17.1909 0.388426C17.4088 0.150052 17.7096 0.0105617 18.0272 0.000575418C18.3447 -0.00941082 18.6532 0.110923 18.8848 0.335162L19.6228 1.05327C19.8543 1.27797 19.9898 1.58824 19.9994 1.91587C20.0091 2.24351 19.8922 2.56171 19.6745 2.80052L7.98937 15.6096C7.87935 15.7305 7.74687 15.8273 7.59981 15.8944C7.45276 15.9614 7.29414 15.9973 7.13337 15.9999C6.9726 16.0024 6.81296 15.9716 6.66396 15.9093C6.51496 15.847 6.37963 15.7545 6.26603 15.6371Z" fill="black" />
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                    <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-1 translate-y-1 z-10'>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <div className='p-6 relative overflow-hidden w-full max-w-3xl mx-auto'>
                                                            <img src={reviewImage} className="w-full h-full object-cover" />
                                                            <div className='absolute top-1/2 transform -translate-y-1/2 left-20'>
                                                                <div className=' bg-white font-semibold text-3xl max-w-max p-2'>Thanks for review!</div>
                                                                <div className='my-8 w-full max-w-max text-center bg-white p-2 font-semibold text-3xl'>Your review will be posted shortly.</div>
                                                                <div className='mt-4 p-2'>
                                                                    <div className="z-[100] relative rounded bg-bermuda w-40 h-10">
                                                                        <div className="z-50 bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                                                                        </div>
                                                                        <button data-modal-hide="popup-modal4" className={`border-2 z-[60] font-semibold focus:outline-none bg-white absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded`}
                                                                            onClick={() => { setIsReview(0); closePopUp(); }}
                                                                        >Continue...</button>
                                                                    </div></div>
                                                            </div>
                                                        </div>
                                }
                            </div>

                            <div className='flex items-center justify-between px-10 py-7 absolute bottom-0 w-full right-0 left-0'>
                                <div>
                                    {(isReview > 1 && isReview < 6) &&
                                        <div className='relative border-2 border-bermuda bg-lightgreen rounded-md'>
                                            <svg onClick={() => setIsReview(isReview - 1)} className="cursor-pointer bg-lightpeach p-1 w-10 h-10 rounded-md border-2 border-bermuda transform -translate-x-1 -translate-y-1 scale-105" viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.0059 13C37.8343 13 38.5059 12.3284 38.5059 11.5C38.5059 10.6716 37.8343 10 37.0059 10V13ZM0.930916 10.4393C0.345131 11.0251 0.345131 11.9749 0.930916 12.5607L10.4769 22.1066C11.0626 22.6924 12.0124 22.6924 12.5982 22.1066C13.184 21.5208 13.184 20.5711 12.5982 19.9853L4.1129 11.5L12.5982 3.01472C13.184 2.42893 13.184 1.47919 12.5982 0.893398C12.0124 0.307611 11.0626 0.307611 10.4769 0.893398L0.930916 10.4393ZM37.0059 10L1.99158 10V13L37.0059 13V10Z" fill="black" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div>
                                    {isReview < 5 && <div className='relative border-2 border-bermuda bg-lightpeach rounded-md'>
                                        <svg onClick={() => setIsReview(isReview + 1)} className="cursor-pointer bg-lightgreen p-1 w-10 h-10 rounded-md border-2 border-bermuda transform -translate-x-1 -translate-y-1 scale-105" viewBox="0 0 39 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.99219 10C1.16376 10 0.492188 10.6716 0.492188 11.5C0.492188 12.3284 1.16376 13 1.99219 13V10ZM38.0671 12.5607C38.6529 11.9749 38.6529 11.0251 38.0671 10.4393L28.5212 0.893398C27.9354 0.307611 26.9857 0.307611 26.3999 0.893398C25.8141 1.47919 25.8141 2.42893 26.3999 3.01472L34.8852 11.5L26.3999 19.9853C25.8141 20.5711 25.8141 21.5208 26.3999 22.1066C26.9857 22.6924 27.9354 22.6924 28.5212 22.1066L38.0671 12.5607ZM1.99219 13H37.0065V10H1.99219V13Z" fill="black" />
                                        </svg>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >

        </>
    )
}

export default ReviewPopUp