// import React from 'react';
// import {
//   Chart as ChartJS,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Pie } from 'react-chartjs-2';

// ChartJS.register(
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend
// );

// export default function ClientPieChart({ items }) {
//   console.log("item", items)
//   const data = {
//     labels: items.map((data) => data.industryServices),
//     datasets: [
//       {
//         data: items.map((data) => data.offeringTagCount),
//         backgroundColor: '#99CED5',
//         borderColor: '#4A9FAB',
//         borderWidth: 1,
//       },
//     ],
//   };
//   return <Pie data={data}
//     options={{
//       plugins: {
//         legend: { display: false }
//       }
//     }} />;
// }
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

export default function PolarAreaChart({ items }) {
    const colors = ["#165DFF","#14C9C9", '#04C800', '#98698E', '#996A68', '#F7BA1E','#DE16FF','#DBFF00','#69749D','#759E77']
    const data = {
        labels: items.map((data) => data.industryServices),
        datasets: [
            {
                data: items.map((data) => data.offeringTagCount),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return <PolarArea data={data}
        options={{
            scales: {
                r: {
                  ticks: {
                    display: false // Hides the labels in the middel (numbers)
                  }
                }
              },
            plugins: {
                legend: { display: false }
            }
        }} 
        />
}