import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './slices/counterSlice'
import adminReducer from './slices/adminSlice'
import companyReducer from './slices/companySlice'
import reviewReducer from './slices/reviewSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    admin: adminReducer,
    company: companyReducer,
    review: reviewReducer
  },
})