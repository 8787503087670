import moment from 'moment';
import React, { useEffect, useState } from 'react'
import bgrating from '../../images/rating-bg.svg';
import { useQuery } from 'react-query';
import { getProjects } from '../../services/service'
import ProjectReviewDetail from '../Project/ProjectReviewDetail';
const colorsbg = ["lightviolet", "babyblue", "lightpink", "lightgreen", "babycream"]

function Projects({ companyDetails, specificCompany, setSpecificCompany }) {
    const { isLoading, data: ProjectRes } = useQuery('getProjects', () => getProjects(companyDetails?.companyName), { refetchOnWindowFocus: false, retryDelay: 10000 })

    const [type, setType] = useState(2)

    useEffect(() => {
        window.scrollTo({ top: 800 })
    }, [])

    return (
        <>
            <div className='flex items-center w-full justify-between px-14'>
                <h1 className='text-2xl font-semibold'>Project Completed</h1>
                <div>
                    <button className={`text-xl font-medium border-2 border-r-0 border-bermuda py-2 px-6 rounded-lg rounded-r-none ${type == 1 ? 'bg-lightviolet' : 'bg-white'}`} onClick={() => setType(1)}>As Client</button>
                    <button className={`text-xl font-medium border-2 border-bermuda py-2 px-6 rounded-lg rounded-l-none ${type == 2 ? 'bg-lightblue' : 'bg-white'}`} onClick={() => setType(2)}>As Vendor</button>
                </div>
            </div>
            {(specificCompany?.name && specificCompany?.id) && <div className='px-14 flex items-center gap-6'>
                <h1 className='text-lg'>Showing <span className='font-medium'>"{specificCompany?.name}"</span> projects</h1>
                <div className='bg-bermuda rounded-lg max-w-max'>
                    <button onClick={() => setSpecificCompany({})} className='bg-lightpink border-2 border-bermuda py-2 px-5 rounded-lg transform -translate-x-1 -translate-y-1 text-sm'>All Projects</button>
                </div>
            </div>}

            {
                isLoading ?
                    <div className='w-full min-h-[250px] grid place-items-center'>
                        <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                        </div>
                    </div>
                    :
                    <>
                        {
                            type == 1 ?
                                !ProjectRes?.data?.clientProjects?.filter(item => specificCompany?.id ? item?.vendorId == specificCompany?.id : true)?.length ? <h1 className='capitalize w-full text-3xl mt-10 text-center font-medium text-gray-300'>No Project Available</h1> : <></>
                                :
                                type == 2 ?
                                    !ProjectRes?.data?.vendorProjects?.filter(item => specificCompany?.id ? item?.companyId == specificCompany?.id : true)?.length ? <h1 className='capitalize w-full text-3xl mt-10 text-center font-medium text-gray-300'>No Project Available</h1> : <></>
                                    :
                                    <></>
                        }
                    </>
            }

            <section className='grid grid-cols-2 gap-4 py-5 px-14 min-h-[250px]'>
                {
                    type == 1 ?
                        ProjectRes?.data?.clientProjects?.filter(item => specificCompany?.id ? item?.vendorId == specificCompany?.id : true)?.map((data, i) => <ClientProject key={i} data={data} type={type} company={companyDetails} />)
                        :
                        ProjectRes?.data?.vendorProjects?.filter(item => specificCompany?.id ? item?.companyId == specificCompany?.id : true)?.map((data, i) => <VendorProject key={i} data={data} type={type} company={companyDetails} />)

                }
            </section>
        </>
    )
}

export default Projects

const ClientProject = ({ data, company, type }) => {

    const [active, setActive] = useState(false);

    let ratingData = data?.allRatings?.reduce((acc, crr) => {
        const temp = acc || crr
        temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
        return temp
    }, null)

    data.avgRating = Number(ratingData?.avgRating / data?.allRatings?.length).toFixed(1);

    let offeringTags = data?.offeringTags.map(item => item?.industryServices)

    return (
        <>
            {active ? <ProjectReviewDetail type={type} reviewData={data} vendors={data.vendors} setActive={setActive} /> : <></>}

            <div className='p-4 mr-3 border border-gray-600 shadow rounded-xl bg-white'>
                <div className='relative'><div className='text-gray-400 absolute -top-2 right-14'>{moment(data?.createdAt).format('DD-MMM-YYYY')}</div>
                    <img src={bgrating} className="z-2 absolute -top-4 right-1 w-8" />
                    <div className='absolute -top-3 right-2 text-white'>{isNaN(data?.avgRating) ? Number(1.0).toFixed(1) : data?.avgRating}</div>
                </div>
                <div className='flex items-center gap-4 text-sm'>
                    {/* <div className={`border border-bermuda w-14 h-14 rounded-lg ${data?.type == 1 ? 'bg-lightviolet' : 'bg-lightblue'}`}> */}
                    <div className='w-14 h-14 rounded-lg transform -translate-x-0.5 -translate-y-0.5'>
                        <img src={data?.logo} alt="" />
                    </div>
                    {/* </div> */}
                    <div>
                        <h1 className={`text-lg font-semibold ${data?.confidential ? 'text-gray-500' : ''}`}>{data?.confidential ? 'Confidential' : data.projectName}</h1>
                        <h1 className='font-semibold'>{data?.company?.companyName}, {data?.company?.companyCountry || data?.company?.headQuartered}</h1>
                        <p className='text-sm text-gray-500'>{data?.company?.sectorOfIndustry} | {data?.company?.typeOfIndustry}</p>
                    </div>
                </div>
                <p className='p-3 max-w-lg mx-auto text-center text-sm h-20 overflow-y-hidden'>{data?.confidential ? <span className='text-gray-500 italic text-base'>This is confidential</span> : data?.description}</p>

                <h1 className='font-medium mb-2'>Services utilized in project</h1>
                <div className='mb-4 flex items-center flex-wrap gap-4 justify-center h-24 overflow-y-hidden'>
                    {
                        offeringTags?.length ?
                            offeringTags?.map((tag, i) => (tag &&
                                <div key={i} className={`rounded-xl border-b-4 border-black text-center text-xs py-2 px-4 font-medium bg-${colorsbg[Math.abs((i - colorsbg.length) % colorsbg.length)]}`}>
                                    {tag}
                                </div>
                            ))
                            :
                            <h1 className='text-gray-600'>No Services Utilized</h1>
                    }
                </div>


                <div className='relative flex items-center justify-between pt-2'>
                    <div className='text-black font-medium'>
                        <h1 className='text-lg'> Project Duration: {data?.duration} month(s)</h1>
                        <h1> Project Cost: ${data?.cost}</h1>
                    </div>
                    <div className=''>
                        <div className="-mt-2 relative rounded-lg bg-bermuda w-32 h-10">
                            <div className="bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                            </div>
                            <button onClick={() => setActive(true)} className={`border-2 rounded-lg focus:outline-none bg-blueshade text-xs font-medium absolute top-0 transform -translate-x-1 -translate-y-1 left-0 w-full h-full border-bermuda`}>View Vendors</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const VendorProject = ({ data, company, type }) => {

    const [active, setActive] = useState(false);
    let ratingData = data?.allRatings?.reduce((acc, crr) => {
        const temp = acc || crr
        temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
        return temp
    }, null)

    data.avgRating = Number(ratingData?.avgRating / data?.allRatings?.length).toFixed(1)
    let offeringTags = [...new Set(data?.offeringTags.map(item => item?.industryServices))]

    return (
        <>
            {active ? <ProjectReviewDetail type={type} reviewData={data} vendors={data.vendors} setActive={setActive} /> : <></>}

            <div className='p-4 mr-3 border border-gray-600 shadow rounded-xl bg-white'>
                <div className='relative'><div className='text-gray-400 absolute -top-2 right-14'>{moment(data?.createdAt).format('DD-MMM-YYYY')}</div>
                    <img src={bgrating} className="z-2 absolute -top-4 right-1 w-8" />
                    <div className='absolute -top-3 right-2 text-white'>{isNaN(data?.avgRating) ? Number(1.0).toFixed(1) : data?.avgRating}</div>
                </div>
                <div className='flex items-center gap-4 text-sm'>
                    {/* <div className={`border border-bermuda w-14 h-14 rounded-lg ${data?.type == 1 ? 'bg-lightviolet' : 'bg-lightblue'}`}> */}
                    <div className='w-14 h-14 rounded-lg transform -translate-x-0.5 -translate-y-0.5'>
                        <img src={data?.logo} alt="" />
                    </div>
                    {/* </div> */}
                    <div>
                        <h1 className={`text-lg font-semibold ${data?.confidential ? 'text-gray-500' : ''}`}>{data?.confidential ? 'Confidential' : data.projectName}</h1>
                        <h1 className='font-semibold'>{data?.company?.companyName}, {data?.company?.companyCountry || data?.company?.headQuartered}</h1>
                        <p className='text-sm text-gray-500'>{data?.company?.sectorOfIndustry} | {data?.company?.typeOfIndustry}</p>
                    </div>
                </div>
                <p className='p-3 max-w-lg mx-auto text-center text-sm h-20 overflow-y-hidden'>{data?.confidential ? <span className='text-gray-500 italic text-base'>This is confidential</span> : data?.description}</p>

                <h1 className='font-medium mb-2'>Services utilized in project</h1>
                <div className='mb-4 flex items-center flex-wrap gap-4 justify-center h-24 overflow-y-hidden'>
                    {
                        offeringTags?.length ?
                            offeringTags?.map((tag, i) => (tag &&
                                <div key={i} className={`rounded-xl border-b-4 border-black text-center text-xs py-2 px-4 font-medium bg-${colorsbg[Math.abs((i - colorsbg.length) % colorsbg.length)]}`}>
                                    {tag}
                                </div>
                            ))
                            :
                            <h1 className='text-gray-600'>No Services Utilized</h1>
                    }
                </div>


                <div className='relative flex items-center justify-between pt-2'>
                    <div className='text-black font-medium'>
                        <h1 className='text-lg'> Project Duration: {data?.duration} month(s)</h1>
                        <h1> Project Cost: ${data?.cost}</h1>
                    </div>
                    <div className=''>
                        <div className="-mt-2 relative rounded-lg bg-bermuda w-32 h-10">
                            <div className="bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                            </div>
                            <button onClick={() => setActive(true)} className={`border-2 rounded-lg focus:outline-none bg-blueshade text-xs font-medium absolute top-0 transform -translate-x-1 -translate-y-1 left-0 w-full h-full border-bermuda`}>View Reviews</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}