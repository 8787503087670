import React, { useEffect, useState } from 'react'
import { DoubleLineChart } from '../Report/DoubleLineChart'
import DynamicRadarChart from '../Charts/DynamicRadarChart'
import { DoughnutChart } from '../Charts/DoughnutChart'
import { DoubleBarChart } from '../Report/DoubleBarChart'
import ReviewStar from '../../images/reviewstar.svg'
import { useQuery } from 'react-query'
import { getCompanySearch, getServices, industryTypes, leaderBoard, reportClientSplit, reportClientsAdded, reportFactorImpression, reportFactorSplit, reportGlobe, reportProjectOverView, reportServiceSplit, reportVendorsAdded } from '../../services/service'
import { useSelector } from 'react-redux'
import { selectAdminDetails } from '../../slices/adminSlice'
import { BarChart } from '../Charts/BarChart'
import { PieChart } from '../Charts/PieChart'
import { openLink } from '../../services/constant'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import geoUrl from '../../services/world-country.json'

const colors = ['#BDF0BC', "#EFB9FF", "#FFE9A7", "#B3EDF3", "#FFB9C7"]

let clientArr = [
    { id: 1, typeOfReview: "Delivery Mng." },
    { id: 2, typeOfReview: "Contract Mng." },
    { id: 3, typeOfReview: "Vendor Mng." },
    { id: 4, typeOfReview: "Stakeholder Mng." },
    { id: 5, typeOfReview: "Cybersec. Mng." },
    { id: 6, typeOfReview: "Resource Mng." },
]
let vendorArr = [
    { typeOfReview: 'Requirements', rating: 1 },
    { typeOfReview: 'Processes', rating: 1 },
    { typeOfReview: 'Financial', rating: 1 },
    { typeOfReview: 'Stakeholder Mng.', rating: 1 },
    { typeOfReview: 'Communication', rating: 1 },
    { typeOfReview: 'Quality check', rating: 1 }
]


const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const days = Array.from({ length: 30 }, (_, i) => i + 1)

function ReportHome() {
    const [filter, setFilter] = useState({
        period: 'month',
        type: '',
        sector: ''
    });
    const adminInfo = useSelector(selectAdminDetails)
    const { data: serviceRes } = useQuery('getServices', getServices, { staleTime: 6000000, retry: false, refetchOnWindowFocus: false })

    const { data: IndustryTypeRes } = useQuery('industryTypes', industryTypes, { staleTime: 60000, refetchOnWindowFocus: false, refetchOnWindowFocus: false })

    let { data: SearchRes } = useQuery('getCompanySearch', getCompanySearch, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    const [globeFilter, setGlobeFilter] = useState(false);


    const { isLoading: ProjectOverviewLoading, isError: ProjectOverviewError, data: ProjectOverviewRes, refetch: refetch1 } = useQuery('reportProjectOverView', () => reportProjectOverView(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: FactorSplitLoading, isError: FactorSplitError, data: FactorSplitRes, refetch: refetch2 } = useQuery('reportFactorSplit', () => reportFactorSplit(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: FactorImpressionLoading, isError: FactorImpressionError, data: FactorImpressionRes, refetch: refetch3 } = useQuery('reportFactorImpression', () => reportFactorImpression(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: ClientSplitLoading, isError: ClientSplitError, data: ClientSplitRes, refetch: refetch4 } = useQuery('reportClientSplit', () => reportClientSplit(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: ServiceSplitLoading, isError: ServiceSplitError, data: ServiceSplitRes, refetch: refetch5 } = useQuery('reportServiceSplit', () => reportServiceSplit(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: ClientsAddedLoading, isError: ClientsAddedError, data: ClientsAddedRes, refetch: refetch6 } = useQuery('reportClientsAdded', () => reportClientsAdded(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: VendorsAddedLoading, isError: VendorsAddedError, data: VendorsAddedRes, refetch: refetch7 } = useQuery('reportVendorsAdded', () => reportVendorsAdded(`period=${filter.period}&type=${filter.type}&sector=${filter.sector}`), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: LeaderBoardLoading, isError: LeaderBoardError, data: LeaderBoardRes, refetch: refetch8 } = useQuery('leaderBoard', () => leaderBoard(), { enabled: false, refetchOnWindowFocus: false, })
    const { isLoading: globeLoading, isError: globeError, data: GlobeRes, refetch: refetch9 } = useQuery('reportGlobe', () => reportGlobe(globeFilter ? `period=${filter.period}&type=${filter.type}&sector=${filter.sector}` : ''), { enabled: false, refetchOnWindowFocus: false, })

    // useEffect(() => {
    //     if (adminInfo)
    //         setFilter({ ...filter, type: adminInfo?.typeOfIndustry || 1, sector: adminInfo?.industrySector })
    // }, [adminInfo])

    useEffect(() => {
        if (adminInfo) {
            refetch1();
            refetch2();
            refetch3();
            refetch4();
            refetch5();
            refetch6();
            refetch7();
            refetch8();
            // refetch9();
        }
    }, [filter, adminInfo])

    useEffect(() => {
        refetch9();
    }, [globeFilter])

    return (
        <>
            <div className='rounded-xl flex items-center gap-6 max-w-max px-6 py-1 mt-6 ml-5 2xl:ml-10' style={{ background: 'rgb(222,255,221)', color: '#008C3D' }}>
                <div className='flex items-center gap-3 cursor-pointer group py-2 px-8'>
                    <span className='text-gray-500 font-light'>Period</span>
                    <div className='flex items-center gap-2 font-medium relative'>
                        <span className='capitalize min-w-max'>{filter.period}ly</span>
                        <svg className='flex-shrink-0' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.69887 11.7801C7.65779 11.74 7.48213 11.5889 7.33763 11.4481C6.42883 10.6228 4.94133 8.4699 4.48729 7.34305C4.41433 7.17192 4.25992 6.73926 4.25 6.5081C4.25 6.28659 4.301 6.07544 4.40442 5.87394C4.54892 5.62277 4.77629 5.42127 5.04475 5.31087C5.23104 5.23979 5.7885 5.12938 5.79842 5.12938C6.40829 5.01898 7.39925 4.95825 8.49433 4.95825C9.53771 4.95825 10.4883 5.01898 11.1074 5.10937C11.1173 5.11972 11.81 5.23013 12.0473 5.35089C12.4808 5.57239 12.75 6.00505 12.75 6.46808V6.5081C12.7394 6.80965 12.4702 7.4438 12.4603 7.4438C12.0055 8.50992 10.591 10.6132 9.65104 11.4585C9.65104 11.4585 9.4095 11.6966 9.25863 11.8001C9.04187 11.9615 8.77342 12.0416 8.50496 12.0416C8.20533 12.0416 7.92625 11.9512 7.69887 11.7801Z" fill="#008C3D" />
                        </svg>

                        <div className='bg-white rounded-lg text-gray-600 p-3 text-sm space-y-2 capitalize font-normal absolute top-6 right-0 w-full z-20 custom-shadow hidden group-hover:inline-block'>
                            <h1 className='cursor-pointer min-w-max hover:text-green-700' onClick={() => setFilter({ ...filter, period: 'week' })}>Weekly</h1>
                            <h1 className='cursor-pointer min-w-max hover:text-green-700' onClick={() => setFilter({ ...filter, period: 'month' })}>Monthly</h1>
                            {/* <h1 className='cursor-pointer min-w-max hover:text-green-700' onClick={() => setFilter({ ...filter, period: 'year' })}>Monthly</h1> */}
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-3 cursor-pointer group py-2 px-8 border-l-2 border-r-2 border-green-600 relative'>
                    <span className='text-gray-500 font-light'>Sector</span>
                    <div className='flex items-center gap-2 font-medium min-w-[200px]'>
                        <span className='capitalize flex-grow'>{filter.sector ? serviceRes?.data?.services[filter.sector - 1]?.sectorOfIndustry : 'All'}</span>
                        <svg className='flex-shrink-0' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.69887 11.7801C7.65779 11.74 7.48213 11.5889 7.33763 11.4481C6.42883 10.6228 4.94133 8.4699 4.48729 7.34305C4.41433 7.17192 4.25992 6.73926 4.25 6.5081C4.25 6.28659 4.301 6.07544 4.40442 5.87394C4.54892 5.62277 4.77629 5.42127 5.04475 5.31087C5.23104 5.23979 5.7885 5.12938 5.79842 5.12938C6.40829 5.01898 7.39925 4.95825 8.49433 4.95825C9.53771 4.95825 10.4883 5.01898 11.1074 5.10937C11.1173 5.11972 11.81 5.23013 12.0473 5.35089C12.4808 5.57239 12.75 6.00505 12.75 6.46808V6.5081C12.7394 6.80965 12.4702 7.4438 12.4603 7.4438C12.0055 8.50992 10.591 10.6132 9.65104 11.4585C9.65104 11.4585 9.4095 11.6966 9.25863 11.8001C9.04187 11.9615 8.77342 12.0416 8.50496 12.0416C8.20533 12.0416 7.92625 11.9512 7.69887 11.7801Z" fill="#008C3D" />
                        </svg>

                        <div className='bg-white rounded-lg text-gray-600 p-3 text-sm space-y-2 capitalize font-normal absolute top-8 right-8 w-full z-20 custom-shadow hidden group-hover:inline-block max-w-max max-h-80 overflow-y-auto'>
                            <h1 onClick={() => setFilter({ ...filter, sector: '' })} className='cursor-pointer hover:text-green-700'>All</h1>
                            {
                                serviceRes?.data?.services?.map(item => <h1 key={item.id} onClick={() => setFilter({ ...filter, sector: item.id })} className='cursor-pointer hover:text-green-700'>{item.sectorOfIndustry}</h1>)
                            }
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-3 cursor-pointer group py-2 px-8 relative'>
                    <span className='text-gray-500 font-light'>Type</span>
                    <div className='flex items-center gap-2 font-medium'>
                        <span className='capitalize flex-grow'>{filter.type ? IndustryTypeRes?.data?.industryTypes[filter.type - 1]?.typeOfIndustry : 'All'}</span>
                        <svg className='flex-shrink-0' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.69887 11.7801C7.65779 11.74 7.48213 11.5889 7.33763 11.4481C6.42883 10.6228 4.94133 8.4699 4.48729 7.34305C4.41433 7.17192 4.25992 6.73926 4.25 6.5081C4.25 6.28659 4.301 6.07544 4.40442 5.87394C4.54892 5.62277 4.77629 5.42127 5.04475 5.31087C5.23104 5.23979 5.7885 5.12938 5.79842 5.12938C6.40829 5.01898 7.39925 4.95825 8.49433 4.95825C9.53771 4.95825 10.4883 5.01898 11.1074 5.10937C11.1173 5.11972 11.81 5.23013 12.0473 5.35089C12.4808 5.57239 12.75 6.00505 12.75 6.46808V6.5081C12.7394 6.80965 12.4702 7.4438 12.4603 7.4438C12.0055 8.50992 10.591 10.6132 9.65104 11.4585C9.65104 11.4585 9.4095 11.6966 9.25863 11.8001C9.04187 11.9615 8.77342 12.0416 8.50496 12.0416C8.20533 12.0416 7.92625 11.9512 7.69887 11.7801Z" fill="#008C3D" />
                        </svg>

                        <div className='bg-white rounded-lg text-gray-600 p-3 text-sm space-y-2 capitalize font-normal absolute top-8 right-8 w-full z-20 min-w-max max-w-max custom-shadow hidden group-hover:inline-block'>
                            <h1 onClick={() => setFilter({ ...filter, type: '' })} className='cursor-pointer hover:text-green-700'>All</h1>
                            {
                                IndustryTypeRes?.data?.industryTypes?.map((item, i) => <h1 onClick={() => setFilter({ ...filter, type: item.id })} key={item.id} className='cursor-pointer hover:text-green-700'>{item?.typeOfIndustry}</h1>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <section className='p-5 2xl:px-10 grid grid-cols-10 items-start justify-center gap-4 2xl:gap-8 max-w-full'>
                <div className='col-span-7'>
                    <GlobeMap data={GlobeRes?.data?.data} globeError={globeError} globeLoading={globeLoading} setGlobeFilter={setGlobeFilter} globeFilter={globeFilter} />

                    <div>
                        <ProjectOverview ProjectOverviewRes={ProjectOverviewRes} filter={filter} />
                    </div>

                    <FactorSplit FactorSplitError={FactorSplitError} FactorSplitLoading={FactorSplitLoading} FactorSplitRes={FactorSplitRes} />

                    <div className='mt-10'>
                        <div className='flex flex-col gap-1 pb-4'>
                            <h1 className='text-3xl font-medium'>How Firms Found You</h1>
                            <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
                            <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
                        </div>
                        <div className='flex flex-wrap items-center justify-around w-full gap-4 2xl:gap-8'>
                            {
                                SearchRes?.data?.knowFirms?.map((item, i) => <div key={i} className='rounded-3xl p-4 w-[180px] flex flex-col items-start justify-center gap-5 custom-shadow' style={{ background: colors[Math.abs((i - colors.length) % colors.length)] }}>
                                    <h1 className='text-sm capitalize'>{item.type}</h1>
                                    <div className='text-2xl font-medium flex items-center gap-1'>
                                        <span>{Intl.NumberFormat('en', {
                                            notation: "compact"
                                        }).format(item.count || 0)}</span>
                                        <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>)
                            }
                            <div className='bg-[#EFB9FF] rounded-3xl p-4 w-[180px] flex flex-col items-start justify-center gap-5 custom-shadow'>
                                <h1 className='text-sm capitalize'>Recommendation</h1>
                                <div className='text-2xl font-medium flex items-center gap-1'>
                                    <span>{Intl.NumberFormat('en', {
                                        notation: "compact"
                                    }).format(SearchRes?.data?.data?.recommendation || 0)}</span>
                                    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className='bg-[#BDF0BC] rounded-3xl p-4 w-[180px] flex flex-col items-start justify-center gap-5 custom-shadow'>
                                <h1 className='text-sm capitalize'>Searches</h1>
                                <div className='text-2xl font-medium flex items-center gap-1'>
                                    <span>{Intl.NumberFormat('en', {
                                        notation: "compact"
                                    }).format(SearchRes?.data?.data?.search || 0)}</span>
                                    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className='bg-[#FFB9C7] rounded-3xl p-4 w-[180px] flex flex-col items-start justify-center gap-5 custom-shadow'>
                                <h1 className='text-sm capitalize'>Comparisons</h1>
                                <div className='text-2xl font-medium flex items-center gap-1'>
                                    <span>{Intl.NumberFormat('en', {
                                        notation: "compact"
                                    }).format(SearchRes?.data?.data?.comparison || 0)}</span>
                                    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div >
                    </div>

                    <SectorSplit ServiceSplitError={ServiceSplitError} ServiceSplitLoading={ServiceSplitLoading} ServiceSplitRes={ServiceSplitRes} />

                    <FactorImpression FactorImpressionError={FactorImpressionError} FactorImpressionLoading={FactorImpressionLoading} FactorImpressionRes={FactorImpressionRes} />

                    <ClientSplit ClientSplitError={ClientSplitError} ClientSplitLoading={ClientSplitLoading} ClientSplitRes={ClientSplitRes} />

                </div>

                <div className='flex justify-center flex-wrap gap-6 col-span-3'>

                    <div className='flex items-center justify-around w-full max-w-xs transform translate-y-3'>
                        <div className='flex items-center gap-2'>
                            <div className='w-5 h-5 rounded-full bg-green-500'>
                            </div>
                            <span>You</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <div className='w-5 h-5 rounded-full bg-blue-500'>
                            </div>
                            <span>Industry Avg.</span>
                        </div>
                    </div>
                    <Clients ClientsAddedRes={ClientsAddedRes} filter={filter} />

                    <Vendors VendorsAddedRes={VendorsAddedRes} filter={filter} />

                    <LeaderBoard LeaderBoardRes={LeaderBoardRes} adminInfo={adminInfo} />
                </div>
            </section>
        </>
    )
}

export default ReportHome

const Loader = ({ height = 'min-h-[250px]' }) => {
    return (<div className={`relative w-full ${height} grid place-items-center`}>
        <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
        </div>
    </div>)
}

const Error = ({ height = 'min-h-[250px]', error = 'Unable to get Graph' }) => {
    return (<div className={`relative w-full ${height} grid place-items-center`}>
        <h1 className='text-lg text-red-500 text-center'>{error}</h1>
    </div>
    )
}

const GlobeMap = ({ data, globeLoading, globeError, globeFilter, setGlobeFilter }) => {
    const [type, setType] = useState(1)
    const colorScale = type == 2 ?
        scaleLinear()
            .domain([0, Math.max(data?.vendors?.map(d => d.count))])
            .range(["#BFEBF2B8", " #BFEBF2"])
        :
        scaleLinear()
            .domain([0, Math.max(data?.clients?.map(d => d.count))])
            .range(["#E6BCFAB8", " #E6BCFA"])

    const [tooltipContent, setTooltipContent] = useState('');

    const handleMouseEnter = (geo, current = { value: 'N/A' }) => {
        setTooltipContent(`${geo.properties.name}: ${current.value}`);
    };

    const handleMouseLeave = () => {
        setTooltipContent('');
    };

    return (
        <>
            <div className='flex justify-between mt-10'>
                <div className='flex items-start gap-6'>
                    <div className='flex flex-col gap-1 pb-4'>
                        <h1 className='text-3xl font-medium'>Global Insights</h1>
                        <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
                        <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
                    </div>
                    <div className='border-2 rounded-full border-bermuda bg-white'>
                        <button className={`text-lg font-medium py-2 px-6 rounded-full ${!globeFilter ? 'bg-lightgreen' : 'bg-white'}`} onClick={() => setGlobeFilter(false)}>Overall</button>
                        <button className={`text-lg font-medium py-2 px-6 rounded-full ${globeFilter ? 'bg-lightgreen' : 'bg-white'}`} onClick={() => setGlobeFilter(true)}>Filter</button>
                    </div>
                </div>
                <div>
                    <button className={`text-lg font-medium border-2 border-r-0 border-bermuda py-2 px-6 rounded-full rounded-r-none ${type == 1 ? 'bg-lightviolet' : 'bg-white'}`} onClick={() => setType(1)}>Client</button>
                    <button className={`text-lg font-medium border-2 border-bermuda py-2 px-6 rounded-full rounded-l-none ${type == 2 ? 'bg-lightblue' : 'bg-white'}`} onClick={() => setType(2)}>Vendor</button>
                </div>
            </div>
            <div className='w-full bg-white mapItem relative'>

                {
                    globeLoading ?
                        <Loader />
                        :
                        globeError ?
                            <Error error='Something Went Wrong' />
                            :
                            data ?
                                <ComposableMap projectionConfig={{ scale: 180 }} style={{ width: '90%', height: '400px' }}>
                                    {(
                                        <Geographies geography={geoUrl}>
                                            {({ geographies }) =>
                                                geographies?.map(geo => {
                                                    const cur = type == 2 ? data?.vendors?.find(s => s.country.toLowerCase()?.trim() === geo.properties.name.toLowerCase()) : data?.clients?.find(s => s.country.toLowerCase()?.trim() === geo.properties.name.toLowerCase());
                                                    let value = cur?.count || 0
                                                    return (
                                                        <Geography
                                                            key={geo.rsmKey}
                                                            geography={geo}
                                                            fill={value ? colorScale(value) : '#d9d9d9'}
                                                            stroke='#FFFFFF'
                                                            onMouseEnter={() => handleMouseEnter(geo, { value: value })}
                                                            onMouseLeave={handleMouseLeave}
                                                            cursor={'pointer'}
                                                            style={{
                                                                default: {
                                                                    fill: value ? colorScale(value) : '#d9d9d9',
                                                                    outline: 'none',
                                                                },
                                                                hover: {
                                                                    fill: type == 2 ? '#BFEBF2' : '#E6BCFA',
                                                                    outline: 'none',
                                                                },
                                                                pressed: {
                                                                    fill: type == 2 ? '#BFEBF2B8' : '#E6BCFAB8',
                                                                    outline: 'none',
                                                                },
                                                            }}
                                                        />
                                                    );
                                                })
                                            }
                                        </Geographies>
                                    )}
                                </ComposableMap>
                                : <></>
                }
                {tooltipContent && (
                    <div className={`absolute bottom-7 left-1/2 transform -translate-x-1/2 text-xl text-gray-800 font-medium`}>
                        {tooltipContent}
                    </div>
                )}
                {/* <div>
                </div> */}
            </div>
        </>
    )
}

const ProjectOverview = ({ ProjectOverviewRes, filter }) => {
    return (<>
        <div className='flex flex-col gap-1 pb-4'>
            <h1 className='text-3xl font-medium'>Project overview</h1>
            <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
            <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
        </div>
        <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100'>
            <h1 className='text-xl font-medium'>Project vs No. of Feedback </h1>
            <div className='p-5'>
                <DoubleLineChart labels={filter.period == 'week' ? week : filter.period == 'month' ? months : days}
                    data1={Array(filter.period == 'week' ? 7 : filter.period == 'month' ? 12 : 30).fill(1).map((item, i) => {
                        if (ProjectOverviewRes?.data?.mineProject[`${i + 1}`]) {
                            return ProjectOverviewRes?.data?.mineProject[`${i + 1}`].reviewCount
                        }
                        else {
                            return 0;
                        }
                    })}
                    data2={Array(filter.period == 'week' ? 7 : filter.period == 'month' ? 12 : 30).fill(1).map((item, i) => {
                        if (ProjectOverviewRes?.data?.otherProject[`${i + 1}`]) {
                            return ProjectOverviewRes?.data?.otherProject[`${i + 1}`].reviewCount
                        }
                        else {
                            return 0;
                        }
                    })}
                />
            </div>
        </div>
    </>)
}

const FactorSplit = ({ FactorSplitLoading, FactorSplitError, FactorSplitRes }) => {
    let clientCompareGraph = [
        {
            data: FactorSplitRes?.data?.mineClientRating?.length ? FactorSplitRes?.data?.mineClientRating?.map(item => Number(item.rating) || 1) : [1, 1, 1, 1, 1, 1],
            backgroundColor: 'rgba(22, 92, 255, 0.3)',
            borderColor: 'rgba(22, 92, 255, 1)',
            borderWidth: 1,
        },
        {
            data: FactorSplitRes?.data?.otherClientRating?.length ? FactorSplitRes?.data?.otherClientRating?.map(item => Number(item.rating) || 1) : [1, 1, 1, 1, 1, 1],
            backgroundColor: 'rgba(20, 201, 201, 0.3)',
            borderColor: 'rgba(20, 201, 201, 1)',
            borderWidth: 1,
        }
    ]
    let vendorCompareGraph = [
        {
            data: FactorSplitRes?.data?.mineVendorRating?.length ? FactorSplitRes?.data?.mineVendorRating?.map(item => Number(item.rating) || 1) : [1, 1, 1, 1, 1, 1],
            backgroundColor: 'rgba(22, 92, 255, 0.3)',
            borderColor: 'rgba(22, 92, 255, 1)',
            borderWidth: 1,
        },
        {
            data: FactorSplitRes?.data?.otherVendorRating?.length ? FactorSplitRes?.data?.otherVendorRating?.map(item => Number(item.rating) || 1) : [1, 1, 1, 1, 1, 1],
            backgroundColor: 'rgba(20, 201, 201, 0.3)',
            borderColor: 'rgba(20, 201, 201, 1)',
            borderWidth: 1,
        }
    ]

    return (<div className='mt-10'>
        <div className='flex flex-col gap-1 pb-4'>
            <h1 className='text-3xl font-medium'>Factor Split</h1>
            <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
            <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
        </div>
        <div className='flex items-center justify-around gap-4 2xl:gap-8'>
            <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 w-full max-w-md'>
                {
                    FactorSplitLoading ? <Loader height='min-h-[250px]' />
                        :
                        FactorSplitError ? <Error />
                            :
                            clientCompareGraph && (FactorSplitRes?.data?.mineClientRating?.length || FactorSplitRes?.data?.otherClientRating?.length) ?
                                <div className='flex flex-col items-center justify-center'>
                                    <h1 className='text-2xl font-medium text-center'>Client Factor review</h1>
                                    <DynamicRadarChart width={295} height={240} labels={FactorSplitRes?.data?.mineClientRating?.length ? FactorSplitRes?.data?.mineClientRating?.map(item => item.typeOfReview) : FactorSplitRes?.data?.otherClientRating?.map(item => item.typeOfReview)} dataset={clientCompareGraph} />
                                </div>
                                :
                                <Error error='No comparison available' />
                }
            </div>
            <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 w-full max-w-md'>
                {
                    FactorSplitLoading ? <Loader height='min-h-[250px]' />
                        :
                        FactorSplitError ? <Error />
                            :
                            vendorCompareGraph && (FactorSplitRes?.data?.mineVendorRating?.length || FactorSplitRes?.data?.otherVendorRating?.length) ?
                                <div className='flex flex-col items-center justify-center'>
                                    <h1 className='text-2xl font-medium text-center'>Vendor Factor review</h1>
                                    <div>
                                        <DynamicRadarChart width={295} height={240} labels={FactorSplitRes?.data?.mineVendorRating?.length ? FactorSplitRes?.data?.mineVendorRating?.map(item => item.typeOfReview) : FactorSplitRes?.data?.otherVendorRating?.map(item => item.typeOfReview)} dataset={vendorCompareGraph} />
                                    </div>
                                </div>
                                :
                                <Error error='No comparison available' />
                }
            </div>
        </div>
    </div>)
}

const SectorSplit = ({ ServiceSplitRes, ServiceSplitError, ServiceSplitLoading }) => {
    return (<div className='mt-10'>
        <div className='flex flex-col gap-1 pb-4'>
            <h1 className='text-3xl font-medium'>Sector Split</h1>
            <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
            <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
        </div>
        <div className='flex items-center justify-around gap-4 2xl:gap-8'>
            <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 w-full max-w-md'>
                <h1 className='text-xl font-medium text-center'>Services Usage</h1>
                <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                        ServiceSplitLoading ? <Loader height='min-h-[250px]' />
                            :
                            ServiceSplitError ? <Error />
                                :
                                ServiceSplitRes?.data?.service_usage?.length ?
                                    <DoughnutChart initialData={ServiceSplitRes?.data?.service_usage?.map(item => { return { industryServices: item.industryServices, value: 1 } })} width={220} height={220} />
                                    : <Error error='No Service used' />
                    }
                </div>
            </div>
            <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 w-full max-w-md'>
                <h1 className='text-xl font-medium text-center'>Sectors Served</h1>
                <div className='p-1 py-4 flex-grow flex flex-col items-center justify-center gap-6'>
                    {
                        ServiceSplitLoading ? <Loader height='min-h-[250px]' />
                            :
                            ServiceSplitError ? <Error />
                                :
                                ServiceSplitRes?.data?.sector_served?.length ?
                                    <DoughnutChart initialData={ServiceSplitRes?.data?.sector_served?.map(item => { return { industryServices: item.sectorOfIndustry, value: 1 } })} width={220} height={220} />
                                    : <Error error='No Sector served' />
                    }
                </div>
            </div >
        </div >
    </div>)
}

const FactorImpression = ({ FactorImpressionLoading, FactorImpressionError, FactorImpressionRes }) => {
    return (<div className='mt-10'>
        <div className='flex flex-col gap-1 pb-4'>
            <h1 className='text-3xl font-medium'>Factors impressions</h1>
            <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
            <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
        </div>
        <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100'>
            <h1 className='text-xl font-medium'>Factor Positive Impact</h1>
            <div className='p-5'>
                {
                    FactorImpressionLoading ? <Loader height='min-h-[250px]' />
                        :
                        FactorImpressionError ? <Error />
                            :
                            <DoubleBarChart
                                labels={Object.keys(FactorImpressionRes?.data?.factorImpact || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImpact)?.map(key => key) : []}
                                firstData={Object.keys(FactorImpressionRes?.data?.factorImpact || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImpact)?.map(key => FactorImpressionRes?.data?.factorImpact[key]?.mineCount) : []}
                                secondData={Object.keys(FactorImpressionRes?.data?.factorImpact || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImpact)?.map(key => FactorImpressionRes?.data?.factorImpact[key]?.otherCount) : []}
                            />
                }
            </div>
        </div>
        <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 mt-4'>
            <h1 className='text-xl font-medium'>Factor improvement needed</h1>
            <div className='p-5'>
                {
                    FactorImpressionLoading ? <Loader height='min-h-[250px]' />
                        :
                        FactorImpressionError ? <Error />
                            :
                            <DoubleBarChart
                                labels={Object.keys(FactorImpressionRes?.data?.factorImprove || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImprove)?.map(key => key) : []}
                                firstData={Object.keys(FactorImpressionRes?.data?.factorImprove || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImprove)?.map(key => FactorImpressionRes?.data?.factorImprove[key]?.mineCount) : []}
                                secondData={Object.keys(FactorImpressionRes?.data?.factorImprove || {})?.length ? Object.keys(FactorImpressionRes?.data?.factorImprove)?.map(key => FactorImpressionRes?.data?.factorImprove[key]?.otherCount) : []}
                            />
                }
            </div>
        </div>
    </div>)
}

const ClientSplit = ({ ClientSplitError, ClientSplitLoading, ClientSplitRes }) => {
    return (
        <div className='mt-10'>
            <div className='flex flex-col gap-1 pb-4'>
                <h1 className='text-3xl font-medium'>Client Split</h1>
                <div className='w-60 rounded-3xl bg-bermuda p-0.5'></div>
                <div className='w-32 rounded-3xl bg-bermuda p-0.5'></div>
            </div>
            <div className='flex items-start justify-around gap-4 2xl:gap-8'>
                <div className='bg-white rounded-lg shadow-lg shadow-gray-100 w-full max-w-md'>
                    <div className='flex flex-col items-center justify-center'>
                        <h1 className='text-2xl font-medium text-center my-6'>Client Factor review</h1>
                        {
                            ClientSplitLoading ? <Loader height='min-h-[250px]' />
                                :
                                ClientSplitError ? <Error />
                                    :
                                    ClientSplitRes?.data?.sector_rating?.length
                                        ?
                                        <BarChart width={360} graphData={ClientSplitRes?.data?.sector_rating?.map(item => {
                                            return {
                                                label: item?.sectorOfIndustry,
                                                value: item?.rating
                                            }
                                        })} />
                                        :
                                        <Error error='No review available' />
                        }
                    </div>
                </div>
                <div className='bg-white rounded-lg p-4 shadow-lg shadow-gray-100 w-full max-w-md'>
                    <div className='flex flex-col items-center justify-center gap-4'>
                        <h1 className='text-2xl font-medium text-center mt-2'>Vendor Factor review</h1>
                        {
                            ClientSplitLoading ? <Loader height='min-h-[250px]' />
                                :
                                ClientSplitError ? <Error />
                                    :
                                    ClientSplitRes?.data?.sector_duration?.length ?
                                        <PieChart width={220} height={220} initialData={ClientSplitRes?.data?.sector_duration?.map(item => {
                                            return {
                                                label: item.sectorOfIndustry,
                                                value: item.duration
                                            }
                                        })} />
                                        :
                                        <Error error='No review available' />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const Clients = ({ ClientsAddedRes, filter }) => {
    return (<div className='bg-white rounded-lg p-2 shadow-lg shadow-gray-100 w-full flex flex-col gap-2 max-w-xs'>
        <div className='flex flex-col items-center justify-center gap-2 bg-sky-100 rounded-lg py-1 w-full'>
            <p>clients added in last {filter.period}</p>
            <div className='text-xl font-medium flex items-center gap-1'>
                <span>{ClientsAddedRes?.data?.clients?.length || 0}</span>
                <svg width="25" height="30" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
        <div>
            <h1>By region</h1>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-green-500`} style={{ width: `${Math.round(ClientsAddedRes?.data?.clientByRegion?.length * 100 / (ClientsAddedRes?.data?.clientByRegion?.length + ClientsAddedRes?.data?.otherClientByRegion?.length + 1))}%` }}></div>
                <span>{ClientsAddedRes?.data?.clientByRegion?.length || 0}</span>
            </div>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-blue-500`} style={{ width: `${Math.round(ClientsAddedRes?.data?.otherClientByRegion?.length * 100 / (ClientsAddedRes?.data?.clientByRegion?.length + ClientsAddedRes?.data?.otherClientByRegion?.length + 1))}%` }}></div>
                <span>{ClientsAddedRes?.data?.otherClientByRegion?.length || 0}</span>
            </div>
        </div>
        <div>
            <h1>By Type of Company</h1>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-green-500 `} style={{ width: `${Math.round(ClientsAddedRes?.data?.clientWithType?.length * 100 / (ClientsAddedRes?.data?.clientWithType?.length + ClientsAddedRes?.data?.otherClientWithType?.length + 1))}%` }}></div>
                <span>{ClientsAddedRes?.data?.clientWithType?.length || 0}</span>
            </div>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-blue-500`} style={{ width: `${Math.round(ClientsAddedRes?.data?.otherClientWithType?.length * 100 / (ClientsAddedRes?.data?.clientWithType?.length + ClientsAddedRes?.data?.otherClientWithType?.length + 1))}%` }}></div>
                <span>{ClientsAddedRes?.data?.otherClientWithType?.length || 0}</span>
            </div>
        </div>
    </div>)
}

const Vendors = ({ VendorsAddedRes, filter }) => {
    return (<div className='bg-white rounded-lg p-2 shadow-lg shadow-gray-100 w-full flex flex-col gap-2 max-w-xs'>
        <div className='flex flex-col items-center justify-center gap-2 bg-sky-100 rounded-lg py-1 w-full'>
            <p>vendors added in last {filter.period}</p>
            <div className='text-xl font-medium flex items-center gap-1'>
                <span>{VendorsAddedRes?.data?.vendors?.length || 0}</span>
                <svg width="25" height="30" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.123 6.72913L16.123 30.4791" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.83888 16.308L16.1219 6.72882L24.4062 16.308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
        <div>
            <h1>By region</h1>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-green-500`} style={{ width: `${Math.round(VendorsAddedRes?.data?.vendorByRegion?.length * 100 / (VendorsAddedRes?.data?.vendorByRegion?.length + VendorsAddedRes?.data?.otherVendorByRegion?.length + 1))}%` }}></div>
                <span>{VendorsAddedRes?.data?.vendorByRegion?.length || 0}</span>
            </div>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-blue-500`} style={{ width: `${Math.round(VendorsAddedRes?.data?.otherVendorByRegion?.length * 100 / (VendorsAddedRes?.data?.vendorByRegion?.length + VendorsAddedRes?.data?.otherVendorByRegion?.length + 1))}%` }}></div>
                <span>{VendorsAddedRes?.data?.otherVendorByRegion?.length || 0}</span>
            </div>
        </div>
        <div>
            <h1>By Type of Company</h1>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-green-500`} style={{ width: `${Math.round(VendorsAddedRes?.data?.vendorWithType?.length * 100 / (VendorsAddedRes?.data?.vendorWithType?.length + VendorsAddedRes?.data?.otherVendorWithType?.length + 1))}%` }}></div>
                <span>{VendorsAddedRes?.data?.vendorWithType?.length || 0}</span>
            </div>
            <div className='w-full flex items-center gap-2 text-sm'>
                <div className={`p-0.5 rounded-full bg-blue-500`} style={{ width: `${Math.round(VendorsAddedRes?.data?.otherVendorWithType?.length * 100 / (VendorsAddedRes?.data?.vendorWithType?.length + VendorsAddedRes?.data?.otherVendorWithType?.length + 1))}%` }}></div>
                <span>{VendorsAddedRes?.data?.otherVendorWithType?.length || 0}</span>
            </div>
        </div>
    </div>)
}

const LeaderBoard = ({ LeaderBoardRes, adminInfo }) => {
    return (<div className='bg-white rounded-lg p-2 shadow-lg shadow-gray-100 w-full flex flex-col gap-2 max-w-xs' >

        <div className='flex flex-col gap-1 p mx-auto'>
            <h1 className='text-2xl font-medium'>Leader Board</h1>
            <div className='w-40 rounded-3xl bg-bermuda p-0.5'></div>
            <div className='w-24 rounded-3xl bg-bermuda p-0.5'></div>
        </div>

        {
            LeaderBoardRes?.data?.companies?.map(company => (<div key={company.companyName} className={`mt-2 hover:bg-green-50 p-2 rounded-lg ${adminInfo?.companyName == company?.companyName ? 'bg-green-50' : ''}`}>
                <h1 className='font-medium text-lg text-orange-300 cursor-pointer' onClick={() => openLink(`https://www.cornerqube.com/company/${company?.companyName}`)}>{company?.companyName}</h1>
                <div className='grid grid-cols-3'>
                    <div className='flex flex-col items-center gap-1'>
                        <h1 className='text-sm 2xl:text-base'>Avg Rating</h1>
                        <div className='flex items-center gap-1 font-medium text-green-600'>
                            <img className='w-5 h-5' src={ReviewStar} alt="" />
                            <span>{company?.rating || 0}</span>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <h1 className='text-sm 2xl:text-base'>Type</h1>
                        <div className='flex items-center gap-1 font-medium text-green-600'>
                            <span>{company?.typeOfIndustry}</span>
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-1'>
                        <h1 className='text-sm 2xl:text-base'>Avg Project</h1>
                        <div className='flex items-center gap-1 font-medium text-green-600 text-center'>
                            <span>
                                {Intl.NumberFormat('en', {
                                    notation: "compact"
                                }).format(company?.duration || 0)} months
                            </span>
                        </div>
                    </div>
                </div>
            </div>))
        }
    </div >)
}