import React from 'react'
import NavBar from '../NavBar/NavBar'
import Circles from '../Reusable/Circles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { selectAdminDetails, selectAdminPlan } from '../../slices/adminSlice'
import { useQuery } from 'react-query'
import { getPlans } from '../../services/service'
import usePayment from '../../hooks/usePayment'
import PaymentSuccess from '../Reusable/PaymentSuccess'
import toast from 'react-hot-toast'

const bg = [`bg-[#DCF0B7]`, `bg-[#C7E295]`, `bg-[#BBD887]`, `bg-[#A7C273]`]

function Pricing() {
    const history = useHistory();
    const user = useSelector(selectAdminDetails);
    const adminPlan = useSelector(selectAdminPlan)
    const { data: PlanRes } = useQuery('getPlans', getPlans, { retry: false, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: 600000 })

    const { createOrder, success, setSuccess } = usePayment()

    const closeSuccess = () => {
        setSuccess(false);
        window.location.replace('/');
    }

    const planClick = (item) => {
        if (user && (!adminPlan || adminPlan?.price != 0) && item.price !== 0) {
            if ((user?.userRole?.toLowerCase() == 'subadmin' || user?.userRole?.toLowerCase() == 'admin')) {
                createOrder({ planId: item.id })
            }
            else {
                toast("Only admin and subadmin can upgrade the plan", { id: 'info' })
            }
        }
        else {
            history.push('/login')
        }
    }

    const checkPlan = (i) => {
        if (i == 0) {
            return true
        }
        else if (i == 1) {
            return user?.typeOfIndustry != 0
        }
        else if (i == 2) {
            return ![1, 2].includes(user?.typeOfIndustry)
        }
        else if (i == 3) {
            return ![3, 4].includes(user?.typeOfIndustry)
        }
    }

    return (
        <>
            {success ? <PaymentSuccess {...success} closeSuccess={closeSuccess} /> : <></>}

            <NavBar />

            <div className='flex items-center justify-center p-8'>
                <div className=' bg-cream rounded border-2 border-bermuda max-w-max'>
                    <div className=' bg-lightviolet rounded border-2 border-bermuda transform -translate-x-1 -translate-y-1'>
                        <div className='bg-white rounded border-2 border-bermuda py-2 px-16 transform -translate-x-1 -translate-y-1'>
                            <span className='text-2xl font-bold'>Choose a plan that suits you best</span>
                        </div>
                    </div>
                </div>
            </div>

            <section className='flex justify-center gap-6 2xl:gap-10 pb-10 pt-3 relative z-10'>
                {
                    PlanRes?.data?.data?.map((item, i) => <div key={i} className={`${bg[i]} h-max w-max rounded-lg border-2 border-bermuda relative cursor-pointer`} >
                        <div className='flex flex-col
                         items-center bg-white rounded-lg border-2 border-bermuda pr-6 pl-7 2xl:pl-8 pt-10 transform -translate-x-2 -translate-y-2 xl:w-72 2xl:w-[21rem] min-h-[33rem]'
                            onClick={() => checkPlan(i) ? {} : planClick(item)}
                        >
                            {checkPlan(i) ? <div className='absolute inset-0 bg-gray-900 bg-opacity-30 z-10 cursor-not-allowed'></div> :
                                <></>
                            }
                            <h1 className='text-xl text-center font-medium'>{item.title}</h1>
                            <span className='py-4'>
                                <img src={item.icon} className='h-20' alt="" />
                            </span>
                            <p className='font-medium'>{item.description}</p>
                            <ul className='list-disc py-6 w-full text-sm'>
                                {
                                    item.items.map((list, i) => <li className='list-outside text-gray-800 mt-1' key={i}>{list}</li>)
                                }
                            </ul>

                            {i == 0 ? <ul className='rounded-xl border-2 border-bermuda p-3 pl-7 list-disc text-sm bg-[#FBE9AF] mt-5'>
                                <h1 className='-ml-4 font-medium'>Only during free trial</h1>
                                {[
                                    `Will not appear search results`,
                                    `No reviews provided  to your firm`,
                                    `Can apply for verification after upgrade.`
                                ].map((list, i) => <li className='list-outside text-gray-800 mt-1' key={i}>{list}</li>)
                                }
                            </ul> : <></>}
                        </div>
                        {checkPlan(i) ? <></> : <div className='flex items-center gap-2 p-1.5 cursor-pointer bg-lightviolet max-w-max min-w-max rounded-lg absolute -bottom-16 left-1/2 transform -translate-x-1/2'
                            onClick={() => planClick(item)}
                        >
                            <span className='font-medium'>Eligible Plan</span>
                            <div className='bg-lightgreen py-1.5 px-3 rounded-lg'>Upgrade Now!</div>
                        </div>}
                    </div>)
                }
            </section >
            {user ? <></> : <div className='relative z-10 pt-0 pb-10 grid place-items-center'>
                <div className='bg-[#BDF0BC] rounded max-w-max border-2 border-bermuda -mt-2'>
                    <button className='py-2 px-4 rounded bg-[#B3D27C] border-2 border-bermuda transform -translate-x-1 -translate-y-1 font-medium text-xl' onClick={() => history.push('/signup')}>Sign up Now for free!</button>
                </div>
            </div>
            }
            <Circles />
        </>
    )
}

export default Pricing
